import { ECustomCell, type IStatusColOptions, type ITableColumn } from "@/models/table.model";
import { EWorkloadNodeArchitecture, type IWorkloadListModal, type WorkloadServicePod } from "@/models/workload.model";
import {
  WorkloadSortFilterFields,
  type Connection,
  type HistoryRecord,
  type Workload,
  type Datasource,
  type Environment,
  type WorkloadRequestResources,
} from "@/swagger-models/workloads-service-client";
import { dateUtil } from "@/utils/date.util";
import { capitalizeString } from "@/utils/string.util/string.util";
import { workloadUtil } from "@/utils/workload.util";
import { formatK8sCpuMemory } from "@/utils/format.util/format.util";
import { InternalConnectionType } from "@/swagger-models/assets-service-client";

//connections
export const allConnectionsTableColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: "name",
    label: "Name",
    field: (row: Connection) => row.name,
    sortable: true,
    align: "left",
    customCell: ECustomCell.NAME_COL,
  },
  connectionType: {
    name: "connection-type",
    label: "Connection type",
    field: (row: Connection) => row.connectionType,
    format: (val: InternalConnectionType) => {
      switch (val) {
        case InternalConnectionType.ExternalUrl:
          return "External URL";
        case InternalConnectionType.NodePort:
          return "Node port";
        case InternalConnectionType.ServingPort:
          return "Serving endpoint";
        default:
          return val || "-";
      }
    },
    sortable: true,
    align: "left",
  },
  url: {
    name: "connection-url",
    label: "URL / Node port",
    field: (row: Connection) => row.url,
    sortable: true,
    align: "left",
    customCell: ECustomCell.WORKLOAD_CONNECTION_COL,
  },
};
export const connectionsTableColumns: Array<ITableColumn> = [
  {
    ...allConnectionsTableColumnsMap.name,
    display: true,
  },
  {
    ...allConnectionsTableColumnsMap.connectionType,
    display: true,
  },
  {
    ...allConnectionsTableColumnsMap.url,
    display: true,
  },
];

//workload events history table
const allEventHistoryColumnsMap = {
  createdAt: {
    name: "createdAt",
    label: "Date & Time",
    field: (historyRecord: HistoryRecord) => historyRecord.meta.creationTimestamp,
    align: "left",
    display: true,
    sortable: true,
    format: (createdAt: string) =>
      createdAt && dateUtil.dateAndTimeFormat(new Date(createdAt), { includeMilliseconds: true, includeSeconds: true }),
  },
  event: {
    name: "event",
    label: "Event",
    field: (historyRecord: HistoryRecord) =>
      workloadUtil.isPhaseUpdateRecordType(historyRecord) ? "Status changed" : historyRecord.spec.event?.reason,
    align: "left",
    sortable: true,
    display: true,
  },
  eventType: {
    name: "eventType",
    label: "Event type",
    field: (historyRecord: HistoryRecord) => historyRecord.spec.event?.type || "-",
    align: "left",
    sortable: true,
    display: true,
  },
  eventIssuer: {
    name: "eventIssuer",
    label: "Event issuer",
    field: (historyRecord: HistoryRecord) => historyRecord.spec.event?.source || "-",
    align: "left",
    display: true,
    sortable: true,
  },
  component: {
    name: "component",
    label: "Component",
    field: (historyRecord: HistoryRecord) => historyRecord.spec.event?.involvedObject?.kind || "",
    align: "left",
    display: true,
    sortable: true,
  },
  details: {
    name: "details",
    label: "Details",
    field: (historyRecord: HistoryRecord) =>
      workloadUtil.isPhaseUpdateRecordType(historyRecord)
        ? historyRecord.spec.phaseUpdate?.phase
        : historyRecord.spec.event?.message,
    align: "left",
    display: true,
    sortable: true,
  },
} as Record<string, ITableColumn>;

export const eventHistoryColumns: Array<ITableColumn> = [
  { ...allEventHistoryColumnsMap.createdAt, display: true },
  { ...allEventHistoryColumnsMap.event, display: true },
  { ...allEventHistoryColumnsMap.eventType, display: true },
  { ...allEventHistoryColumnsMap.eventIssuer, display: true },
  { ...allEventHistoryColumnsMap.component, display: true },
  { ...allEventHistoryColumnsMap.details, display: true },
];

//pods modal
export const allWorkloadPodsModalColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: "name",
    label: "Pod",
    field: (pod: WorkloadServicePod) => pod.name,
    sortable: true,
    align: "left",
  },
  status: {
    name: "status",
    label: "Status",
    field: (pod: WorkloadServicePod) => pod,
    sortable: true,
    align: "left",
    format: (pod: WorkloadServicePod): IStatusColOptions | string => workloadUtil.getPodStatusColOptions(pod),
    sort: (podA: WorkloadServicePod, podB: WorkloadServicePod): number => podA.k8sPhase.localeCompare(podB.k8sPhase),
    exportFormat: (pod: WorkloadServicePod) => pod.k8sPhase || "NA",
    filterKey: "status",
    customCell: ECustomCell.STATUS_COL,
  },
  gpuComputeAllocation: {
    name: "gpuComputeAllocation",
    label: "GPU compute allocation",
    field: (pod: WorkloadServicePod): number | null => {
      return pod.allocatedResources?.gpu || null;
    },
    format: (gpuCompute: number | null): number | string => (gpuCompute || gpuCompute === 0 ? gpuCompute : "-"),
    sortable: true,
    align: "left",
  },
  gpuMemoryAllocation: {
    name: "gpuMemoryAllocation",
    label: "GPU memory allocation",
    field: (pod: WorkloadServicePod): string | null | undefined => {
      return pod.allocatedResources?.gpuMemory;
    },
    format: (gpuMemory: string | null): string => (gpuMemory ? formatK8sCpuMemory(gpuMemory) : "-"),
    sortable: true,
    align: "left",
  },
  nodeName: {
    name: "nodeName",
    label: "Node",
    field: (pod: WorkloadServicePod) => pod.nodeName || "-",
    sortable: true,
    align: "left",
  },
  nodePools: {
    name: WorkloadSortFilterFields.Nodepool,
    label: "Node pool",
    field: (pod: WorkloadServicePod) => pod.currentNodePool || "-",
    sortable: true,
    align: "left",
  },
  image: {
    name: "image",
    label: "Image",
    field: (pod: WorkloadServicePod) => pod.containers[0].image,
    sortable: true,
    align: "left",
  },
  created: {
    name: "created",
    label: "Creation time",
    field: (pod: WorkloadServicePod) => pod.createdAt,
    format: (createdAt: string): string => (!createdAt ? "-" : dateUtil.dateAndTimeFormat(new Date(createdAt))),
    sortable: true,
    align: "left",
  },
};

export const workloadPodsModalTableColumns: Array<ITableColumn> = [
  { ...allWorkloadPodsModalColumnsMap.name, display: true },
  { ...allWorkloadPodsModalColumnsMap.status, display: true },
  { ...allWorkloadPodsModalColumnsMap.nodeName, display: true },
  { ...allWorkloadPodsModalColumnsMap.nodePools, display: true },
  { ...allWorkloadPodsModalColumnsMap.image, display: true },
  { ...allWorkloadPodsModalColumnsMap.created, display: true },
  { ...allWorkloadPodsModalColumnsMap.gpuComputeAllocation, display: true },
  { ...allWorkloadPodsModalColumnsMap.gpuMemoryAllocation, display: true },
];

const workloadTypeMap: Record<string, string> = {
  Training: "Training",
  Interactive: "Workspace",
};

//workloads table data
export const allWorkloadColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: WorkloadSortFilterFields.Name,
    label: "Workload",
    field: (workload: Workload) => workload.name,
    sortable: true,
    align: "left",
    customCell: ECustomCell.NAME_COL,
  },
  type: {
    name: WorkloadSortFilterFields.Type,
    label: "Type",
    field: (workload: Workload) => workload.type,
    format: (workloadType: string) => workloadTypeMap[workloadType] || capitalizeString(workloadType),
    sortable: true,
    align: "left",
  },
  preemptible: {
    name: "preemptible",
    label: "Preemptible",
    field: (workload: Workload) => workload.preemptible,
    format: (preemptible: boolean | null | undefined) =>
      preemptible === undefined || preemptible === null ? "-" : preemptible,
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  status: {
    name: WorkloadSortFilterFields.Phase,
    label: "Status",
    field: (workload: Workload) => workload,
    sortable: true,
    align: "left",
    customCell: ECustomCell.STATUS_COL,
    format: (workload: Workload): IStatusColOptions | string =>
      workloadUtil.getWorkloadStatusColOptions(workload, workload.phaseMessage),
    sort: (workloadA: Workload, workloadB: Workload): number => workloadA.phase.localeCompare(workloadB.phase),
    exportFormat: (workload: Workload) => workload.phase || "NA",
    filterKey: "status",
  },
  submittedBy: {
    name: WorkloadSortFilterFields.SubmittedBy,
    label: "Created by",
    field: (workload: Workload) => workload.submittedBy || "-",
    sortable: true,
    align: "left",
  },
  departmentName: {
    name: WorkloadSortFilterFields.DepartmentName,
    label: "Department",
    field: (workload: Workload) => workload.departmentName || "-",
    sortable: true,
    align: "left",
  },
  project: {
    name: WorkloadSortFilterFields.ProjectName,
    label: "Project",
    field: (workload: Workload) => workload.projectName,
    sortable: true,
    align: "left",
  },
  nodePools: {
    name: WorkloadSortFilterFields.Nodepool,
    label: "Node pools",
    field: (workload: Workload): string[] => {
      if (workload.currentNodePools && workload.currentNodePools.length > 0) {
        return workload.currentNodePools;
      }
      if (workload.requestedNodePools?.length === 1) {
        return workload.requestedNodePools;
      }
      return [];
    },
    format: (currentNodePools: string[]): string => (currentNodePools.length > 0 ? currentNodePools.join(",") : "-"),
    sortable: true,
    align: "left",
    hideFilter: false,
  },
  runningPodsVsRequestedPods: {
    name: "runningPodsVsRequestedPods",
    label: "Running / requested pods",
    field: (workload: Workload) => workload,
    format: (workload: Workload): string[] => [workloadUtil.getRunningVsRequestedDisplayedValue(workload)],
    sortable: false,
    align: "left",
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "pods-clicked" },
    hideFilter: true,
  },
  runningPodsVsRequestedPodsForModal: {
    name: "runningPodsVsRequestedPods",
    label: "Running / requested pods",
    field: (workload: Workload) => workload,
    format: (workload: Workload): string => workloadUtil.getRunningVsRequestedDisplayedValue(workload),
    sortable: false,
    align: "left",
    customCell: ECustomCell.LIST_COL,
    hideFilter: true,
  },
  creationTime: {
    name: WorkloadSortFilterFields.CreatedAt,
    label: "Creation time",
    field: (workload: Workload) => workload.createdAt,
    format: (createdAt: string): string => {
      return !createdAt ? "-" : dateUtil.dateFormat(new Date(createdAt), "dd/MM/yyyy HH:mm a");
    },
    sortable: true,
    align: "left",
  },
  completionTime: {
    name: WorkloadSortFilterFields.CompletedAt,
    label: "Completion time",
    field: (workload: Workload) => workload.completedAt,
    format: (completedAt: string): string => {
      return !completedAt ? "-" : dateUtil.dateFormat(new Date(completedAt), "dd/MM/yyyy HH:mm a");
    },
    sortable: true,
    align: "left",
    hideFilter: true,
  },
  dataSource: {
    name: "data-sources",
    label: "Data source(s)",
    field: (workload: Workload) => workload.datasources,
    sortable: false,
    hideFilter: true,
    align: "left",
    format: (dataSources: Datasource[]): Array<string> => {
      if (!dataSources?.length) return [];
      return dataSources.map((datasource: Datasource) => datasource.name);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "data-source-clicked" },
  },
  environment: {
    name: "environment",
    label: "Environment",
    field: (workload: Workload) => workload.environments,
    sortable: false,
    align: "left",
    format: (environments: Environment[]) => {
      if (!environments?.length) return ["-"];
      return environments.map((env: Environment) => env.name);
    },
    customCell: ECustomCell.STRINGS_LIST_COL,
    hideFilter: true,
  },
  multiNode: {
    name: "multi-node",
    label: "Workload architecture",
    field: (row: Workload) => row.distributedFramework,
    sortable: false,
    align: "left",
    format: (distributedFramework: string | undefined) =>
      distributedFramework ? EWorkloadNodeArchitecture.Distributed : EWorkloadNodeArchitecture.Standard,
    hideFilter: true,
  },
  requestedGPU: {
    name: "requestedGPU",
    label: "GPU compute request",
    field: (workload: Workload) => {
      const requestedResources: WorkloadRequestResources | undefined | null =
        workload.podsRequestedResources || workload.workloadRequestedResources;
      return requestedResources?.gpu?.request || "-";
    },
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  requestedGPUMemory: {
    name: "requestedGPUMemory",
    label: "GPU memory request",
    field: (workload: Workload): string | null | undefined => {
      const requestedResources: WorkloadRequestResources | undefined | null =
        workload.podsRequestedResources || workload.workloadRequestedResources;
      return requestedResources?.gpuMemory?.request;
    },
    format: (gpuMemory: string) => (gpuMemory ? formatK8sCpuMemory(gpuMemory) : "-"),
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  requestedCPUMemory: {
    name: "requestedCPUMemory",
    label: "CPU memory request",
    field: (workload: Workload): string | null | undefined => {
      const requestedResources: WorkloadRequestResources | undefined | null =
        workload.podsRequestedResources || workload.workloadRequestedResources;
      return requestedResources?.cpuMemory?.request;
    },
    format: (cpuMemory: string) => (cpuMemory ? formatK8sCpuMemory(cpuMemory) : "-"),
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  requestedCPUCompute: {
    name: "requestedCPUCompute",
    label: "CPU compute request",
    field: (workload: Workload) => workload.podsRequestedResources?.cpu?.request || "-",
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  connections: {
    name: "connections",
    label: "Connection(s)",
    field: (workload: Workload) => workloadUtil.getWorkloadConnectionsForGrid(workload),
    sortable: false,
    align: "left",
    format: (connections: Array<Connection>): number[] | string => {
      const amount = connections?.length;
      return amount ? [amount] : "-";
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "connections-clicked" },
    hideFilter: true,
  },
};

export const workloadIndexColumns: Array<ITableColumn> = [
  { ...allWorkloadColumnsMap.name, display: true, mandatory: true },
  { ...allWorkloadColumnsMap.type, display: true },
  { ...allWorkloadColumnsMap.preemptible, display: false },
  { ...allWorkloadColumnsMap.status, display: true },
  { ...allWorkloadColumnsMap.project, display: true },
  { ...allWorkloadColumnsMap.departmentName, display: true },
  { ...allWorkloadColumnsMap.submittedBy, display: false },
  { ...allWorkloadColumnsMap.nodePools, display: true },
  { ...allWorkloadColumnsMap.runningPodsVsRequestedPods, display: true },
  { ...allWorkloadColumnsMap.creationTime, display: false },
  { ...allWorkloadColumnsMap.completionTime, display: false },
  { ...allWorkloadColumnsMap.connections, display: false },
  { ...allWorkloadColumnsMap.dataSource, display: false },
  { ...allWorkloadColumnsMap.environment, display: false },
  { ...allWorkloadColumnsMap.multiNode, display: false },
  { ...allWorkloadColumnsMap.requestedGPU, display: true },
  { ...allWorkloadColumnsMap.requestedGPUMemory, display: true },
  { ...allWorkloadColumnsMap.requestedCPUCompute, display: false },
  { ...allWorkloadColumnsMap.requestedCPUMemory, display: false },
];

//workloads list modal
export const workloadListModalColumns: Array<ITableColumn> = [
  { ...allWorkloadColumnsMap.name, display: true },
  { ...allWorkloadColumnsMap.type, display: true },
  { ...allWorkloadColumnsMap.status, display: true },
  { ...allWorkloadColumnsMap.submittedBy, display: true },
  { ...allWorkloadColumnsMap.runningPodsVsRequestedPodsForModal, display: true },
  { ...allWorkloadColumnsMap.creationTime, display: true },
  { ...allWorkloadColumnsMap.createdAt, display: true },
  { ...allWorkloadColumnsMap.requestedGPU, display: true },
  { ...allWorkloadColumnsMap.requestedGPUMemory, display: true },
  { ...allWorkloadColumnsMap.requestedCPUMemory, display: true },
  { ...allWorkloadColumnsMap.requestedCPUCompute, display: true },
];

export const workloadDependentColumns = {
  multiNode: new Set([allWorkloadColumnsMap.multiNode.name]),
  department: new Set([allWorkloadColumnsMap.departmentName.name]),
  nodePools: new Set([allWorkloadColumnsMap.nodePools.name]),
};

export const allWorkloadMiniTableColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: "name",
    label: "Workload",
    field: (row: IWorkloadListModal) => row.name,
    sortable: true,
    align: "left",
    customCell: ECustomCell.NAME_COL,
  },
  type: {
    name: "type",
    label: "Type",
    field: (row: IWorkloadListModal) => row.type,
    sortable: true,
    align: "left",
  },
  status: {
    name: "workload-status",
    label: "Status",
    field: (row: IWorkloadListModal) => row.status,
    sortable: true,
    align: "left",
    customCell: ECustomCell.STATUS_COL,
    sort: (statusA: IStatusColOptions, statusB: IStatusColOptions): number => {
      if (statusA.status && statusB.status) {
        return statusA.status.localeCompare(statusB.status);
      }
      return -1;
    },
    exportFormat: (status: IStatusColOptions) => status.status || "NA",
    filterKey: "status",
  },
};

export const workloadMiniTableColumns: Array<ITableColumn> = [
  { ...allWorkloadMiniTableColumnsMap.name, display: true },
  { ...allWorkloadMiniTableColumnsMap.type, display: true },
  { ...allWorkloadMiniTableColumnsMap.status, display: true },
];
