/* tslint:disable */
/* eslint-disable */
/**
 * identity-manager
 * An API for managing runai realms in mappers
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AppTokenRequest
 */
export interface AppTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof AppTokenRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AppTokenRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AppTokenRequest
     */
    'secret': string;
}
/**
 * 
 * @export
 * @interface AppTokenResponse
 */
export interface AppTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof AppTokenResponse
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof AppTokenResponse
     */
    'id_token': string;
}
/**
 * 
 * @export
 * @interface Application
 */
export interface Application {
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'createdAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'updatedAt': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Application
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'lastLogin': string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ApplicationCreationRequest
 */
export interface ApplicationCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationCreationRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ApplicationPatchRequest
 */
export interface ApplicationPatchRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationPatchRequest
     */
    'enabled': boolean;
}
/**
 * 
 * @export
 * @interface ApplicationPostResponse
 */
export interface ApplicationPostResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationPostResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPostResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPostResponse
     */
    'secret': string;
}
/**
 * 
 * @export
 * @interface Client
 */
export interface Client {
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'secret': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Client
     */
    'redirectUris'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof Client
     */
    'attributes'?: object;
}
/**
 * 
 * @export
 * @interface ClientAttributeCreationRequest
 */
export interface ClientAttributeCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientAttributeCreationRequest
     */
    'realm'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAttributeCreationRequest
     */
    'mimir_header'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAttributeCreationRequest
     */
    'tenant_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAttributeCreationRequest
     */
    'runai.cluster_id'?: string;
}
/**
 * 
 * @export
 * @interface ClientCreationRequest
 */
export interface ClientCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientCreationRequest
     */
    'type': ClientCreationRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ClientCreationRequest
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof ClientCreationRequest
     */
    'tenantId'?: string;
    /**
     * 
     * @type {ClientAttributeCreationRequest}
     * @memberof ClientCreationRequest
     */
    'attributes'?: ClientAttributeCreationRequest;
}

export const ClientCreationRequestTypeEnum = {
    Agent: 'Agent',
    WorkloadsAuth: 'WorkloadsAuth'
} as const;

export type ClientCreationRequestTypeEnum = typeof ClientCreationRequestTypeEnum[keyof typeof ClientCreationRequestTypeEnum];

/**
 * 
 * @export
 * @interface ClientRedirectUrisUpdateRequest
 */
export interface ClientRedirectUrisUpdateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientRedirectUrisUpdateRequest
     */
    'redirectUris': Array<string>;
}
/**
 * 
 * @export
 * @interface ConfigSamlResponse
 */
export interface ConfigSamlResponse {
    /**
     * 
     * @type {string}
     * @memberof ConfigSamlResponse
     */
    'entity_id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigSamlResponse
     */
    'redirect_uri': string;
}
/**
 * 
 * @export
 * @interface CreateInternalClient201Response
 */
export interface CreateInternalClient201Response {
    /**
     * 
     * @type {string}
     * @memberof CreateInternalClient201Response
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface DeleteRealm200Response
 */
export interface DeleteRealm200Response {
    /**
     * 
     * @type {string}
     * @memberof DeleteRealm200Response
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface GetClientSecret200Response
 */
export interface GetClientSecret200Response {
    /**
     * 
     * @type {string}
     * @memberof GetClientSecret200Response
     */
    'secret'?: string;
}
/**
 * 
 * @export
 * @interface Idp
 */
export interface Idp {
    /**
     * 
     * @type {string}
     * @memberof Idp
     */
    'alias': string;
    /**
     * 
     * @type {string}
     * @memberof Idp
     */
    'type': IdpTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Idp
     */
    'redirectUri': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Idp
     */
    'config': { [key: string]: string; };
}

export const IdpTypeEnum = {
    Saml: 'saml',
    Oidc: 'oidc',
    OpenshiftV4: 'openshift-v4'
} as const;

export type IdpTypeEnum = typeof IdpTypeEnum[keyof typeof IdpTypeEnum];

/**
 * 
 * @export
 * @interface IdpCreationRequest
 */
export interface IdpCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof IdpCreationRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdpCreationRequest
     */
    'type': IdpCreationRequestTypeEnum;
    /**
     * 
     * @type {SamlCreationData}
     * @memberof IdpCreationRequest
     */
    'samlData'?: SamlCreationData | null;
    /**
     * 
     * @type {OidcCreationData}
     * @memberof IdpCreationRequest
     */
    'oidcData'?: OidcCreationData | null;
    /**
     * 
     * @type {OcpCreationData}
     * @memberof IdpCreationRequest
     */
    'ocpData'?: OcpCreationData | null;
    /**
     * 
     * @type {Mappers}
     * @memberof IdpCreationRequest
     */
    'mappers'?: Mappers;
}

export const IdpCreationRequestTypeEnum = {
    Saml: 'saml',
    Oidc: 'oidc',
    OpenshiftV4: 'openshift-v4'
} as const;

export type IdpCreationRequestTypeEnum = typeof IdpCreationRequestTypeEnum[keyof typeof IdpCreationRequestTypeEnum];

/**
 * 
 * @export
 * @interface IdpPostResponse
 */
export interface IdpPostResponse {
    /**
     * 
     * @type {string}
     * @memberof IdpPostResponse
     */
    'alias': string;
}
/**
 * 
 * @export
 * @interface IdpResources
 */
export interface IdpResources {
    /**
     * 
     * @type {string}
     * @memberof IdpResources
     */
    'resourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdpResources
     */
    'resource'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdpResources
     */
    'type'?: IdpResourcesTypeEnum;
}

export const IdpResourcesTypeEnum = {
    Url: 'url',
    File: 'file'
} as const;

export type IdpResourcesTypeEnum = typeof IdpResourcesTypeEnum[keyof typeof IdpResourcesTypeEnum];

/**
 * 
 * @export
 * @interface InternalApplicationCreationRequest
 */
export interface InternalApplicationCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof InternalApplicationCreationRequest
     */
    'type': InternalApplicationCreationRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InternalApplicationCreationRequest
     */
    'tenantName'?: string;
    /**
     * 
     * @type {InternalApplicationCreationRequestMeta}
     * @memberof InternalApplicationCreationRequest
     */
    'meta': InternalApplicationCreationRequestMeta;
}

export const InternalApplicationCreationRequestTypeEnum = {
    MimirWriter: 'MimirWriter'
} as const;

export type InternalApplicationCreationRequestTypeEnum = typeof InternalApplicationCreationRequestTypeEnum[keyof typeof InternalApplicationCreationRequestTypeEnum];

/**
 * @type InternalApplicationCreationRequestMeta
 * @export
 */
export type InternalApplicationCreationRequestMeta = MimirCreationMeta;

/**
 * 
 * @export
 * @interface InternalUserCreationRequest
 */
export interface InternalUserCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof InternalUserCreationRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InternalUserCreationRequest
     */
    'realm': string;
    /**
     * 
     * @type {string}
     * @memberof InternalUserCreationRequest
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof InternalUserCreationRequest
     */
    'resetPassword': boolean;
}
/**
 * 
 * @export
 * @interface Mappers
 */
export interface Mappers {
    /**
     * 
     * @type {string}
     * @memberof Mappers
     */
    'gid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Mappers
     */
    'uid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Mappers
     */
    'groups'?: string;
    /**
     * 
     * @type {string}
     * @memberof Mappers
     */
    'supplementaryGroups'?: string;
    /**
     * 
     * @type {string}
     * @memberof Mappers
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface MimirCreationMeta
 */
export interface MimirCreationMeta {
    /**
     * 
     * @type {string}
     * @memberof MimirCreationMeta
     */
    'mimirId'?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface OcpCreationData
 */
export interface OcpCreationData {
    /**
     * 
     * @type {string}
     * @memberof OcpCreationData
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OcpCreationData
     */
    'idpBaseUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OcpCreationData
     */
    'idpSecret': string;
}
/**
 * 
 * @export
 * @interface OidcCreationData
 */
export interface OidcCreationData {
    /**
     * 
     * @type {string}
     * @memberof OidcCreationData
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof OidcCreationData
     */
    'clientSecret': string;
    /**
     * 
     * @type {string}
     * @memberof OidcCreationData
     */
    'discoverDocumentUrl': string;
}
/**
 * 
 * @export
 * @interface RealmCreationRequest
 */
export interface RealmCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof RealmCreationRequest
     */
    'realm_name': string;
    /**
     * 
     * @type {string}
     * @memberof RealmCreationRequest
     */
    'tenant_id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealmCreationRequest
     */
    'external_redirect_uri'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RealmCreationResponse
 */
export interface RealmCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof RealmCreationResponse
     */
    'realm_name': string;
    /**
     * 
     * @type {string}
     * @memberof RealmCreationResponse
     */
    'domain': string;
    /**
     * 
     * @type {Array<Client>}
     * @memberof RealmCreationResponse
     */
    'clients': Array<Client>;
}
/**
 * 
 * @export
 * @interface RegenerateApplicationSecret200Response
 */
export interface RegenerateApplicationSecret200Response {
    /**
     * 
     * @type {string}
     * @memberof RegenerateApplicationSecret200Response
     */
    'secret'?: string;
}
/**
 * 
 * @export
 * @interface ResetUserPassword200Response
 */
export interface ResetUserPassword200Response {
    /**
     * 
     * @type {string}
     * @memberof ResetUserPassword200Response
     */
    'tempPassword'?: string;
}
/**
 * 
 * @export
 * @interface SamlCreationData
 */
export interface SamlCreationData {
    /**
     * 
     * @type {string}
     * @memberof SamlCreationData
     */
    'metadataXmlUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof SamlCreationData
     */
    'metadataXmlFile'?: string;
    /**
     * 
     * @type {string}
     * @memberof SamlCreationData
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SamlCreationData
     */
    'metadataXmlType'?: SamlCreationDataMetadataXmlTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SamlCreationData
     */
    'entityId'?: string;
}

export const SamlCreationDataMetadataXmlTypeEnum = {
    Url: 'url',
    File: 'file'
} as const;

export type SamlCreationDataMetadataXmlTypeEnum = typeof SamlCreationDataMetadataXmlTypeEnum[keyof typeof SamlCreationDataMetadataXmlTypeEnum];

/**
 * 
 * @export
 * @interface TokenRequest
 */
export interface TokenRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'grantType'?: TokenRequestGrantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'appID'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'appSecret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'redirectUri'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'refreshToken'?: string;
}

export const TokenRequestGrantTypeEnum = {
    AppToken: 'app_token',
    RefreshToken: 'refresh_token',
    ExchangeToken: 'exchange_token'
} as const;

export type TokenRequestGrantTypeEnum = typeof TokenRequestGrantTypeEnum[keyof typeof TokenRequestGrantTypeEnum];

/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'idToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'externalToken'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastLogin': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isLocal': boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'groups'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserCreationRequest
 */
export interface UserCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof UserCreationRequest
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreationRequest
     */
    'resetPassword'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UserPostResponse
 */
export interface UserPostResponse {
    /**
     * 
     * @type {string}
     * @memberof UserPostResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserPostResponse
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserPostResponse
     */
    'tempPassword': string;
}

/**
 * ApplicationsApi - axios parameter creator
 * @export
 */
export const ApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create application
         * @param {ApplicationCreationRequest} applicationCreationRequest Application object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplication: async (applicationCreationRequest: ApplicationCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationCreationRequest' is not null or undefined
            assertParamExists('createApplication', 'applicationCreationRequest', applicationCreationRequest)
            const localVarPath = `/api/v1/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete application by ID
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationById: async (appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('deleteApplicationById', 'appId', appId)
            const localVarPath = `/api/v1/apps/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get application by ID
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationById: async (appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getApplicationById', 'appId', appId)
            const localVarPath = `/api/v1/apps/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get applications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get installer app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerApp: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/apps/installer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary regenerate application secret
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateApplicationSecret: async (appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('regenerateApplicationSecret', 'appId', appId)
            const localVarPath = `/api/v1/apps/{appId}/secret`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update application by ID
         * @param {string} appId The application id to retrieve
         * @param {ApplicationPatchRequest} [applicationPatchRequest] Application object that needs to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationById: async (appId: string, applicationPatchRequest?: ApplicationPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('updateApplicationById', 'appId', appId)
            const localVarPath = `/api/v1/apps/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationsApi - functional programming interface
 * @export
 */
export const ApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create application
         * @param {ApplicationCreationRequest} applicationCreationRequest Application object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplication(applicationCreationRequest: ApplicationCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApplication(applicationCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete application by ID
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationById(appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplicationById(appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get application by ID
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationById(appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationById(appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get applications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Application>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get installer app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstallerApp(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstallerApp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary regenerate application secret
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regenerateApplicationSecret(appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegenerateApplicationSecret200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regenerateApplicationSecret(appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update application by ID
         * @param {string} appId The application id to retrieve
         * @param {ApplicationPatchRequest} [applicationPatchRequest] Application object that needs to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationById(appId: string, applicationPatchRequest?: ApplicationPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicationById(appId, applicationPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationsApi - factory interface
 * @export
 */
export const ApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationsApiFp(configuration)
    return {
        /**
         * 
         * @summary create application
         * @param {ApplicationCreationRequest} applicationCreationRequest Application object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplication(applicationCreationRequest: ApplicationCreationRequest, options?: any): AxiosPromise<ApplicationPostResponse> {
            return localVarFp.createApplication(applicationCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete application by ID
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationById(appId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteApplicationById(appId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get application by ID
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationById(appId: string, options?: any): AxiosPromise<Application> {
            return localVarFp.getApplicationById(appId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get applications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplications(options?: any): AxiosPromise<Array<Application>> {
            return localVarFp.getApplications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get installer app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerApp(options?: any): AxiosPromise<ApplicationPostResponse> {
            return localVarFp.getInstallerApp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary regenerate application secret
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateApplicationSecret(appId: string, options?: any): AxiosPromise<RegenerateApplicationSecret200Response> {
            return localVarFp.regenerateApplicationSecret(appId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update application by ID
         * @param {string} appId The application id to retrieve
         * @param {ApplicationPatchRequest} [applicationPatchRequest] Application object that needs to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationById(appId: string, applicationPatchRequest?: ApplicationPatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateApplicationById(appId, applicationPatchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationsApi - object-oriented interface
 * @export
 * @class ApplicationsApi
 * @extends {BaseAPI}
 */
export class ApplicationsApi extends BaseAPI {
    /**
     * 
     * @summary create application
     * @param {ApplicationCreationRequest} applicationCreationRequest Application object to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public createApplication(applicationCreationRequest: ApplicationCreationRequest, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).createApplication(applicationCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete application by ID
     * @param {string} appId The application id to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public deleteApplicationById(appId: string, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).deleteApplicationById(appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get application by ID
     * @param {string} appId The application id to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public getApplicationById(appId: string, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).getApplicationById(appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get applications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public getApplications(options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).getApplications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get installer app
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public getInstallerApp(options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).getInstallerApp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary regenerate application secret
     * @param {string} appId The application id to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public regenerateApplicationSecret(appId: string, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).regenerateApplicationSecret(appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update application by ID
     * @param {string} appId The application id to retrieve
     * @param {ApplicationPatchRequest} [applicationPatchRequest] Application object that needs to be updated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public updateApplicationById(appId: string, applicationPatchRequest?: ApplicationPatchRequest, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).updateApplicationById(appId, applicationPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationsInternalApi - axios parameter creator
 * @export
 */
export const ApplicationsInternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary internal create application
         * @param {InternalApplicationCreationRequest} internalApplicationCreationRequest Application object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateApplication: async (internalApplicationCreationRequest: InternalApplicationCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'internalApplicationCreationRequest' is not null or undefined
            assertParamExists('internalCreateApplication', 'internalApplicationCreationRequest', internalApplicationCreationRequest)
            const localVarPath = `/internal/api/v1/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalApplicationCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationsInternalApi - functional programming interface
 * @export
 */
export const ApplicationsInternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationsInternalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary internal create application
         * @param {InternalApplicationCreationRequest} internalApplicationCreationRequest Application object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateApplication(internalApplicationCreationRequest: InternalApplicationCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateApplication(internalApplicationCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationsInternalApi - factory interface
 * @export
 */
export const ApplicationsInternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationsInternalApiFp(configuration)
    return {
        /**
         * 
         * @summary internal create application
         * @param {InternalApplicationCreationRequest} internalApplicationCreationRequest Application object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateApplication(internalApplicationCreationRequest: InternalApplicationCreationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.internalCreateApplication(internalApplicationCreationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationsInternalApi - object-oriented interface
 * @export
 * @class ApplicationsInternalApi
 * @extends {BaseAPI}
 */
export class ApplicationsInternalApi extends BaseAPI {
    /**
     * 
     * @summary internal create application
     * @param {InternalApplicationCreationRequest} internalApplicationCreationRequest Application object to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsInternalApi
     */
    public internalCreateApplication(internalApplicationCreationRequest: InternalApplicationCreationRequest, options?: AxiosRequestConfig) {
        return ApplicationsInternalApiFp(this.configuration).internalCreateApplication(internalApplicationCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create internal client
         * @param {ClientCreationRequest} clientCreationRequest Client object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInternalClient: async (clientCreationRequest: ClientCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientCreationRequest' is not null or undefined
            assertParamExists('createInternalClient', 'clientCreationRequest', clientCreationRequest)
            const localVarPath = `/internal/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete client
         * @param {string} id ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteClient', 'id', id)
            const localVarPath = `/internal/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all clients
         * @param {string} [filter] Filter results by client attribute.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClients: async (filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get client secret
         * @param {string} id The client \&quot;id\&quot; to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientSecret: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getClientSecret', 'id', id)
            const localVarPath = `/internal/clients/{id}/secret`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update client redirect uris by id
         * @param {string} id The client \&quot;id\&quot; to retrieve
         * @param {ClientRedirectUrisUpdateRequest} clientRedirectUrisUpdateRequest Updated Client redirect uris
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRedirectUris: async (id: string, clientRedirectUrisUpdateRequest: ClientRedirectUrisUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRedirectUris', 'id', id)
            // verify required parameter 'clientRedirectUrisUpdateRequest' is not null or undefined
            assertParamExists('updateRedirectUris', 'clientRedirectUrisUpdateRequest', clientRedirectUrisUpdateRequest)
            const localVarPath = `/internal/clients/{id}/redirectUris`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientRedirectUrisUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create internal client
         * @param {ClientCreationRequest} clientCreationRequest Client object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInternalClient(clientCreationRequest: ClientCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateInternalClient201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInternalClient(clientCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete client
         * @param {string} id ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all clients
         * @param {string} [filter] Filter results by client attribute.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllClients(filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Client>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllClients(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get client secret
         * @param {string} id The client \&quot;id\&quot; to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientSecret(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientSecret200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientSecret(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update client redirect uris by id
         * @param {string} id The client \&quot;id\&quot; to retrieve
         * @param {ClientRedirectUrisUpdateRequest} clientRedirectUrisUpdateRequest Updated Client redirect uris
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRedirectUris(id: string, clientRedirectUrisUpdateRequest: ClientRedirectUrisUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRedirectUris(id, clientRedirectUrisUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsApiFp(configuration)
    return {
        /**
         * 
         * @summary create internal client
         * @param {ClientCreationRequest} clientCreationRequest Client object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInternalClient(clientCreationRequest: ClientCreationRequest, options?: any): AxiosPromise<CreateInternalClient201Response> {
            return localVarFp.createInternalClient(clientCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete client
         * @param {string} id ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClient(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteClient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all clients
         * @param {string} [filter] Filter results by client attribute.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClients(filter?: string, options?: any): AxiosPromise<Array<Client>> {
            return localVarFp.getAllClients(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get client secret
         * @param {string} id The client \&quot;id\&quot; to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientSecret(id: string, options?: any): AxiosPromise<GetClientSecret200Response> {
            return localVarFp.getClientSecret(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update client redirect uris by id
         * @param {string} id The client \&quot;id\&quot; to retrieve
         * @param {ClientRedirectUrisUpdateRequest} clientRedirectUrisUpdateRequest Updated Client redirect uris
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRedirectUris(id: string, clientRedirectUrisUpdateRequest: ClientRedirectUrisUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateRedirectUris(id, clientRedirectUrisUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     * 
     * @summary create internal client
     * @param {ClientCreationRequest} clientCreationRequest Client object to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public createInternalClient(clientCreationRequest: ClientCreationRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).createInternalClient(clientCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete client
     * @param {string} id ID of the client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public deleteClient(id: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).deleteClient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all clients
     * @param {string} [filter] Filter results by client attribute.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public getAllClients(filter?: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).getAllClients(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get client secret
     * @param {string} id The client \&quot;id\&quot; to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public getClientSecret(id: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).getClientSecret(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update client redirect uris by id
     * @param {string} id The client \&quot;id\&quot; to retrieve
     * @param {ClientRedirectUrisUpdateRequest} clientRedirectUrisUpdateRequest Updated Client redirect uris
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public updateRedirectUris(id: string, clientRedirectUrisUpdateRequest: ClientRedirectUrisUpdateRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).updateRedirectUris(id, clientRedirectUrisUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IdpsApi - axios parameter creator
 * @export
 */
export const IdpsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary configure external idp
         * @param {IdpCreationRequest} idpCreationRequest idp configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIdp: async (idpCreationRequest: IdpCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idpCreationRequest' is not null or undefined
            assertParamExists('createIdp', 'idpCreationRequest', idpCreationRequest)
            const localVarPath = `/api/v1/idps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(idpCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete external idp
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIdp: async (idp: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idp' is not null or undefined
            assertParamExists('deleteIdp', 'idp', idp)
            const localVarPath = `/api/v1/idps/{idp}`
                .replace(`{${"idp"}}`, encodeURIComponent(String(idp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get external idp
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdp: async (idp: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idp' is not null or undefined
            assertParamExists('getIdp', 'idp', idp)
            const localVarPath = `/api/v1/idps/{idp}`
                .replace(`{${"idp"}}`, encodeURIComponent(String(idp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get idp mappers
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdpMappers: async (idp: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idp' is not null or undefined
            assertParamExists('getIdpMappers', 'idp', idp)
            const localVarPath = `/api/v1/idps/{idp}/mappers`
                .replace(`{${"idp"}}`, encodeURIComponent(String(idp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get idp resources
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdpResources: async (idp: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idp' is not null or undefined
            assertParamExists('getIdpResources', 'idp', idp)
            const localVarPath = `/api/v1/idps/{idp}/resources`
                .replace(`{${"idp"}}`, encodeURIComponent(String(idp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get external idps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdps: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/idps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update external idp
         * @param {string} idp The alias of the idp
         * @param {IdpCreationRequest} idpCreationRequest Updated IdP object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdp: async (idp: string, idpCreationRequest: IdpCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idp' is not null or undefined
            assertParamExists('updateIdp', 'idp', idp)
            // verify required parameter 'idpCreationRequest' is not null or undefined
            assertParamExists('updateIdp', 'idpCreationRequest', idpCreationRequest)
            const localVarPath = `/api/v1/idps/{idp}`
                .replace(`{${"idp"}}`, encodeURIComponent(String(idp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(idpCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update idp mappers
         * @param {string} idp The alias of the idp
         * @param {Mappers} mappers map of our mappers and their source in the external idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdpMappers: async (idp: string, mappers: Mappers, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idp' is not null or undefined
            assertParamExists('updateIdpMappers', 'idp', idp)
            // verify required parameter 'mappers' is not null or undefined
            assertParamExists('updateIdpMappers', 'mappers', mappers)
            const localVarPath = `/api/v1/idps/{idp}/mappers`
                .replace(`{${"idp"}}`, encodeURIComponent(String(idp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mappers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdpsApi - functional programming interface
 * @export
 */
export const IdpsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IdpsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary configure external idp
         * @param {IdpCreationRequest} idpCreationRequest idp configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIdp(idpCreationRequest: IdpCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdpPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIdp(idpCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete external idp
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIdp(idp: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIdp(idp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get external idp
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdp(idp: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Idp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdp(idp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get idp mappers
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdpMappers(idp: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mappers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdpMappers(idp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get idp resources
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdpResources(idp: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdpResources>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdpResources(idp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get external idps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdps(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Idp>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdps(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update external idp
         * @param {string} idp The alias of the idp
         * @param {IdpCreationRequest} idpCreationRequest Updated IdP object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIdp(idp: string, idpCreationRequest: IdpCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIdp(idp, idpCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update idp mappers
         * @param {string} idp The alias of the idp
         * @param {Mappers} mappers map of our mappers and their source in the external idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIdpMappers(idp: string, mappers: Mappers, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIdpMappers(idp, mappers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IdpsApi - factory interface
 * @export
 */
export const IdpsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IdpsApiFp(configuration)
    return {
        /**
         * 
         * @summary configure external idp
         * @param {IdpCreationRequest} idpCreationRequest idp configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIdp(idpCreationRequest: IdpCreationRequest, options?: any): AxiosPromise<IdpPostResponse> {
            return localVarFp.createIdp(idpCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete external idp
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIdp(idp: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteIdp(idp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get external idp
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdp(idp: string, options?: any): AxiosPromise<Idp> {
            return localVarFp.getIdp(idp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get idp mappers
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdpMappers(idp: string, options?: any): AxiosPromise<Mappers> {
            return localVarFp.getIdpMappers(idp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get idp resources
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdpResources(idp: string, options?: any): AxiosPromise<IdpResources> {
            return localVarFp.getIdpResources(idp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get external idps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdps(options?: any): AxiosPromise<Array<Idp>> {
            return localVarFp.getIdps(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update external idp
         * @param {string} idp The alias of the idp
         * @param {IdpCreationRequest} idpCreationRequest Updated IdP object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdp(idp: string, idpCreationRequest: IdpCreationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateIdp(idp, idpCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update idp mappers
         * @param {string} idp The alias of the idp
         * @param {Mappers} mappers map of our mappers and their source in the external idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdpMappers(idp: string, mappers: Mappers, options?: any): AxiosPromise<void> {
            return localVarFp.updateIdpMappers(idp, mappers, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdpsApi - object-oriented interface
 * @export
 * @class IdpsApi
 * @extends {BaseAPI}
 */
export class IdpsApi extends BaseAPI {
    /**
     * 
     * @summary configure external idp
     * @param {IdpCreationRequest} idpCreationRequest idp configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdpsApi
     */
    public createIdp(idpCreationRequest: IdpCreationRequest, options?: AxiosRequestConfig) {
        return IdpsApiFp(this.configuration).createIdp(idpCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete external idp
     * @param {string} idp The alias of the idp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdpsApi
     */
    public deleteIdp(idp: string, options?: AxiosRequestConfig) {
        return IdpsApiFp(this.configuration).deleteIdp(idp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get external idp
     * @param {string} idp The alias of the idp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdpsApi
     */
    public getIdp(idp: string, options?: AxiosRequestConfig) {
        return IdpsApiFp(this.configuration).getIdp(idp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get idp mappers
     * @param {string} idp The alias of the idp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdpsApi
     */
    public getIdpMappers(idp: string, options?: AxiosRequestConfig) {
        return IdpsApiFp(this.configuration).getIdpMappers(idp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get idp resources
     * @param {string} idp The alias of the idp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdpsApi
     */
    public getIdpResources(idp: string, options?: AxiosRequestConfig) {
        return IdpsApiFp(this.configuration).getIdpResources(idp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get external idps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdpsApi
     */
    public getIdps(options?: AxiosRequestConfig) {
        return IdpsApiFp(this.configuration).getIdps(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update external idp
     * @param {string} idp The alias of the idp
     * @param {IdpCreationRequest} idpCreationRequest Updated IdP object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdpsApi
     */
    public updateIdp(idp: string, idpCreationRequest: IdpCreationRequest, options?: AxiosRequestConfig) {
        return IdpsApiFp(this.configuration).updateIdp(idp, idpCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update idp mappers
     * @param {string} idp The alias of the idp
     * @param {Mappers} mappers map of our mappers and their source in the external idp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdpsApi
     */
    public updateIdpMappers(idp: string, mappers: Mappers, options?: AxiosRequestConfig) {
        return IdpsApiFp(this.configuration).updateIdpMappers(idp, mappers, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RealmApi - axios parameter creator
 * @export
 */
export const RealmApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new realm for runai tenant. 
         * @summary Create runai realm
         * @param {RealmCreationRequest} realmCreationRequest The tenant on behalf realm will be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRealm: async (realmCreationRequest: RealmCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realmCreationRequest' is not null or undefined
            assertParamExists('createRealm', 'realmCreationRequest', realmCreationRequest)
            const localVarPath = `/api/v1/realms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(realmCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete realm
         * @param {string} realm The name of the realm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRealm: async (realm: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realm' is not null or undefined
            assertParamExists('deleteRealm', 'realm', realm)
            const localVarPath = `/internal/realms/{realm}`
                .replace(`{${"realm"}}`, encodeURIComponent(String(realm)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RealmApi - functional programming interface
 * @export
 */
export const RealmApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RealmApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new realm for runai tenant. 
         * @summary Create runai realm
         * @param {RealmCreationRequest} realmCreationRequest The tenant on behalf realm will be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRealm(realmCreationRequest: RealmCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealmCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRealm(realmCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete realm
         * @param {string} realm The name of the realm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRealm(realm: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteRealm200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRealm(realm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RealmApi - factory interface
 * @export
 */
export const RealmApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RealmApiFp(configuration)
    return {
        /**
         * Create a new realm for runai tenant. 
         * @summary Create runai realm
         * @param {RealmCreationRequest} realmCreationRequest The tenant on behalf realm will be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRealm(realmCreationRequest: RealmCreationRequest, options?: any): AxiosPromise<RealmCreationResponse> {
            return localVarFp.createRealm(realmCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete realm
         * @param {string} realm The name of the realm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRealm(realm: string, options?: any): AxiosPromise<DeleteRealm200Response> {
            return localVarFp.deleteRealm(realm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RealmApi - object-oriented interface
 * @export
 * @class RealmApi
 * @extends {BaseAPI}
 */
export class RealmApi extends BaseAPI {
    /**
     * Create a new realm for runai tenant. 
     * @summary Create runai realm
     * @param {RealmCreationRequest} realmCreationRequest The tenant on behalf realm will be created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealmApi
     */
    public createRealm(realmCreationRequest: RealmCreationRequest, options?: AxiosRequestConfig) {
        return RealmApiFp(this.configuration).createRealm(realmCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete realm
     * @param {string} realm The name of the realm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealmApi
     */
    public deleteRealm(realm: string, options?: AxiosRequestConfig) {
        return RealmApiFp(this.configuration).deleteRealm(realm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TokensApi - axios parameter creator
 * @export
 */
export const TokensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve access token for an application. The application token is retrieved from the authorization server. 
         * @summary get application token
         * @param {AppTokenRequest} [appTokenRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appToken: async (appTokenRequest?: AppTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/k8s/auth/oauth/apptoken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exchanges an authorization code for an access token. The authorization code is retrieved from the authorization server. 
         * @summary exchange code for token
         * @param {string} redirectUri The redirect uri to redirect to after the authorization server completes the authorization flow
         * @param {string} code The exchange code retrieved from the idp server
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        exchangeCodeForToken: async (redirectUri: string, code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'redirectUri' is not null or undefined
            assertParamExists('exchangeCodeForToken', 'redirectUri', redirectUri)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('exchangeCodeForToken', 'code', code)
            const localVarPath = `/v1/k8s/auth/token/exchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirect_uri'] = redirectUri;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get authentication token.
         * @summary Authentication token
         * @param {TokenRequest} [tokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantToken: async (tokenRequest?: TokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Refreshes an user tokens. The refresh token is retrieved from the authorization server. 
         * @summary refresh token
         * @param {string} refreshToken The refresh token retrieved from the idp server
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        refreshToken: async (refreshToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('refreshToken', 'refreshToken', refreshToken)
            const localVarPath = `/v1/k8s/auth/oauth/tokens/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (refreshToken !== undefined) {
                localVarQueryParameter['refresh_token'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokensApi - functional programming interface
 * @export
 */
export const TokensApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokensApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve access token for an application. The application token is retrieved from the authorization server. 
         * @summary get application token
         * @param {AppTokenRequest} [appTokenRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appToken(appTokenRequest?: AppTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appToken(appTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exchanges an authorization code for an access token. The authorization code is retrieved from the authorization server. 
         * @summary exchange code for token
         * @param {string} redirectUri The redirect uri to redirect to after the authorization server completes the authorization flow
         * @param {string} code The exchange code retrieved from the idp server
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async exchangeCodeForToken(redirectUri: string, code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangeCodeForToken(redirectUri, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get authentication token.
         * @summary Authentication token
         * @param {TokenRequest} [tokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async grantToken(tokenRequest?: TokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.grantToken(tokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Refreshes an user tokens. The refresh token is retrieved from the authorization server. 
         * @summary refresh token
         * @param {string} refreshToken The refresh token retrieved from the idp server
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async refreshToken(refreshToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TokensApi - factory interface
 * @export
 */
export const TokensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokensApiFp(configuration)
    return {
        /**
         * Retrieve access token for an application. The application token is retrieved from the authorization server. 
         * @summary get application token
         * @param {AppTokenRequest} [appTokenRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appToken(appTokenRequest?: AppTokenRequest, options?: any): AxiosPromise<AppTokenResponse> {
            return localVarFp.appToken(appTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Exchanges an authorization code for an access token. The authorization code is retrieved from the authorization server. 
         * @summary exchange code for token
         * @param {string} redirectUri The redirect uri to redirect to after the authorization server completes the authorization flow
         * @param {string} code The exchange code retrieved from the idp server
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        exchangeCodeForToken(redirectUri: string, code: string, options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.exchangeCodeForToken(redirectUri, code, options).then((request) => request(axios, basePath));
        },
        /**
         * Get authentication token.
         * @summary Authentication token
         * @param {TokenRequest} [tokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantToken(tokenRequest?: TokenRequest, options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.grantToken(tokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Refreshes an user tokens. The refresh token is retrieved from the authorization server. 
         * @summary refresh token
         * @param {string} refreshToken The refresh token retrieved from the idp server
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        refreshToken(refreshToken: string, options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.refreshToken(refreshToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokensApi - object-oriented interface
 * @export
 * @class TokensApi
 * @extends {BaseAPI}
 */
export class TokensApi extends BaseAPI {
    /**
     * Retrieve access token for an application. The application token is retrieved from the authorization server. 
     * @summary get application token
     * @param {AppTokenRequest} [appTokenRequest] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    public appToken(appTokenRequest?: AppTokenRequest, options?: AxiosRequestConfig) {
        return TokensApiFp(this.configuration).appToken(appTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exchanges an authorization code for an access token. The authorization code is retrieved from the authorization server. 
     * @summary exchange code for token
     * @param {string} redirectUri The redirect uri to redirect to after the authorization server completes the authorization flow
     * @param {string} code The exchange code retrieved from the idp server
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    public exchangeCodeForToken(redirectUri: string, code: string, options?: AxiosRequestConfig) {
        return TokensApiFp(this.configuration).exchangeCodeForToken(redirectUri, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get authentication token.
     * @summary Authentication token
     * @param {TokenRequest} [tokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    public grantToken(tokenRequest?: TokenRequest, options?: AxiosRequestConfig) {
        return TokensApiFp(this.configuration).grantToken(tokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Refreshes an user tokens. The refresh token is retrieved from the authorization server. 
     * @summary refresh token
     * @param {string} refreshToken The refresh token retrieved from the idp server
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    public refreshToken(refreshToken: string, options?: AxiosRequestConfig) {
        return TokensApiFp(this.configuration).refreshToken(refreshToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create intenral user
         * @param {InternalUserCreationRequest} internalUserCreationRequest Internal user object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntenralUser: async (internalUserCreationRequest: InternalUserCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'internalUserCreationRequest' is not null or undefined
            assertParamExists('createIntenralUser', 'internalUserCreationRequest', internalUserCreationRequest)
            const localVarPath = `/internal/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalUserCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create user
         * @param {UserCreationRequest} userCreationRequest User object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userCreationRequest: UserCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreationRequest' is not null or undefined
            assertParamExists('createUser', 'userCreationRequest', userCreationRequest)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete user by ID
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserById: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserById', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get user by ID
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserById', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get users
         * @param {string} [filter] Filter results by user attribute.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary logout user
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('logoutUser', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/logout`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reset user password
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPassword: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resetUserPassword', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/password`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create intenral user
         * @param {InternalUserCreationRequest} internalUserCreationRequest Internal user object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIntenralUser(internalUserCreationRequest: InternalUserCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIntenralUser(internalUserCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create user
         * @param {UserCreationRequest} userCreationRequest User object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userCreationRequest: UserCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete user by ID
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserById(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserById(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get user by ID
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get users
         * @param {string} [filter] Filter results by user attribute.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary logout user
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary reset user password
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetUserPassword(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetUserPassword200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetUserPassword(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary create intenral user
         * @param {InternalUserCreationRequest} internalUserCreationRequest Internal user object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntenralUser(internalUserCreationRequest: InternalUserCreationRequest, options?: any): AxiosPromise<UserPostResponse> {
            return localVarFp.createIntenralUser(internalUserCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create user
         * @param {UserCreationRequest} userCreationRequest User object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userCreationRequest: UserCreationRequest, options?: any): AxiosPromise<UserPostResponse> {
            return localVarFp.createUser(userCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete user by ID
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserById(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get user by ID
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get users
         * @param {string} [filter] Filter results by user attribute.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(filter?: string, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getUsers(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary logout user
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.logoutUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reset user password
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPassword(userId: string, options?: any): AxiosPromise<ResetUserPassword200Response> {
            return localVarFp.resetUserPassword(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary create intenral user
     * @param {InternalUserCreationRequest} internalUserCreationRequest Internal user object to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createIntenralUser(internalUserCreationRequest: InternalUserCreationRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createIntenralUser(internalUserCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create user
     * @param {UserCreationRequest} userCreationRequest User object to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(userCreationRequest: UserCreationRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUser(userCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete user by ID
     * @param {string} userId The id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserById(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUserById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get user by ID
     * @param {string} userId The id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserById(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get users
     * @param {string} [filter] Filter results by user attribute.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(filter?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsers(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary logout user
     * @param {string} userId The id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public logoutUser(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).logoutUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reset user password
     * @param {string} userId The id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetUserPassword(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).resetUserPassword(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


