export interface NotificationSettings {
  key: string;
  type: NotificationType;
  enabled: boolean;
  title: string;
  category: NotificationCategory;
}

export enum NotificationType {
  WORKLOAD = "workload",
}

export enum NotificationCategory {
  STATUS = "status",
  TIMEOUT = "timeout",
}
