<template>
  <div class="idp-setup-fields col-12">
    <div>
      <div>Add your identity provider metadata</div>
      <div class="row col-12">
        <q-tooltip v-if="disabled" anchor="center middle">
          This can't be modified. To make changes, remove the identity provider and add a new one.
        </q-tooltip>
        <q-input
          class="col-12 q-mb-lg"
          :model-value="oidcNewFields.discoverDocumentUrl"
          :placeholder="urlPlaceholder"
          debounce="300"
          :rules="[isValidUrl]"
          no-error-icon
          @update:model-value="updateDiscoveryUrl"
          stack-label
          label="Discovery URL"
          ref="oidcUrlInput"
          :disable="disabled"
          autofocus
        />
        <div class="row col-12">
          <q-input
            class="col-5"
            :model-value="oidcNewFields.clientId"
            debounce="300"
            :rules="[isNotEmptyClientID]"
            no-error-icon
            @update:model-value="updateClientId"
            stack-label
            label="Client ID"
            ref="oidcClientIdInput"
            :disable="disabled"
          />
          <div class="col-1" />
          <q-input
            class="col-6 items-end"
            :model-value="oidcNewFields.clientSecret"
            debounce="300"
            :rules="[isNotEmptySecret]"
            no-error-icon
            @update:model-value="updateClientSecret"
            stack-label
            label="Client secret"
            ref="oidcClientSecretInput"
            :disable="disabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { isNotEmpty, isValidUrl } from "@/common/form.validators";
import type { QInput } from "quasar";
import type { OidcCreationData } from "@/swagger-models/identity-manager-client";

export default defineComponent({
  name: "oidc-fields",
  emits: ["updated"],
  props: {
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      oidcNewFields: {} as OidcCreationData,
    };
  },
  computed: {
    urlPlaceholder(): string {
      return this.disabled ? "" : "Enter URL";
    },
  },
  methods: {
    async validate(): Promise<boolean> {
      return (
        (await (this.$refs.oidcUrlInput as QInput).validate()) &&
        (await (this.$refs.oidcClientIdInput as QInput).validate()) &&
        (await (this.$refs.oidcClientSecretInput as QInput).validate())
      );
    },
    isNotEmptyClientID(value: string): boolean | string {
      return isNotEmpty(value) || "Enter ID";
    },
    isNotEmptySecret(value: string): boolean | string {
      return isNotEmpty(value) || "Enter a secret";
    },
    isValidUrl(url: string): boolean | string {
      return isValidUrl(url) || "Enter a valid URL";
    },
    updateClientId(clientId: string): void {
      this.oidcNewFields.clientId = clientId;
      this.onUpdated();
    },
    updateClientSecret(clientSecret: string): void {
      this.oidcNewFields.clientSecret = clientSecret;
      this.onUpdated();
    },
    updateDiscoveryUrl(discoveryUrl: string): void {
      this.oidcNewFields.discoverDocumentUrl = discoveryUrl;
      this.onUpdated();
    },
    onUpdated(): void {
      this.$emit("updated", this.oidcNewFields, this.isNewIdpFieldsValid());
    },
    isNewIdpFieldsValid(): boolean {
      return (
        !!this.oidcNewFields.discoverDocumentUrl &&
        isValidUrl(this.oidcNewFields.discoverDocumentUrl) &&
        isNotEmpty(this.oidcNewFields.clientId) &&
        isNotEmpty(this.oidcNewFields.clientSecret)
      );
    },
  },
});
</script>
