<template>
  <runai-feature-intro-modal :is-open="displayModal" :options="modalOptions" @next="handleNext" @back="handleCancel">
    <div class="row overview-intro-modal">
      <div class="col-6 q-pr-sm">
        <q-card-section class="intro-text-container">
          <p class="intro-text-title q-pt-lg">Get more out of your resources</p>
          <br />
          <p class="intro-text-body">
            Stay on top of your physical resources:<br />
            free, allocated, consumed
          </p>

          <p class="intro-text-body">
            Monitor your business units and <br />
            utilization by projects.
          </p>
          <p class="intro-text-body">
            Take action based on real-time<br />
            snapshots of your resources.
          </p>
          <p class="intro-text-body">Go ahead and get to work!</p>

          <div class="q-mt-lg"><p class="intro-text-body text-bold">Try it out now!</p></div>
        </q-card-section>
      </div>
      <div class="col-6">
        <runai-svg-icon name="overview-intro" size="482" />
      </div>
    </div>
  </runai-feature-intro-modal>
</template>
<script lang="ts">
import { defineComponent } from "vue";
//cmps
import RunaiFeatureIntroModal from "@/components/common/runai-feature-intro-modal/runai-feature-intro-modal.vue";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
//model
import type { IRunaiFeatureIntroModalOptions } from "@/components/common/runai-feature-intro-modal/runai-feature-intro-modal.model";
//util
import { alertUtil } from "@/utils/alert.util";
import { storageUtil } from "@/utils/storage.util";
//store
import { useAppStore } from "@/stores/app.store";
//const
import { OVERVIEW_INTRO_MODAL_SHOWN } from "@/common/storage.constant";

export default defineComponent({
  name: "overview-feature-intro-modal",
  components: { RunaiSvgIcon, RunaiFeatureIntroModal },
  data() {
    return {
      appStore: useAppStore(),
      displayModal: false as boolean,
      modalOptions: {
        header: "New Overview Experience",
        backBtnText: "Back to legacy view ",
      } as IRunaiFeatureIntroModalOptions,
    };
  },
  created() {
    this.displayModal = storageUtil.get(OVERVIEW_INTRO_MODAL_SHOWN) === null;
  },
  methods: {
    handleNext(): void {
      this.appStore.setOverviewV2ModeOn();
      this.$q.notify(alertUtil.getSuccess("Switched to the new overview experience"));
      this.setOverviewIntroModalShown();
    },
    handleCancel(): void {
      this.appStore.setOverviewV2ModeOff();
      this.$q.notify(alertUtil.getSuccess("Switched to the legacy view"));
      this.setOverviewIntroModalShown();
    },
    setOverviewIntroModalShown(): void {
      this.displayModal = false;
      storageUtil.save(OVERVIEW_INTRO_MODAL_SHOWN, true);
    },
  },
});
</script>

<style lang="scss" scoped>
.overview-intro-modal {
  .intro-text-container {
    height: 90%;
    background-color: $body-background-color;
    color: $black-70;
  }

  .intro-text-body {
    font-size: 20px;
    line-height: 24px;
  }

  .intro-text-title {
    font-weight: 500;
    font-size: 36px;
    line-height: 42.19px;
    text-align: left;
  }
}
</style>
