<template>
  <q-td>
    <div class="row justify-between items-center">
      <div class="url-text ellipsis">
        {{ urlText }}
      </div>
      <div class="flex gap-15">
        <runai-copy-to-clipboard-btn :text-to-copy="urlText"></runai-copy-to-clipboard-btn>
        <q-btn
          class="connect-btn"
          flat
          size="10px"
          round
          icon="fa-solid fa-arrow-up-right-from-square"
          @click="connect"
          :disable="isDisabled"
          v-permission="{ resourceType: ResourceType.Workloads, action: Action.Create }"
        >
          <q-tooltip v-if="connectTooltipText" class="tool-tip-dark">{{ connectTooltipText }} </q-tooltip>
        </q-btn>
      </div>
    </div>
  </q-td>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// components
import { RunaiCopyToClipboardBtn } from "@/components/common/runai-copy-to-clipboard-btn";
// models
import type { ITableColumn } from "@/models/table.model";
import { Action, ResourceType } from "@/swagger-models/authorization-client";
import { EWorkloadType, type IWorkloadConnectionsModalOptions } from "@/models/workload.model";
import { InternalConnectionType } from "@/swagger-models/assets-service-client";

// utils
import { copyToClipboard } from "quasar";
import { getFieldContent } from "@/utils/table-format.util";
import { alertUtil } from "@/utils/alert.util";

export default defineComponent({
  components: {
    RunaiCopyToClipboardBtn,
  },
  emits: ["custom-cell-event"],
  inject: ["workloadDetails"],
  props: {
    row: {
      type: Object as PropType<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: true,
    },
    column: {
      type: Object as PropType<ITableColumn>,
      required: true,
    },
  },
  data() {
    return {
      ResourceType,
      Action,
    };
  },
  computed: {
    urlText(): string {
      return getFieldContent(this.column, this.row) || "";
    },
    connectTooltipText(): string {
      if (this.isServingPort) return "";
      if (!this.isAllowed) return "Only the workload creator can connect to this tool";
      if (!this.isWorkloadRunning) return "To connect, the selected workload must be running";

      return "Connect";
    },
    isWorkloadRunning(): boolean {
      return !!(this.workloadDetails as IWorkloadConnectionsModalOptions["workloadDetails"])?.isRunning;
    },
    isDisabled(): boolean {
      return this.isServingPort || !this.isWorkloadRunning || !this.isAllowed;
    },
    isServingPort(): boolean {
      return this.row.connectionType === InternalConnectionType.ServingPort;
    },
    isAllowed(): boolean {
      if (!this.row.authorizedUsers?.length) return true;
      const currentUser = (this.workloadDetails as IWorkloadConnectionsModalOptions["workloadDetails"])?.currentUser;
      return this.row.authorizedUsers.includes(currentUser);
    },
  },
  methods: {
    connect(): void {
      if (!this.workloadDetails) return;

      const workloadDetails = this.workloadDetails as IWorkloadConnectionsModalOptions["workloadDetails"];

      if (workloadDetails?.type === EWorkloadType.Inference) {
        // TODO: This is a temp solution until we will fix the actionsSupport for inference
        const customUrl = `${window.location.origin}/${workloadDetails.projectName}/${workloadDetails.name}`;
        window.open(customUrl);
        return;
      }
      const url: string = this.urlText;
      window.open(url.startsWith("http") ? url : "http://" + url);
    },
    async copyText(): Promise<void> {
      await copyToClipboard(this.urlText);
      this.$q.notify(alertUtil.getSuccess("Copied!"));
    },
  },
});
</script>
<style lang="scss" scoped>
.connect-btn {
  color: $black-54;
}
.url-text {
  flex: 1;
}
</style>
