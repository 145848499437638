import { defineStore } from "pinia";

import type { IAssetsFilter } from "@/models/filter.model";
import type {
  EnvironmentAsset,
  EnvironmentCreationRequest,
  EnvironmentUpdateRequest,
  ModelAsset,
} from "@/swagger-models/assets-service-client";
import { Action, type PermittedScopes, ResourceType } from "@/swagger-models/authorization-client";
import { environmentService } from "@/services/control-plane/environment.service/environment.service";
import { registryService } from "@/services/control-plane/registry.service/registry.service";
import { permissionService } from "@/services/authorization/permission.service/permission.service";
import { filterService } from "@/services/filter.service/filter.service";
import { allEnvironmentColumns } from "@/table-models/environment.table-model";
import { useModelSpecStore } from "./model-spec.store";
import { useSettingStore } from "./setting.store";

export const useEnvironmentStore = defineStore("Environment", {
  state: () => ({
    environments: [] as Array<EnvironmentAsset>,
    registryId: null as string | null,
    actionPermissionsByScopes: {} as Record<Action, PermittedScopes>,
  }),
  getters: {
    environmentList(): Array<EnvironmentAsset> {
      return this.environments;
    },
  },
  actions: {
    async loadActionPermissions(): Promise<void> {
      if (Object.keys(this.actionPermissionsByScopes).length > 0) return;
      this.actionPermissionsByScopes = await permissionService.getPermittedScopesForAction(ResourceType.Environments);
    },
    async loadEnvironments(filterBy: IAssetsFilter = {}): Promise<void> {
      const [envList, modelList] = await Promise.all([environmentService.list(filterBy), this.loadModels()]);
      const filteredEnvs = filterService.filterListByTableFilters(envList, filterBy, allEnvironmentColumns);
      this.environments = this.filterEnvironmentsByModels(filteredEnvs, modelList);
      return;
    },
    async loadModels(): Promise<ModelAsset[]> {
      // This is a wrapper just to make sure we don't send request when not needed
      if (!useSettingStore().isAllowDeployingGatedModelsEnabled) {
        return useModelSpecStore().loadModels();
      }
      return [];
    },
    filterEnvironmentsByModels(envList: EnvironmentAsset[], modelList: ModelAsset[]): EnvironmentAsset[] {
      const gatedModelsEnvironmentIds = new Set();
      modelList.forEach((model) => {
        if (!useSettingStore().isAllowDeployingGatedModelsEnabled && model.spec.license != "") {
          if (model.spec.assets?.environment) gatedModelsEnvironmentIds.add(model.spec.assets?.environment);
        }
      });
      if (!gatedModelsEnvironmentIds.size) return envList;
      return envList.filter((env) => !gatedModelsEnvironmentIds.has(env.meta.id));
    },
    async loadById(environmentId: string): Promise<EnvironmentAsset> {
      let environment: EnvironmentAsset | undefined = this.environmentList.find(
        (env: EnvironmentAsset) => env.meta.id === environmentId,
      );
      if (!environment) environment = await environmentService.getById(environmentId);
      return environment;
    },
    async remove(environmentId: string): Promise<void> {
      await environmentService.remove(environmentId);
    },
    async update(environmentId: string, environment: EnvironmentUpdateRequest): Promise<void> {
      await environmentService.update(environmentId, environment);
    },
    async createEnvironment(environment: EnvironmentCreationRequest): Promise<EnvironmentAsset> {
      return await environmentService.save(environment);
    },
    async loadRegistry(): Promise<void> {
      try {
        const registries = await registryService.getRegistries();
        if (registries?.entries?.length > 0) {
          this.registryId = registries.entries[0].meta.id;
        }
      } catch (error: unknown) {
        console.error("Cannot get registry id.", error);
        return;
      }
    },
  },
});
