<template>
  <section class="runai-itemized-list">
    <div class="q-py-md q-gutter-md row items-center">
      <span>{{ title }}</span>
      <runai-tooltip v-if="titleTooltip" :tooltip-text="titleTooltip" tooltip-position="right" />
    </div>
    <section class="list-container">
      <runai-itemized-row
        class="runai-itemized-list-row"
        v-for="item in items"
        :key="item.id"
        :item="item"
        :disabled-tooltip="getRowDisabledReason(item)"
        :disabled="item.locked || disable"
        @changed="itemChanged"
        @remove="removeItem"
      />
    </section>
    <runai-tooltip-wrapper :display-tooltip="disableAddItemBtn" :tooltip-text="addItemBtnTooltipText">
      <q-btn
        aid="add-item-button"
        class="add-item-button"
        flat
        color="primary"
        :label="`+ ${addItemBtn}`"
        :disable="disableAddItemBtn || disable"
        @click="addItem"
      ></q-btn>
    </runai-tooltip-wrapper>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// models
import type { IItemizedListItem, IItemizedRow } from "./runai-itemized-list.model";

// components
import { RunaiItemizedRow } from "./runai-itemized-row";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";
import { RunaiTooltip } from "@/components/common/runai-tooltip";

// utils
import { omit, makeId } from "@/utils/common.util";

export default defineComponent({
  components: {
    RunaiItemizedRow,
    RunaiTooltipWrapper,
    RunaiTooltip,
  },
  emits: ["changed"],
  props: {
    itemsList: {
      type: Array as PropType<Array<IItemizedListItem>>,
      required: false,
      default: () => [],
    },
    title: {
      type: String as PropType<string>,
      required: false,
    },
    titleTooltip: {
      type: String as PropType<string>,
      required: false,
    },
    addItemBtn: {
      type: String as PropType<string>,
      required: false,
    },
    addItemBtnTooltipText: {
      type: String as PropType<string>,
      required: false,
    },
    disableAddItemBtn: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    disable: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      listModel: [] as Array<IItemizedRow>,
    };
  },
  created() {
    this.listModel = this.itemsList.map((item: IItemizedListItem) => ({ ...item, id: makeId() }));
  },
  computed: {
    displayedItems(): Array<IItemizedRow> {
      return this.listModel.filter((item: IItemizedRow) => !item.deleted);
    },
    lockedItems(): Array<IItemizedRow> {
      return this.displayedItems.filter((item: IItemizedRow) => item.locked);
    },
    items(): Array<IItemizedRow> {
      return [...this.lockedItems, ...this.displayedItems.filter((item: IItemizedRow) => !item.locked)];
    },
  },
  methods: {
    addItem(): void {
      if (this.listModel.find((item: IItemizedRow) => item.name === "" && item.value === "")) return; // we want only one empty item

      const item: IItemizedRow = { name: "", value: "", id: makeId() };
      this.listModel.push(item);
      this.updateChanged(this.listModel);
    },
    itemChanged(item: IItemizedRow): void {
      this.listModel = this.listModel.map((currItem: IItemizedRow) => (currItem.id === item.id ? item : currItem));
      this.updateChanged(this.listModel);
    },
    removeItem(item: IItemizedRow): void {
      if (item.isDefault) {
        this.listModel = this.listModel.map((currItem: IItemizedRow) => {
          return currItem.id === item.id ? { ...item, deleted: true } : currItem;
        });
      } else {
        this.listModel = this.listModel.filter((currItem: IItemizedRow) => currItem.id !== item.id);
      }
      this.updateChanged(this.listModel);
    },

    updateChanged(itemizedList: Array<IItemizedRow>): void {
      this.$emit(
        "changed",
        itemizedList.map((currItem: IItemizedRow) => omit(currItem, ["id"])),
      );
    },
    getRowDisabledReason(item: IItemizedRow): string {
      return item.locked ? String(item.lockedReason) : "";
    },
  },
});
</script>
<style lang="scss" scoped>
.runai-itemized-list {
  .runai-itemized-list-row {
    margin-bottom: 15px;
  }
}
</style>
