// services
import { storageUtil } from "@/utils/storage.util";
import { urlService } from "@/services/url.service/url.service";
import { getFieldContent } from "@/utils/table-format.util";
import { arrayToObjectMap, deepCopy } from "@/utils/common.util";

// models
import {
  EFilterOperator,
  ESortOrder,
  type IAssetsFilter,
  type IFilterBy,
  type IFilterModel,
  type IFilterOption,
  type IPaginationFilter,
} from "@/models/filter.model";
import type { ETableFilters, ITableColumn } from "@/models/table.model";
export const filterService = {
  filterByColumns,
  getAvailableFilterOptions,
  getFilterOptions,
  getEmptyFilterByModel,
  loadFilters,
  saveFilters,
  filterBySearchTerm,
  getDefaultFilters,
  filterBySearchTermAndByColumns,
  mapFilterToPaginationParams,
  mapColumnFiltersToStringArray,
  setColumnFilter,
  filterListByTableFilters,
};

/**
 * @entities -  an array of departments/projects to be filtered.
 * @filters - an array of filters , each filter has a name, field and term
 * @entityColumnsModel - an array of tableColumns , in some fields the displayed data needs to be formatted, and the specific format function is on the column object.
 */
function filterByColumns<T>(
  entities: Array<T>,
  filters: Array<IFilterModel>,
  entityColumnsModel: Array<ITableColumn>,
): Array<T> {
  const columnsMap = arrayToObjectMap(entityColumnsModel, "name");
  return entities.filter((entity: T): boolean => {
    return filters.every((filter: IFilterModel): boolean => {
      const currCol: ITableColumn = columnsMap[filter.name];
      // ignoring Column filter under sepecific circumstances
      if (currCol.ignoreFilter && currCol.ignoreFilter(entity)) return true;

      // the next line is for the case when reloading the
      // page and field is a function the stringify remove it,
      // so we need to return the field
      filter.field ??= currCol.field;
      let currField: string | number | object =
        typeof filter.field === "function" ? filter.field(entity) : entity[filter.field as keyof object];
      if (currCol.format) {
        if (currCol.filterKey) currField = currCol.format(currField, entity)[currCol.filterKey];
        else currField = currCol.format(currField, entity);
      }
      switch (typeof currField) {
        case "string":
          return currField.toLowerCase().includes(filter.term.toLowerCase());
        case "number":
          return currField.toString().includes(filter.term);
        case "object":
          if (Array.isArray(currField)) {
            return currField.some((item: string) => item.includes(filter.term));
          } else {
            return false;
          }
        default:
          return false;
      }
    });
  });
}

/**
 * @items -  an array of departments/projects to be filtered.
 * @term - a term to search through all the columns.
 * @displayedColumns - an array of the displayed columns in the table.
 * @columns - the full columns list
 */
function filterBySearchTerm<T>(
  items: Array<T>,
  term: string,
  displayedColumns: Array<string>,
  columns: Array<ITableColumn>,
): Array<T> {
  const columnsToFilter: Array<ITableColumn> = _getColumnsToFilter(displayedColumns, columns);
  return items.filter((item: T) => {
    const itemValues = _getSearchableValues<T>(item, columnsToFilter);
    return itemValues.some((value: string | number | boolean | Record<string, string>): boolean => {
      if (!value) return false;
      switch (typeof value) {
        case "string":
          return value.toLowerCase().includes(term.toLowerCase());
        case "number":
          return value === +term;
        case "boolean":
          return value === !!term;
        case "object": {
          if (value["filterKey"]) {
            // this serves for the cases where the column format returns an object and needs to know under which key the value is
            return value[value.filterKey].toLowerCase().includes(term.toLowerCase());
          }
          return false;
        }
      }
    });
  });
}

function _getSearchableValues<T>(item: T, columns: Array<ITableColumn>): Array<string | number | boolean> {
  const filterValues: Array<string | number | boolean> = columns.map((col: ITableColumn) => getFieldContent(col, item));
  return filterValues.flat();
}

function getAvailableFilterOptions(activeFilters: Array<IFilterModel>, filterOptions: Array<IFilterOption>) {
  const filtersModelNamesMap: Map<string, string> = activeFilters.reduce((acc, fm: IFilterModel) => {
    acc.set(fm.name, fm.name);
    return acc;
  }, new Map());
  return filterOptions.filter((fo: IFilterOption) => !filtersModelNamesMap.get(fo.name));
}

function getEmptyFilterByModel(options: IFilterBy): IFilterBy {
  const defaultFilterOptions = {
    sortBy: options.sortBy,
    descending: false,
    page: 1,
    rowsPerPage: 20,
    columnFilters: [],
    searchTerm: "",
  } as IFilterBy;

  return { ...defaultFilterOptions, ...options };
}

function saveFilters(tableName: ETableFilters, filters: IFilterBy): void {
  const searchParams: string = urlService.toSearchParams(filters);
  if (searchParams) {
    const url = window.location.href.split("?")[0];
    window.history.replaceState(window.history.state, "", `${url}?${searchParams}`);
  }

  storageUtil.save(tableName, filters);
}

function loadFilters(
  location: Location,
  tableName: ETableFilters,
  defaultFilterOptions: IFilterBy,
  forceCleanup = false,
): IFilterBy {
  const defaultsFilters: IFilterBy = getEmptyFilterByModel(defaultFilterOptions);

  if (forceCleanup) return { ...defaultsFilters };

  const storageFiltering: IFilterBy | null = storageUtil.get<IFilterBy | null>(tableName);
  const urlParamsFilters: IAssetsFilter = urlService.getFiltersObj(location);
  const displayedColumns: Array<string> = storageFiltering?.displayedColumns || defaultsFilters.displayedColumns || [];

  return urlParamsFilters && Object.keys(urlParamsFilters).length !== 0
    ? {
        ...defaultsFilters,
        ...storageFiltering,
        ...urlParamsFilters,
        displayedColumns,
      }
    : { ...defaultsFilters, ...storageFiltering };
}

function getDefaultFilters(sortBy: string, columns: Array<ITableColumn>): IFilterBy {
  return {
    sortBy: sortBy,
    displayedColumns: columns.filter((col: ITableColumn) => col.display).map((col: ITableColumn) => col.name),
  };
}

// Destructured the column object to a filter option object.
function getFilterOptions(columns: Array<ITableColumn>): Array<IFilterOption> {
  return columns.map(
    ({ field, label, name }: ITableColumn): IFilterOption => ({
      field,
      label,
      name,
    }),
  );
}

function _getColumnsToFilter(displayedColumns: Array<string>, columns: Array<ITableColumn>): Array<ITableColumn> {
  const displayedColumnsMap: Set<string> = new Set(displayedColumns);
  return columns.filter((col: ITableColumn) => displayedColumnsMap.has(col.name));
}

function filterBySearchTermAndByColumns<T>(
  entities: Array<T>,
  filterBy: IFilterBy,
  columns: Array<ITableColumn>,
): Array<T> {
  let entitiesFiltered = [...entities];

  if (filterBy.searchTerm && filterBy.displayedColumns) {
    entitiesFiltered = filterBySearchTerm(entities, filterBy.searchTerm, filterBy.displayedColumns, columns);
  }

  if (filterBy.columnFilters && filterBy.columnFilters.length) {
    entitiesFiltered = filterByColumns(entitiesFiltered, filterBy.columnFilters, columns);
  }
  return entitiesFiltered;
}

function mapColumnFiltersToStringArray(
  columnFilters?: Array<IFilterModel>,
  searchTerm?: string,
  searchTermField = "name",
): string[] {
  // Map columnFilters, currently we support only =@ (contains)
  const filterBy =
    columnFilters
      ?.map(({ term, name }) => {
        if (term && name) {
          return `${name}${EFilterOperator.Contains}${term}`;
        }
        return null;
      })
      .filter((filter) => filter !== null) || [];

  if (searchTerm) {
    //when using pagination with free text search we only support searchTermField contains.
    filterBy.push(`${searchTermField}${EFilterOperator.Contains}${searchTerm}`);
  }
  return filterBy;
}

function mapFilterToPaginationParams(filter: IFilterBy, searchTermField?: string): IPaginationFilter {
  // Map sortOrder
  const sortOrder: ESortOrder = filter.descending ? ESortOrder.Desc : ESortOrder.Asc;

  const filterBy: string[] = mapColumnFiltersToStringArray(filter.columnFilters, filter.searchTerm, searchTermField);

  const offset: number = ((filter.page || 0) - 1) * (filter.rowsPerPage || 0);
  return {
    offset: offset,
    limit: filter.rowsPerPage,
    sortOrder: sortOrder,
    sortBy: filter.sortBy,
    filterBy: filterBy,
  };
}

function setColumnFilter(
  filters: IFilterBy,
  term: string,
  name: string,
  filterStorageKey: ETableFilters,
  sortBy = "name",
) {
  if (filters.columnFilters && !filters.columnFilters.some((colFilter: IFilterModel) => colFilter.term === term)) {
    filters.columnFilters.push({
      term: term,
      name: name,
      field: () => "",
    });
    filterService.saveFilters(filterStorageKey, {
      ...filters,
      sortBy: sortBy,
    });
  }
}

function filterListByTableFilters<T>(list: T[], filterBy: IFilterBy, columns: ITableColumn[]): T[] {
  if (!filterBy || !filterBy.displayedColumns) return list;

  let filteredList: T[] = deepCopy(list);
  if (filterBy.searchTerm) {
    filteredList = filterBySearchTerm<T>(filteredList, filterBy.searchTerm, filterBy.displayedColumns, columns);
  }

  if (filterBy.columnFilters && filterBy.columnFilters.length) {
    filteredList = filterService.filterByColumns(filteredList, filterBy.columnFilters, columns);
  }
  return filteredList;
}
