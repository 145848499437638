import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";

import type { ENodeTelemetryGroupBy, ICluster, TClusterVersions } from "@/models/cluster.model";

import { API, K8S_API } from "@/common/api.constant";

import axios, { type AxiosRequestConfig, type AxiosResponse } from "axios";

import { clusterServiceApi } from "@/services/infra/client-apis/cluster-service-api/cluster-service-api";
import type { MetricsType, MetricsResponse } from "@/swagger-models/cluster-service-client";
import type { NodeTelemetryType, TelemetryResponse } from "@/swagger-models/cluster-service-client";
import { httpService } from "@/services/infra/https.service/http.service";
import { fileService, FileType } from "@/services/file.service/file.service";

export const clusterService = {
  list,
  remove,
  create,
  getOperatorVersions,
  update,
  getAwsClustaerInstallationUrl,
  checkClusterConnection,
  getClusterMetrics,
  getClusterMetricsCsv,
  getNodeTelemetry,
  getNodeTelemetryCsv,
  getNodepoolMetrics,
};

const ENDPOINT = `${K8S_API.v1}/clusters`;
const ENDPOINT_V2 = `${API.v1}/clusters`;
const NODE_TELEMETRY_ENDPOINT = `${API.v1}/nodes/telemetry`;

// api calls
async function list(): Promise<Array<ICluster>> {
  return controlPlaneService.get(ENDPOINT);
}

async function remove(uuid: string): Promise<void> {
  await controlPlaneService.delete(`${ENDPOINT}/${uuid}`);
}

async function update(uuid: string, updatedCluster: ICluster): Promise<void> {
  await controlPlaneService.put(`${ENDPOINT}/${uuid}`, updatedCluster);
}

function create(name: string): Promise<ICluster> {
  return controlPlaneService.post(ENDPOINT, { name });
}

function getOperatorVersions(): Promise<Record<TClusterVersions, string>> {
  return controlPlaneService.get(`${ENDPOINT}/versions`);
}

async function getNodepoolMetrics(
  clusterUuid: string,
  nodepoolName: string,
  start: string,
  end: string,
  metricType: Array<MetricsType>,
  numberOfSamples?: number,
) {
  const res: AxiosResponse<MetricsResponse> = await clusterServiceApi.nodePoolsApi.getNodepoolMetrics(
    clusterUuid,
    nodepoolName,
    start,
    end,
    metricType,
    numberOfSamples,
  );
  return res.data;
}

function getAwsClustaerInstallationUrl(clusterId: string): Promise<string> {
  return controlPlaneService.get(`${ENDPOINT_V2}/${clusterId}/installation-url`);
}

async function getClusterMetrics(
  clusterUuid: string,
  start: string,
  end: string,
  metricType: Array<MetricsType>,
  numberOfSamples?: number,
): Promise<MetricsResponse> {
  const res: AxiosResponse<MetricsResponse> = await clusterServiceApi.clustersApi.getClusterMetrics(
    clusterUuid,
    start,
    end,
    metricType,
    numberOfSamples,
  );

  return res.data;
}

async function getClusterMetricsCsv(
  clusterUuid: string,
  start: string,
  end: string,
  metricType: Array<MetricsType>,
  numberOfSamples?: number,
): Promise<void> {
  const query: Record<string, string | number | Array<string>> = {
    metricType: metricType,
    start: start,
    end: end,
  };
  if (numberOfSamples) {
    query["numberOfSamples"] = numberOfSamples;
  }
  await fileService.downloadFile(ENDPOINT_V2 + "/" + clusterUuid + "/metrics", FileType.csv, undefined, query, {});
}

async function checkClusterConnection(
  clusterDomain: string,
  accessToken: string,
  externalToken: string,
): Promise<number> {
  let headers = {};
  headers = externalToken
    ? {
        Accept: "application/json, text/plain, */*",
        Authorization: `Bearer ${externalToken}`,
        AuthInfo: `Bearer ${accessToken}`,
      }
    : {
        Accept: "application/json, text/plain, */*",
        Authorization: `Bearer ${accessToken}`,
      };

  const domain = clusterDomain.endsWith("/") ? clusterDomain.slice(0, -1) : clusterDomain;

  const requestOptions: AxiosRequestConfig = {
    url: `${domain}/researcher/api/v1/whoami`,
    method: "GET",
    headers: headers,
  };
  try {
    const response = await axios(requestOptions);
    return response.status;
  } catch {
    return 401;
  }
}

async function getNodeTelemetry(
  telemetryType: NodeTelemetryType,
  clusterId?: string,
  nodepoolName?: string,
  groupBy?: Array<ENodeTelemetryGroupBy>,
): Promise<TelemetryResponse> {
  try {
    const response = await clusterServiceApi.nodesApi.getNodeTelemetry(telemetryType, clusterId, nodepoolName, groupBy);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getNodeTelemetryCsv(
  telemetryType: NodeTelemetryType,
  clusterId?: string,
  nodepoolName?: string,
  groupBy?: Array<ENodeTelemetryGroupBy>,
): Promise<void> {
  try {
    const query: Record<string, string | number | Array<string>> = {
      telemetryType: telemetryType,
    };
    if (clusterId) {
      query["clusterId"] = clusterId;
    }
    if (nodepoolName) {
      query["nodepoolName"] = nodepoolName;
    }
    if (groupBy && groupBy.length > 0) {
      query["groupBy"] = groupBy;
    }
    await fileService.downloadFile(NODE_TELEMETRY_ENDPOINT, FileType.csv, undefined, query, {});
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}
