import { defineStore } from "pinia";

// services
import { modelAssetService } from "@/services/control-plane/model-asset.service/model-asset.service";

// models
import type { ModelAsset, ModelInferenceCreationRequest, ModelInference } from "@/swagger-models/assets-service-client";

export const useModelSpecStore = defineStore("ModelSpec", {
  state: () => ({
    modelAssets: [] as Array<ModelAsset>,
    selectedModelAsset: null as ModelAsset | null,
  }),
  getters: {},
  actions: {
    async loadModels(): Promise<Array<ModelAsset>> {
      this.modelAssets = await modelAssetService.list();
      return this.modelAssets;
    },
    async deployModelInference(modelInferenceCreation: ModelInferenceCreationRequest): Promise<ModelInference> {
      const modelInference: ModelInference = await modelAssetService.deployModelInference(modelInferenceCreation);
      return modelInference;
    },
    async loadModelAsset(modelId: string): Promise<ModelAsset> {
      this.selectedModelAsset = await modelAssetService.getById(modelId);
      return this.selectedModelAsset;
    },
    setSelectedModelAsset(selectedModelAsset: ModelAsset | null): void {
      this.selectedModelAsset = selectedModelAsset;
    },
    resetCreateInferenceData(): void {
      this.selectedModelAsset = null;
    },
  },
});
