import type { RunaiRouteRecord } from "vue-router";
import UserSettingsIndex from "@/views/user-settings/user-settings-index.vue";

export enum USER_SETTINGS_ROUTE_NAMES {
  USER_SETTINGS_INDEX = "user-settings-index",
}

export const userSettingsRoutes: RunaiRouteRecord = {
  path: "/user-settings",
  component: () => import("@/views/user-settings/user-settings-app.vue"),
  children: [
    {
      path: "",
      name: USER_SETTINGS_ROUTE_NAMES.USER_SETTINGS_INDEX,
      component: UserSettingsIndex,
      meta: {
        pageTitle: "Settings",
        resourceType: null,
        minPermittedActions: [],
        fullWidth: true,
        supportBack: true,
        requiresAuth: true,
        hideClusterSelection: true,
      },
    },
  ],
};
