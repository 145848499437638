import type { IFilterBy } from "@/models/filter.model";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import type { IAccessRuleRecords } from "@/models/access-rule.model";
import type { IUser } from "@/models/user.model";
import { API } from "@/common/api.constant";
import type { IApplication } from "@/models/applications.model";
import {
  type AccessRule,
  type AccessRuleCreationFields,
  type CountAccessRules200Response,
  type GetAccessRules200Response,
  type ScopeType,
  SubjectType,
} from "@/swagger-models/authorization-client";
export interface IAccessRulesFilter {
  subjectType?: SubjectType;
  subjectIdFilter?: string;
  subjectIds?: Array<string>;
  limit?: number;
  offset?: number;
  lastUpdated?: string;
  includeDeleted?: boolean;
  clusterId?: string;
  scopeType?: ScopeType;
  scopeId?: string;
  roleId?: number;
}

export const accessRuleService = {
  list,
  deleteAccessRule,
  createAccessRule,
  getSubjectIds,
  getAccessRules,
  getAccessRulesCount,
  getAccessRulesV2,
};

async function list(): Promise<AccessRule[]> {
  const assignmentsRecords: IAccessRuleRecords = await controlPlaneService.get(`${API.accessRules}`);
  const assignments: Array<AccessRule> = assignmentsRecords.accessRules;
  return assignments;
}

async function createAccessRule(accessRuleCreationFields: AccessRuleCreationFields): Promise<AccessRule> {
  return await controlPlaneService.post(`${API.accessRules}`, accessRuleCreationFields);
}

async function deleteAccessRule(accessRuleId: number): Promise<void> {
  await controlPlaneService.delete(`${API.accessRules}/${accessRuleId}`);
}

async function getAccessRules(filter: IAccessRulesFilter): Promise<IAccessRuleRecords> {
  return await controlPlaneService.get(`${API.accessRules}`, {
    ...filter,
    subjectIds: filter.subjectIds?.join(","),
  } as IAccessRulesFilter);
}

async function getSubjectIds(isSSO: boolean, subjectType: SubjectType, searchQuery: string): Promise<Array<string>> {
  let subjectIds: Array<string> = new Array<string>();
  if (subjectType === SubjectType.User) {
    const subjects: Array<IUser> = await getUsers();
    subjectIds = subjects.map((user: IUser) => user.username);
  } else if (subjectType === SubjectType.App) {
    const subjects: Array<IApplication> = await getApps();
    subjectIds = subjects.map((app: IApplication) => app.name);
  }
  subjectIds = subjectIds.filter((name) => {
    return name.toLowerCase().includes(searchQuery.toLowerCase());
  });
  if (isSSO) {
    const accessRules: IAccessRuleRecords = await controlPlaneService.get(
      `${API.accessRules}?subjectType=${subjectType}&subjectIdFilter=${searchQuery}`,
    );
    const subjectIdsSet = new Set<string>();
    accessRules.accessRules.forEach((accessRule: AccessRule) => {
      subjectIdsSet.add(accessRule.subjectId);
    });
    subjectIds.forEach((subjectId: string) => {
      subjectIdsSet.add(subjectId);
    });

    // Convert Set to array
    subjectIds = Array.from(subjectIdsSet);
  }
  return subjectIds;
}

async function getUsers(): Promise<Array<IUser>> {
  return controlPlaneService.get(`${API.v1}/users`);
}

async function getApps(): Promise<Array<IApplication>> {
  return controlPlaneService.get(`${API.v1}/apps`);
}

//pagination
async function getAccessRulesV2(filters?: IFilterBy): Promise<AccessRule[]> {
  const response: GetAccessRules200Response = await controlPlaneService.get(`${API.accessRules}`, filters);
  return response.accessRules;
}

async function getAccessRulesCount(filterBy?: Array<string>): Promise<number> {
  const response: CountAccessRules200Response = await controlPlaneService.get(`${API.accessRules}/count`, { filterBy });
  return response.count;
}
