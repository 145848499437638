<template>
  <q-btn class="copy-clipboard-btn" flat size="10px" round icon="fas fa-copy" @click="copyText">
    <q-tooltip :anchor="tooltipAnchor" :offset="tooltipOffset"> Copy to clipboard </q-tooltip>
  </q-btn>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { copyToClipboard } from "quasar";
//util
import { alertUtil } from "@/utils/alert.util";
export default defineComponent({
  name: "runai-copy-to-clipboard-btn",
  props: {
    textToCopy: {
      type: String as PropType<string>,
      required: true,
    },
    tooltipOffset: {
      type: Array as PropType<number[]>,
      required: false,
    },
    tooltipAnchor: {
      type: String as PropType<
        | "top left"
        | "top middle"
        | "top right"
        | "top start"
        | "top end"
        | "center left"
        | "center middle"
        | "center right"
        | "center start"
        | "center end"
        | "bottom left"
        | "bottom middle"
        | "bottom right"
        | "bottom start"
        | "bottom end"
      >,
      required: false,
    },
  },
  methods: {
    async copyText(): Promise<void> {
      await copyToClipboard(this.textToCopy);
      this.$q.notify(alertUtil.getSuccess("Copied!"));
    },
  },
});
</script>

<style lang="scss" scoped>
.copy-clipboard-btn {
  color: $black-54;
}
</style>
