/* tslint:disable */
/* eslint-disable */
/**
 * Metrics
 * An API for querying metrics
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface GetMetrics200Response
 */
export interface GetMetrics200Response {
    /**
     * 
     * @type {string}
     * @memberof GetMetrics200Response
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof GetMetrics200Response
     */
    'query': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMetrics200Response
     */
    'isRange': boolean;
    /**
     * 
     * @type {Array<Result>}
     * @memberof GetMetrics200Response
     */
    'data': Array<Result>;
}
/**
 * 
 * @export
 * @interface Measurement
 */
export interface Measurement {
    /**
     * 
     * @type {string}
     * @memberof Measurement
     */
    'timestamp': string;
    /**
     * 
     * @type {string}
     * @memberof Measurement
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface MetricInformation
 */
export interface MetricInformation {
    /**
     * 
     * @type {string}
     * @memberof MetricInformation
     */
    'name': string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof MetricInformation
     */
    'filters'?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<string>}
     * @memberof MetricInformation
     */
    'responseLabels'?: Array<string>;
    /**
     * 
     * @type {MetricInformationRange}
     * @memberof MetricInformation
     */
    'range'?: MetricInformationRange | null;
}
/**
 * 
 * @export
 * @interface MetricInformationRange
 */
export interface MetricInformationRange {
    /**
     * timestamp from when to fetch data in UTC
     * @type {string}
     * @memberof MetricInformationRange
     */
    'start': string;
    /**
     * timestamp until when to fetch data in UTC
     * @type {string}
     * @memberof MetricInformationRange
     */
    'end': string;
    /**
     * the number of samples to take in the time range from start to end.
     * @type {number}
     * @memberof MetricInformationRange
     */
    'numOfSamples'?: number;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Result
     */
    'metricLabels': { [key: string]: string; };
    /**
     * 
     * @type {Array<Measurement>}
     * @memberof Result
     */
    'measurements': Array<Measurement>;
}

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get metrics.
         * @param {MetricInformation} metricInformation Requested Metric Information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics: async (metricInformation: MetricInformation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricInformation' is not null or undefined
            assertParamExists('getMetrics', 'metricInformation', metricInformation)
            const localVarPath = `/api/v1/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricInformation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get metrics.
         * @param {MetricInformation} metricInformation Requested Metric Information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetrics(metricInformation: MetricInformation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMetrics200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetrics(metricInformation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get metrics.
         * @param {MetricInformation} metricInformation Requested Metric Information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics(metricInformation: MetricInformation, options?: any): AxiosPromise<GetMetrics200Response> {
            return localVarFp.getMetrics(metricInformation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
    /**
     * 
     * @summary Get metrics.
     * @param {MetricInformation} metricInformation Requested Metric Information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getMetrics(metricInformation: MetricInformation, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getMetrics(metricInformation, options).then((request) => request(this.axios, this.basePath));
    }
}


