import type {
  ModelAsset,
  ModelListResponse,
  ModelInferenceCreationRequest,
  ModelInference,
} from "@/swagger-models/assets-service-client";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { workloadService } from "@/services/cluster/workload.service/workload.service";
import type { IWorkloadCreate, IWorkloadResponse } from "@/models/workload.model";
import { API } from "@/common/api.constant";

import type { IModelAssetFilters } from "@/models/model-asset.model";
import { pick } from "@/utils/common.util";

export const modelAssetService = {
  list,
  getById,
  deployModelInference,
};

const modelAssetEndpoint = `${API.asset}/model`;
const modelInferenceEndpoint = `${API.v1}/model-inference`;

async function list(filterBy: IModelAssetFilters = {}): Promise<Array<ModelAsset>> {
  const filters: IModelAssetFilters = pick(filterBy, "environmentId");
  const modelsAssetRes: ModelListResponse = await controlPlaneService.get(modelAssetEndpoint, filters);
  return modelsAssetRes.entries;
}

async function getById(modelId: string): Promise<ModelAsset> {
  return await controlPlaneService.get(`${modelAssetEndpoint}/${modelId}`);
}

async function deployModelInference(modelInferenceCreation: ModelInferenceCreationRequest): Promise<ModelInference> {
  const modelInference: ModelInference = await controlPlaneService.post(modelInferenceEndpoint, modelInferenceCreation);
  await _createWorkload(modelInference.workload as IWorkloadCreate);
  return modelInference;
}

async function _createWorkload(workloadCreate: IWorkloadCreate): Promise<IWorkloadResponse> {
  return workloadService.createWorkload("InferenceWorkload", workloadCreate.metadata, {
    ...workloadCreate.spec,
    name: { value: workloadCreate.metadata.name },
  });
}
