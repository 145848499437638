<template>
  <div class="q-px-sm q-pb-sm">
    <template v-for="(group, index) in widgetsLayout" :key="index">
      <div class="row q-gutter-sm q-pt-sm">
        <template v-for="(widget, widgetIndex) in group" :key="widgetIndex">
          <div class="col" :class="getColClass(group.length, index)">
            <component :is="widget" :cluster-id="clusterId" :filter-by-dates="filterByDates" :filter-by="filterBy" />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { EWidgetComponent } from "@/models/chart.model";
import type { IRangeDates } from "@/utils/date.util";
//stat widgets
import { RunningWorkloadsWidget } from "@/components/dashboard-v2/widgets/stat-widgets/running-workloads-widget";
import { PendingWorkloadsWidget } from "@/components/dashboard-v2/widgets/stat-widgets/pending-workloads-widget";
import { ReadyNodesWidget } from "@/components/dashboard-v2/widgets/stat-widgets/ready-nodes-widget/";
import { AllocatedGpuWidget } from "@/components/dashboard-v2/widgets/stat-widgets/allocated-gpu-widget/";
import { ReadyGpuWidget } from "@/components/dashboard-v2/widgets/stat-widgets/ready-gpu-widget/";
//chart widgets
import { WorkloadStatusWidget } from "@/components/dashboard-v2/widgets/chart-widgets/workload-status-widget";
import { NodePoolFreeResourcesWidget } from "@/components/dashboard-v2/widgets/chart-widgets/node-pool-free-resources-widget";
import { ResourceAllocationByWorkloadWidget } from "@/components/dashboard-v2/widgets/chart-widgets/resource-allocation-by-workload-widget/";
import { GpuAllocationRatioWidget } from "@/components/dashboard-v2/widgets/chart-widgets/gpu-allocation-ratio-widget/";
//time range widgets
import { ResourceAllocationTimeRangeWidget } from "@/components/dashboard-v2/widgets/time-range-widgets/resource-allocation-time-range-widget";
import { ResourceUtilizationTimeRangeWidget } from "@/components/dashboard-v2/widgets/time-range-widgets/resource-utilization-time-range-widget";
//model
import type { IFilterModel } from "@/models/filter.model";

export default defineComponent({
  name: "widget-layout",
  components: {
    ReadyGpuWidget,
    AllocatedGpuWidget,
    ReadyNodesWidget,
    GpuAllocationRatioWidget,
    NodePoolFreeResourcesWidget,
    ResourceAllocationByWorkloadWidget,
    WorkloadStatusWidget,
    PendingWorkloadsWidget,
    RunningWorkloadsWidget,
    ResourceAllocationTimeRangeWidget,
    ResourceUtilizationTimeRangeWidget,
  },
  props: {
    widgetsLayout: {
      type: Array as PropType<EWidgetComponent[][]>,
      required: true,
    },
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
    filterByDates: {
      type: Object as PropType<IRangeDates>,
      required: false,
    },
    filterBy: {
      type: Array as PropType<IFilterModel[]>,
      required: false,
    },
  },
  methods: {
    getColClass(widgetCount: number, rowIndex: number): string {
      if (rowIndex === 1) {
        //temp solution for overview specific - need to refactor when dealing with responsive design
        return `col-xl col-sm-12 q-pr-xl-sm`;
      }
      let columnSize = Math.floor(12 / widgetCount);

      if (columnSize === 2) {
        return "";
      }
      if (columnSize === 6) {
        columnSize = 12;
      }
      return `col-md col-sm-${columnSize}`;
    },
  },
});
</script>

<style scoped lang="scss">
@media (max-width: 1919px) {
  .q-pr-xl-sm {
    padding-right: 8px;
  }
}
</style>
