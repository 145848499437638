<template>
  <q-drawer
    :model-value="showDrawer"
    :width="240"
    :mini-width="60"
    show-if-above
    :mini="miniState"
    :mini-to-overlay="overlay"
    behavior="desktop"
    @mouseover="onMouseOver"
    @mouseout="onMouseOut"
    side="left"
    class="side-nav"
    bordered
  >
    <side-nav-list v-if="showDashboardsList" :items="dashboards" @toggle-menu="dashboardClicked" />
    <q-separator class="separator" />
    <side-nav-list :items="managements" />
    <q-separator class="separator" />
    <side-nav-list :items="workloads" />
    <q-separator class="separator" />
    <side-nav-list :items="assets" />
    <q-separator class="separator" />
    <side-nav-list :items="events" />
  </q-drawer>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

import SideNavList from "./side-nav-list.vue";

import type { INavItem } from "./side-nav.model";

import { useSettingStore } from "@/stores/setting.store";
import { useAppStore } from "@/stores/app.store";
import { useClusterStore } from "@/stores/cluster.store";
import { usePermissionStore } from "@/stores/permissions.store";
import { isNewerVersion } from "@/utils/version.util";
import { assetsLinks, dashboardsLinks, eventsLinks, managementsLinks, workloadsLinks } from "./side-nav.model";
import { DASHBOARD_ROUTE_NAMES } from "@/router/dashboard.routes";
import { DEPARTMENT_ROUTE_NAMES } from "@/router/department.routes";
import type { SettingKeys } from "@/models/setting.model";
import { useAuthStore } from "@/stores/auth.store";
import { ResourceType, Action } from "@/swagger-models/authorization-client";

export default defineComponent({
  components: {
    SideNavList,
  },
  emits: ["mouse-over", "mouse-out"],
  props: {
    miniState: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    showDrawer: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    overlay: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      clusterStore: useClusterStore(),
      settingStore: useSettingStore(),
      permissionStore: usePermissionStore(),
      appStore: useAppStore(),
      authStore: useAuthStore(),
      drawer: false as boolean,
      activePage: "menu-icon-workspaces" as string,
      isDashboardMenuOpen: false as boolean,
    };
  },
  created() {
    if (this.$route.path.includes("dashboards")) {
      this.isDashboardMenuOpen = true;
    }
  },
  computed: {
    isDepartmentEnabled(): boolean {
      return this.settingStore.isDepartmentEnabled;
    },
    showDashboardsList(): boolean {
      return this.dashboards.some((item: INavItem) => item.subLinks?.length);
    },
    dashboards(): Array<INavItem> {
      return dashboardsLinks.map((item: INavItem) => {
        if (item.name === DASHBOARD_ROUTE_NAMES.OVERVIEW_INDEX) {
          return {
            ...item,
            open: this.isDashboardMenuOpen,
            hidden: this.isItemHidden(item),
            subLinks: item.subLinks?.filter((subItem: INavItem) => {
              switch (subItem.name) {
                case DASHBOARD_ROUTE_NAMES.CLUSTERS_DASHBOARD_INDEX:
                  return this.clusterStore.clusters.length > 1 && !this.isItemHidden(subItem);
                case DASHBOARD_ROUTE_NAMES.CONSUMPTION_INDEX:
                case DASHBOARD_ROUTE_NAMES.RESOURCES_INDEX:
                  return (
                    !this.authStore.isTrial &&
                    !this.isItemHidden(subItem) &&
                    this.permissionStore.isActionGrantedForEveryResource(
                      [ResourceType.Cluster, ResourceType.Department, ResourceType.Project],
                      Action.Read,
                    )
                  );
                default:
                  return !this.isItemHidden(subItem);
              }
            }),
          };
        }
        return { ...item, hidden: this.isItemHidden(item) };
      });
    },
    managements(): Array<INavItem> {
      return managementsLinks.map((item: INavItem) => {
        if (item.name === DEPARTMENT_ROUTE_NAMES.DEPARTMENT_INDEX) {
          return {
            ...item,
            hidden: !this.settingStore.isDepartmentEnabled || this.isItemHidden(item),
          };
        }
        return { ...item, hidden: this.isItemHidden(item) };
      });
    },
    workloads(): Array<INavItem> {
      return workloadsLinks.map((item: INavItem) => {
        return {
          ...item,
          hidden: this.isItemHidden(item),
        };
      });
    },
    assets(): Array<INavItem> {
      return assetsLinks.map((item: INavItem) => {
        return { ...item, hidden: this.isItemHidden(item) };
      });
    },
    events(): Array<INavItem> {
      return eventsLinks.map((item: INavItem) => {
        return { ...item, hidden: this.isItemHidden(item) };
      });
    },
  },
  methods: {
    isItemHidden(item: INavItem): boolean {
      const hasNoPermissions =
        item.minPermittedActions &&
        item.resourceType &&
        !this.permissionStore.hasSomePermittedActions(item.resourceType, item.minPermittedActions);

      const isFeatureOff = item.featureFlagName && !this.isFeatureOn(item.featureFlagName);
      const isNotSupportedVersion = item.minClusterVersion && !this.isNewerClusterVersion(item.minClusterVersion);

      return !!(hasNoPermissions || isFeatureOff || isNotSupportedVersion);
    },
    onMouseOver(): void {
      if (!this.miniState && this.overlay) return;
      this.$emit("mouse-over");
    },
    onMouseOut(): void {
      if (!this.miniState && !this.overlay) return;
      this.$emit("mouse-out");
    },
    dashboardClicked(item: INavItem): void {
      this.isDashboardMenuOpen = !item.open;
      if (this.isDashboardMenuOpen && item.subLinks?.length) {
        this.$router.push({ name: item.subLinks[0].name });
      }
    },
    isNewerClusterVersion(minClusterVersion: string): boolean {
      const clusterVersion: string = this.clusterStore.currentClusterVersion;
      return !!(clusterVersion && isNewerVersion(clusterVersion, minClusterVersion));
    },
    isFeatureOn(featureName: SettingKeys): boolean {
      return this.settingStore.settings.get(featureName)?.value || false;
    },
  },
});
</script>

<style lang="scss">
.side-nav {
  background-color: $navy;
  color: $white;

  .separator {
    background-color: $white-20;
  }
}
</style>
