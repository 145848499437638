/* eslint-disable */

import type {
  WorkspacePolicyDefaultsAndRules,
  EnvironmentVariable,
  WorkspacePolicyRules,
  WorkspacePolicyDefaults,
  PolicyMeta,
  PolicyType,
} from "@/swagger-models/policy-service-client";
import {
  PolicySyncStatusOfClusterStatusEnum,
  ScopeType,
  type PolicyListEntry,
} from "@/swagger-models/policy-service-client";

import type { IItemizedListItem } from "@/components/common/runai-itemized-list";
import { tableUtil } from "../table.util/table.util";
import type { IStatusColOptions } from "@/models/table.model";
import { capitalizeString } from "../string.util/string.util";

export const policyUtil = {
  mapPolicyToTableRows,
  mergeEnvironmentVariablesWithPolicyDefaults,
  createPolicyIdFromPolicyTypeAndMeta,
  createPolicyId,
  createPolicyIdFromPolicyListEntry,
  getPolicyTypeScopeAndScopeId,
  getPolicyStatusColOptions,
  getQueryObjectFromPolicyId,
  getPolicyScopePropertiesFromPolicyId,
};

export const policyIdSeperator = "_";

type IListPolicyQueryPolicyType = "Workspace" | "Training";
export interface IListPolicyQuery {
  policyType: IListPolicyQueryPolicyType;
  scope: ScopeType;
  departmentId?: string;
  projectId?: string;
  clusterId?: string;
}

export interface IPolicyTypeScopeAndScopeId {
  type: PolicyType;
  scope: ScopeType;
  scopeId: string | undefined;
}

export interface IPolicyScopeProperties {
  scope: ScopeType;
  projectId?: number | null;
  departmentId?: string | null;
  clusterId?: string | null;
}

const RULES_OPTIONS = [
  "canAdd",
  "canEdit",
  "required",
  "min",
  "max",
  "step",
  "options",
  "itemRules",
  "members",
  "locked",
];

function mapPolicyToTableRows(policy: WorkspacePolicyDefaultsAndRules): any {
  const policyMap: any = {};
  for (const key in policy.rules) {
    policyMap[key] = getParametersRules(policy.rules[key as keyof WorkspacePolicyRules]);
  }

  for (const key in policy.defaults) {
    policyMap[key] ??= {}; // if the key is not in the rules, it will be added here
    policyMap[key] = getParametersDefault(policyMap[key], policy.defaults[key as keyof WorkspacePolicyDefaults]);
  }
  return policyMap;
}

function getParametersRules(ruleSet: any, parameterPrefix = ""): any {
  let keyRulesMap: any = {};
  Object.entries(ruleSet).reduce((acc, [parameter, rules]: [string, any]) => {
    const key = parameterPrefix ? `${parameterPrefix}.${parameter}` : parameter;
    if (!isParameter(rules)) {
      keyRulesMap = { ...acc, ...getParametersRules(rules, key) };
    } else {
      keyRulesMap[key] ??= { name: key };
      if (rules.itemRules || rules.members) {
        if (rules.itemRules?.sourceOfRule) keyRulesMap[key].sourceOfRule = rules.itemRules.sourceOfRule;
        if (rules.members?.sourceOfRule && !keyRulesMap[key].sourceOfRule)
          keyRulesMap[key].sourceOfRule = rules.itemRules.members;
        keyRulesMap[key].rules = { ...getRules(rules.itemRules || {}), ...getRules(rules.members || {}) };
      } else {
        const { sourceOfRule, ...restRules } = rules;
        keyRulesMap[key].sourceOfRule = sourceOfRule;
        keyRulesMap[key].rules = restRules;
      }
    }
    return keyRulesMap;
  }, keyRulesMap);
  return keyRulesMap;
}

function getParametersDefault(rulesSet: any, defaultSet: any, parameterPrefix = "") {
  Object.entries(defaultSet).reduce((acc, [parameter, defaults]) => {
    const key = parameterPrefix ? `${parameterPrefix}.${parameter}` : parameter;
    if (defaults && typeof defaults === "object" && !Array.isArray(defaults)) {
      rulesSet = { ...rulesSet, ...getParametersDefault(rulesSet, defaults, key) };
    } else {
      rulesSet[key] ??= { name: key };
      rulesSet[key].default = defaults;
    }
    return rulesSet;
  }, rulesSet);
  return rulesSet;
}

function isParameter(rules: any): boolean {
  return Object.keys(rules).some((rule) => RULES_OPTIONS.includes(rule));
}

function getRules(rules: any): Record<string, any> {
  if (!rules || typeof rules !== "object" || Array.isArray(rules)) return {};
  return Object.entries(rules).reduce((rulesMap, [key, value]: [string, any]) => {
    if (RULES_OPTIONS.includes(key)) {
      rulesMap[key] = value;
    } else {
      rulesMap = { ...rulesMap, ...getRules(value) };
    }
    return rulesMap;
  }, {} as Record<string, any>);
}

function mergeEnvironmentVariablesWithPolicyDefaults(
  environmentVariables: Array<IItemizedListItem> | undefined,
  policy?: WorkspacePolicyDefaultsAndRules,
): Array<IItemizedListItem> {
  let list: Array<IItemizedListItem> = environmentVariables || [];

  if (!policy) return list;

  if (policy.defaults?.environment?.environmentVariables) {
    const defaultsVars = new Set(
      policy.defaults.environment.environmentVariables.map(
        (envVar: EnvironmentVariable) => `${envVar.name}|${envVar.value}`,
      ),
    );

    const deletedLst = new Set(
      list
        .filter((item: IItemizedListItem) => item.deleted)
        .map((envVar: EnvironmentVariable) => `${envVar.name}|${envVar.value}`),
    );

    // filtering the default items from copied/ template workload
    list = list.filter((item: IItemizedListItem) => !defaultsVars.has(`${item.name}|${item.value}`));

    // adding the defaults from the policy
    list = list.concat(
      policy.defaults.environment.environmentVariables.map((envVar: EnvironmentVariable) => {
        return {
          ...envVar,
          deleted: deletedLst.has(`${envVar.name}|${envVar.value}`),
          isDefault: true,
        };
      }),
    );
  }

  if (!policy.rules?.environment?.environmentVariables?.itemRules) return list;

  if (policy.rules.environment.environmentVariables.itemRules.locked) {
    const lockedKeys: Set<string> | undefined = new Set(policy.rules.environment.environmentVariables.itemRules.locked);
    list = list.map((item: IItemizedListItem) => {
      let locked = false;
      let lockedReason = "";
      if (lockedKeys?.has(item.name)) {
        locked = true;
        lockedReason = "These values have been defined by the administrator";
      }

      return {
        ...item,
        locked,
        lockedReason,
      };
    });
  }

  return list;
}

function createPolicyIdFromPolicyListEntry(policy: PolicyListEntry) {
  if (!policy.type) return policyIdSeperator;
  return createPolicyIdFromPolicyTypeAndMeta(policy.type, policy.meta);
}

function createPolicyIdFromPolicyTypeAndMeta(policyType: PolicyType, policyMeta?: PolicyMeta): string {
  if (!policyMeta) return policyIdSeperator;
  const { scope, projectId, departmentId, clusterId } = policyMeta;
  switch (scope) {
    case ScopeType.Tenant:
      return policyUtil.createPolicyId(policyType, ScopeType.Tenant);
    case ScopeType.Cluster:
      return policyUtil.createPolicyId(policyType, ScopeType.Cluster, String(clusterId));
    case ScopeType.Department:
      return policyUtil.createPolicyId(policyType, ScopeType.Department, String(departmentId));
    case ScopeType.Project:
      return policyUtil.createPolicyId(policyType, ScopeType.Project, String(projectId));
    default:
      return policyIdSeperator;
  }
}

function createPolicyId(policyType: PolicyType, policyScope: ScopeType, scopeId?: string | number): string {
  return !!scopeId
    ? `${policyType}${policyIdSeperator}${policyScope}${policyIdSeperator}${scopeId}`
    : `${policyType}${policyIdSeperator}${policyScope}`;
}

function getPolicyTypeScopeAndScopeId(policyId: string): IPolicyTypeScopeAndScopeId {
  const [type, scope, scopeId] = policyId.split(policyIdSeperator);
  return {
    type: type as PolicyType,
    scope: scope as ScopeType,
    scopeId,
  };
}

function getQueryObjectFromPolicyId(policyId: string): IListPolicyQuery {
  const policyTypeAndScope: IPolicyTypeScopeAndScopeId = getPolicyTypeScopeAndScopeId(policyId);

  const policyType = capitalizeString(policyTypeAndScope.type) as IListPolicyQueryPolicyType;

  switch (policyTypeAndScope.scope) {
    case ScopeType.Tenant:
      return {
        policyType,
        scope: policyTypeAndScope.scope,
      };
    case ScopeType.Cluster:
      return {
        policyType,
        scope: policyTypeAndScope.scope,
        clusterId: policyTypeAndScope.scopeId,
      };
    case ScopeType.Department:
      return {
        policyType,
        scope: policyTypeAndScope.scope,
        departmentId: policyTypeAndScope.scopeId,
      };
    case ScopeType.Project:
      return {
        policyType,
        scope: policyTypeAndScope.scope,
        projectId: policyTypeAndScope.scopeId,
      };
  }
}
function getPolicyScopePropertiesFromPolicyId(policyId: string): IPolicyScopeProperties {
  const policyTypeAndScope: IPolicyTypeScopeAndScopeId = getPolicyTypeScopeAndScopeId(policyId);

  switch (policyTypeAndScope.scope) {
    case ScopeType.Tenant:
      return {
        scope: policyTypeAndScope.scope,
      };
    case ScopeType.Cluster:
      return {
        scope: policyTypeAndScope.scope,
        clusterId: String(policyTypeAndScope.scopeId) || undefined,
      };
    case ScopeType.Department:
      return {
        scope: policyTypeAndScope.scope,
        departmentId: String(policyTypeAndScope.scopeId) || undefined,
      };
    case ScopeType.Project:
      return {
        scope: policyTypeAndScope.scope,
        projectId: Number(policyTypeAndScope.scopeId) || undefined,
      };
  }
}

function getPolicyStatusColOptions(
  status?: PolicySyncStatusOfClusterStatusEnum,
  errorMessage?: string,
): IStatusColOptions {
  if (!status) {
    return {
      status: "-",
      tooltipText: "",
      displayAnimation: false,
      filterKey: "status",
    };
  }
  return tableUtil.getStatusColOptions(policyStatusMap[status], errorMessage);
}

export const policyStatusMap: Record<PolicySyncStatusOfClusterStatusEnum, IStatusColOptions> = {
  [PolicySyncStatusOfClusterStatusEnum.Ready]: {
    status: "Ready",
    color: "success",
    displayAnimation: false,
    filterKey: "status",
  },
  [PolicySyncStatusOfClusterStatusEnum.NotReady]: {
    status: "Not Ready",
    color: "negative",
    displayAnimation: false,
    filterKey: "status",
  },
  [PolicySyncStatusOfClusterStatusEnum.Failed]: {
    status: "Failed",
    color: "negative",
    displayAnimation: false,
    filterKey: "status",
  },
  [PolicySyncStatusOfClusterStatusEnum.Deleted]: {
    status: "Deleted",
    displayAnimation: false,
    filterKey: "status",
  },
  [PolicySyncStatusOfClusterStatusEnum.DeletionFailed]: {
    status: "Failed",
    color: "negative",
    displayAnimation: false,
    filterKey: "status",
  },
  [PolicySyncStatusOfClusterStatusEnum.Applying]: {
    status: "Updating...",
    displayAnimation: true,
    filterKey: "status",
  },
  [PolicySyncStatusOfClusterStatusEnum.Deleting]: {
    status: "Deleting...",
    displayAnimation: true,
    filterKey: "status",
  },
  [PolicySyncStatusOfClusterStatusEnum.PendingDeletion]: {
    status: "Deleting...",
    displayAnimation: true,
    filterKey: "status",
  },
};
