<template>
  <q-chip clickable size="md" :removable="removable" class="runai-filter-chip">
    {{ title }}:
    <span class="q-px-xs text-weight-medium"> {{ value }}</span>
    <q-menu
      class="page-filter-popup"
      transition-show="scale"
      transition-hide="scale"
      transition-duration="500"
      :model-value="display"
      @hide="$emit('close')"
    >
      <form @submit.prevent>
        <q-card-section class="row justify-between items-center q-py-sm">
          <div class="text-weight-medium">{{ title }}</div>
          <q-btn
            flat
            icon="fa-regular fa-xmark"
            class="q-pa-xs"
            round
            v-close-popup
            @click="$emit('close')"
            aid="close-btn"
          ></q-btn>
        </q-card-section>
        <q-card-section class="q-py-sm">
          <slot name="input-component"></slot>
        </q-card-section>
        <q-card-section class="row justify-end q-py-sm">
          <q-btn aid="apply-btn" type="submit" flat color="primary" label="Apply" v-close-popup @click="$emit('save')" />
        </q-card-section>
      </form>
    </q-menu>
  </q-chip>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  emits: ["save", "close"],
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    value: {
      type: String as PropType<string>,
      required: true,
    },
    display: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    removable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.page-filter-popup {
  width: 250px;
}
</style>
