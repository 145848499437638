import { createApp } from "vue";
import { createPinia } from "pinia";
import { Quasar, Notify } from "quasar";
import { useDirectives } from "./directives";
import { VueDOMPurifyHTML, VueDOMPurifyHTMLOptions } from "./plugins/vue-dom-purify-html";
import App from "./App.vue";
import router from "./router";
import "./plugins";

// Import fontawesome icon libraries
import iconSet from "./plugins/fontawesome-v6-pro";

import "@quasar/extras/roboto-font/roboto-font.css";
import "quasar/src/css/index.sass";

import "@/assets/scss/main.scss";

const app = createApp(App);

useDirectives(app);

app.use(createPinia());
app.use(router);
app.use(Quasar, {
  plugins: { Notify }, // import Quasar plugins and add here
  iconSet,
});
app.use(VueDOMPurifyHTML, VueDOMPurifyHTMLOptions);
app.mount("#app");
