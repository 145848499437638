<template>
  <runai-expansion-item class="security-section settings-container" label="Security" :subheader="sectionSubHeader">
    <sso-settings />
    <div class="row items-center q-mt-xl">
      <span class="q-mr-md">Set logout redirect URL</span>
      <runai-tooltip
        aid="tooltip-logout-redirect-url"
        tooltip-text="Users will be redirected to this URL after logging out from Run:ai."
        width="400px"
        tooltip-position="right"
      />
    </div>
    <settings-url-field
      :server-url="securitySettings.logoutUrl || ''"
      @save="saveLogoutUrl"
      delete-tool-tip="Remove redirect URL"
      edit-tool-tip="Edit redirect URL"
      add-button-label="+ redirect url"
      input-label="Redirect URL"
      delete-sub-title="Users will be directed back to the Run:ai sign-in screen after signing out."
    />
    <div class="row items-center q-mt-xl q-mb-sm">
      <span class="q-mr-md">Set session timeout</span>
      <runai-tooltip
        aid="tooltip-session-timeout"
        tooltip-text="Upon session timeout, users will be logged out automatically."
        width="400px"
        tooltip-position="right"
      />
    </div>
    <div class="input-with-label-wrapper row items-center q-mb-md q-mr-sm">
      <q-input
        label="Minutes"
        :model-value="securityInputs.timeout"
        @update:model-value="updateSessionTimeout(+$event)"
        class="q-mb-sm cost-input"
        no-error-icon
        outlined
        type="number"
        :min="0"
        @blur="saveSessionTimeout"
      />
    </div>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
// Models
// Components
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
// Stores
import { useSettingStore } from "@/stores/setting.store";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { ISecuritySettings, SettingKeys, settingUpdateSuccessMessage } from "@/models/setting.model";
import { alertUtil } from "@/utils/alert.util";
import SsoSettings from "@/components/settings/sections/security/sso-settings/sso-settings.vue";
import { useIdpsStore } from "@/stores/idps.store";
import { IdpTypeEnum } from "@/swagger-models/identity-manager-client";
import SettingsUrlField from "@/components/settings/settings-url-field/settings-url-field.vue";
import { settingsUtil } from "@/utils/settings.util";
// Utils

export default defineComponent({
  name: "security-section",
  components: {
    SettingsUrlField,
    SsoSettings,
    RunaiTooltip,
    RunaiExpansionItem,
  },
  props: {
    securitySettings: {
      type: Object as PropType<ISecuritySettings>,
      required: true,
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
      idpStore: useIdpsStore(),
      selectIdentityProvider: false as boolean,
      showLogoutUrl: false as boolean,
      timeoutHasChanged: false as boolean,
      securityInputs: {
        timeout: 60,
        logoutUrl: null,
      } as ISecuritySettings,
      originalSecurityInputs: {
        timeout: 60,
        logoutUrl: null,
      } as ISecuritySettings,
    };
  },
  created() {
    this.securityInputs = { ...this.securitySettings };
    this.originalSecurityInputs = { ...this.securitySettings };
  },
  computed: {
    sectionSubHeader(): string {
      const currentIdp = this.idpStore.currentIdp;
      switch (currentIdp?.alias) {
        case IdpTypeEnum.Saml:
          return "SSO - SAML 2.0";
        case IdpTypeEnum.Oidc:
          return "SSO - OpenID Connect";
        case IdpTypeEnum.OpenshiftV4:
          return "SSO - OpenShift V4";
        default:
          return "None";
      }
    },
  },
  methods: {
    updateSessionTimeout(value: number): void {
      if (value !== this.securityInputs.timeout) {
        this.securityInputs.timeout = value;
        this.timeoutHasChanged = true;
      } else {
        this.timeoutHasChanged = false;
      }
    },
    async saveSessionTimeout(): Promise<void> {
      if (this.timeoutHasChanged) {
        await this.saveSettings(SettingKeys.InactivityMinutesTillLogout, this.securityInputs.timeout);
      }
    },
    async saveLogoutUrl(url: string): Promise<void> {
      await this.saveSettings(SettingKeys.LogoutURI, url);
    },
    async saveSettings(key: SettingKeys, value: string | boolean | number): Promise<void> {
      try {
        await this.settingStore.updateSetting(key, value);
        this.originalSecurityInputs = { ...this.securityInputs };
        this.$q.notify(alertUtil.getSuccess(settingUpdateSuccessMessage));
      } catch (error: unknown) {
        this.$q.notify(settingsUtil.getSettingErrorMessage());
      }
    },
  },
});
</script>
