<template>
  <runai-expansion-item
    label="Model"
    class="model-catalog-section q-pb-none"
    :default-opened="isSelectable"
    :disable-opening="!isSelectable"
    :disable-closing="isSelectable"
    hide-expend-icon
  >
    <template #subheader>
      <div class="row items-center justify-between q-pr-sm">
        <span>{{ summary }}</span>
      </div>
    </template>
    <runai-form-card-section
      :loading="loading"
      main-message="Select an existing model to launch a new inference quickly"
      :cards-list="modelCards"
      :selected-cards-ids="selectedModelIds"
      search-name="models"
      @selected-card-changed="onSelectedModel"
      :sort-options="{ name: true, creation: true }"
      default-sort-option="name"
    />
    <div class="col-12">
      <q-field class="hidden-field" :model-value="selectedModelId" :rules="[isRequired]"></q-field>
    </div>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// Models
import type { ModelAsset } from "@/swagger-models/assets-service-client";
import { TCardCmpName, type ICardListItem } from "@/components/common/runai-card-list";

// Components
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { RunaiFormCardSection } from "@/components/common";

// Utils
import { required } from "@/common/form.validators";

// Constants
import { errorMessages } from "@/common/error-message.constant";
import { useSettingStore } from "@/stores/setting.store";

export default defineComponent({
  components: {
    RunaiExpansionItem,
    RunaiFormCardSection,
  },
  props: {
    entityType: {
      type: String as PropType<string>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    inferenceModels: {
      type: Array as PropType<Array<ModelAsset>>,
      required: true,
    },
    selectedModelId: {
      type: [String, null] as PropType<string | null>,
      required: true,
    },
    isSelectable: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
    };
  },
  computed: {
    allowDeployingGatedModelsEnabled(): boolean {
      return this.settingStore.isAllowDeployingGatedModelsEnabled;
    },
    modelCards(): Array<ICardListItem> {
      return this.inferenceModels.map((model: ModelAsset) => {
        const name = model.meta.name;
        let disabledModel = model.spec.license != "" && !this.allowDeployingGatedModelsEnabled;

        return {
          id: model.meta.id,
          disabled: disabledModel,
          tooltip: disabledModel ? "This is a gated model. Contact Run:ai support to enable its use." : "",
          cardName: TCardCmpName.MODEL_CATALOG,
          searchValues: [name].filter((val) => !!val),
          data: model,
          sortInfo: {
            name: model.meta.name,
            createdAt: model.meta.createdAt,
          },
        };
      });
    },
    selectedModelIds(): Array<string> {
      return this.selectedModelId ? [String(this.selectedModelId)] : [];
    },
    summary(): string {
      return this.inferenceModels.find((im: ModelAsset) => im.meta.id === this.selectedModelId)?.meta.name || "None";
    },
  },
  methods: {
    onSelectedModel(selectedItems: Array<string>): void {
      selectedItems.length === 0 ? this.$emit("model-changed", null) : this.$emit("model-changed", selectedItems[0]);
    },
    isRequired(val: string | null): boolean | string {
      return (val && required(val)) || errorMessages.SELECT_MODEL;
    },
  },
});
</script>
