import { ResourceType, Action } from "@/swagger-models/authorization-client";
import type { RunaiRouteRecord } from "vue-router";

export enum CREDENTIAL_ROUTE_NAMES {
  CREDENTIAL_INDEX = "credential-index",
  CREDENTIAL_USERPASS_NEW = "userpass-new",
  CREDENTIAL_ACCESS_KEY_NEW = "access-key-new",
  CREDENTIAL_DOCKER_REGISTRY_NEW = "docker-registry-new",
}

export const credentialRoutes: RunaiRouteRecord = {
  path: "/credentials",
  // route level code-splitting
  // this generates a separate chunk (department.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import("../views/credential/credential-app.vue"),
  children: [
    {
      path: "",
      name: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_INDEX,
      component: () => import("../views/credential/credential-index.vue"),
      meta: {
        pageTitle: "Credentials",
        resourceType: ResourceType.Credentials,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
        hideClusterSelection: true,
      },
    },
    {
      path: "userpass/new",
      name: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_USERPASS_NEW,
      component: () => import("@/views/credential/userpass/userpass-new.vue"),
      meta: {
        pageTitle: "New credential",
        fullWidth: true,
        requestToLeave: true,
        backPageName: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_INDEX,
        resourceType: ResourceType.Credentials,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
        hideClusterSelection: true,
      },
    },
    {
      path: "access-key/new",
      name: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_ACCESS_KEY_NEW,
      component: () => import("@/views/credential/access-key/access-key-new.vue"),
      meta: {
        pageTitle: "New credential",
        fullWidth: true,
        requestToLeave: true,
        backPageName: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_INDEX,
        resourceType: ResourceType.Credentials,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
        hideClusterSelection: true,
      },
    },
    {
      path: "docker-registry/new",
      name: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_DOCKER_REGISTRY_NEW,
      component: () => import("@/views/credential/docker-registry/docker-registry-new.vue"),
      meta: {
        pageTitle: "New credential",
        fullWidth: true,
        requestToLeave: true,
        backPageName: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_INDEX,
        resourceType: ResourceType.Credentials,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
        hideClusterSelection: true,
      },
    },
  ],
};
