<template>
  <div class="toggle-setting row items-center">
    <div class="col-4 items-center">
      <div class="row items-center">
        <div class="col-10">
          <span class="q-mr-md text-subtitle1 subtitle">{{ setting?.label }}</span>
        </div>
        <div class="col-2">
          <q-toggle
            :model-value="Boolean(setting?.value)"
            @update:model-value="updateSettings(setting.key, $event)"
            :disable="disabled"
            :aid="toggleAid"
          >
            <q-tooltip v-if="tooltipMessage" anchor="bottom end">{{ tooltipMessage }}</q-tooltip>
          </q-toggle>
        </div>
      </div>
    </div>
    <q-separator vertical class="q-mx-lg" />
    <div class="col-5">
      <div class="description" v-html="setting?.description"></div>
      <runai-information-bar v-if="warningMessage" class="q-mt-md" type="warning">
        {{ warningMessage }}
      </runai-information-bar>
    </div>
    <div class="col-2 toggle-item">
      <q-badge
        v-if="badgeText"
        class="badge"
        outline
        align="middle"
        :class="{ legacy: isLegacy, experimental: isExperimental, beta: isBeta, emptyBadge: !hasBadge }"
      >
        {{ badgeText }}
        <q-tooltip v-if="badgeTooltip" max-width="300px" anchor="bottom end">{{ badgeTooltip }}</q-tooltip>
      </q-badge>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { type PropType, defineComponent } from "vue";

// components
import RunaiInformationBar from "@/components/common/runai-information-bar/runai-information-bar.vue";

//stores
import { useSettingStore } from "@/stores/setting.store";

// models
import { AlphaStage, EBadgeLabel, settingUpdateSuccessMessage } from "@/models/setting.model";
import type { Setting } from "@/swagger-models/backend-client";
import type { SettingKeys } from "@/models/setting.model";

// utils
import { settingsUtil } from "@/utils/settings.util";
import { alertUtil } from "@/utils/alert.util";

export default defineComponent({
  name: "toggle-setting",
  components: { RunaiInformationBar },
  emits: ["update-setting"],
  props: {
    setting: {
      type: Object as PropType<Setting>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    warningMessage: {
      type: String,
      default: "",
    },
    emitOnly: {
      type: Boolean,
      default: false,
    },
    tooltipMessage: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    settingStore: useSettingStore(),
  }),
  computed: {
    hasBadge(): boolean {
      return this.badgeText !== "";
    },
    isExperimental(): boolean {
      return this.setting?.stage === AlphaStage;
    },
    isBeta(): boolean {
      return this.setting?.stage === EBadgeLabel.Beta;
    },
    isLegacy(): boolean {
      return this.setting?.stage === EBadgeLabel.Legacy;
    },
    badgeText(): string {
      switch (this.setting?.stage) {
        case AlphaStage:
          return EBadgeLabel.Experimental;
        case EBadgeLabel.Beta:
          return EBadgeLabel.Beta;
        case EBadgeLabel.Legacy:
          return EBadgeLabel.Legacy;
        default:
          return "";
      }
    },
    badgeTooltip(): string {
      switch (this.setting?.stage) {
        case AlphaStage:
          return "This feature is in early development. It may not be stable and could be removed or changed significantly in future versions. Use with caution.";
        case EBadgeLabel.Beta:
          return "This feature is still being developed for official release in a future version and may have some limitations.  Use with caution.";
        case EBadgeLabel.Legacy:
          return "This feature is scheduled to be removed in future versions. We recommend using alternatives if available.  Use only if necessary.";
        default:
          return "";
      }
    },
    toggleAid(): string {
      return `toggle-setting-${this.setting?.key}`;
    },
  },
  methods: {
    async updateSettings(key: string | undefined, value: string | number | boolean | null): Promise<void> {
      if (value === null || key === undefined) return;
      if (!this.emitOnly) {
        try {
          await this.settingStore.updateSetting(key as SettingKeys, value);
          this.$q.notify(alertUtil.getSuccess(settingUpdateSuccessMessage));
        } catch (err) {
          this.$q.notify(settingsUtil.getSettingErrorMessage());
        }
      }
      this.$emit("update-setting", key, value);
    },
  },
});
</script>
<style lang="scss" scoped>
.badge {
  cursor: pointer;
  font-size: 12px;
  width: 90px;
}
.legacy {
  color: $black-54 !important;
  padding: 1px 24px;
}
.experimental {
  color: $lighter-green !important;
  padding: 1px 10px;
}
.beta {
  color: $runai-orange !important;
  padding: 1px 30px;
}
.toggle-item {
  text-align: center;
}
.description {
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.2;
  color: $black-54;
}
.subtitle {
  line-height: 1.5;
}
.emptyBadge {
  display: none;
  border: white;
}
</style>
