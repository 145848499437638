<template>
  <section class="settings-index q-pt-md">
    <user-profile-section />
    <email-notifications-section :notifications-settings="notificationsSettings" />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// stores
import { useAppStore } from "@/stores/app.store";
import UserProfileSection from "@/components/user-settings/sections/user-profile-section/user-profile-section.vue";
import EmailNotificationsSection from "@/components/user-settings/sections/email-notifications-section/email-notifications-section.vue";
import type { NotificationSettings } from "@/models/user-setting.model";
import { NotificationCategory, NotificationType } from "@/models/user-setting.model";

export default defineComponent({
  name: "user-settings-index",
  components: { EmailNotificationsSection, UserProfileSection },
  data() {
    return {
      appStore: useAppStore(),
      // mock until real data
      notificationsSettings: [
        {
          key: "all",
          type: NotificationType.WORKLOAD,
          enabled: false,
          title: "All",
          category: NotificationCategory.STATUS,
        },
        {
          key: "creating",
          type: NotificationType.WORKLOAD,
          enabled: true,
          title: "Creating",
          category: NotificationCategory.STATUS,
        },
        {
          key: "pending",
          type: NotificationType.WORKLOAD,
          enabled: false,
          title: "Pending",
          category: NotificationCategory.STATUS,
        },
        {
          key: "initializing",
          type: NotificationType.WORKLOAD,
          enabled: true,
          title: "Initializing",
          category: NotificationCategory.STATUS,
        },
        {
          key: "running",
          type: NotificationType.WORKLOAD,
          enabled: true,
          title: "Running",
          category: NotificationCategory.STATUS,
        },
        {
          key: "running-with-issues",
          type: NotificationType.WORKLOAD,
          enabled: false,
          title: "Running with issues",
          category: NotificationCategory.STATUS,
        },
        {
          key: "completed",
          type: NotificationType.WORKLOAD,
          enabled: true,
          title: "Completed",
          category: NotificationCategory.STATUS,
        },
        {
          key: "failed",
          type: NotificationType.WORKLOAD,
          enabled: true,
          title: "Failed",
          category: NotificationCategory.STATUS,
        },
        {
          key: "stopped",
          type: NotificationType.WORKLOAD,
          enabled: true,
          title: "Stopped",
          category: NotificationCategory.STATUS,
        },
        {
          key: "deleted",
          type: NotificationType.WORKLOAD,
          enabled: true,
          title: "Deleted",
          category: NotificationCategory.STATUS,
        },
        {
          key: "workspace",
          type: NotificationType.WORKLOAD,
          enabled: false,
          title: "Workspace",
          category: NotificationCategory.TIMEOUT,
        },
        {
          key: "creating",
          type: NotificationType.WORKLOAD,
          enabled: true,
          title: "Creating",
          category: NotificationCategory.TIMEOUT,
        },
      ] as Array<NotificationSettings>,
    };
  },
  created() {
    this.appStore.setPageLoading(false);
  },
  computed: {},
  methods: {},
});
</script>
