import { defineStore } from "pinia";
import { useClusterStore } from "./cluster.store";

import type { IFilterBy } from "@/models/filter.model";
import { nodePoolColumns } from "@/table-models/node-pool.table-model";
import { filterService } from "@/services/filter.service/filter.service";
import type { INodePool } from "@/models/node-pool.model";
import { nodePoolService } from "@/services/control-plane/node-pool.service/node-pool.service";
import { isNewerVersion } from "@/utils/version.util";
import { MIN_NODE_WORKLOAD_METRICS_VERSION, TEST_ENV_VERSION } from "@/common/version.constant";

export const useNodePoolStore = defineStore("NodePool", {
  state: () => ({
    nodePools: [] as Array<INodePool>,
    selected: null as INodePool | null,
    nodePoolsCount: 0 as number,
    clusterStore: useClusterStore(),
    nodePoolAdded: null as null | INodePool,
  }),
  getters: {
    lastCreatedNodePool(): INodePool | null {
      return this.nodePoolAdded;
    },
    nodePoolList(): Array<INodePool> {
      if (!this.nodePoolAdded) return this.nodePools;
      return this.nodePools.filter((nodePool: INodePool) => nodePool.id !== this.nodePoolAdded?.id);
    },
    selectedNodePool(): INodePool | null {
      return this.selected;
    },
    isOnlyDefaultNodePool(): boolean {
      return this.nodePoolsCount === 1;
    },
    hasOverProvisioning(): boolean {
      return this.nodePools.some((nodePool: INodePool) => nodePool.overProvisioningRatio != 1);
    },
  },
  actions: {
    async loadNodePools(filterBy: IFilterBy = {}, withMetric = false): Promise<void> {
      const clusterUuid: string = this.clusterStore.currentClusterId;
      if (this.nodePools.length === 0) {
        //first load
        const nodePools = await nodePoolService.getNodePools(clusterUuid);
        this.nodePools = nodePools.map((nodePool: INodePool) => {
          return { ...nodePool, nodes: JSON.parse(nodePool.nodes) };
        });
      } else {
        const updatedNodePools = await nodePoolService.getNodePools(clusterUuid);
        this.nodePools = updatedNodePools.map((updatedNodePool: INodePool) => {
          // we want to keep the metric data and only update data from backend.
          const nodePool: INodePool | undefined = this.nodePools.find(
            (nodePool: INodePool) => nodePool.name === updatedNodePool?.name,
          );
          const nodes: string = updatedNodePool?.nodes ? JSON.parse(updatedNodePool.nodes) : nodePool?.nodes;
          return { ...nodePool, ...updatedNodePool, nodes };
        });
        this.updateNodePoolAddedWithRealtimeData(updatedNodePools);
      }

      if (withMetric) {
        await this.loadNodePoolsWithMetrics(filterBy);
      }
    },
    updateNodePoolAddedWithRealtimeData(nodePools: INodePool[]): void {
      if (this.nodePoolAdded === null) return;

      const updatedNodePoolAdded = nodePools.find(
        (updatedNode: INodePool) => updatedNode.name === this.nodePoolAdded?.name,
      );
      if (updatedNodePoolAdded) {
        this.nodePoolAdded = { ...this.nodePoolAdded, ...updatedNodePoolAdded };
      }
    },
    async loadNodePoolsWithMetrics(filterBy: IFilterBy): Promise<void> {
      const clusterVersion = this.clusterStore.currentClusterVersion;
      const useNewMetrics =
        isNewerVersion(clusterVersion, MIN_NODE_WORKLOAD_METRICS_VERSION) || clusterVersion.includes(TEST_ENV_VERSION);
      const displayedColumns = nodePoolColumns.filter((column) => column.display);
      const npList = await nodePoolService.list(
        this.nodePools,
        clusterVersion,
        this.clusterStore.currentClusterId,
        displayedColumns,
        useNewMetrics,
      );
      this.nodePools = filterService.filterListByTableFilters(npList, filterBy, nodePoolColumns);
    },
    async loadNodePoolsCount(): Promise<void> {
      const nodePools: INodePool[] = await nodePoolService.getNodePools(this.clusterStore.currentClusterId);
      this.nodePoolsCount = nodePools.length;
    },
    setSelectedNodePool(nodePool: INodePool | null): void {
      this.selected = nodePool;
    },
    async addNodePool(nodePool: INodePool): Promise<void> {
      const createdNodePool: INodePool = await nodePoolService.create(this.clusterStore.currentClusterId, {
        name: nodePool.name,
        labelKey: nodePool.labelKey,
        labelValue: nodePool.labelValue,
        placementStrategy: nodePool.placementStrategy,
        overProvisioningRatio: nodePool.overProvisioningRatio,
      });
      if (!createdNodePool) return;
      this.setNodePoolAdded(createdNodePool);
    },
    async editNodePool(nodePool: INodePool): Promise<void> {
      await nodePoolService.edit(this.clusterStore.currentClusterId, nodePool.id, {
        labelKey: nodePool.labelKey,
        labelValue: nodePool.labelValue,
        placementStrategy: nodePool.placementStrategy,
        overProvisioningRatio: nodePool.overProvisioningRatio,
      });
    },
    async removeNodePool(nodePoolId: number): Promise<void> {
      await nodePoolService.remove(this.clusterStore.currentClusterId, nodePoolId);
    },
    setNodePoolAdded(nodePoolAdded: INodePool): void {
      this.nodePoolAdded = nodePoolAdded;
    },
    removeNodePoolAdded(): void {
      this.nodePoolAdded = null;
    },
    getNodePool(nodePoolId: number): INodePool | undefined {
      return this.nodePools.find((nodePool: INodePool) => nodePool.id === nodePoolId);
    },
  },
});
