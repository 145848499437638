import {
  Configuration,
  TenantsApi,
  type ConfigurationParameters,
  ManagementApi,
} from "@/swagger-models/tenants-manager-client";

class TenantManagerApi {
  configuration: Configuration;

  managementApi: ManagementApi;
  tenantsApi: TenantsApi;

  constructor() {
    this.configuration = new Configuration();

    this.managementApi = new ManagementApi(this.configuration, this.baseUrl);
    this.tenantsApi = new TenantsApi(this.configuration, this.baseUrl);
  }

  get baseUrl() {
    return `${window.location.origin}`;
  }

  public set setAccessToken(accessToken: ConfigurationParameters["accessToken"]) {
    this.configuration.accessToken = accessToken;
  }
}

export const tenantManagerApi = new TenantManagerApi();
