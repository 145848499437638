import type { BreadcrumbsFormatterCallbackFunction, Options as HighchartsOptions } from "highcharts";

export enum CHART_COLOR_PALETTE {
  // Don't change the order of the colors!!!
  // The order of the colors is important for the chart it will be consistent and look nice.
  ELECTRIC_SKY_BLUE = "#0BB4FF",
  MIDNIGHT_INDIGO = "#372FB5",
  LIVELY_LIME = "#50E991",
  CORAL_ORANGE = "#FF8552",
  STORMY_LAVENDER = "#6075AF",
  GOLDEN_APRICOT = "#EBAD5D",
  ROYAL_PURPLE = "#B048F3",
  AQUAMARINE = "#55D6BE",
  MAGENTA_BERRY = "#D336B9",
}

export const TURQUOISE = "#208EB7";

export enum SINGLE_STAT_COLOR_PALETTE {
  GREEN = "#4CAF50",
  RED = "#EF5350",
  YELLOW = "#FFAB00",
}
export const chartColorPaletteArray = Object.entries(CHART_COLOR_PALETTE).map(([, value]) => value);

export interface IChartData {
  name: string;
  y: number;
  drilldown?: string;
}

export enum EChartType {
  COLUMN = "column",
  STACKED_COLUMN = "stacked_column",
  MULTI_BAR = "multi_bar",
}

export interface IChartSeries {
  name: string;
  data: Array<IChartData>;
}

export interface IDrilldownSeries {
  id: string;
  data: [string, number][];
}

export interface IColumnChartSeries {
  title: string;
  data: number[];
  tooltipTitle?: string;
}

export interface IColorConfigurationItem {
  from: number;
  to: number;
  color: SINGLE_STAT_COLOR_PALETTE;
}

export const UNKNOWN_COLOR = CHART_COLOR_PALETTE.ROYAL_PURPLE;

//server side drilldown
export const DEFAULT_CHART_OPTIONS = {
  plotOptions: {
    column: {
      pointWidth: 30,
      borderWidth: 0,
    },
  },
  tooltip: {
    backgroundColor: "#46465C",
    style: {
      color: "#F0F0F0",
      fontSize: "12px",
    },
    borderColor: "#46465C",
    shape: "square",
    headerFormat: "",
  },
  legend: { enabled: true },
  title: "",
  style: {
    color: "#F0F0F0",
    fontSize: "12px",
  },
  borderColor: "#46465C",
  shape: "square",
  chart: {
    type: "column",
    height: 250,
    style: {
      fontFamily: "Roboto",
    },
    events: {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      drilldown: () => {},
    },
  },

  yAxis: {
    min: 0,
    max: 100,
    title: {
      text: "Ratio",
    },
    labels: {
      format: "{value}%",
    },
  },
  xAxis: {
    type: "category",
  },
  colors: chartColorPaletteArray,
  series: [],
  drilldown: {
    series: [],
  },
  credits: {
    enabled: false,
  },
  allowPointDrilldown: false,
} as HighchartsOptions;

//dashboard widgets

export enum EWidgetComponent {
  WorkloadStatus = "WorkloadStatusWidget",
  RunningWorkloads = "RunningWorkloadsWidget",
  PendingWorkloads = "PendingWorkloadsWidget",
  ResourceAllocationTimeRangeWidget = "ResourceAllocationTimeRangeWidget",
  ResourceUtilizationTimeRangeWidget = "ResourceUtilizationTimeRangeWidget",
  NodePoolFreeResourcesWidget = "NodePoolFreeResourcesWidget",
  WorkloadAverageWaitTimeWidget = "WorkloadAverageWaitTimeWidget",
  ResourceAllocationByWorkloadWidget = "ResourceAllocationByWorkloadWidget",
  ReadyNodesWidget = "ReadyNodesWidget",
  ReadyGpuWidget = "ReadyGpuWidget",
  AllocatedGpuWidget = "AllocatedGpuWidget",
  GpuAllocationRatioWidget = "GpuAllocationRatioWidget",
}

export enum EWidgetEntity {
  Department = "Department",
  Nodepool = "Node pool",
  Node = "Node",
  Workload = "Workload",
}

export interface IWidgetWrapperOptions {
  title: string;
  timeFrame: string;
  linkText?: string;
  entityType?: EWidgetEntity;
}

export const TOOLTIP_BACKGROUND_COLOR = "#000000CC";
export const TOOLTIP_TEXT_COLOR = "#FFFFFF";
export const ACTIVE_AXIS_LABEL_COLOR = "#0654fe";
export interface IChartWidgetOptions {
  type: "column" | "line" | "area" | "scatter" | "packedbubble";
  height: number;
  sharedTooltip?: boolean;
  showSharedCrosshair?: boolean;
  yAxisTitle: string;
  showYAxisPercentage?: boolean;
  yAxisFormatFunction?: (value: string | number) => string;
  drilldownFormatter?: BreadcrumbsFormatterCallbackFunction;
}

export interface ITooltipPoint {
  name: string;
  y: string | number | null | undefined;
  color?: string;
  symbol?: string;
}

export type TMeasurementsTimeAndValue = [number, number | null];
