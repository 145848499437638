import { CLUSTER_COLUMN_FILTER_NAME } from "@/models/filter.model";
import { ECustomCell, type ITableColumn } from "@/models/table.model";
import { Scope, type AssetRef, type AssetUsageInfo, type ComputeAsset } from "@/swagger-models/assets-service-client";
import { assetsUtil } from "@/utils/assets.util/assets.util";
import { dateUtil } from "@/utils/date.util";
import { getMemoryUnitsForUI } from "@/utils/table-format.util";

export const allComputeResourceColumnsMap: Record<string, ITableColumn> = {
  computeResource: {
    name: "compute-resource",
    label: "Compute resource",
    field: (row) => row.meta.name,
    sortable: true,
    align: "left",
  },
  gpuDevicesRequest: {
    name: "gpu-devices-request",
    label: "GPU devices request per pod",
    field: (row: ComputeAsset) => row.spec.gpuDevicesRequest,
    sortable: true,
    align: "left",
    format: (gpuDevicesRequest?: number): string => {
      return typeof gpuDevicesRequest === "number" ? String(gpuDevicesRequest) : "-";
    },
  },
  gpuMemoryRequest: {
    name: "gpu-memory-request",
    label: "GPU memory request per device",
    field: (row: ComputeAsset) => row,
    sortable: true,
    align: "left",
    format: (row: ComputeAsset): string => {
      const { gpuPortionRequest, gpuMemoryRequest, migProfile } = row.spec;
      return (
        (gpuMemoryRequest && getMemoryUnitsForUI(gpuMemoryRequest)) ||
        (gpuPortionRequest && `${gpuPortionRequest * 100}%`) ||
        migProfile ||
        "-"
      );
    },
  },
  gpuMemoryLimit: {
    name: "gpu-memory-limit",
    label: "GPU memory limit per device",
    field: (row: ComputeAsset) => row,
    sortable: true,
    align: "left",
    format: (row: ComputeAsset): string => {
      const { gpuPortionLimit, gpuMemoryLimit } = row.spec;
      // currently no limit property for mig
      return (
        (gpuMemoryLimit && getMemoryUnitsForUI(gpuMemoryLimit)) ||
        (gpuPortionLimit && `${gpuPortionLimit * 100}%`) ||
        "-"
      );
    },
  },
  cpuCoresRequest: {
    name: "cpu-cores-request",
    label: "CPU compute request",
    field: (row: ComputeAsset) => row.spec.cpuCoreRequest,
    sortable: true,
    align: "left",
    format: (cpuCoreRequest?: number): string => {
      if (typeof cpuCoreRequest !== "number") return "-";
      let postfix = "cores";
      let amount = cpuCoreRequest;
      if (cpuCoreRequest < 1 && cpuCoreRequest !== 0) {
        amount *= 1000;
        postfix = "millicores";
      }
      return `${amount} ${postfix}`;
    },
  },
  cpuCoresLimit: {
    name: "cpu-cores-limit",
    label: "CPU compute limit",
    field: (row: ComputeAsset) => row.spec.cpuCoreLimit,
    sortable: true,
    align: "left",
    format: (cpuCoreLimit?: number): string => {
      if (typeof cpuCoreLimit !== "number") return "-";
      let postfix = "cores";
      let amount = cpuCoreLimit;
      if (cpuCoreLimit < 1 && cpuCoreLimit !== 0) {
        amount *= 1000;
        postfix = "millicores";
      }
      return `${amount} ${postfix}`;
    },
  },
  cpuMemoryRequest: {
    name: "cpu-memory-request",
    label: "CPU memory request",
    field: (row: ComputeAsset) => getMemoryUnitsForUI(row.spec.cpuMemoryRequest || ""),
    sortable: true,
    align: "left",
    sort: (a: string, b: string) => {
      let aMemory = Number(a?.split(" ")[0] || "");
      let bMemory = Number(b?.split(" ")[0] || "");
      const aUnit = a?.split(" ")[1] || "";
      const bUnit = b?.split(" ")[1] || "";
      if (aUnit === "GB") {
        aMemory *= 1000;
      }
      if (bUnit === "GB") {
        bMemory *= 1000;
      }
      return aMemory - bMemory;
    },
  },
  cpuMemoryLimit: {
    name: "cpu-memory-limit",
    label: "CPU memory limit",
    field: (row: ComputeAsset) => getMemoryUnitsForUI(row.spec.cpuMemoryLimit || ""),
    sortable: true,
    align: "left",
    sort: (a: string, b: string) => {
      let aMemory = Number(a?.split(" ")[0] || "");
      let bMemory = Number(b?.split(" ")[0] || "");
      const aUnit = a?.split(" ")[1] || "";
      const bUnit = b?.split(" ")[1] || "";
      if (aUnit === "GB") {
        aMemory *= 1000;
      }
      if (bUnit === "GB") {
        bMemory *= 1000;
      }
      return aMemory - bMemory;
    },
  },
  scope: {
    name: "scope",
    label: "Scope",
    field: (row: ComputeAsset) => row.meta.projectName,
    sortable: true,
    align: "left",
    format: (scope: Scope, computeResource: ComputeAsset): string | number => {
      switch (scope) {
        case Scope.Project:
          return Number(computeResource.meta.projectId);
        case Scope.Department:
          return String(computeResource.meta.departmentId);
        case Scope.Cluster:
          return String(computeResource.meta.clusterId);
        default:
          return String(computeResource.meta.tenantId);
      }
    },
    customCell: ECustomCell.SCOPE_COL,
    hideFilter: true,
  },
  workloads: {
    name: "workloads",
    label: "Workload(s)",
    field: (row: ComputeAsset) => row.usedBy,
    sortable: true,
    align: "left",
    format: (usageInfo: AssetUsageInfo | undefined): Array<string> => {
      if (!usageInfo) return [];
      return assetsUtil.extractWorkloadNames(usageInfo);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "workload-clicked" },
  },
  templates: {
    name: "templates",
    label: "Template(s)",
    field: (row: ComputeAsset) => row.usedBy?.templates,
    sortable: true,
    align: "left",
    format: (templates: Array<AssetRef>): Array<string> => {
      if (!templates?.length) return [];
      return templates.map((template: AssetRef) => template.name);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "workload-template-clicked" },
  },
  createdBy: {
    name: "created-by",
    label: "Created by",
    field: (row: ComputeAsset) => row.meta.createdBy,
    sortable: true,
    align: "left",
  },
  createdAt: {
    name: "created-at",
    label: "Creation time",
    field: (row: ComputeAsset) => row.meta.createdAt,
    sortable: true,
    align: "left",
    format: (createdAt: string) => dateUtil.dateAndTimeFormat(new Date(createdAt)),
  },
  cluster: {
    name: CLUSTER_COLUMN_FILTER_NAME,
    label: "Cluster",
    field: (row: ComputeAsset) => row.meta.clusterId,
    sortable: true,
    align: "left",
    customCell: ECustomCell.CLUSTER_ID_TO_NAME_COL,
    hideFilter: true,
    ignoreFilter: (row: ComputeAsset) => {
      // ignoring this column filter if the scope is system or tenant (as clusterId is undefined)
      return !row.meta.clusterId && (row.meta.scope === Scope.System || row.meta.scope === Scope.Tenant);
    },
  },
};

export const allComputeResourceColumns: Array<ITableColumn> = [
  allComputeResourceColumnsMap.computeResource,
  allComputeResourceColumnsMap.gpuDevicesRequest,
  allComputeResourceColumnsMap.gpuMemoryRequest,
  allComputeResourceColumnsMap.gpuMemoryLimit,
  allComputeResourceColumnsMap.cpuMemoryRequest,
  allComputeResourceColumnsMap.cpuMemoryLimit,
  allComputeResourceColumnsMap.cpuCoresRequest,
  allComputeResourceColumnsMap.cpuCoresLimit,
  allComputeResourceColumnsMap.scope,
  allComputeResourceColumnsMap.workloads,
  allComputeResourceColumnsMap.templates,
  allComputeResourceColumnsMap.createdBy,
  allComputeResourceColumnsMap.createdAt,
  allComputeResourceColumnsMap.cluster,
];

export const computeResourceIndexColumns: Array<ITableColumn> = [
  { ...allComputeResourceColumnsMap.computeResource, display: true, mandatory: true },
  { ...allComputeResourceColumnsMap.gpuDevicesRequest, display: true },
  { ...allComputeResourceColumnsMap.gpuMemoryRequest, display: true },
  { ...allComputeResourceColumnsMap.gpuMemoryLimit, display: true },
  { ...allComputeResourceColumnsMap.cpuMemoryRequest, display: true },
  { ...allComputeResourceColumnsMap.cpuMemoryLimit, display: true },
  { ...allComputeResourceColumnsMap.cpuCoresRequest, display: true },
  { ...allComputeResourceColumnsMap.cpuCoresLimit, display: true },
  { ...allComputeResourceColumnsMap.scope, display: true },
  { ...allComputeResourceColumnsMap.workloads, display: true },
  { ...allComputeResourceColumnsMap.templates, display: false },
  { ...allComputeResourceColumnsMap.createdBy, display: true },
  { ...allComputeResourceColumnsMap.createdAt, display: true },
  { ...allComputeResourceColumnsMap.cluster, display: true },
];
