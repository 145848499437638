<template>
  <runai-expansion-item class="general-section" label="General" :subheader="summary" aid="general-section">
    <section class="row no-wrap justify-between q-mb-md" v-if="showOverQuotaSection">
      <q-toggle
        aid="allow-over-quota-input"
        :model-value="generalModel.allowOverQuota"
        :label="`Allow the ${workloadText} to exceed the project's quota`"
        left-label
        class="text-no-wrap"
        @update:model-value="allowOverQuotaChanged"
        :disable="sectionDisabled"
      />
      <q-banner class="bg-orange-1 q-ml-sm col-5">
        <div class="row no-wrap items-center">
          <q-icon name="fa-regular fa-triangle-exclamation" size="sm" color="warning" />
          <div class="q-ml-md text-caption">
            Note: <span class="capitalized">{{ workloadText }}s</span> running over quota may stop at any time
          </div>
        </div>
      </q-banner>
    </section>

    <auto-delete-time-section
      :workload-type="workloadText"
      :model-value="generalModel.autoDeletionTimeAfterCompletionSeconds"
      @update:model-value="autoDeletionTimeoutChanged"
      :disable="sectionDisabled"
    />

    <section class="annotations-section">
      <runai-itemized-list
        :items-list="annotationsList"
        title="Set annotation(s)"
        title-tooltip="Kubernetes annotations are key-value pairs attached to the workload. They are used for storing additional descriptive metadata to enable documentation, monitoring and automation."
        add-item-btn="annotation"
        @changed="onAnnotationUpdated"
        :disable="sectionDisabled"
      />
    </section>
    <section class="labels-section">
      <runai-itemized-list
        :items-list="labelsList"
        title="Set label(s)"
        title-tooltip="Kubernetes labels are key-value pairs attached to the workload. They are used for categorizing to enable querying."
        add-item-btn="label"
        @changed="onLabelUpdated"
        :disable="sectionDisabled"
      />
    </section>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// Components
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { RunaiItemizedList } from "@/components/common";
import { AutoDeleteTimeSection } from "./auto-delete-time-section";

// Models
import type { IUIGeneralSectionModel } from "./general-section.model";
import { EWorkloadFormType } from "@/models/workload.model";
import type { IItemizedListItem } from "@/components/common/runai-itemized-list";
import type { Annotation, Label } from "@/swagger-models/assets-service-client";

// utils
import { pick } from "@/utils/common.util";

export default defineComponent({
  name: "general-section",
  components: {
    RunaiExpansionItem,
    RunaiItemizedList,
    AutoDeleteTimeSection,
  },
  emits: ["general-model-changed"],
  props: {
    workloadFormType: {
      type: String as PropType<EWorkloadFormType>,
      required: true,
    },
    generalModel: {
      type: Object as PropType<IUIGeneralSectionModel>,
      required: true,
    },
    sectionDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  computed: {
    workloadText(): string {
      return this.workloadFormType === EWorkloadFormType.Template ? "workload" : this.workloadFormType;
    },
    summary(): string {
      if (this.showOverQuotaSection) {
        const prefix: string = this.generalModel.allowOverQuota ? "Allowed" : "Not allowed";
        return prefix + " to exceed the project's quota";
      }
      return "";
    },
    showOverQuotaSection(): boolean {
      return this.workloadFormType === EWorkloadFormType.Workspace;
    },
    annotationsList(): Array<IItemizedListItem> {
      return this.generalModel.annotations?.map((item: Annotation) => pick(item, "name", "value", "deleted")) || [];
    },
    labelsList(): Array<IItemizedListItem> {
      return this.generalModel.labels?.map((item: Annotation) => pick(item, "name", "value", "deleted")) || [];
    },
  },
  methods: {
    allowOverQuotaChanged(allowOverQuota: boolean): void {
      this.$emit("general-model-changed", { ...this.generalModel, allowOverQuota });
    },
    onAnnotationUpdated(annotations: Array<Annotation>) {
      this.$emit("general-model-changed", { ...this.generalModel, annotations });
    },
    onLabelUpdated(labels: Array<Label>) {
      this.$emit("general-model-changed", { ...this.generalModel, labels });
    },
    autoDeletionTimeoutChanged(autoDeletionTimeAfterCompletionSeconds: number | null): void {
      this.$emit("general-model-changed", { ...this.generalModel, autoDeletionTimeAfterCompletionSeconds });
    },
  },
});
</script>
