<template>
  <setting-editable-field hide-buttons :label="settingBoxMessages.headerTitle"> </setting-editable-field>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import type { OidcCreationData } from "@/swagger-models/identity-manager-client";
import type { IWrapperBoxSettingMessages } from "@/models/setting.model";
import { idpCommon } from "../idp-common/idp-common";
import SettingEditableField from "@/components/settings/setting-editable-field/setting-editable-field.vue";

export default defineComponent({
  name: "ocp-settings",
  components: { SettingEditableField },
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      sourceOidcSettings: {} as OidcCreationData,
    };
  },
  computed: {
    settingBoxMessages(): IWrapperBoxSettingMessages {
      // sso users not relevant for ocp for now, will be changed later, when it will be editable
      return idpCommon.getSettingsBoxMessages(this.title, false);
    },
  },
});
</script>
