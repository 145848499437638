<template>
  <q-dialog aid="feature-intro-dialog" class="feature-intro-dialog" :model-value="isOpen" persistent>
    <q-card class="dialog-content-container">
      <q-card-section class="row justify-between items-center"
        ><h6>{{ options.header }}</h6>
      </q-card-section>
      <q-separator />
      <q-card-section class="q-px-md q-py-sm feature-intro-dialog-header">
        <slot></slot>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right" class="q-pt-md">
        <q-btn :label="options.backBtnText" aid="back-to-legacy-btn" flat color="primary" @click="$emit('back')" />
        <q-btn
          label="go to new experience"
          aid="continue-to-feature-btn"
          color="primary"
          @click="$emit('next')"
          class="next-button"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// model
import type { IRunaiFeatureIntroModalOptions } from "@/components/common/runai-feature-intro-modal/runai-feature-intro-modal.model";

export default defineComponent({
  name: "runai-feature-intro-modal",
  emits: ["next", "back"],
  props: {
    options: {
      type: Object as PropType<IRunaiFeatureIntroModalOptions>,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modelValue: true as boolean,
    };
  },
});
</script>

<style lang="scss" scoped>
.feature-intro-dialog {
  .dialog-content-container {
    width: 904px;
    max-width: 80vw;
    .feature-intro-dialog-header {
      max-height: 60vh;
      overflow: hidden;
    }
  }
}
</style>
