import type { INodeWorkload } from "@/models/node-workload.model";
import type { INode } from "@/models/node.model";
import { ECustomCell, type IStatusColOptions, type ITableColumn } from "@/models/table.model";
import { memoryFormat } from "@/utils/format.util";
import { nodeUtil } from "@/utils/node.util";
import { percentFormat, tableNumberFormat, tableNumberWithPercentFormat } from "@/utils/table-format.util";

export const allNodeColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: "node",
    label: "Node",
    field: "node",
    sortable: true,
    align: "left",
    display: true,
  },
  status: {
    name: "status",
    label: "Status",
    field: (node) => node,
    sortable: true,
    align: "left",
    display: true,
    filterKey: "status", // this is a special case where the format returns an object and needs this logic for filtering
    format: (node: INode): IStatusColOptions => {
      return nodeUtil.getStatusColOptions(node);
    },
    exportFormat: (node: INode) => node?.nodeStatus || "NA",
    customCell: ECustomCell.STATUS_COL,
  },
  nodePool: {
    name: "node-pool",
    label: "Node pool",
    field: "nodePool",
    sortable: true,
    align: "left",
    display: true,
  },
  gpuType: {
    name: "gpu-type",
    label: "GPU type",
    field: "gpuType",
    sortable: true,
    align: "left",
    display: true,
    format: (type: string) => type || "-",
  },
  gpu: {
    name: "gpu",
    label: "GPU devices",
    field: (node: INode) => node,
    sortable: true,
    align: "left",
    display: true,
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "gpus-clicked" },
    format: (node: INode): string[] | string => {
      if (node.gpuDevices && node.gpuDevices?.length > 0) {
        return ["" + node.gpuDevices?.length];
      }
      return node.totalGpus && parseInt(node.totalGpus) > 0 ? node.totalGpus : [];
    },
  },
  freeGpu: {
    name: "free-gpu",
    label: "Free GPU devices",
    field: "freeGpus",
    sortable: true,
    align: "left",
    display: false,
    format: (freeGpus: number) => (freeGpus != null ? freeGpus : "-"),
  },
  gpuMemory: {
    name: "gpu-memory",
    label: "GPU memory",
    field: "totalGpuMemory",
    sortable: true,
    align: "left",
    display: true,
    format: memoryFormat,
  },
  allocatedGpu: {
    name: "allocated-gpu",
    label: "Allocated GPUs",
    field: "allocatedGpus",
    sortable: true,
    align: "left",
    display: true,
    format: tableNumberFormat(),
  },
  usedGpuMemory: {
    name: "used-gpu-memory",
    label: "Used GPU memory",
    field: "usedGpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: memoryFormat,
  },
  utilization: {
    name: "utilization",
    label: "GPU compute utilization",
    field: "utilization",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  memoryUtilization: {
    name: "memoryUtilization",
    label: "GPU memory utilization",
    field: "usedGpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: percentFormat("totalGpuMemory"),
  },
  cpu: {
    name: "cpu",
    label: "CPU (Cores)",
    field: "totalCpus",
    sortable: true,
    align: "left",
    display: true,
    format: tableNumberFormat(),
  },
  cpuMemory: {
    name: "cpu-memory",
    label: "CPU memory",
    field: "totalCpuMemory",
    sortable: true,
    align: "left",
    display: true,
    format: memoryFormat,
  },
  allocatedCpu: {
    name: "allocated-cpu",
    label: "Allocated CPU (Cores)",
    field: "allocatedCpus",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat(),
  },
  allocatedCpuMemory: {
    name: "allocated-cpu-memory",
    label: "Allocated CPU memory",
    field: "allocatedMemory",
    sortable: true,
    align: "left",
    display: false,
    format: memoryFormat,
  },
  usedCpuMemory: {
    name: "used-cpu-memory",
    label: "Used CPU memory",
    field: "usedCpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: memoryFormat,
  },
  cpuUtilization: {
    name: "cpu-utilization",
    label: "CPU compute utilization",
    field: "usedCpus",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  cpuMemoryUtilization: {
    name: "memory-utilization",
    label: "CPU memory utilization",
    field: "usedCpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: percentFormat("totalCpuMemory"),
  },
  swapCpuMemory: {
    name: "swap-cpu-memory",
    label: "Used swap CPU memory",
    field: "swapCpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberWithPercentFormat("totalCpuMemory", true),
  },
  workloads: {
    name: "workloads",
    label: "Workload(s)",
    field: (row: INode) => row.workloads,
    sortable: false,
    align: "left",
    display: true,
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "workloads-clicked" },
    format: (workloads: Array<INodeWorkload>): string[] => {
      if (!workloads?.length) return [];
      return workloads.map((workload: INodeWorkload) => workload.name);
    },
  },
  graphicsEngineActivity: {
    name: "graphics-engine-activity",
    label: "Graphics engine activity",
    field: "graphicsEngineActivity",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  smActivity: {
    name: "sm-activity",
    label: "SM activity",
    field: "smActivity",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  smOccupancy: {
    name: "sm-occupancy",
    label: "SM occupancy",
    field: "smOccupancy",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  tensorActivity: {
    name: "tensor-activity",
    label: "Tensor activity",
    field: "tensorActivity",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  fp64EngineActivity: {
    name: "fp64-engine-activity",
    label: "FP64 engine activity",
    field: "fp64EngineActivity",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  fp32EngineActivity: {
    name: "fp32-engine-activity",
    label: "FP32 engine activity",
    field: "fp32EngineActivity",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  fp16EngineActivity: {
    name: "fp16-engine-activity",
    label: "FP16 engine activity",
    field: "fp16EngineActivity",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  memoryBWUtilization: {
    name: "memory-bw-utilization",
    label: "Memory bandwidth utilization",
    field: "memoryBWUtilization",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  nvLinkBandwidthRead: {
    name: "nv-link-bandwidth-read",
    label: "NVLink received bandwidth",
    field: "nvLinkBandwidthRead",
    sortable: true,
    align: "left",
    display: false,
    format: (val: string) => {
      const formattedVal: string = memoryFormat(val);
      return formattedVal !== "-" ? formattedVal + "/s" : formattedVal;
    },
  },
  nvLinkBandwidthTransmit: {
    name: "nv-link-bandwidth-transmit",
    label: "NVLink transmitted bandwidth",
    field: "nvLinkBandwidthTransmit",
    sortable: true,
    align: "left",
    display: false,
    format: (val: string) => {
      const formattedVal: string = memoryFormat(val);
      return formattedVal !== "-" ? formattedVal + "/s" : formattedVal;
    },
  },
  pcieBandwidthRead: {
    name: "pcie-bandwidth-read",
    label: "PCIe received bandwidth",
    field: "pcieBandwidthRead",
    sortable: true,
    align: "left",
    display: false,
    format: (val: string) => {
      const formattedVal: string = memoryFormat(val);
      return formattedVal !== "-" ? formattedVal + "/s" : formattedVal;
    },
  },
  pcieBandwidthTransmit: {
    name: "pcie-bandwidth-transmit",
    label: "PCIe transmitted bandwidth",
    field: "pcieBandwidthTransmit",
    sortable: true,
    align: "left",
    display: false,
    format: (val: string) => {
      const formattedVal: string = memoryFormat(val);
      return formattedVal !== "-" ? formattedVal + "/s" : formattedVal;
    },
  },
};

export const allNodesColumns = [
  { ...allNodeColumnsMap.name, mandatory: true },
  { ...allNodeColumnsMap.status },
  { ...allNodeColumnsMap.nodePool },
  { ...allNodeColumnsMap.gpuType },
  { ...allNodeColumnsMap.gpu },
  { ...allNodeColumnsMap.freeGpu },
  { ...allNodeColumnsMap.gpuMemory },
  { ...allNodeColumnsMap.allocatedGpu },
  { ...allNodeColumnsMap.usedGpuMemory },
  { ...allNodeColumnsMap.utilization },
  { ...allNodeColumnsMap.memoryUtilization },
  { ...allNodeColumnsMap.cpu },
  { ...allNodeColumnsMap.cpuMemory },
  { ...allNodeColumnsMap.allocatedCpu },
  { ...allNodeColumnsMap.allocatedCpuMemory },
  { ...allNodeColumnsMap.usedCpuMemory },
  { ...allNodeColumnsMap.cpuUtilization },
  { ...allNodeColumnsMap.cpuMemoryUtilization },
  { ...allNodeColumnsMap.swapCpuMemory },
  { ...allNodeColumnsMap.graphicsEngineActivity },
  { ...allNodeColumnsMap.smActivity },
  { ...allNodeColumnsMap.smOccupancy },
  { ...allNodeColumnsMap.tensorActivity },
  { ...allNodeColumnsMap.fp64EngineActivity },
  { ...allNodeColumnsMap.fp32EngineActivity },
  { ...allNodeColumnsMap.fp16EngineActivity },
  { ...allNodeColumnsMap.memoryBWUtilization },
  { ...allNodeColumnsMap.nvLinkBandwidthRead },
  { ...allNodeColumnsMap.nvLinkBandwidthTransmit },
  { ...allNodeColumnsMap.pcieBandwidthRead },
  { ...allNodeColumnsMap.pcieBandwidthTransmit },
  { ...allNodeColumnsMap.workloads },
] as Array<ITableColumn>;

export const nodeMiniTableColumns = [
  { ...allNodeColumnsMap.name, display: true },
  { ...allNodeColumnsMap.gpuType, display: true },
  { ...allNodeColumnsMap.allocatedGpu, display: true },
  { ...allNodeColumnsMap.cpu, display: true },
  { ...allNodeColumnsMap.cpuMemory, display: true },
  { ...allNodeColumnsMap.allocatedCpu, display: true },
  { ...allNodeColumnsMap.allocatedCpuMemory, display: true },
] as Array<ITableColumn>;

export const nodeAdvancedMetricsColumns = [
  { ...allNodeColumnsMap.graphicsEngineActivity },
  { ...allNodeColumnsMap.smActivity },
  { ...allNodeColumnsMap.smOccupancy },
  { ...allNodeColumnsMap.tensorActivity },
  { ...allNodeColumnsMap.fp64EngineActivity },
  { ...allNodeColumnsMap.fp32EngineActivity },
  { ...allNodeColumnsMap.fp16EngineActivity },
  { ...allNodeColumnsMap.memoryBWUtilization },
  { ...allNodeColumnsMap.nvLinkBandwidthRead },
  { ...allNodeColumnsMap.nvLinkBandwidthTransmit },
  { ...allNodeColumnsMap.pcieBandwidthRead },
  { ...allNodeColumnsMap.pcieBandwidthTransmit },
];
