<template>
  <section class="training-new column items-center q-pt-md">
    <runai-form-wrapper :form-state="training">
      <q-form ref="trainingForm">
        <section class="create-training-form">
          <runai-expansion-wrapper>
            <project-section
              entity-type="training"
              aid="project-section"
              :loading="loadingProject"
              :selected-project-id="training.projectId"
              :projects="projects"
              @project-changed="onSelectedProject"
            />

            <transition-group name="fade">
              <template v-if="training.projectId && training.projectId !== -1">
                <multi-node-section
                  v-if="isClusterVersionSupportDistributedTraining"
                  :is-selectable="true"
                  :multi-node-section-model="training.distributed"
                  @update-multi-node-section-model="updateMultiNodeSection"
                />

                <template-section
                  entity-type="training"
                  aid="template-section"
                  :templates="templates"
                  :loading="loadingTemplate"
                  @template-changed="onSelectedTemplate"
                  :template-id="templateId"
                />
                <workload-name-section
                  entity-type="training"
                  aid="training-name-section"
                  v-model:name="training.name"
                  :disable-closing="true"
                  :project-id="training.projectId"
                  :cluster-id="clusterStore.currentClusterId"
                />
              </template>
            </transition-group>
          </runai-expansion-wrapper>
          <section class="row items-center q-mt-md">
            <q-field
              class="col-4 form-hint no-padding"
              :model-value="displayFormHint"
              :rules="[isFormIncomplete]"
            ></q-field>
            <div class="buttons q-ml-auto">
              <q-btn flat class="q-mr-sm" color="primary" label="Cancel" aid="cancel-btn" @click="onCancel" />
              <q-btn
                label="continue"
                aid="create-training-continue-btn"
                @click="continueToEdit"
                :loading="submitting"
                color="primary"
              ></q-btn>
            </div>
          </section>
        </section>
      </q-form>
    </runai-form-wrapper>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// store
import { useTrainingStore } from "@/stores/training.store";
import { useProjectStore } from "@/stores/project.store";
import { useClusterStore } from "@/stores/cluster.store";
import { useWorkloadTemplateStore } from "@/stores/workload-template.store";
import { useAppStore } from "@/stores/app.store";
import { useSettingStore } from "@/stores/setting.store";

// services
import { alertUtil } from "@/utils/alert.util";
import { requestToLeave } from "@/services/infra/router.service/router.service";
import { dataSourceService } from "@/services/control-plane/data-source.service/data-source.service";

// cmps
import { RunaiFormWrapper } from "@/components/common/runai-form-wrapper";
import { RunaiExpansionWrapper } from "@/components/common/runai-expansion-wrapper";
import { WorkloadNameSection } from "@/components/section/workload-name-section";
import { ProjectSection } from "@/components/section/project-section";
import { TemplateSection } from "@/components/section/template-section";
import { MultiNodeSection } from "@/components/section/multi-node-section";

// models
import type { IProjectResources, IProject } from "@/models/project.model";
import {
  type WorkloadTemplate,
  type DatasourceRef,
  type AssetIdAndKind,
  type PVCAsset,
  EnvironmentAssetSpecUidGidSourceEnum,
} from "@/swagger-models/assets-service-client";
import type { IUIDistributed, IUIWorkloadCreation } from "@/models/workload.model";
import type { IAssetsFilter } from "@/models/filter.model";

// constant
import { errorMessages } from "@/common/error-message.constant";
// common
import { required } from "@/common/form.validators";

// route
import { TRAINING_ROUTE_NAMES } from "@/router/training.routes/training.routes.names";

// utils
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { dataSourceUtil } from "@/utils/data-source.util";
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes";
import { useEnvironmentStore } from "@/stores/environment.store";
import { useAuthStore } from "@/stores/auth.store";

export default defineComponent({
  components: {
    RunaiExpansionWrapper,
    RunaiFormWrapper,
    ProjectSection,
    WorkloadNameSection,
    TemplateSection,
    MultiNodeSection,
  },
  data() {
    return {
      trainingStore: useTrainingStore(),
      workloadTemplateStore: useWorkloadTemplateStore(),
      projectStore: useProjectStore(),
      appStore: useAppStore(),
      clusterStore: useClusterStore(),
      settingStore: useSettingStore(),
      environmentStore: useEnvironmentStore(),
      authStore: useAuthStore(),
      loadingProject: false as boolean,
      loadingTemplate: false as boolean,
      trainingForm: null as HTMLFormElement | null,
      training: workloadUtil.getEmptyUIWorkloadCreation() as IUIWorkloadCreation,
      templateId: null as string | null,
      submitting: false as boolean,
      displayFormHint: false as boolean,
      timeOutId: null as ReturnType<typeof setTimeout> | null,
    };
  },
  async created() {
    this.appStore.setPageLoading(false);
    await this.loadProjects();

    this.training.name = this.trainingStore.trainingName;
    if (this.training.projectId < 0) this.training.projectId = this.trainingStore.training.projectId;
    this.training.distributed = this.trainingStore.training.distributed;
    this.templateId = this.trainingStore.templateId;
    if (this.templateId === null && this.training.projectId) {
      this.templateId = "-1";
    }
  },
  mounted() {
    this.trainingForm = this.$refs["trainingForm"] as HTMLFormElement;
  },
  computed: {
    projects(): Array<IProjectResources> {
      return this.projectStore.projectResourceList;
    },
    templates(): Array<WorkloadTemplate> {
      return this.workloadTemplateStore.workloadTemplateList;
    },
    isClusterVersionSupportDistributedTraining(): boolean {
      return this.clusterStore.isVersionSupportDistributedTraining;
    },
    templatesFilter(): IAssetsFilter {
      const templatesFilter: IAssetsFilter = {
        projectId: this.training.projectId,
        isTraining: true,
      };
      if (this.training.distributed) {
        templatesFilter.isDistributed = true;
        if (this.training.distributed?.distFramework)
          templatesFilter.distributedFramework = this.training.distributed.distFramework;
      }
      return templatesFilter;
    },
  },
  methods: {
    async loadProjects(): Promise<void> {
      try {
        this.loadingProject = true;
        await Promise.all([this.projectStore.loadProjectsQuotas(), this.projectStore.loadProjects()]);

        if (this.projects.length === 1) {
          await this.onSelectedProject(this.projects[0].id);
        }
      } catch (error: unknown) {
        this.$q.notify(alertUtil.getError("Failed to load projects"));
        console.error(error);
        this.appStore.setFallback(true);
      } finally {
        this.loadingProject = false;
      }
    },
    async loadTemplates(): Promise<void> {
      this.loadingTemplate = true;
      await this.workloadTemplateStore.loadWorkloadTemplates(this.templatesFilter);
      if (this.templates.length === 0) {
        this.templateId = "-1";
      }
      this.loadingTemplate = false;
    },
    async onSelectedProject(projectId: number | null): Promise<void> {
      try {
        this.training.projectId = projectId || -1;
        this.templateId = null;
        if (!projectId) return;
        await this.loadTemplates();
      } catch (error: unknown) {
        console.error(error);
      }
    },
    onSelectedTemplate(templateId: string | null): void {
      this.templateId = templateId;
    },
    updateMultiNodeSection(distributed: IUIDistributed): void {
      this.training.distributed = distributed;
      this.loadTemplates();
    },
    redirectToPrevRoute(): void {
      this.$router.push({ name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX });
    },
    async onCancel(): Promise<void> {
      const allowToLeave: boolean = await requestToLeave();
      if (allowToLeave) {
        this.redirectToPrevRoute();
      }
    },
    async continueToEdit(): Promise<void> {
      this.displayFormHint = false;
      this.submitting = true;
      const success = await this.validate();
      if (!success) {
        this.submitting = false;
        this.showHint();
        return;
      }

      this.training.specificEnv = {
        nodePools: this.getNodePoolsFromProject(this.training.projectId),
      };

      const template: WorkloadTemplate | undefined = this.templates.find(
        (template) => template.meta.id === this.templateId,
      );
      if (template) {
        this.trainingStore.setTemplate(this.templateId);
        this.training.specificEnv = workloadUtil.getUIWorkloadSpecificEnv(template.spec.specificEnv || {});
        this.training.assets.environment = template.spec.assets.environment.id;

        await this.setSpecificUserGidUidIfNeeded();

        this.training.assets.compute = template.spec.assets.compute?.id || null;
        this.training.assets.datasources = template.spec.assets.datasources?.map(
          (ds: DatasourceRef): AssetIdAndKind => ({ id: ds.id, kind: ds.kind }),
        );
        if (template.spec.assets.workloadVolumes?.length) {
          const pvcs: Array<PVCAsset> = await dataSourceService.loadPVCAssets(template.spec.assets.workloadVolumes);
          this.training.assets.uiVolumes = dataSourceUtil.mapPvcsToUiVolumes(pvcs);
        }
      }

      this.trainingStore.setTraining(this.training);
      await this.$router.push({ name: TRAINING_ROUTE_NAMES.TRAINING_ASSETS_EDIT });
    },
    validate(): Promise<boolean> {
      return (this.trainingForm as HTMLFormElement).validate();
    },
    isRequiredTemplate(val: string): boolean | string {
      return required(val) || errorMessages.SELECT_TEMPLATE;
    },
    showHint(): void {
      this.displayFormHint = true;
      this.timeOutId && clearTimeout(this.timeOutId);
      this.timeOutId = setTimeout(() => (this.displayFormHint = false), 15000);
    },
    isFormIncomplete(val: boolean): boolean | string {
      return !val ? true : "Please review and fix the issues in the form";
    },
    findProject(projectId: number): IProject | undefined {
      return this.projectStore.projects.find((project: IProject) => project.id === projectId);
    },
    getNodePoolsFromProject(projectId: number): Array<string> | null {
      const project: IProject | undefined = this.findProject(projectId);
      if (!project) return null;

      return project.defaultNodePools || null;
    },
    async setSpecificUserGidUidIfNeeded(): Promise<void> {
      if (this.training.assets.environment) {
        const relevantEnvironment = await this.environmentStore.loadById(this.training.assets.environment);
        if (relevantEnvironment?.spec.uidGidSource === EnvironmentAssetSpecUidGidSourceEnum.FromIdpToken) {
          this.training.specificEnv.runAsUid = this.authStore.getUID || null;
          this.training.specificEnv.runAsGid = this.authStore.getGID || null;
        }
      }
    },
  },
  unmounted() {
    this.timeOutId && clearTimeout(this.timeOutId);
  },
});
</script>
