import { defineStore } from "pinia";

// models
import type { Training, WorkloadCreationRequest } from "@/swagger-models/assets-service-client";
import { ResourceType, type Action, type PermittedScopes } from "@/swagger-models/authorization-client";
import type { IFilterBy } from "@/models/filter.model";
import { EOrderDirection } from "@/models/filter.model";
import type { IProjectResources } from "@/models/project.model";
import type { IUIWorkloadCreation } from "@/models/workload.model";

// services
import { trainingService } from "@/services/control-plane/training.service/training.service";
import { projectService } from "@/services/control-plane/project.service/project.service";

// stores
import { useClusterStore } from "@/stores/cluster.store";
import type { ITrainingList } from "@/models/training.model";

// utils
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { permissionService } from "@/services/authorization/permission.service/permission.service";

export const useTrainingStore = defineStore("Training", {
  state: () => ({
    trainings: [] as Array<ITrainingList>,
    training: workloadUtil.getEmptyUIWorkloadCreation() as IUIWorkloadCreation,
    templateId: null as string | null,
    projects: [] as Array<IProjectResources>,
    selectedTraining: null as Training | null,
    trainingAdded: null as ITrainingList | null,
    actionPermissionsByScopes: {} as Record<Action, PermittedScopes>,
  }),
  getters: {
    trainingName(): string {
      return this.training.name || "";
    },
    selectedTemplate(): string | null {
      return this.templateId;
    },
    projectList(): Array<IProjectResources> {
      return this.projects;
    },
    trainingList(): Array<ITrainingList> {
      if (!this.trainingAdded) return this.trainings;
      return this.trainings.filter((training: ITrainingList) => training.meta.id !== this.trainingAdded?.meta.id);
    },
    lastCreatedTraining(): ITrainingList | null {
      return this.trainingAdded;
    },
  },
  actions: {
    async loadActionPermissions(): Promise<void> {
      if (Object.keys(this.actionPermissionsByScopes).length > 0) return;
      this.actionPermissionsByScopes = await permissionService.getPermittedScopesForAction(ResourceType.Trainings);
    },
    async loadTrainings(filterBy: IFilterBy = {}): Promise<void> {
      const clusterStore = useClusterStore();

      this.trainings = await trainingService.list(clusterStore.currentClusterId, {
        ...filterBy,
        sortBy: filterBy.sortBy || "",
        sortDirection: filterBy.descending ? EOrderDirection.DESC : EOrderDirection.ASC,
      });
    },
    async loadById(id: string): Promise<Training> {
      this.selectedTraining = await trainingService.getById(id);
      return this.selectedTraining;
    },
    async loadLastCreatedTraining(clusterId: string, id: string): Promise<ITrainingList> {
      this.trainingAdded = await trainingService.getByIdFromCluster(clusterId, id);
      return this.trainingAdded;
    },

    setTraining(training: IUIWorkloadCreation): void {
      this.training = training;
    },
    setTemplate(templateId: string | null): void {
      this.templateId = templateId;
    },

    resetCreateTrainingData(): void {
      this.templateId = null;
      this.training = workloadUtil.getEmptyUIWorkloadCreation();
    },

    async loadProjects(): Promise<Array<IProjectResources>> {
      const clusterStore = useClusterStore();
      this.projects = await projectService.listQuota(clusterStore.currentClusterId);
      return this.projects;
    },
    async createTraining(training: WorkloadCreationRequest): Promise<ITrainingList | null> {
      const createdTraining: Training = await trainingService.createFromAssets(training);
      const clusterStore = useClusterStore();
      this.trainingAdded = await trainingService.getByIdFromCluster(
        clusterStore.currentClusterId,
        createdTraining.meta.id,
      );
      return this.trainingAdded;
    },

    async activateTraining(training: ITrainingList): Promise<void> {
      await trainingService.activate(training);
    },
    async stopTrainig(training: ITrainingList): Promise<void> {
      await trainingService.stop(training);
    },
    async deleteTraining(training: ITrainingList): Promise<ITrainingList> {
      const trainingDeleted: ITrainingList = await trainingService.remove(training);
      this.trainings = this.trainings.map((currTraining: ITrainingList) => {
        return currTraining.meta.id === trainingDeleted.meta.id ? trainingDeleted : currTraining;
      });
      return trainingDeleted;
    },
    removeTrainingAdded(): void {
      this.trainingAdded = null;
    },
  },
});
