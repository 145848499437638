<template>
  <runai-expansion-item class="settings-container" label="Resources" :subheader="sectionSubHeader">
    <div v-if="resourceSettings.departments" class="row items-center q-mb-md">
      <toggle-setting :setting="resourceSettings.departments" />
    </div>
    <template v-if="resourceSettings.projectsOverDepartmentQuota">
      <q-separator class="q-mb-md hide-if-last-child" />
      <div>
        <toggle-setting :setting="resourceSettings.projectsOverDepartmentQuota" />
      </div>
    </template>
    <template v-if="resourceSettings.cpuOverQuota">
      <q-separator class="q-my-md hide-if-last-child" />
      <div>
        <toggle-setting :setting="resourceSettings.cpuOverQuota" />
      </div>
    </template>
    <template v-if="resourceSettings.overQuotaPriority">
      <q-separator class="q-my-md hide-if-last-child" />
      <div>
        <toggle-setting :setting="resourceSettings.overQuotaPriority" />
      </div>
    </template>
    <template v-if="resourceSettings.gpuOptimization">
      <q-separator class="q-my-md hide-if-last-child" />
      <div>
        <toggle-setting
          :setting="resourceSettings.gpuOptimization"
          :disabled="isGpuOptimizationDisabled"
          :tooltip-message="gpuDisabledMessage"
        />
      </div>
    </template>
  </runai-expansion-item>
</template>

<script lang="ts">
// components
import { defineComponent, PropType } from "vue";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import ToggleSetting from "@/components/settings/toggle-setting/toggle-setting.vue";

// utils
import { settingsUtil } from "@/utils/settings.util";

// models
import type { IResourceSectionSettings } from "@/models/setting.model";

// stores
import { useNodePoolStore } from "@/stores/node-pool.store";

export default defineComponent({
  name: "resources-section",
  components: {
    ToggleSetting,
    RunaiExpansionItem,
  },
  props: {
    resourceSettings: {
      type: Object as PropType<IResourceSectionSettings>,
      required: true,
    },
  },
  data() {
    return {
      nodePoolsStore: useNodePoolStore(),
    };
  },
  computed: {
    sectionSubHeader(): string {
      return settingsUtil.getToggleSectionCollapsedTitle(this.resourceSettings);
    },
    isGpuOptimizationDisabled(): boolean {
      return this.nodePoolsStore.hasOverProvisioning && Boolean(this.resourceSettings?.gpuOptimization?.value);
    },
    gpuDisabledMessage(): string {
      return this.isGpuOptimizationDisabled
        ? "To disable GPU resource optimization, first remove GPU resource optimization enforcement from all node pools."
        : "";
    },
  },
});
</script>
