import { prometheusService } from "@/services/control-plane/prometheus.service/prometheus.service";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import type { IProject, IAllocatedResourceMetric, IProjectCreate, IProjectResources } from "@/models/project.model";
import type { IFilterBy } from "@/models/filter.model";
import { API, K8S_API } from "@/common/api.constant";
import { deepCopy, pick } from "@/utils/common.util";
import { isNewerVersion } from "@/utils/version.util";
import type { IPrometheusMetric, IPrometheusResponse } from "@/models/prometheus.model";
import { ScopeType, type PolicyType, type WorkspacePolicy } from "@/swagger-models/policy-service-client";
import { MIN_NEW_PROJECT_METRIC_VERSION } from "@/common/version.constant";
export const projectService = {
  list,
  listQuota,
  createProject,
  updateProject,
  deleteProject,
  loadPolicy,
  enrichProjectsWithResourcesMetrics,
};

const getEndpoint = (clusterUuid: string): string => `${K8S_API.v1}/clusters/${clusterUuid}/projects`;

// api calls
async function list(clusterUuid: string, filterBy: IFilterBy = {}): Promise<Array<IProject>> {
  const filters: IFilterBy = pick(filterBy, "sortBy", "page", "rowsPerPage");
  return controlPlaneService.get(getEndpoint(clusterUuid), {
    ...filters,
    excludePermissions: true,
  });
}

async function listQuota(clusterUuid: string): Promise<Array<IProjectResources>> {
  const projectsResources: Array<IProjectResources> = await controlPlaneService.get(
    getEndpoint(clusterUuid) + "/quotas?excludePermissions=true",
  );
  return projectsResources;
}

async function createProject(project: IProjectCreate, clusterUuid: string): Promise<IProject> {
  const endpoint = `${getEndpoint(clusterUuid)}?excludePermissions=true`;
  return controlPlaneService.post(endpoint, project);
}
async function updateProject(project: IProject, clusterUuid: string): Promise<IProject> {
  const endpoint = `${getEndpoint(clusterUuid)}/${project.id}?excludePermissions=true`;
  return controlPlaneService.put(endpoint, project);
}
async function deleteProject(projectId: number, clusterUuid: string): Promise<IProject> {
  const endpoint = `${getEndpoint(clusterUuid)}/${projectId}?excludePermissions=true`;
  return controlPlaneService.delete(endpoint);
}

async function loadPolicy(workloadType: PolicyType, projectId: number): Promise<WorkspacePolicy> {
  return controlPlaneService.get(`${API.v1}/policy/${workloadType}?scope=${ScopeType.Project}&projectId=${projectId}`);
}

async function enrichProjectsWithResourcesMetrics(
  projects: IProject[],
  clusterUuid: string,
  clusterVersion: string,
): Promise<IProject[]> {
  const promQueries = _createProjectResourcesPromQueries(clusterUuid, clusterVersion);
  const projectWithMetrics = deepCopy(projects);
  try {
    const responses: IPrometheusResponse[] = await prometheusService.multipleQueries(promQueries);
    const resourcesSumPerProject: IAllocatedResourceMetric = _calculateAllocatedResources(responses);

    for (const project of projectWithMetrics) {
      project.allocatedGpus = resourcesSumPerProject[project.name]?.allocatedGpus;
      project.allocatedCpu = resourcesSumPerProject[project.name]?.allocatedCpu;
      project.allocatedMemory = resourcesSumPerProject[project.name]?.allocatedMemory;
    }
  } catch (error) {
    console.error(error);
  }
  return projectWithMetrics;
}

function _calculateAllocatedResources(data: IPrometheusResponse[]): IAllocatedResourceMetric {
  const projectSum: IAllocatedResourceMetric = {};

  data.forEach((item: IPrometheusResponse) => {
    const key: string = item.key;
    const values: IPrometheusMetric[] = item.data;

    values.forEach((promMetric: IPrometheusMetric) => {
      const project = promMetric.metric.project;
      const allocatedResource = parseFloat(promMetric.value[1]);

      if (!(project in projectSum)) {
        projectSum[project] = {};
      }

      if (key in projectSum[project]) {
        projectSum[project][key] += allocatedResource;
      } else {
        projectSum[project][key] = allocatedResource;
      }
    });
  });

  return projectSum;
}

function _createProjectResourcesPromQueries(clusterUuid: string, clusterVersion: string): Record<string, string> {
  const clusterFilter = `clusterId="${clusterUuid}"`;
  if (isNewerVersion(clusterVersion, MIN_NEW_PROJECT_METRIC_VERSION)) {
    return {
      allocatedGpus: `sum(runai_allocated_gpu_count_per_project{${clusterFilter}}) by(project)`,
      allocatedCpu: `sum(runai_allocated_millicpus_per_pod{${clusterFilter}}) by(project)`,
      allocatedMemory: `sum(runai_allocated_memory_per_pod{${clusterFilter}}) by(project)`,
    };
  }

  return {
    allocatedGpus: `sum(runai_allocated_gpus{${clusterFilter}}) by (project)`,
    allocatedCpu: `sum(runai_active_job_cpu_allocated_cores{${clusterFilter}} * on(job_uuid) group_left(project) runai_pod_group_info{${clusterFilter}}) by (project)`,
    allocatedMemory: `sum(runai_active_job_memory_allocated_bytes{${clusterFilter}} * on(job_uuid) group_left(project) runai_pod_group_info{${clusterFilter}}) by (project)`,
  };
}
