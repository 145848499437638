import type { INodePool } from "@/models/node-pool.model";
import type { INodeWorkload } from "@/models/node-workload.model";
import { ECustomCell, type ITableColumn } from "@/models/table.model";
import { dateUtil } from "@/utils/date.util";
import { memoryFormat } from "@/utils/format.util";
import { nodePoolUtil } from "@/utils/node-pool.util/node-pool.util";
import { percentFormat, tableNumberFormat } from "@/utils/table-format.util";
import { PlacementStrategy, binPackLabel, spreadLabel } from "@/models/node-pool.model";

export const nodePoolColumns = [
  {
    name: "name",
    label: "Node pool",
    field: (row: INodePool) => row.name,
    sortable: true,
    align: "left",
    display: true,
    mandatory: true,
  },
  {
    name: "status",
    label: "Status",
    field: "status",
    sortable: true,
    align: "left",
    display: true,
    customCell: ECustomCell.NODE_POOL_STATUS_COL,
  },
  {
    name: "label-key",
    label: "Label key",
    field: "labelKey",
    sortable: true,
    align: "left",
    display: true,
    format: (key: string) => key || "-",
  },
  {
    name: "label-value",
    label: "Label value",
    field: "labelValue",
    sortable: true,
    align: "left",
    display: true,
    format: (val: string) => val || "-",
  },
  {
    name: "nodes",
    label: "Node(s)",
    field: (row: INodePool) => (Array.isArray(row.nodes) ? row.nodes : row?.nodes?.split(",")),
    sortable: false,
    align: "left",
    display: true,
    format: (nodes: Array<string>): string[] | string => {
      if (!nodes?.length) return [];
      if (nodes[0] === "") return "-";
      return nodes.map((node: string) => node.trim());
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "nodes-clicked" },
  },
  {
    name: "gpu",
    label: "GPU devices",
    field: "totalGpus",
    sortable: true,
    align: "left",
    display: true,
    format: tableNumberFormat(),
  },
  {
    name: "gpu-memory",
    label: "GPU memory",
    field: "totalGpuMemory",
    sortable: true,
    align: "left",
    display: true,
    format: memoryFormat,
  },
  {
    name: "gpu-project-quota",
    label: "Projects' GPU quota",
    field: "projectsGpus",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat(),
  },
  {
    name: "allocated-gpu",
    label: "Allocated GPUs",
    field: "allocatedGpus",
    sortable: true,
    align: "left",
    display: true,
    format: tableNumberFormat(),
  },
  {
    name: "used-gpu-memory",
    label: "Used GPU memory",
    field: "usedGpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: memoryFormat,
  },
  {
    name: "utilization",
    label: "GPU compute utilization",
    field: "utilization",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  {
    name: "memoryUtilization",
    label: "GPU memory utilization",
    field: "usedGpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: percentFormat("totalGpuMemory"),
  },
  {
    name: "cpu",
    label: "CPUs (Cores)",
    field: "totalCpus",
    sortable: true,
    align: "left",
    display: true,
    format: tableNumberFormat(),
  },
  {
    name: "cpu-memory",
    label: "CPU memory",
    field: "totalCpuMemory",
    sortable: true,
    align: "left",
    display: true,
    format: memoryFormat,
  },
  {
    name: "cpu-project-quota",
    label: "Projects' CPU quota (Cores)",
    field: "projectsCpus",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat(),
  },
  {
    name: "memory-project-quota",
    label: "Projects' CPU memory quota",
    field: "projectsMemory",
    sortable: true,
    align: "left",
    display: false,
    format: memoryFormat,
  },
  {
    name: "allocated-cpu",
    label: "Allocated CPUs (Cores)",
    field: "allocatedCpus",
    sortable: true,
    align: "left",
    display: true,
    format: tableNumberFormat(),
  },
  {
    name: "allocated-cpu-memory",
    label: "Allocated CPU memory",
    field: "allocatedMemory",
    sortable: true,
    align: "left",
    display: true,
    format: memoryFormat,
  },
  {
    name: "used-cpu-memory",
    label: "Used CPU memory",
    field: "usedCpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: memoryFormat,
  },
  {
    name: "cpu-compute-utilization",
    label: "CPU compute utilization",
    field: "usedCpus",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  {
    name: "cpu-memory-utilization",
    label: "CPU memory utilization",
    field: "usedCpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: percentFormat("totalCpuMemory"),
  },
  {
    name: "gpu-placement-strategy",
    label: "GPU placement strategy",
    field: (row) => row.placementStrategy.gpu,
    sortable: true,
    align: "left",
    display: false,
    format: (PlacementStrategy) => (PlacementStrategy === PlacementStrategy.Spread ? spreadLabel : binPackLabel),
  },
  {
    name: "cpu-placement-strategy",
    label: "CPU placement strategy",
    field: (row) => row.placementStrategy.cpu,
    sortable: true,
    align: "left",
    display: false,
    format: (placementStrategy) => (placementStrategy === PlacementStrategy.Spread ? spreadLabel : binPackLabel),
  },
  {
    name: "node-pool-updated-at",
    label: "Last update",
    field: "updatedAt",
    format: (updatedAt: string) => dateUtil.dateAndTimeFormat(new Date(updatedAt)),
    sortable: true,
    align: "left",
    display: false,
  },
  {
    name: "node-pool-created-at",
    label: "Creation time",
    field: "createdAt",
    format: (createdAt: string) => dateUtil.dateAndTimeFormat(new Date(createdAt)),
    sortable: true,
    align: "left",
    display: false,
  },
  {
    name: "workloads",
    label: "Workload(s)",
    field: (row) => row.workloads,
    sortable: false,
    align: "left",
    display: false,
    format: (workloads: Array<INodeWorkload>): string[] => {
      if (!workloads?.length) return [];
      return workloads.map((workload: INodeWorkload) => workload.name);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "workloads-clicked" },
  },
  {
    name: "over-provisioning-ratio",
    label: "GPU resource optimization ratio",
    field: (row: INodePool) => row.overProvisioningRatio,
    format: (overProvisioningRatio: number): string | number =>
      nodePoolUtil.getNodePoolOprDisplayValue(overProvisioningRatio),
    sortable: true,
    align: "left",
    display: true,
  },
] as Array<ITableColumn>;
