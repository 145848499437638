<template>
  <q-header elevated class="top-bar" style="height: 60px">
    <q-toolbar style="height: 100%" class="row justify-between items-center">
      <div class="row items-center">
        <span class="menu-icon" v-if="!fullWidthPage">
          <q-btn
            flat
            round
            @click="$emit('toggle-drawer')"
            aria-label="Menu"
            size="12px"
            icon="fa-sharp fa-regular fa-bars"
          />
        </span>
        <back-or-close-btn v-if="fullWidthPage && canLeavePage" />
        <runai-svg-icon class="logo" name="runai-logo" />
        <q-separator vertical color="white" class="separator" />
        <span aid="page-title" class="page-title">{{ pageTitle }}</span>
        <overview-feature-toggle-buttons v-if="displayOverviewFeatureToggleButtons" class="q-ml-md" />
        <q-badge
          v-if="displayViewOnlyMessage"
          aid="view-only-badge"
          color="grey"
          class="q-ml-md q-py-xs q-px-md"
          id="view-only-badge"
        >
          <q-icon name="fa-solid fa-eye" class="q-mr-sm" />
          <span>View only</span>
          <q-tooltip>You can only view this screen</q-tooltip>
        </q-badge>
      </div>

      <cluster-dropdown v-if="displayClusterDropDown" />

      <div class="right-btns row items-center">
        <setup-dropdown />
        <help-dropdown />
        <div v-if="getLogo">
          <img class="rounded-logo" :src="'data:image/png;base64, ' + getLogo" alt="" />
        </div>
        <div v-if="!isTrial" class="tenant-name">{{ tenantName }}</div>
        <profile-dropdown />
      </div>
    </q-toolbar>
  </q-header>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// cmps
import { ClusterDropdown } from "./cluster-dropdown";
import { SetupDropdown } from "./setup-dropdown";
import { HelpDropdown } from "./help-dropdown";
import { BackOrCloseBtn } from "@/components/infra/top-header/back-or-close-btn";
import { ProfileDropdown } from "./profile-dropdown";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import { OverviewFeatureToggleButtons } from "@/components/dashboard-v2/overview/overview-feature-toggle-components/overview-feature-toggle-buttons/";
// stores
import { useAuthStore } from "@/stores/auth.store";
import { useAppStore } from "@/stores/app.store";
import { useClusterStore } from "@/stores/cluster.store";
import { usePermissionStore } from "@/stores/permissions.store";
import { useSettingStore } from "@/stores/setting.store";

// models
import { Action, ResourceType } from "@/swagger-models/authorization-client";
import type { RunaiRouteRecord } from "vue-router";

//routes
import { DASHBOARD_ROUTE_NAMES, dashboardRoutes } from "@/router/dashboard.routes";
import { CLUSTER_ROUTE_NAMES } from "@/router/cluster.routes";

export default defineComponent({
  components: {
    OverviewFeatureToggleButtons,
    BackOrCloseBtn,
    ClusterDropdown,
    SetupDropdown,
    HelpDropdown,
    ProfileDropdown,
    RunaiSvgIcon,
  },
  emits: ["toggle-drawer"],
  props: {
    fullWidthPage: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  data() {
    return {
      authStore: useAuthStore(),
      appStore: useAppStore(),
      permissionStore: usePermissionStore(),
      clusterStore: useClusterStore(),
      settingStore: useSettingStore(),
    };
  },
  computed: {
    pageTitle(): string {
      return (this.$route.meta.pageTitle as string) || "";
    },
    tenantName(): string {
      return this.authStore.tenant?.displayName || "";
    },
    displayClusterDropDown(): boolean {
      return this.appStore.shouldShowClusterSelection;
    },
    displayViewOnlyMessage(): boolean {
      const currentResourceType: ResourceType | ResourceType[] | null = this.$route.meta.resourceType;
      if (!currentResourceType) return false;
      const routeName = this.$router.currentRoute.value.name;
      if (
        dashboardRoutes.children &&
        dashboardRoutes.children.some((route: RunaiRouteRecord) => route.name === routeName)
      ) {
        return false;
      }
      return !this.permissionStore.hasSomePermittedActions(currentResourceType, [
        Action.Update,
        Action.Create,
        Action.Delete,
      ]);
    },
    canLeavePage(): boolean {
      return !(this.clusterStore.clusterList.length === 0 && this.$route.name === CLUSTER_ROUTE_NAMES.CLUSTER_NEW);
    },
    isTrial(): boolean {
      return this.authStore.isTrial;
    },
    getLogo(): string | undefined {
      return this.authStore.tenant.logo;
    },
    displayOverviewFeatureToggleButtons(): boolean {
      return this.isOverviewPage && this.hasOverviewV2ModePermission;
    },
    isOverviewPage(): boolean {
      return this.$route.name === DASHBOARD_ROUTE_NAMES.OVERVIEW_INDEX;
    },
    hasOverviewV2ModePermission(): boolean {
      return this.permissionStore.hasOverviewV2ModePermission();
    },
  },
});
</script>

<style lang="scss" scoped>
#view-only-badge {
  width: 110px;
  height: 27px;
  border-radius: 2px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}
.top-bar {
  height: 54px;
  background-color: $navy;

  .logo {
    margin-left: 1.8rem;
  }

  .separator {
    margin-inline: 20px;
    height: 30px;
    opacity: 0.3;
    align-self: center;
  }

  .menu-icon {
    padding-left: 5px;
  }

  .page-title {
    font-size: 16px;
    font-weight: 500;
    color: $white;
    font-family: Roboto, sans-serif;
  }

  .tenant-name {
    font-size: 0.875rem;
    color: $white;
    border: 0.125rem solid rgba($color: #ffffff, $alpha: 0.5);
    border-radius: 0.5rem;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 0 0.625rem;
    height: 40px;
    display: flex;
    align-items: center;
    font-weight: 500;
  }
  .right-btns {
    gap: 15px;
  }

  .rounded-logo {
    border-radius: 40%;
    width: 35px;
    height: 35px;
    margin: 10px 8px 8px 8px;
  }
}
</style>
