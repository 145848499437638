<template>
  <div class="column full-height">
    <div class="row full-height">
      <div class="col-8 main-content">
        <div>
          <runai-svg-icon name="runai" class="logo-container"></runai-svg-icon>
        </div>
        <div class="title">Ready to start running?</div>
        <div class="q-pt-xl">
          <q-btn class="action-button" label="LET'S GO!" color="primary" @click="onGoClick" />
        </div>
      </div>
      <div class="col-4 cluster-connected">
        <div class="profile"><profile-dropdown dark-mode /></div>
        <div class="q-pa-md q-mx-auto connected-title">
          <div>Cluster connected!</div>
          <div class="q-pt-xl connected-icon">
            <vue3-lottie :animation-data="clusterConnect3" :height="189" :width="256" :speed="1.0" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// store
import { useAppStore } from "@/stores/app.store";

// components
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";

// services
import { storageUtil } from "@/utils/storage.util";

import { Vue3Lottie } from "vue3-lottie";
import clusterConnect3 from "@/assets/icons/animation/cluster/clusterConnect3.json";
import ProfileDropdown from "@/components/infra/top-header/profile-dropdown/profile-dropdown.vue";
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes";

const CONNECTED_PAGE_CLICKED = "connectedPageClicked";
export default defineComponent({
  components: { ProfileDropdown, RunaiSvgIcon, Vue3Lottie },
  data() {
    return {
      appStore: useAppStore(),
      clusterConnect3: clusterConnect3 as object,
    };
  },
  created() {
    this.appStore.setPageLoading(false);
  },
  computed: {},
  methods: {
    onGoClick(): void {
      storageUtil.save(CONNECTED_PAGE_CLICKED, true);
      this.$router.push({ name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX });
    },
  },
});
</script>
<style lang="scss" scoped>
.main-content {
  background-color: $navy;
  padding: 45px 0 0 45px;
}

.connected {
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.full-height {
  flex: 1;
}

.logo-container {
  width: 60px;
  height: 40px;
}

.title {
  font-size: 36px;
  font-weight: 500;
  color: $white;
  margin-top: 80px;
  line-height: 50px;
}

.action-button {
  height: 36px;
  border-radius: 3px;
}

.connected-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
  text-align: center;
}
.cluster-connected {
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.connected-icon {
  margin-top: 28px;
  font-size: 40px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
  text-align: center;
  color: $primary;
}

.profile {
  position: absolute;
  top: 0;
  right: 0;
  margin: 30px;
}
</style>
