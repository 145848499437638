<template>
  <section class="delete-modal-options q-px-md q-py-md column">
    <p class="q-mb-sm">
      Department <b>{{ department?.name }}</b> contains the following projects:
    </p>

    <ul class="q-pb-md">
      <li v-for="proj in department?.projects" :key="proj.id">
        {{ proj.name }}
      </li>
    </ul>

    <p class="q-mt-lg">Before deleting the department, either:</p>

    <div class="row">
      <div class="col">
        <q-radio
          dense
          v-model="action"
          val="delete"
          label="Delete all the department’s projects"
          @update:model-value="onModelChanged"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-6 select-row">
        <q-radio
          dense
          v-model="action"
          val="pass"
          label="Move all projects to another department "
          @update:model-value="onModelChanged"
        />
      </div>
      <div class="col-4" v-if="action === 'pass'">
        <q-select v-model="selectedDepartment" :options="options" @update:model-value="onModelChanged" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import type { IDepartment } from "@/models/department.model";
import { defineComponent, type PropType } from "vue";
import { EDeleteOrPass } from "@/components/department/delete-department-modal/delete-department.models";
import type { ISelectDepartment, IProjectOptsModel } from "./delete-department.models";

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    department: {
      type: Object as PropType<IDepartment>,
      required: true,
    },
    departmentList: {
      type: Array as PropType<Array<IDepartment>>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<IProjectOptsModel>,
      required: true,
    },
  },
  data() {
    return {
      selectedDepartment: null as ISelectDepartment | null,
      action: EDeleteOrPass.Delete as string,
    };
  },
  created() {
    this.action = this.modelValue ? this.modelValue?.action : this.action;
    this.selectedDepartment = this.options[0];
    this.onModelChanged();
  },
  computed: {
    options(): Array<ISelectDepartment> {
      const options = this.departmentList.length
        ? this.departmentList.map((d: IDepartment) => ({
            label: d.name,
            value: d.id,
          }))
        : [{ label: "No options", value: -1 }];
      return options;
    },
  },
  methods: {
    onModelChanged(): void {
      if (!this.selectedDepartment) {
        this.selectedDepartment = this.options[0];
      }
      if (this.action === EDeleteOrPass.Delete || this.selectedDepartment?.value === -1) {
        this.$emit("update:modelValue", { action: EDeleteOrPass.Delete });
      } else {
        this.$emit("update:modelValue", {
          action: this.action,
          departmentId: this.selectedDepartment?.value,
        });
      }
    },
  },
});
</script>

<style scoped lang="scss">
.delete-modal-options {
  li {
    display: inline-block;
    &:not(:last-child):after {
      content: ",";
      margin: 0 5px 0 0;
    }
  }
  .department-select {
    flex: 1;
  }

  .select-row {
    display: flex;
    align-items: center;
    height: 56px;
    margin-right: 10px;
  }
}
</style>
