import { ResourceType, Action } from "@/swagger-models/authorization-client";

import SettingsIndex from "@/views/settings/settings-index.vue";
import type { RunaiRouteRecord } from "vue-router";

export enum SETTINGS_ROUTE_NAMES {
  SETTINGS_INDEX = "settings-index",
}

export const settingsRoutes: RunaiRouteRecord = {
  path: "/general-settings",
  component: () => import("@/views/settings/settings-app.vue"),
  children: [
    {
      path: "",
      name: SETTINGS_ROUTE_NAMES.SETTINGS_INDEX,
      component: SettingsIndex,
      meta: {
        pageTitle: "General settings",
        resourceType: ResourceType.Settings,
        minPermittedActions: [Action.Update],
        fullWidth: true,
        supportBack: true,
        requiresAuth: true,
        hideClusterSelection: true,
      },
    },
  ],
};
