import { type INodePool, MIN_OVER_PROVISIONING_RATIO } from "@/models/node-pool.model";
import type { Node } from "@/swagger-models/cluster-service-client";
import { binPackLabel, PlacementStrategy, spreadLabel } from "@/models/node-pool.model";

export const nodePoolUtil = {
  enrichNodePoolsWithInfoFromCluster,
  placementStrategyText,
  getNodePoolOprDisplayValue,
};

type TotalGpusMap = Record<string, number>;

function enrichNodePoolsWithInfoFromCluster(nodePools: INodePool[], clusterServiceNodes: Node[]): INodePool[] {
  const totalGpusMap: TotalGpusMap = {};

  clusterServiceNodes.forEach((node) => {
    const { name, gpuInfo } = node;
    if (gpuInfo) totalGpusMap[name] = gpuInfo.gpuCount;
  });

  const enrichedNodePools = nodePools.map((nodePool) => {
    const nodeNames = nodePool.nodes.split(", ");
    const totalGpus = nodeNames.reduce((total, nodeName) => total + (totalGpusMap[nodeName] || 0), 0);

    return { ...nodePool, totalGpus };
  });

  return enrichedNodePools;
}

function placementStrategyText(strategy: PlacementStrategy): string {
  return strategy === PlacementStrategy.Binpack ? binPackLabel : spreadLabel;
}

function getNodePoolOprDisplayValue(overProvisioningRatio: number): string {
  if (overProvisioningRatio === MIN_OVER_PROVISIONING_RATIO) {
    return "Flexible";
  }
  return `Fixed ${overProvisioningRatio}`;
}
