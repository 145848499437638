<template>
  <runai-expansion-item
    label="Scope"
    :default-opened="!readOnly"
    :hide-expend-icon="readOnly"
    :disable-opening="readOnly"
    aid="runai-scope-section"
    :section-invalid="sectionInvalid"
  >
    <template #subheader>
      <span aid="scope-section-subheader">{{ summary }}</span>
    </template>
    <section v-show="!readOnly">
      <div class="q-mb-md" v-if="kindOfAsset">Set the scope for this {{ kindOfAsset }}</div>
      <div class="row items-center">
        <q-field
          aid="scope-field"
          class="col-10"
          ref="scope-input"
          color="grey-8"
          :model-value="scope"
          label="Scope"
          no-error-icon
          clearable
          @clear="resetScope"
          @focus="openTree"
          clear-icon="far fa-close"
          lazy-rules
          :rules="[isValidScope]"
        >
          {{ scope }}
        </q-field>
        <q-btn
          aid="scope-tree-btn"
          class="q-py-sm q-ml-md"
          ref="tree-btn"
          color="primary"
          size="xs"
          icon="fas fa-folder-tree"
        >
          <runai-org-tree-with-pop-over
            :hide-other-clusters="!showAllClusters"
            :selected="selectedScope || undefined"
            @update:selected="changedScope"
            @hide="validateScope"
            :allowed-scopes="allowedScopes"
            :scopes-supported-versions="scopesSupportedVersions"
            :disable-tenant="disableTenant"
            :disable-cluster="disableCluster"
            :forced-disabled-scopes="forcedDisabledScopes"
          ></runai-org-tree-with-pop-over>
        </q-btn>
      </div>
    </section>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { RunaiOrgTreeWithPopOver } from "@/components/common/runai-org-tree/with-popover";
// Constants
import { errorMessages } from "@/common/error-message.constant";
import type { IOrgTreeNode, IOrgTreeNodeId } from "@/models/org-tree.model";
import { ScopeType, type PermittedScopes } from "@/swagger-models/authorization-client";
import { Scope } from "@/swagger-models/assets-service-client";

import type { QBtn, QField } from "quasar";
import { useAuthStore } from "@/stores/auth.store";
import { orgTreeService } from "@/services/control-plane/rbac/org-tree.service/org-tree.service";
import { useClusterStore } from "@/stores/cluster.store";
import type { IScopeModel } from "@/models/global.model";
import { MIN_ClUSTER_SCOPE_ASSETS } from "@/common/version.constant";
import { useAppStore } from "@/stores/app.store";

export default defineComponent({
  components: {
    RunaiExpansionItem,
    RunaiOrgTreeWithPopOver,
  },
  emits: ["is-section-invalid", "changed-scope"],
  props: {
    readOnly: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    kindOfAsset: {
      type: String as PropType<string>,
      required: false,
    },
    scopeModel: {
      type: Object as PropType<IScopeModel>,
      required: true,
    },
    allowedScopes: {
      type: Object as PropType<PermittedScopes>,
      required: false,
    },
    scopesSupportedVersions: {
      type: Object as PropType<Record<ScopeType, string>>,
      default: () => ({}),
    },
    forcedDisabledScopes: {
      type: Object as PropType<Record<ScopeType, boolean>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      appStore: useAppStore(),
      authStore: useAuthStore(),
      clusterStore: useClusterStore(),
      tree: [] as Array<IOrgTreeNode>,
      disableTenant: false as boolean,
      disableCluster: false as boolean,
    };
  },
  created() {
    this.appStore.setHideClusterSelection(this.showAllClusters);
    this.checkClusterVersionForSupportedScopes();

    // Need the tree here to get the path of the selected scope before the tree component is created.
    this.tree = [orgTreeService.getOrgTree(this.authStore.orgUnitList)];
  },
  computed: {
    showAllClusters(): boolean {
      return this.clusterStore.isAllClustersSupportSpecificVersion(MIN_ClUSTER_SCOPE_ASSETS);
    },
    scope(): string | null {
      if (!this.selectedScope || !this.tree.length) return null;
      // Setting the value of the scope input if the scope is set.
      return orgTreeService.getNodePathById(this.tree[0], this.selectedScope);
    },
    selectedScope(): IOrgTreeNodeId | null {
      if (!this.scopeModel.scope) return null;
      const details: IOrgTreeNodeId = { type: this.scopeModel.scope as ScopeType, id: "" };
      switch (this.scopeModel.scope) {
        case ScopeType.Tenant:
          if (!this.authStore.tenant.id) return null;
          details.id = this.authStore.tenant.id.toString();
          break;
        case ScopeType.Cluster:
          if (!this.scopeModel.clusterId) return null;
          details.id = this.scopeModel.clusterId?.toString();
          break;
        case ScopeType.Department:
          if (!this.scopeModel.departmentId) return null;
          details.id = this.scopeModel.departmentId?.toString();
          break;
        case ScopeType.Project:
          if (!this.scopeModel.projectId) return null;
          details.id = this.scopeModel.projectId?.toString();
          break;
      }
      return details;
    },
    sectionInvalid(): boolean {
      return !this.scope;
    },
    summary(): string {
      return this.scope || "None";
    },
  },
  methods: {
    validateScope(): void {
      (this.$refs["scope-input"] as QField).validate();
    },
    isValidScope(val: string | null): boolean | string {
      return val ? true : errorMessages.SCOPE_TYPE_NOT_EMPTY;
    },
    changedScope(selectedScope: IOrgTreeNodeId): void {
      const parentClusterUuid = orgTreeService.getParentClusterUuid(
        this.authStore.orgUnitList,
        selectedScope.id,
        selectedScope.type,
      );
      const updatedScope: IScopeModel = { scope: selectedScope.type, parentClusterUuid };
      switch (selectedScope.type) {
        case ScopeType.Tenant:
          break;
        case Scope.Cluster:
          updatedScope.clusterId = selectedScope.id;
          break;
        case Scope.Department:
          updatedScope.departmentId = selectedScope.id;
          break;
        case Scope.Project:
          updatedScope.projectId = Number(selectedScope.id);
          break;
      }

      this.$emit("changed-scope", updatedScope);
    },
    resetScope(): void {
      this.$emit("changed-scope", { scope: null });
    },
    openTree(): void {
      (this.$refs["tree-btn"] as QBtn).$el.click();
      this.$nextTick(() => {
        (this.$refs["scope-input"] as QField).resetValidation();
      });
    },
    checkClusterVersionForSupportedScopes(): void {
      let supportClusterScope: boolean;
      if (this.showAllClusters) {
        this.disableTenant = true;
        this.disableCluster = false;
        return;
      } else {
        supportClusterScope = this.clusterStore.isClusterVersionSufficient(
          this.clusterStore.currentClusterId,
          MIN_ClUSTER_SCOPE_ASSETS,
        );
        this.disableCluster = !supportClusterScope;
        this.disableTenant = supportClusterScope;
        return;
      }
    },
  },
  watch: {
    sectionInvalid: {
      handler(newVal: boolean): void {
        this.$emit("is-section-invalid", newVal);
      },
      immediate: true,
    },
  },
});
</script>
<style lang="scss" scoped></style>
