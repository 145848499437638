import { API } from "@/common/api.constant";
import type { ClusterControlPlaneConfigAgentClient } from "@/swagger-models/backend-client";
import type { IAssetsFilter } from "@/models/filter.model";
import { pick } from "@/utils/common.util";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import type {
  IApplication,
  IApplicationCreationRequeset,
  IApplicationCreationResponse,
  IApplicationRegenerateSecretResponse,
} from "@/models/applications.model";

export const applicationsService = {
  list,
  remove,
  create,
  regenerateSecret,
  getRunAiInstallerAppClient,
};
const endpoint = `${API.v1}/apps`;

async function list(filterBy: IAssetsFilter): Promise<IApplication[]> {
  const filters: IAssetsFilter = pick(filterBy, "projectId", "scope", "usageInfo");
  return await controlPlaneService.get(`${endpoint}`, filters);
}

async function remove(id: string): Promise<void> {
  return controlPlaneService.delete(`${endpoint}/${id}`);
}

async function create(appCreationRequest: IApplicationCreationRequeset): Promise<IApplicationCreationResponse> {
  return controlPlaneService.post(`${endpoint}`, appCreationRequest);
}

async function regenerateSecret(id: string): Promise<IApplicationRegenerateSecretResponse> {
  return controlPlaneService.post(`v1/k8s/apps/${id}/secret`);
}

async function getRunAiInstallerAppClient(): Promise<ClusterControlPlaneConfigAgentClient> {
  return await controlPlaneService.get(`${endpoint}/installer`);
}
