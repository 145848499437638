import type { IWorkloadCreateFormConfig } from "src/components/workload/workload-create/workload-create-form";

export function getWorkloadConfig(): IWorkloadCreateFormConfig {
  const workloadConfig: IWorkloadCreateFormConfig = {
    project: {
      projects: [],
    },
    workloadName: {
      sectionDefaultOpen: false,
    },
    environment: {
      environments: [],
      sectionDefaultOpen: true,
      sectionOptions: {
        canAddEnvVariable: true,
        isToolBoxRequired: true,
      },
    },
    compute: {
      sectionDefaultOpen: true,
      isRequired: true,
      computeResources: [],
      sectionOptions: {
        applyPolicyDefaults: false,
      },
    },
    dataSource: {
      dataSources: [],
      sectionDefaultOpen: false,
    },
    volumes: {
      storageClasses: [],
      sectionDefaultOpen: false,
    },
    general: {
      sectionDefaultOpen: false,
    },
  };

  return workloadConfig;
}
