<template>
  <div class="row items-center border-bottom q-pb-xs node-pool-row">
    <template v-if="!isOnlyDefaultNodePool">
      <div class="col" v-if="isProjectEntity">
        <runai-select
          v-if="prioritiesOptions"
          borderless
          class="priority-select"
          :data-testid="`priority-select-${resource.nodePool.name}`"
          :options="prioritiesOptions"
          :option-disable="($event: string | number) => isPrioritiesOptionDisabled($event, resource.nodePool.name)"
          :model-value="getNodePoolPriority(resource.nodePool.name)"
          @update:model-value="setNodePoolPriority($event, resource.nodePool.name)"
        />
      </div>
      <div class="col node-pool-name"><runai-ellipsis-text-with-tooltip :text="resource.nodePool.name" /></div
    ></template>
    <div v-else class="col text-subtitle1 text-weight-medium">{{ entityTitle }}</div>
    <div class="col">
      <gpu-quota-input
        v-if="isDepartmentEnabled"
        :read-only="readOnly"
        :is-alert-on="isGpuAlertOn"
        :projects-deserved-gpu="isProjectEntity ? projectsDeservedResources.gpu : undefined"
        :department-deserved-gpu="maxGpuInput"
        :allocated-non-preemptible-gpu="allocatedNonPreemptibleResources.gpu"
        :model-value="resource.gpu.deserved"
        :data-testid="`gpu-quota-button-${resource.nodePool.name}`"
        @update:model-value="updateNodePoolResource($options.RESOURCE_TYPE.GPU, $event)"
      />
      <gpu-quota-input
        v-else
        hide-slider
        :read-only="readOnly"
        :model-value="resource.gpu.deserved"
        :data-testid="`gpu-quota-button-${resource.nodePool.name}`"
        @update:model-value="updateNodePoolResource($options.RESOURCE_TYPE.GPU, $event)"
      />
    </div>
    <template v-if="isCpuEnabled">
      <div class="col">
        <cpu-quota-input
          v-if="isDepartmentEnabled"
          :read-only="readOnly"
          :is-alert-on="isCpuAlertOn"
          :projects-deserved-cpu="isProjectEntity ? getDeservedValue(projectsDeservedResources.cpu) : undefined"
          :department-deserved-cpu="maxCpuCoresInput"
          :projects-deserved-cpu-is-unlimited="isResourceUnlimited(projectsDeservedResources.cpu)"
          :department-deserved-cpu-is-unlimited="isResourceUnlimited(departmentDeservedResources.cpu)"
          :allocated-non-preemptible-cpu="allocatedNonPreemptibleResources.cpu"
          :model-value="cpuInCores"
          :data-testid="`cpu-quota-button-${resource.nodePool.name}`"
          @update:model-value="updateNodePoolResource($options.RESOURCE_TYPE.CPU, $event)"
        />
        <cpu-quota-input
          v-else
          hide-slider
          :read-only="readOnly"
          :model-value="cpuInCores"
          :data-testid="`cpu-quota-button-${resource.nodePool.name}`"
          @update:model-value="updateNodePoolResource($options.RESOURCE_TYPE.CPU, $event)"
        />
      </div>
      <div class="col">
        <memory-quota-input
          v-if="isDepartmentEnabled"
          :read-only="readOnly"
          :is-alert-on="isMemoryAlertOn"
          :projects-deserved-memory="isProjectEntity ? getDeservedValue(projectsDeservedResources.memory) : undefined"
          :department-deserved-memory="maxCpuMemoryInput"
          :department-deserved-memory-is-unlimited="
            isProjectEntity ? isResourceUnlimited(departmentDeservedResources.memory) : undefined
          "
          :projects-deserved-memory-is-unlimited="
            isProjectEntity ? isResourceUnlimited(projectsDeservedResources.memory) : undefined
          "
          :allocated-non-preemptible-memory="allocatedNonPreemptibleResources.memory"
          :model-value="resource.memory.deserved"
          :data-testid="`memory-quota-button-${resource.nodePool.name}`"
          @update:model-value="updateNodePoolResource($options.RESOURCE_TYPE.MEMORY, $event)"
        />
        <memory-quota-input
          v-else
          hide-slider
          :read-only="readOnly"
          :model-value="resource.memory.deserved"
          :data-testid="`memory-quota-button-${resource.nodePool.name}`"
          @update:model-value="updateNodePoolResource($options.RESOURCE_TYPE.MEMORY, $event)"
        />
      </div>
    </template>
    <div class="col" v-if="isOverQuotaPriorityEnabled && isProjectEntity">
      <runai-select
        dense
        class="over-quota-select"
        :data-testid="`over-quota-priority-select-${resource.nodePool.name}`"
        :options="$options.overQuotaPriorityOptions"
        :model-value="getSelectedOverQuotaOption()"
        @update:model-value="updateNodePoolResourceOverQuota($event.value)"
        outlined
      />
    </div>
    <div class="col" v-else-if="isProjectEntity">
      <runai-select
        dense
        class="over-quota-select"
        :data-testid="`over-quota-select-${resource.nodePool.name}`"
        :model-value="getSelectedOverQuotaOption()"
        @update:model-value="updateNodePoolResourceOverQuota($event.value)"
        :options="$options.overQuotaOptions"
        outlined
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

//utils
import { resourceUtil } from "@/utils/resource.util";
//model
import type { ISelectOption } from "@/models/global.model";
import type { INodePoolResources } from "@/models/project.model";
import {
  CPU_VALUE_FACTOR,
  defaultQuotaOption,
  defaultQuotaPriorityOption,
  EMPTY_PRIORITY_VALUE,
  EQuotaEntity,
  type EResourceState,
  EResourceType,
  type INodePoolAllocatedNonPreemptibleSum,
  type INodePoolIsOverQuota,
  type INodePoolResourcesSum,
  MAX_PRESENTED_CPU_CORES,
  MAX_PRESENTED_CPU_MEMORY_MIB,
  MIN_PRESENTED_CPU_CORES,
  MIN_PRESENTED_GPU,
  overQuotaOptions,
  overQuotaPriorityOptions,
  MIN_PRESENTED_CPU_MEMORY_MIB,
} from "@/models/resource.model";
// cmps
import MemoryQuotaInput from "@/components/quota-management/quota-inputs/cpu-memory-quota-input/memory-quota-input.vue";
import { RunaiEllipsisTextWithTooltip } from "@/components/common/runai-ellipsis-text-with-tooltip";
import { RunaiSelect } from "../../../common/runai-select";
import { useNodeStore } from "@/stores/node.store";
import { CpuQuotaInput } from "../../quota-inputs/cpu-quota-input";
import { GpuQuotaInput } from "../../quota-inputs/gpu-quota-input";

export default defineComponent({
  components: { RunaiEllipsisTextWithTooltip, CpuQuotaInput, GpuQuotaInput, MemoryQuotaInput, RunaiSelect },
  emits: ["update-node-pool-priority", "update-node-pool-resource", "update-node-pool-over-quota"],
  inject: ["entity"],
  EQuotaEntity: EQuotaEntity,
  RESOURCE_TYPE: EResourceType,
  overQuotaOptions: overQuotaOptions,
  overQuotaPriorityOptions: overQuotaPriorityOptions,
  props: {
    readOnly: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    resource: {
      type: Object as PropType<INodePoolResources>,
      required: true,
    },
    priorities: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    prioritiesOptions: {
      type: Array as PropType<Array<string | number>>,
      required: false,
    },
    isDepartmentEnabled: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isCpuEnabled: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isOnlyDefaultNodePool: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isOverQuotaPriorityEnabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isNodePoolPriorityDisabled: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    nodePoolPriority: {
      type: [String, Number] as PropType<string | number>,
      default: "empty",
    },
    departmentName: {
      type: String as PropType<string>,
      default: "",
    },
    isNodePoolOverQuota: {
      type: Object as PropType<INodePoolIsOverQuota>,
      default: () => ({ gpu: false, cpu: false, memory: false }),
    },
    projectsDeservedResources: {
      type: Object as PropType<INodePoolResourcesSum>,
      default: () => ({ gpu: 0, cpu: 0, memory: 0 }),
    },
    departmentDeservedResources: {
      type: Object as PropType<INodePoolResourcesSum>,
      required: false,
      default: () => ({ gpu: 0, cpu: 0, memory: 0 }),
    },
    allocatedNonPreemptibleResources: {
      type: Object as PropType<INodePoolAllocatedNonPreemptibleSum>,
      default: () => ({ gpu: 0, cpu: 0, memory: 0 }),
    },
  },
  data() {
    return {
      nodeStore: useNodeStore(),
    };
  },
  computed: {
    isProjectEntity(): boolean {
      return this.entity === EQuotaEntity.project;
    },
    EQuotaEntity(): typeof EQuotaEntity {
      return EQuotaEntity;
    },
    entityTitle(): string {
      return this.isProjectEntity ? "Project total" : "Department total";
    },
    cpuInCores(): number | null {
      if (this.resource.cpu.deserved === null || this.resource.cpu.deserved === undefined) {
        return null;
      }
      return this.resource.cpu.deserved / CPU_VALUE_FACTOR;
    },
    maxGpuInput(): number {
      if (this.isProjectEntity) {
        return this.departmentDeservedResources.gpu;
      }
      return Math.max(MIN_PRESENTED_GPU, this.nodeStore.totalClusterGpu);
    },
    maxCpuCoresInput(): number {
      if (this.isProjectEntity) {
        return resourceUtil.getDeservedValue(this.departmentDeservedResources.cpu);
      }
      const clusterCpuMilliCores = this.nodeStore.totalClusterCpuCores * CPU_VALUE_FACTOR;
      if (MAX_PRESENTED_CPU_CORES * CPU_VALUE_FACTOR > clusterCpuMilliCores) {
        return Math.max(MIN_PRESENTED_CPU_CORES * CPU_VALUE_FACTOR, clusterCpuMilliCores);
      }
      return MAX_PRESENTED_CPU_CORES * CPU_VALUE_FACTOR;
    },
    maxCpuMemoryInput(): number | EResourceState {
      if (this.isProjectEntity) {
        return this.getDeservedValue(this.departmentDeservedResources.memory);
      }
      const clusterCpuMemoryMib = resourceUtil.fromBytesToMiB(this.nodeStore.totalClusterCpuMemory);
      if (MAX_PRESENTED_CPU_MEMORY_MIB > clusterCpuMemoryMib) {
        return Math.max(MIN_PRESENTED_CPU_MEMORY_MIB, clusterCpuMemoryMib);
      }
      return MAX_PRESENTED_CPU_MEMORY_MIB;
    },
    isGpuAlertOn(): boolean {
      return this.isResourceAlertOn(EResourceType.GPU, this.resource.gpu.deserved);
    },
    isCpuAlertOn(): boolean {
      return this.isResourceAlertOn(EResourceType.CPU, this.resource.cpu.deserved);
    },
    isMemoryAlertOn(): boolean {
      return this.isResourceAlertOn(EResourceType.MEMORY, this.resource.memory.deserved);
    },
  },
  methods: {
    isPrioritiesOptionDisabled(value: string | number, nodePoolName: string): boolean {
      const index = +value - 1;
      if (value === EMPTY_PRIORITY_VALUE) {
        return false;
      }

      return index > this.priorities.length || this.priorities.includes(nodePoolName) || !!this.priorities[index];
    },
    getNodePoolPriority(nodePoolName: string): string | number {
      if (this.priorities.includes(nodePoolName)) {
        return this.priorities.indexOf(nodePoolName) + 1;
      }
      return EMPTY_PRIORITY_VALUE;
    },
    setNodePoolPriority(value: string | number, nodePoolName: string): void {
      this.$emit("update-node-pool-priority", { value, nodePoolName });
    },
    updateNodePoolResource(resourceType: EResourceType, resourceValue: number | null): void {
      this.$emit("update-node-pool-resource", { name: this.resource.nodePool.name, resourceType, resourceValue });
    },
    getDeservedValue(resourceValue: EResourceState | number) {
      return resourceUtil.getDeservedValue(resourceValue);
    },
    isResourceUnlimited(resourceValue: number | EResourceState): boolean {
      return resourceUtil.isResourceUnlimited(resourceValue);
    },
    updateNodePoolResourceOverQuota(overQuota: number): void {
      this.$emit("update-node-pool-over-quota", { name: this.resource.nodePool.name, overQuota });
    },
    getSelectedOverQuotaOption(): ISelectOption {
      if (this.isOverQuotaPriorityEnabled) {
        if (this.resource.gpu.overQuotaWeight === null || this.resource.gpu.overQuotaWeight === undefined) {
          return defaultQuotaPriorityOption;
        }
        return {
          label: resourceUtil.getOverQuotaPriorityKeyByValue(this.resource.gpu.overQuotaWeight),
          value: this.resource.gpu.overQuotaWeight,
        };
      } else {
        if (this.resource.gpu.overQuotaWeight === null || this.resource.gpu.overQuotaWeight === undefined) {
          return defaultQuotaOption;
        }
        return {
          label: resourceUtil.getOverQuotaKeyByValue(this.resource.gpu.overQuotaWeight),
          value: this.resource.gpu.overQuotaWeight,
        };
      }
    },
    isResourceUnderAllocatedNonPreemptible(
      resourceType: EResourceType,
      resourceValue: number | null | undefined,
    ): boolean {
      if (resourceValue === null || resourceValue === undefined) return false;
      switch (resourceType) {
        case EResourceType.GPU:
          return resourceUtil.isResourceUnderAllocatedNonPreemptible(
            resourceValue,
            this.allocatedNonPreemptibleResources.gpu,
          );
        case EResourceType.CPU:
          return resourceUtil.isResourceUnderAllocatedNonPreemptible(
            resourceValue / CPU_VALUE_FACTOR,
            this.allocatedNonPreemptibleResources.cpu / CPU_VALUE_FACTOR,
          );
        case EResourceType.MEMORY:
          return resourceUtil.isResourceUnderAllocatedNonPreemptible(
            resourceValue,
            this.allocatedNonPreemptibleResources.memory,
          );
        default:
          return false;
      }
    },
    isNodePoolResourceOverQuota(resourceType: EResourceType, resourceValue: number | null | undefined): boolean {
      if (this.entity === EQuotaEntity.department) return false;

      switch (resourceType) {
        case EResourceType.GPU:
          return this.isNodePoolOverQuota.gpu;
        case EResourceType.CPU:
          return (
            this.isNodePoolOverQuota.cpu ||
            (resourceValue === null && !this.isResourceUnlimited(this.departmentDeservedResources.cpu))
          );
        case EResourceType.MEMORY:
          return (
            this.isNodePoolOverQuota.memory ||
            (resourceValue === null && !this.isResourceUnlimited(this.departmentDeservedResources.memory))
          );
      }
    },
    isResourceAlertOn(resourceType: EResourceType, resourceValue: number | null | undefined): boolean {
      return (
        this.isResourceUnderAllocatedNonPreemptible(resourceType, resourceValue) ||
        this.isNodePoolResourceOverQuota(resourceType, resourceValue)
      );
    },
  },
});
</script>
<style scoped lang="scss">
.node-pool-row {
  min-height: 65px;
}
.priority-select {
  width: 60px;
}
.over-quota-select {
  cursor: pointer;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid $black-54;
  text-align: center;
  box-shadow: 0 1px 6px $black-15;
  color: $black-70;
  background-color: white;
  font-weight: 500;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 1px 6px 2px $black-25;
  }
}
.border-bottom {
  border-bottom: 1px solid $black-12;
}
</style>
