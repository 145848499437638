// services
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";

// utils
import { pick } from "@/utils/common.util";

// models
import type { DisplayedJob } from "@/swagger-models/assets-service-client";
import type { IFilterBy, IFilterModel } from "@/models/filter.model";
// constants
import { K8S_API } from "@/common/api.constant";

export const jobService = {
  getJobs,
  list,
  getWorkloadsCount,
};

async function getJobs(clusterUuid: string, queryParams: string[] = []): Promise<Array<DisplayedJob>> {
  const data = await controlPlaneService.get(`${_jobEndpoint(clusterUuid)}?limit=1200&${queryParams.join("&")}`);

  return data as Array<DisplayedJob>;
}
function buildColumnFiltersQueryString(columnFilters?: Array<IFilterModel>, searchTerm?: string): string {
  const filters = columnFilters?.map(({ term, name }) => `${name}:${term}`) || [];
  if (searchTerm) {
    filters.push(`text:${searchTerm}`);
  }

  return filters.length > 0 ? `filter=${filters.join(" ")}` : "filter=jobType:Train|Interactive|Inference";
}
function filterJobsQueryToUrlQuery(filterBy: IFilterBy): string {
  const filters: IFilterBy = pick(filterBy, "sortBy", "page", "rowsPerPage");
  const from = ((filters.page || 0) - 1) * (filters.rowsPerPage || 0);
  const params = [
    buildColumnFiltersQueryString(filterBy.columnFilters, filterBy.searchTerm),
    filters.sortBy && `sortBy=${filters.sortBy}&sortDirection=${filterBy.descending ? "desc" : "asc"}`,
    filters.page && `from=${from}`,
    filters.rowsPerPage && `limit=${filters.rowsPerPage}`,
  ].filter(Boolean);

  return params.length > 0 ? params.join("&") : "";
}
async function list(clusterUuid: string, filterBy: IFilterBy = {}): Promise<Array<DisplayedJob>> {
  const params = filterJobsQueryToUrlQuery(filterBy);

  return await controlPlaneService.get(`${_jobEndpoint(clusterUuid)}/?${params}`);
}

// helpers
function _jobEndpoint(clusterUuid: string): string {
  return `${K8S_API.v1}/clusters/${clusterUuid}/jobs`;
}

async function getWorkloadsCount(clusterUuid: string, filterBy: IFilterBy): Promise<{ count: number }> {
  const params: string = buildColumnFiltersQueryString(filterBy.columnFilters, filterBy.searchTerm);

  return controlPlaneService.get(`${_jobEndpoint(clusterUuid)}/count?${params}`);
}
