<template>
  <setting-wrapper-box
    :setting-box-messages="settingBoxMessages"
    :remove-disabled="isSSOUser"
    has-header
    :has-header-buttons="!isEditMode"
    :has-footer="isEditMode"
    :validation-message="validationMessage"
    @removed-clicked="removeClicked"
    @secondary-button-clicked="onCancelEdit"
    @primary-button-clicked="onUpdate"
    @edit-clicked="onEdit"
  >
    <div v-if="!isEditMode" class="col-12">
      <div v-if="hasIdpAttributes">Use the following if required by the identity provider</div>
      <setting-readable-field label="Redirect URL" v-if="idp?.redirectUri">
        <div class="row no-wrap items-center">
          <div class="col-9 q-pt-md q-pl-md">
            {{ idp.redirectUri }}
          </div>
        </div>
      </setting-readable-field>
      <setting-readable-field label="Entity ID" v-if="idp?.config.entityId">
        <div class="row no-wrap items-center">
          <div class="col-9 q-pt-md q-pl-md">
            {{ idp.config.entityId }}
          </div>
        </div>
      </setting-readable-field>
    </div>
    <div class="col-12" v-else>
      <div class="q-mb-lg">
        <oidc-fields
          :disabled="!isNewIdp"
          :idp-fields="oidcNewFields"
          @updated="updateOidcNewFields"
          ref="oidcSetupFieldsEl"
        />
      </div>
      <idp-mappers :idp-mappers="idpMappersItems" />
    </div>
  </setting-wrapper-box>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import SettingWrapperBox from "@/components/settings/setting-wrapper-box/setting-wrapper-box.vue";
import type { Idp, Mappers, OidcCreationData } from "@/swagger-models/identity-manager-client";
import SettingReadableField from "@/components/settings/setting-readable-field/setting-readable-field.vue";
import IdpMappers from "@/components/settings/sections/security/sso-settings/idps/idp-mappers/idp-mappers.vue";
import type { IIdpMapperItem, IWrapperBoxSettingMessages } from "@/models/setting.model";
import { settingsUtil } from "@/utils/settings.util";
import { useAuthStore } from "@/stores/auth.store";
import { OidcFields } from "./oidc-fields";
import { idpCommon } from "../idp-common/idp-common";

export default defineComponent({
  name: "oidc-settings",
  components: { OidcFields, IdpMappers, SettingReadableField, SettingWrapperBox },
  emits: ["remove", "add-idp", "update-idp", "cancel-edit-idp"],
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    idp: {
      type: Object as PropType<Idp> | null,
      required: false,
    },
    idpMappers: {
      type: Object as PropType<Mappers>,
      required: false,
    },
    isNewIdp: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      isEditMode: false,
      idpMappersItems: this._getIdpMappers() as Array<IIdpMapperItem>,
      oidcNewFields: {} as OidcCreationData,
      isValidNewFields: false,
      authStore: useAuthStore(),
    };
  },
  created(): void {
    this.isEditMode = this.isNewIdp;
  },
  computed: {
    settingBoxMessages(): IWrapperBoxSettingMessages {
      return idpCommon.getSettingsBoxMessages(this.title, this.isSSOUser);
    },
    isSSOUser(): boolean {
      return this.authStore.isCurrentUserSSO;
    },
    isIdpMapperChanged(): boolean {
      return !settingsUtil.isIdpMappersEqual(this.idpMappers || {}, this.idpMappersItems);
    },
    validationMessage(): string {
      if (this.isEditMode && (this.isIdpMapperChanged || this.newFieldsHasChanges)) return "Unsaved changes";
      return "";
    },
    newFieldsHasChanges(): boolean {
      return !Object.values(this.oidcNewFields).every((value) => value === "");
    },
    hasIdpAttributes(): boolean {
      return (this.idp?.redirectUri || this.idp?.config.entityId || "") !== "";
    },
  },
  methods: {
    removeClicked(): void {
      this.$emit("remove");
    },
    onEdit(): void {
      this.isEditMode = true;
      this.idpMappersItems = this._getIdpMappers();
    },
    updateOidcNewFields(newOidcFields: OidcCreationData, isValid: boolean): void {
      this.isValidNewFields = isValid;
      this.oidcNewFields = newOidcFields;
    },
    async onUpdate(): Promise<void> {
      if (this.isNewIdp) {
        await (this.$refs.oidcSetupFieldsEl as typeof OidcFields).validate();
        if (!this.isValidNewFields) return;
        this.$emit("add-idp", this.oidcNewFields, settingsUtil.getMappersFromIdpMapperItems(this.idpMappersItems));
      } else {
        if (!this.isIdpMapperChanged) return;
        this.$emit("update-idp", settingsUtil.getMappersFromIdpMapperItems(this.idpMappersItems));
      }
    },
    onCancelEdit(): void {
      this.isEditMode = false;
      this.$emit("cancel-edit-idp");
    },
    changeToReadMode(): void {
      this.isEditMode = false;
      this.oidcNewFields = {} as OidcCreationData;
    },
    _getIdpMappers(): Array<IIdpMapperItem> {
      if (!this.idpMappers) return [];
      return [...settingsUtil.getIdpMappersItems(this.idpMappers)];
    },
  },
});
</script>
