<template>
  <runai-filter-chip
    title="Cluster"
    :value="clusterName"
    @save="$emit('update-filter', selected)"
    @close="selected = filterValue"
  >
    <template v-slot:input-component>
      <runai-radio-options v-model="selected" :options="options" option-max-width="200px"></runai-radio-options>
    </template>
  </runai-filter-chip>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "@vue/runtime-core";
// stores
import { useClusterStore } from "@/stores/cluster.store";
// cmps
import { RunaiFilterChip } from "@/components/common/runai-filter-chip";
// type
import type { ISelectOption } from "@/models/global.model";
import type { ICluster } from "@/models/cluster.model";

import { RunaiRadioOptions } from "@/components/common/runai-radio-options";

const allOption: ISelectClusterOption = { label: "All", value: "" };

interface ISelectClusterOption extends ISelectOption {
  value: string;
}

export default defineComponent({
  components: {
    RunaiFilterChip,
    RunaiRadioOptions,
  },
  emits: ["update-filter"],
  props: {
    filterValue: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      clusterStore: useClusterStore(),
      selected: allOption.value as ISelectClusterOption["value"],
    };
  },
  computed: {
    options(): ISelectClusterOption[] {
      const options: ISelectClusterOption[] = this.clusterStore.clusterList.map((c: ICluster) => {
        return { label: c.name, value: c.uuid };
      });
      options.unshift(allOption);
      return options;
    },
    clusterName(): string {
      return this.options.find((opt: ISelectClusterOption) => opt.value === this.filterValue)?.label || allOption.label;
    },
  },
  watch: {
    filterValue: {
      handler(newValue) {
        this.selected = newValue || allOption.value;
      },
      immediate: true,
    },
  },
});
</script>
