import { SpecificRunAutoScalingAutoScalingThresholdMetricEnum } from "@/swagger-models/assets-service-client";

export interface IScaleDownOption {
  id: string;
  name: string;
}

export interface IThresholdMetricOption {
  id: SpecificRunAutoScalingAutoScalingThresholdMetricEnum;
  name: string;
}

export interface IAutoScaleData {
  minReplicas: number;
  maxReplicas: number;
  thresholdMetric: SpecificRunAutoScalingAutoScalingThresholdMetricEnum | undefined;
  thresholdValue: number | undefined;
}

export const scaleDownOptions: Array<IScaleDownOption> = [
  {
    id: "never",
    name: "Never",
  },
  {
    id: "scaleDownToZero",
    name: "After 1 minute of inactivity",
  },
];

export const thresholdMetricOptions: Array<IThresholdMetricOption> = [
  {
    id: SpecificRunAutoScalingAutoScalingThresholdMetricEnum.Throughput,
    name: "Throughput (Requests/sec)",
  },
  {
    id: SpecificRunAutoScalingAutoScalingThresholdMetricEnum.Concurrency,
    name: "Concurrency (Requests/sec)",
  },
];
