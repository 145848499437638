export enum DEPLOYMENT_ROUTE_NAMES {
  DEPLOYMENT_NEW = "inference-new",
  DEPLOYMENT_HISTORY = "inference-history",
  DEPLOYMENT_ASSETS_EDIT = "inference-assets-edit",
  DEPLOYMENT_MODEL_EDIT = "inference-model-edit",
  DEPLOYMENT_ENVIRONMENT_NEW = "inference-environment-new",
  DEPLOYMENT_COMPUTE_RESOURCE_NEW = "inference-compute-resource-new",
  DEPLOYMENT_HOST_PATH_NEW = "inference-host-path-new",
  DEPLOYMENT_NFS_NEW = "inference-nfs-new",
  DEPLOYMENT_S3_NEW = "inference-s3-new",
  DEPLOYMENT_GIT_NEW = "inference-git-new",
  DEPLOYMENT_PVC_NEW = "inference-pvc-new",
}
