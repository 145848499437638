<template>
  <runai-expansion-item class="notifications-section settings-container" label="Notifications" :subheader="subheader">
    <system-notifications :setting="adminMessageSetting" />
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { IAdminMessageSetting, SettingKeys } from "@/models/setting.model";
import { useSettingStore } from "@/stores/setting.store";
import type { Setting } from "@/swagger-models/backend-client";
import SystemNotifications from "@/components/settings/sections/notifications/system-notifications/system-notifications.vue";

export default defineComponent({
  name: "notifications-section",
  components: { SystemNotifications, RunaiExpansionItem },
  data() {
    return {
      settingStore: useSettingStore(),
    };
  },
  computed: {
    adminMessageSetting(): IAdminMessageSetting | undefined {
      return this.settingStore.settingList.find((setting: Setting) => setting.key === SettingKeys.AdminMessage) as
        | IAdminMessageSetting
        | undefined;
    },
    subheader(): string {
      return this.adminMessageSetting?.value.msg ? "System notification" : "None";
    },
  },
});
</script>
