import { CLUSTER_COLUMN_FILTER_NAME } from "@/models/filter.model";
import { ECustomCell, type ITableColumn } from "@/models/table.model";
import { EWorkloadNodeArchitecture } from "@/models/workload.model";
import {
  Scope,
  type EnvironmentAsset,
  type AssetRef,
  type WorkloadSupportedTypes,
  type AssetUsageInfo,
  type Connection,
  InternalConnectionType,
} from "@/swagger-models/assets-service-client";
import { assetsUtil } from "@/utils/assets.util/assets.util";
import { dateUtil } from "@/utils/date.util";
import { workloadUtil } from "@/utils/workload.util";

export const allEnvironmentColumnsMap: Record<string, ITableColumn> = {
  environment: {
    name: "environment",
    label: "Environment",
    field: (row: EnvironmentAsset) => row.meta.name,
    sortable: true,
    align: "left",
    display: true,
    customCell: ECustomCell.NAME_COL,
  },
  scope: {
    name: "scope",
    label: "Scope",
    field: (row: EnvironmentAsset) => row.meta.scope,
    sortable: true,
    align: "left",
    display: true,
    format: (scope: Scope, env: EnvironmentAsset): string | number => {
      switch (scope) {
        case Scope.Project:
          return Number(env.meta.projectId);
        case Scope.Department:
          return String(env.meta.departmentId);
        case Scope.Cluster:
          return String(env.meta.clusterId);
        default:
          return String(env.meta.tenantId);
      }
    },
    customCell: ECustomCell.SCOPE_COL,
    hideFilter: true,
  },
  image: {
    name: "image",
    label: "Image",
    field: (row: EnvironmentAsset) => row.spec.image,
    sortable: true,
    align: "left",
    display: true,
  },
  multiNode: {
    name: "multi-node",
    label: "Workload architecture",
    field: (row: EnvironmentAsset) => row.meta.workloadSupportedTypes?.distributed,
    sortable: true,
    align: "left",
    format: (val: boolean | undefined) =>
      val ? EWorkloadNodeArchitecture.Distributed : EWorkloadNodeArchitecture.Standard,
    display: true,
  },
  tools: {
    name: "tools",
    label: "Tool(s)",
    field: (row: EnvironmentAsset) =>
      row.spec.connections?.filter((connection: Connection) => connection.name !== InternalConnectionType.ServingPort),
    sortable: true,
    align: "left",
    display: true,
    format: (connections: Array<Connection>): string[] => {
      if (!connections?.length) return [];
      return connections.map((con) => con.name);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "tool-clicked" },
  },
  workloads: {
    name: "workloads",
    label: "Workload(s)",
    field: (row) => row.usedBy,
    sortable: true,
    align: "left",
    format: (usageInfo: AssetUsageInfo | undefined): Array<string> => {
      if (!usageInfo) return [];
      return assetsUtil.extractWorkloadNames(usageInfo);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "workload-clicked" },
  },
  workloadTypes: {
    name: "workload-types",
    label: "Workload types",
    field: (row) => row.meta.workloadSupportedTypes,
    sortable: true,
    align: "left",
    format: (workloadTypes: WorkloadSupportedTypes | undefined): string => {
      if (!workloadTypes) return "-";

      return workloadUtil.convertWorkloadTypesToString(workloadTypes);
    },
  },
  templates: {
    name: "templates",
    label: "Template(s)",
    field: (row: EnvironmentAsset) => row.usedBy?.templates,
    sortable: true,
    align: "left",
    display: false,
    format: (templates: Array<AssetRef>): Array<string> => {
      if (!templates?.length) return [];
      return templates.map((template: AssetRef) => template.name);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "workload-template-clicked" },
  },
  createdBy: {
    name: "created-by",
    label: "Created by",
    field: (row: EnvironmentAsset) => row.meta.createdBy,
    sortable: true,
    align: "left",
    display: false,
  },
  createdAt: {
    name: "created-at",
    label: "Creation time",
    field: (row: EnvironmentAsset) => row.meta.createdAt,
    sortable: true,
    align: "left",
    display: true,
    format: (createdAt: string) => dateUtil.dateAndTimeFormat(new Date(createdAt)),
  },
  cluster: {
    name: CLUSTER_COLUMN_FILTER_NAME,
    label: "Cluster",
    field: (row) => row.meta.clusterId,
    sortable: true,
    align: "left",
    customCell: ECustomCell.CLUSTER_ID_TO_NAME_COL,
    hideFilter: true,
    ignoreFilter: (row: EnvironmentAsset) => {
      // ignoring this column filter if the scope is system or tenant (as clusterId is undefined)
      return !row.meta.clusterId && (row.meta.scope === Scope.System || row.meta.scope === Scope.Tenant);
    },
  },
};

export const allEnvironmentColumns: Array<ITableColumn> = [
  allEnvironmentColumnsMap.environment,
  allEnvironmentColumnsMap.scope,
  allEnvironmentColumnsMap.image,
  allEnvironmentColumnsMap.multiNode,
  allEnvironmentColumnsMap.tools,
  allEnvironmentColumnsMap.workloads,
  allEnvironmentColumnsMap.workloadTypes,
  allEnvironmentColumnsMap.templates,
  allEnvironmentColumnsMap.createdBy,
  allEnvironmentColumnsMap.createdAt,
  allEnvironmentColumnsMap.cluster,
];

export const environmentIndexColumns: Array<ITableColumn> = [
  { ...allEnvironmentColumnsMap.environment, display: true, mandatory: true },
  { ...allEnvironmentColumnsMap.scope, display: true },
  { ...allEnvironmentColumnsMap.image, display: true },
  { ...allEnvironmentColumnsMap.multiNode, display: false },
  { ...allEnvironmentColumnsMap.tools, display: true },
  { ...allEnvironmentColumnsMap.workloads, display: true },
  { ...allEnvironmentColumnsMap.workloadTypes, display: true },
  { ...allEnvironmentColumnsMap.templates, display: false },
  { ...allEnvironmentColumnsMap.createdBy, display: false },
  { ...allEnvironmentColumnsMap.createdAt, display: true },
  { ...allEnvironmentColumnsMap.cluster, display: true },
];

export const allToolTableColumnsMap: Record<string, ITableColumn> = {
  toolName: {
    name: "tool-name",
    label: "Tool name",
    field: (row) => row.name,
    sortable: true,
    align: "left",
  },
  connectionType: {
    name: "connection-type",
    label: "Connection type",
    field: (row) => row.internalToolInfo.connectionType,
    sortable: true,
    align: "left",
  },
};

export const toolTableColumns: Array<ITableColumn> = [
  {
    ...allToolTableColumnsMap.toolName,
    display: true,
  },
  {
    ...allToolTableColumnsMap.connectionType,
    display: true,
  },
];
