import { defineStore } from "pinia";

// models
import type { WorkloadCreationRequest, Workspace } from "@/swagger-models/assets-service-client";
import { ResourceType, type Action, type PermittedScopes } from "@/swagger-models/authorization-client";
import type { IFilterBy } from "@/models/filter.model";
import { EOrderDirection } from "@/models/filter.model";
import type { IWorkspaceList } from "@/models/workspace.model";
import type { IProjectResources } from "@/models/project.model";
import type { IUIWorkloadCreation } from "@/models/workload.model";

// services
import { workspaceService } from "@/services/control-plane/workspace.service/workspace.service";
import { projectService } from "@/services/control-plane/project.service/project.service";
import { permissionService } from "@/services/authorization/permission.service/permission.service";

// stores
import { useClusterStore } from "@/stores/cluster.store";

// utils
import { workloadUtil } from "@/utils/workload.util/workload.util";

export const useWorkspaceStore = defineStore("Workspace", {
  state: () => ({
    workspaces: [] as Array<IWorkspaceList>,
    workspace: workloadUtil.getEmptyUIWorkloadCreation() as IUIWorkloadCreation,
    templateId: null as string | null,
    projects: [] as Array<IProjectResources>,
    selectedWorkspace: null as Workspace | null,
    workspaceAdded: null as IWorkspaceList | null,
    actionPermissionsByScopes: {} as Record<Action, PermittedScopes>,
  }),
  getters: {
    workspaceName(): string {
      return this.workspace.name || "";
    },
    selectedTemplate(): string | null {
      return this.templateId;
    },
    projectList(): Array<IProjectResources> {
      return this.projects;
    },
    workspaceList(): Array<IWorkspaceList> {
      if (!this.workspaceAdded) return this.workspaces;
      return this.workspaces.filter((workspace: IWorkspaceList) => workspace.meta.id !== this.workspaceAdded?.meta.id);
    },
    lastCreatedWorkspace(): IWorkspaceList | null {
      return this.workspaceAdded;
    },
  },
  actions: {
    async loadActionPermissions(): Promise<void> {
      if (Object.keys(this.actionPermissionsByScopes).length > 0) return;
      this.actionPermissionsByScopes = await permissionService.getPermittedScopesForAction(ResourceType.Workspaces);
    },
    async loadWorkspaces(filterBy: IFilterBy = {}): Promise<void> {
      const clusterStore = useClusterStore();

      this.workspaces = await workspaceService.list(clusterStore.currentClusterId, {
        ...filterBy,
        sortBy: filterBy.sortBy || "",
        sortDirection: filterBy.descending ? EOrderDirection.DESC : EOrderDirection.ASC,
      });
    },
    async loadById(id: string): Promise<Workspace> {
      this.selectedWorkspace = await workspaceService.getById(id);
      return this.selectedWorkspace;
    },
    async loadLastCreatedWorkspace(clusterId: string, id: string): Promise<IWorkspaceList> {
      this.workspaceAdded = await workspaceService.getByIdFromCluster(clusterId, id);
      return this.workspaceAdded;
    },

    setWorkspace(workspace: IUIWorkloadCreation): void {
      this.workspace = workspace;
    },
    setTemplate(templateId: string | null): void {
      this.templateId = templateId;
    },

    resetCreateWorkspaceData(): void {
      this.templateId = null;
      this.workspace = workloadUtil.getEmptyUIWorkloadCreation();
    },

    async loadProjects(): Promise<Array<IProjectResources>> {
      const clusterStore = useClusterStore();
      this.projects = await projectService.listQuota(clusterStore.currentClusterId);
      return this.projects;
    },
    async createWorkspace(workspace: WorkloadCreationRequest): Promise<IWorkspaceList | null> {
      const createdWorkspace: Workspace = await workspaceService.createFromAssets(workspace);
      const clusterStore = useClusterStore();
      this.workspaceAdded = await workspaceService.getByIdFromCluster(
        clusterStore.currentClusterId,
        createdWorkspace.meta.id,
      );
      return this.workspaceAdded;
    },

    async activateWorkspace(workspace: IWorkspaceList): Promise<void> {
      await workspaceService.activate(workspace);
    },
    async stopWorkspace(workspace: IWorkspaceList): Promise<void> {
      await workspaceService.stop(workspace);
    },
    async deleteWorkspace(workspace: IWorkspaceList): Promise<IWorkspaceList> {
      const workspaceDeleted: IWorkspaceList = await workspaceService.remove(workspace);
      this.workspaces = this.workspaces.map((currWorkspace: IWorkspaceList) => {
        return currWorkspace.meta.id === workspaceDeleted.meta.id ? workspaceDeleted : currWorkspace;
      });
      return workspaceDeleted;
    },
    removeWorkspaceAdded(): void {
      this.workspaceAdded = null;
    },
  },
});
