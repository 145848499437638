<template>
  <section class="inference-new column items-center q-pt-md">
    <runai-form-wrapper ref="deploymentForm" :form-state="deployment">
      <section class="create-inference-form">
        <runai-expansion-wrapper>
          <project-section
            entity-type="inference"
            aid="project-section"
            :loading="loadingProject"
            :selected-project-id="deployment.projectId"
            :projects="projects"
            @project-changed="onSelectedProject"
          />

          <transition-group name="fade">
            <template v-if="deployment.projectId && deployment.projectId !== -1">
              <inference-type
                v-if="showInferenceTypeSelection"
                :is-selectable="allowTypeAndModelSections"
                :inference-type-model="inferenceType"
                @update-section-model="inferenceTypeChanged"
              />

              <model-catalog-section
                v-if="inferenceType.isFromModel"
                :is-selectable="allowTypeAndModelSections"
                entity-type="inference"
                aid="model-catalog-section"
                :loading="loadingModels"
                :selected-model-id="modelAssetId"
                :inference-models="modelAssets"
                @model-changed="onModelAssetChanged"
              />

              <workload-name-section
                entity-type="inference"
                aid="inference-name-section"
                v-model:name="deployment.name"
                :disable-closing="true"
                :project-id="deployment.projectId"
                :cluster-id="clusterStore.currentCluster.uuid"
              />
            </template>
          </transition-group>
        </runai-expansion-wrapper>
        <section class="row items-center q-mt-md">
          <q-field
            class="col-4 form-hint no-padding"
            :model-value="displayFormHint"
            :rules="[isFormIncomplete]"
          ></q-field>
          <div class="buttons q-ml-auto">
            <q-btn flat class="q-mr-sm" color="primary" label="Cancel" aid="cancel-btn" @click="onCancel" />
            <q-btn
              label="continue"
              aid="create-inference-continue-btn"
              @click="continueToEdit"
              :loading="submitting"
              color="primary"
            ></q-btn>
          </div>
        </section>
      </section>
    </runai-form-wrapper>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// cmps
import { RunaiFormWrapper } from "@/components/common/runai-form-wrapper";
import { RunaiExpansionWrapper } from "@/components/common/runai-expansion-wrapper";
import { WorkloadNameSection } from "@/components/section/workload-name-section";
import { ProjectSection } from "@/components/section/project-section";
import { InferenceType } from "@/components/section/inference-type";
import { ModelCatalogSection } from "@/components/section/model-catalog-section";

// models
import type { IProjectResources, IProject } from "@/models/project.model";
import type { IUIWorkloadCreation } from "@/models/workload.model";
import type { IInferenceType } from "@/components/section/inference-type";
import { SettingKeys } from "@/models/setting.model";
import type { ModelAsset } from "@/swagger-models/assets-service-client";

// Stores
import { useAppStore } from "@/stores/app.store";
import { useProjectStore } from "@/stores/project.store";
import { useClusterStore } from "@/stores/cluster.store";
import { useDeploymentStore } from "@/stores/deployment.store";
import { useModelSpecStore } from "@/stores/model-spec.store";
import { useSettingStore } from "@/stores/setting.store";

// services
import { requestToLeave } from "@/services/infra/router.service/router.service";

// utils
import { alertUtil } from "@/utils/alert.util";
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { DEPLOYMENT_ROUTE_NAMES } from "@/router/deployment.routes/deployment.routes.names";

const MIN_REPLICAS_DEFAULT_VALUE = 1;
const MAX_REPLICAS_DEFAULT_VALUE = 1;

export default defineComponent({
  components: {
    RunaiExpansionWrapper,
    RunaiFormWrapper,
    ProjectSection,
    WorkloadNameSection,
    InferenceType,
    ModelCatalogSection,
  },
  data() {
    return {
      appStore: useAppStore(),
      projectStore: useProjectStore(),
      clusterStore: useClusterStore(),
      deploymentStore: useDeploymentStore(),
      modelSpecStore: useModelSpecStore(),
      settingStore: useSettingStore(),
      deployment: workloadUtil.getEmptyUIWorkloadCreation({
        autoScaleData: {
          minReplicas: MIN_REPLICAS_DEFAULT_VALUE,
          maxReplicas: MAX_REPLICAS_DEFAULT_VALUE,
          thresholdMetric: undefined,
          thresholdValue: undefined,
        },
      }) as IUIWorkloadCreation,
      loadingProject: false as boolean,
      loadingModels: false as boolean,
      deploymentForm: null as HTMLFormElement | null,
      submitting: false as boolean,
      displayFormHint: false as boolean,
      timeOutId: null as ReturnType<typeof setTimeout> | null,
      modelAssetId: null as string | null,
      inferenceType: { isFromModel: false } as IInferenceType,
      showInferenceTypeSelection: false as boolean,
      allowTypeAndModelSections: true as boolean,
    };
  },
  async created() {
    this.modelAssetId = this.$route.query.modelId?.toString() || "";
    if (this.modelAssetId) this.allowTypeAndModelSections = false;
    if (this.modelSpecStore.selectedModelAsset) {
      this.modelAssetId = this.modelSpecStore.selectedModelAsset.meta.id;
    }

    this.showInferenceTypeSelection = this.settingStore.isFeatureEnabled(SettingKeys.EnableModelCatalog);
    await this.loadProjects();
    if (this.showInferenceTypeSelection && this.modelAssetId) {
      await this.loadModels();
      await this.modelSpecStore.loadModelAsset(this.modelAssetId);
      this.inferenceType.isFromModel = true;
    }

    this.deployment.name = this.deploymentStore.deploymentName;
    this.appStore.setPageLoading(false);
  },
  mounted() {
    this.deploymentForm = this.$refs["deploymentForm"] as HTMLFormElement;
  },
  computed: {
    projects(): Array<IProjectResources> {
      return this.projectStore.projectResourceList;
    },
    isCreatedFromModel(): boolean {
      return !!this.modelSpecStore.selectedModelAsset;
    },
    modelAssets(): Array<ModelAsset> {
      return this.modelSpecStore.modelAssets;
    },
  },
  methods: {
    async loadProjects(): Promise<void> {
      try {
        this.loadingProject = true;
        await Promise.all([this.projectStore.loadProjectsQuotas(), this.projectStore.loadProjects()]);
        if (this.projects.length === 1) {
          await this.onSelectedProject(this.projects[0].id);
        }
      } catch (error: unknown) {
        this.$q.notify(alertUtil.getError("Failed to load projects"));
        console.error(error);
        this.appStore.setFallback(true);
      } finally {
        this.loadingProject = false;
      }
    },
    async loadModels(): Promise<void> {
      try {
        this.loadingModels = true;
        await this.modelSpecStore.loadModels();
      } catch (error: unknown) {
        this.$q.notify(alertUtil.getError("Failed to load models"));
        console.error(error);
        this.appStore.setFallback(true);
      } finally {
        this.loadingModels = false;
      }
    },
    async onSelectedProject(projectId: number | null): Promise<void> {
      this.deployment.projectId = projectId || -1;
    },
    isFormIncomplete(val: boolean): boolean | string {
      return !val ? true : "Please review and fix the issues in the form";
    },
    validate(): Promise<boolean> {
      return (this.deploymentForm as HTMLFormElement).validate();
    },
    async continueToEdit(): Promise<void> {
      this.displayFormHint = false;
      this.submitting = true;
      const success = await this.validate();
      if (!success) {
        this.submitting = false;
        this.showHint();
        return;
      }

      this.deployment.specificEnv = {
        ...this.deployment.specificEnv,
        nodePools: this.getNodePoolsFromProject(this.deployment.projectId),
      };

      if (!this.inferenceType.isFromModel) {
        this.modelSpecStore.resetCreateInferenceData();
      }

      this.deploymentStore.setInference(this.deployment);
      if (this.isCreatedFromModel) {
        await this.$router.push({ name: DEPLOYMENT_ROUTE_NAMES.DEPLOYMENT_MODEL_EDIT });
      } else {
        await this.$router.push({ name: DEPLOYMENT_ROUTE_NAMES.DEPLOYMENT_ASSETS_EDIT });
      }
    },
    showHint(): void {
      this.displayFormHint = true;
      this.timeOutId && clearTimeout(this.timeOutId);
      this.timeOutId = setTimeout(() => (this.displayFormHint = false), 15000);
    },
    getNodePoolsFromProject(projectId: number): Array<string> | null {
      const project: IProject | undefined = this.findProject(projectId);
      if (!project) return null;

      return project.defaultNodePools || null;
    },
    findProject(projectId: number): IProject | undefined {
      return this.projectStore.projects.find((project: IProject) => project.id === projectId);
    },
    async onCancel(): Promise<void> {
      const allowToLeave: boolean = await requestToLeave();
      if (allowToLeave) {
        this.$router.back();
      }
    },
    inferenceTypeChanged(inferenceType: IInferenceType): void {
      this.inferenceType = inferenceType;
      if (this.inferenceType.isFromModel) {
        this.loadModels();
      }
    },
    onModelAssetChanged(modelAssetId: string): void {
      this.modelAssetId = modelAssetId;
      const modelAsset: ModelAsset | null =
        this.modelAssets.find((modelAsset: ModelAsset) => modelAsset.meta.id === modelAssetId) || null;
      this.modelSpecStore.setSelectedModelAsset(modelAsset);
    },
  },
  unmounted() {
    this.timeOutId && clearTimeout(this.timeOutId);
  },
});
</script>
