<template>
  <runai-expansion-item class="email-settings-container" label="Email notifications" :subheader="notifications">
    <div class="q-mb-md">Send me an email about my workloads when:</div>
    <div class="row items-center q-mb-sm q-mt-lg">
      <span class="q-mr-md text-weight-bold">The status changed to:</span>
      <runai-tooltip
        aid="tool-tip-system-notification"
        tooltip-text="An email will be sent when a workload's status changes to the selected status(es)."
        width="400px"
        tooltip-position="right"
      />
    </div>
    <div class="q-py-sm" v-for="notification in statusNotifications" :key="notification.key">
      <q-checkbox v-model="notification.enabled" dense :label="notification.title" />
    </div>
    <div class="row items-center q-mb-sm q-mt-lg text-weight-bold">
      <span class="q-mr-md">One of these is about to stop due to timeout:</span>
      <runai-tooltip
        aid="tool-tip-system-notification"
        tooltip-text="An email will be sent when half the time remains before a workload is set to time out, as defined in the project scheduling rules."
        width="400px"
        tooltip-position="right"
      />
    </div>
    <div class="q-py-sm" v-for="notification in timeoutNotifications" :key="notification.key">
      <q-checkbox v-model="notification.enabled" dense :label="notification.title" />
    </div>
    <div class="row footer">
      <div class="justify-start validation-message col-9 q-pt-sm"><span v-if="hasChanges">Unapplied changes</span></div>
      <div class="justify-end no-wrap q-gutter-sm col-3">
        <q-btn label="cancel" flat color="primary" @click="cancel" />
        <q-btn label="save" flat color="primary" @click="save" />
      </div>
    </div>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import type { NotificationSettings } from "@/models/user-setting.model";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { NotificationCategory } from "@/models/user-setting.model";
import { deepCopy } from "@/utils/common.util";
import { alertUtil } from "@/utils/alert.util";

export default defineComponent({
  name: "email-notifications-section",
  components: {
    RunaiTooltip,
    RunaiExpansionItem,
  },
  emits: ["save"],
  props: {
    notificationsSettings: {
      type: Array as PropType<NotificationSettings[]>,
      required: true,
    },
  },
  data() {
    return {
      notificationsSettingsState: [] as Array<NotificationSettings>,
    };
  },
  created() {
    this.resetSettings();
  },

  computed: {
    notifications(): string {
      const enabledNotifications: number | string =
        this.notificationsSettingsState.filter((setting) => setting.enabled)?.length || "None";
      const allNotifications: number = this.notificationsSettingsState.length;
      return `${enabledNotifications} / ${allNotifications}  notification types`;
    },
    statusNotifications(): Array<NotificationSettings> {
      return this.notificationsSettingsState.filter((setting) => setting.category === NotificationCategory.STATUS);
    },
    timeoutNotifications(): Array<NotificationSettings> {
      return this.notificationsSettingsState.filter((setting) => setting.category === NotificationCategory.TIMEOUT);
    },
    hasChanges(): boolean {
      return this.notificationsSettingsState.some(
        (setting, index) => setting.enabled !== this.notificationsSettings[index].enabled,
      );
    },
  },
  methods: {
    cancel() {
      this.resetSettings();
    },
    save(): void {
      if (this.hasChanges) {
        this.$emit("save", this.notificationsSettingsState);
        this.$q.notify(alertUtil.getSuccess(`Settings saved`));
      }
    },
    resetSettings(): void {
      this.notificationsSettingsState = deepCopy(this.notificationsSettings);
    },
  },
});
</script>
<style lang="scss" scoped>
.email-settings-container {
  margin: auto;
  width: 800px;

  .footer {
    border-top: #dddddd 1px solid;
    margin: 10px -20px 0 -20px;
    padding-top: 10px;

    .validation-message {
      color: $negative;
      font-size: 12px;
      padding-left: 20px;
    }
  }
}
</style>
