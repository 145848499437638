import type { INodeAffinityOption, INodePoolResources, INodePoolsResourcesRow, IProject } from "@/models/project.model";
import { EResourceState, EResourceType } from "@/models/resource.model";
import { ECustomCell, type IStatusColOptions, type ITableColumn } from "@/models/table.model";
import { dateUtil } from "@/utils/date.util";
import { memoryFormat, toPercent } from "@/utils/format.util";
import { projectUtil } from "@/utils/project.util";
import { resourceUtil } from "@/utils/resource.util";
import { tableUtil } from "@/utils/table.util";

export const allProjectColumnsMap: Record<string, ITableColumn> = {
  projectName: {
    name: "project-name",
    label: "Project",
    field: (row: IProject) => row.name,
    sortable: true,
    align: "left",
  },
  departmentName: {
    name: "department-name",
    label: "Department",
    field: (row: IProject) => row.departmentName,
    sortable: true,
    align: "left",
  },
  status: {
    name: "status",
    label: "Status",
    field: (row: IProject) => row,
    sortable: true,
    align: "left",
    format: (project: IProject): IStatusColOptions => {
      return projectUtil.getStatusColOptions(project.phase, project.status);
    },
    filterKey: "status", // this is a special case where the format returns an object and needs this logic for filtering
    sort: (projectA: IProject, projectB: IProject): number => {
      return projectA.phase.localeCompare(projectB.phase);
    },
    customCell: ECustomCell.STATUS_COL,
    exportFormat: (project: IProject) => project?.status?.phase || "NA",
  },
  nodePools: {
    name: "node-pools",
    label: "Node pool(s) with quota",
    field: (row: IProject) => row.nodePoolsResources,
    sortable: true,
    align: "left",
    format: (nodePoolResources: INodePoolResources[]): string[] =>
      nodePoolResources.map((nodePoolResources) => nodePoolResources.nodePool.name),
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "node-pools-clicked" },
  },
  accessRules: {
    name: "accessRules",
    label: "Subject(s)",
    field: (row: IProject) => row?.roles,
    sortable: false,
    align: "left",
    customCell: ECustomCell.ROLE_ASSIGMENT_COL,
    customCellEvent: { emitName: "access-rules-clicked" },
  },
  allocatedCpu: {
    name: "allocated-cpu",
    label: "Allocated CPUs (Cores)",
    field: (row: IProject) => row.allocatedCpu,
    sortable: true,
    align: "left",
    format: (allocatedCpu: number | undefined) =>
      allocatedCpu ? resourceUtil.getResourceDisplayValue(allocatedCpu, EResourceType.CPU) : "-",
  },
  allocatedMemory: {
    name: "allocated-memory",
    label: "Allocated CPU memory",
    field: (row: IProject) => row.allocatedMemory,
    sortable: true,
    align: "left",
    format: (allocatedMemory: number | undefined) => (allocatedMemory ? memoryFormat(allocatedMemory) : "-"),
    sort: (fieldA, fieldB, rowA, rowB) => tableUtil.customColumnSort(allProjectColumnsMap.allocatedMemory, rowA, rowB),
  },
  allocatedGpus: {
    name: "allocated-gpus",
    label: "Allocated GPUs",
    field: (row: IProject) => row.allocatedGpus,
    sortable: true,
    align: "left",
    format: (allocatedGpus: number | undefined) =>
      allocatedGpus ? resourceUtil.getResourceDisplayValue(allocatedGpus, EResourceType.GPU) : "-",
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allProjectColumnsMap.allocatedGpus, rowA, rowB, (val) =>
        val !== "-" ? parseFloat(val) : -1,
      ),
  },
  gpuUtilization: {
    name: "gpu-utilization",
    label: "GPU allocation ratio",
    field: (row: IProject) => row.allocatedGpus,
    sortable: true,
    align: "left",
    format: (allocatedGpus: number | undefined, project: IProject) =>
      toPercent(allocatedGpus || 0, resourceUtil.sumOfResourcesByType(project.nodePoolsResources, EResourceType.GPU)),
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allProjectColumnsMap.gpuUtilization, rowA, rowB, (val) =>
        val !== "-" ? parseFloat(val) : 0,
      ),
  },
  cpuUtilization: {
    name: "cpu-utilization",
    label: "CPU allocation ratio",
    field: (row: IProject) => row.allocatedCpu,
    sortable: true,
    align: "left",
    format: (allocatedCpu: number | undefined, project: IProject) =>
      toPercent(allocatedCpu || 0, resourceUtil.sumOfResourcesByType(project.nodePoolsResources, EResourceType.CPU)),
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allProjectColumnsMap.cpuUtilization, rowA, rowB, (val) =>
        val !== "-" ? parseFloat(val) : -1,
      ),
  },
  memoryUtilization: {
    name: "memory-utilization",
    label: "CPU memory allocation ratio",
    field: (row: IProject) => row.allocatedMemory,
    sortable: true,
    align: "left",
    format: (allocatedMemory: number | undefined, project: IProject) =>
      toPercent(
        resourceUtil.fromBytesToMiB(allocatedMemory || 0),
        resourceUtil.sumOfResourcesByType(project.nodePoolsResources, EResourceType.MEMORY),
      ),
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allProjectColumnsMap.memoryUtilization, rowA, rowB, (val) =>
        val !== "-" ? parseFloat(val) : -1,
      ),
  },
  gpuQuota: {
    name: "gpu-quota",
    label: "GPU quota",
    field: (row: IProject) => row.nodePoolsResources,
    sortable: true,
    align: "left",
    format: (nodePoolResources: INodePoolResources[]) =>
      resourceUtil.getResourcesDisplayValue(nodePoolResources, EResourceType.GPU),
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allProjectColumnsMap.gpuQuota, rowA, rowB, parseFloat),
  },
  cpuQuota: {
    name: "cpu-quota",
    label: "CPU quota (Cores)",
    field: (row: IProject) => row.nodePoolsResources,
    sortable: true,
    align: "left",
    format: (nodePoolResources: INodePoolResources[]) =>
      resourceUtil.getResourcesDisplayValue(nodePoolResources, EResourceType.CPU),
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allProjectColumnsMap.cpuQuota, rowA, rowB, (val) =>
        val !== EResourceState.Unlimited ? parseFloat(val) : -1,
      ),
  },
  cpuMemoryQuota: {
    name: "cpu-memory-quota",
    label: "CPU memory quota",
    field: (row: IProject) => row.nodePoolsResources,
    sortable: true,
    align: "left",
    format: (nodePoolResources: INodePoolResources[]) =>
      resourceUtil.getResourcesDisplayValue(nodePoolResources, EResourceType.MEMORY),
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allProjectColumnsMap.cpuMemoryQuota, rowA, rowB, (val) =>
        val !== EResourceState.Unlimited ? parseFloat(val) : -1,
      ),
  },
  nodeAffinityTrain: {
    name: "node-affinity-train",
    label: "Node affinity of training workloads",
    field: (row: IProject) => row.nodeAffinity.train,
    sortable: false,
    align: "left",
    format: (nodeAffinityOption: INodeAffinityOption) => nodeAffinityOption.selectedTypes.map((type) => type.name),
    customCell: ECustomCell.STRINGS_LIST_COL,
  },
  nodeAffinityInteractive: {
    name: "node-affinity-interactive",
    label: "Node affinity of interactive workloads",
    field: (row: IProject) => row.nodeAffinity.interactive,
    sortable: false,
    align: "left",
    format: (nodeAffinityOption: INodeAffinityOption) => nodeAffinityOption.selectedTypes.map((type) => type.name),
    customCell: ECustomCell.STRINGS_LIST_COL,
  },
  trainingJobMaxIdleDurationSecs: {
    name: "training-job-max-idle-duration-secs",
    label: "Idle time limit of training workloads",
    field: (row: IProject) => row.trainingJobMaxIdleDurationSecs,
    sortable: true,
    align: "left",
    format: (trainingJobMaxIdleDurationSecs) =>
      trainingJobMaxIdleDurationSecs ? dateUtil.formatDuration(trainingJobMaxIdleDurationSecs) : "-",
  },
  interactivePreemptibleJobMaxIdleDurationSecs: {
    name: "interactive-preemptible-job-max-idle-duration-secs",
    label: "Idle time limit of preemptible workloads",
    field: (row: IProject) => row.interactivePreemptibleJobMaxIdleDurationSecs,
    sortable: true,
    align: "left",
    format: (interactivePreemptibleJobMaxIdleDurationSecs) =>
      interactivePreemptibleJobMaxIdleDurationSecs
        ? dateUtil.formatDuration(interactivePreemptibleJobMaxIdleDurationSecs)
        : "-",
  },
  interactiveJobMaxIdleDurationSecs: {
    name: "interactive-job-max-idle-duration-secs",
    label: "Idle time limit of non preemptible workloads",
    field: (row: IProject) => row.interactiveJobMaxIdleDurationSecs,
    sortable: true,
    align: "left",
    format: (interactiveJobMaxIdleDurationSecs) =>
      interactiveJobMaxIdleDurationSecs ? dateUtil.formatDuration(interactiveJobMaxIdleDurationSecs) : "-",
  },
  interactiveJobTimeLimitSecs: {
    name: "interactive-job-time-limit-secs",
    label: "Interactive workloads time limit",
    field: (row: IProject) => row.interactiveJobTimeLimitSecs,
    sortable: true,
    align: "left",
    format: (interactiveJobTimeLimitSecs) =>
      interactiveJobTimeLimitSecs ? dateUtil.formatDuration(interactiveJobTimeLimitSecs) : "-",
  },
  trainingJobTimeLimitSecs: {
    name: "training-job-time-limit-secs",
    label: "Training workloads time limit",
    field: (row: IProject) => row.trainingJobTimeLimitSecs,
    sortable: true,
    align: "left",
    format: (trainingJobTimeLimitSecs) =>
      trainingJobTimeLimitSecs ? dateUtil.formatDuration(trainingJobTimeLimitSecs) : "-",
  },
  createdAt: {
    name: "created-at",
    label: "Creation time",
    field: (row: IProject) => row.createdAt,
    sortable: true,
    align: "left",
    format: (createdAt: string) => (createdAt ? dateUtil.dateAndTimeFormat(new Date(createdAt)) : "-"),
  },
  workloads: {
    name: "workloads",
    label: "Workload(s)",
    field: () => "View",
    align: "left",
    sortable: false,
    hideFilter: true,
    customCell: ECustomCell.LINK_COL,
    customCellEvent: { emitName: "workloads-clicked" },
  },
};

export const allProjectColumns: Array<ITableColumn> = [
  allProjectColumnsMap.projectName,
  allProjectColumnsMap.departmentName,
  allProjectColumnsMap.status,
  allProjectColumnsMap.nodePools,
  allProjectColumnsMap.accessRules,
  allProjectColumnsMap.gpuQuota,
  allProjectColumnsMap.cpuQuota,
  allProjectColumnsMap.cpuMemoryQuota,
  allProjectColumnsMap.createdAt,
  allProjectColumnsMap.nodeAffinityTrain,
  allProjectColumnsMap.nodeAffinityInteractive,
  allProjectColumnsMap.interactiveJobMaxIdleDurationSecs,
  allProjectColumnsMap.trainingJobMaxIdleDurationSecs,
  allProjectColumnsMap.interactivePreemptibleJobMaxIdleDurationSecs,
  allProjectColumnsMap.interactiveJobTimeLimitSecs,
  allProjectColumnsMap.trainingJobTimeLimitSecs,
];

export const projectIndexColumns: Array<ITableColumn> = [
  { ...allProjectColumnsMap.projectName, display: true },
  { ...allProjectColumnsMap.departmentName, display: true },
  { ...allProjectColumnsMap.status, display: true },
  { ...allProjectColumnsMap.nodePools, display: true },
  { ...allProjectColumnsMap.accessRules, display: true },
  { ...allProjectColumnsMap.allocatedGpus, display: true },
  { ...allProjectColumnsMap.allocatedCpu, display: true },
  { ...allProjectColumnsMap.allocatedMemory, display: true },
  { ...allProjectColumnsMap.gpuUtilization, display: true },
  { ...allProjectColumnsMap.cpuUtilization, display: true },
  { ...allProjectColumnsMap.memoryUtilization, display: true },
  { ...allProjectColumnsMap.gpuQuota, display: true },
  { ...allProjectColumnsMap.cpuQuota, display: true },
  { ...allProjectColumnsMap.cpuMemoryQuota, display: true },
  { ...allProjectColumnsMap.nodeAffinityTrain, display: false },
  { ...allProjectColumnsMap.nodeAffinityInteractive, display: false },
  { ...allProjectColumnsMap.trainingJobMaxIdleDurationSecs, display: false },
  { ...allProjectColumnsMap.interactivePreemptibleJobMaxIdleDurationSecs, display: false },
  { ...allProjectColumnsMap.interactiveJobMaxIdleDurationSecs, display: false },
  { ...allProjectColumnsMap.interactiveJobTimeLimitSecs, display: false },
  { ...allProjectColumnsMap.trainingJobTimeLimitSecs, display: false },
  { ...allProjectColumnsMap.createdAt, display: false },
  { ...allProjectColumnsMap.workloads, display: false },
];

export const projectMiniTableColumns = [
  { ...allProjectColumnsMap.projectName, display: true },
  { ...allProjectColumnsMap.gpuQuota, display: true },
  { ...allProjectColumnsMap.cpuQuota, display: true },
  { ...allProjectColumnsMap.cpuMemoryQuota, display: true },
  { ...allProjectColumnsMap.allocatedGpus, display: true },
  { ...allProjectColumnsMap.allocatedCpu, display: true },
  { ...allProjectColumnsMap.allocatedMemory, display: true },
  { ...allProjectColumnsMap.gpuUtilization, display: true },
  { ...allProjectColumnsMap.cpuUtilization, display: true },
];

//node pools
export const allNodePoolsColumnsMap: Record<string, ITableColumn> = {
  nodePoolName: {
    name: "node-pool-name",
    label: "Node pool",
    field: (row: INodePoolsResourcesRow) => row.nodePool.name,
    sortable: true,
    align: "left",
  },
  gpuQuota: {
    name: "gpu",
    label: "GPU quota",
    field: (row: INodePoolsResourcesRow) => row.gpu.deserved,
    sortable: true,
    align: "left",
    format: (gpu: number) => resourceUtil.getResourceDisplayValue(gpu, EResourceType.GPU),
  },
  cpuQuota: {
    name: "cpu-quota",
    label: "CPU (Cores)",
    field: (row: INodePoolsResourcesRow) => row.cpu.deserved,
    sortable: true,
    align: "left",
    format: (cpu: number) => resourceUtil.getResourceDisplayValue(cpu, EResourceType.CPU),
  },
  cpuMemoryQuota: {
    name: "cpuMemory",
    label: "CPU memory",
    field: (row: INodePoolsResourcesRow) => row.memory.deserved,
    sortable: true,
    align: "left",
    format: (memory: number) => resourceUtil.getResourceDisplayValue(memory, EResourceType.MEMORY),
  },
  allocatedGpus: {
    name: "allocatedGpus",
    label: "Allocated GPUs",
    field: (row: INodePoolsResourcesRow) => row.allocatedGpus,
    sortable: true,
    align: "left",
    format: (allocatedGpus: string | undefined) =>
      allocatedGpus ? resourceUtil.getResourceDisplayValue(parseFloat(allocatedGpus), EResourceType.GPU) : "-",
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allNodePoolsColumnsMap.gpuQuota, rowA, rowB, parseFloat),
  },
  allocatedCpu: {
    name: "allocatedCpu",
    label: "Allocated CPU (Cores)",
    field: (row: INodePoolsResourcesRow) => row.allocatedCpu,
    sortable: true,
    align: "left",
    format: (allocatedCpu: string | undefined) =>
      allocatedCpu ? resourceUtil.getResourceDisplayValue(parseFloat(allocatedCpu), EResourceType.CPU) : "-",
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allNodePoolsColumnsMap.allocatedCpu, rowA, rowB, parseFloat),
  },
  allocatedMemory: {
    name: "allocatedMemory",
    label: "Allocated CPU memory",
    field: (row: INodePoolsResourcesRow) => row.allocatedMemory,
    sortable: true,
    align: "left",
    format: (allocatedMemory: string | undefined) => (allocatedMemory ? memoryFormat(allocatedMemory) : "-"),
  },
  priority: {
    name: "priority",
    label: "Order of priority",
    field: (row: INodePoolsResourcesRow) => row.priority,
    sortable: true,
    align: "left",
  },
};

export const nodePoolsMiniTableColumns: Array<ITableColumn> = [
  { ...allNodePoolsColumnsMap.nodePoolName, display: true },
  { ...allNodePoolsColumnsMap.gpuQuota, display: true },
  { ...allNodePoolsColumnsMap.cpuQuota, display: true },
  { ...allNodePoolsColumnsMap.cpuMemoryQuota, display: true },
  { ...allNodePoolsColumnsMap.allocatedGpus, display: true },
  { ...allNodePoolsColumnsMap.allocatedCpu, display: true },
  { ...allNodePoolsColumnsMap.allocatedMemory, display: true },
  { ...allNodePoolsColumnsMap.priority, display: true },
];

//columns that displayed depends on feature flags or versions
export const projectDependentColumns = {
  cpu: new Set([
    allProjectColumnsMap.cpuMemoryQuota.name,
    allProjectColumnsMap.cpuQuota.name,
    allProjectColumnsMap.memoryUtilization.name,
    allProjectColumnsMap.cpuUtilization.name,
    allProjectColumnsMap.allocatedCpu.name,
    allProjectColumnsMap.allocatedMemory.name,
  ]),
  department: new Set([allProjectColumnsMap.departmentName.name]),
  status: new Set([allProjectColumnsMap.status.name]),
  nodePools: new Set([allProjectColumnsMap.nodePools.name]),
  trainingJobTimeLimitSecs: new Set([allProjectColumnsMap.trainingJobTimeLimitSecs.name]),
  accessRules: new Set([allProjectColumnsMap.accessRules.name]),
  workloads: new Set([allProjectColumnsMap.workloads.name]),
};

export const nodePoolsDependentColumns = {
  cpu: [
    allNodePoolsColumnsMap.cpuQuota.name,
    allNodePoolsColumnsMap.cpuMemoryQuota.name,
    allNodePoolsColumnsMap.allocatedCpu.name,
    allNodePoolsColumnsMap.allocatedMemory.name,
  ],
  priority: [allNodePoolsColumnsMap.priority.name],
  allocatedResources: [
    allNodePoolsColumnsMap.allocatedGpus.name,
    allNodePoolsColumnsMap.allocatedCpu.name,
    allNodePoolsColumnsMap.allocatedMemory.name,
  ],
};
