<template>
  <q-btn
    v-if="displayV2ModeOnBtn"
    @click="setOverviewV2ModeOn"
    class="overview-v2-mode-on-btn"
    aid="overview-mode-on-btn"
    no-caps
    >Try the new overview
    <q-tooltip class="tooltip-dark">Try the new overview experience</q-tooltip>
  </q-btn>
  <q-btn v-else @click="setOverviewV2ModeOff" aid="overview-v2-mode-on-off" class="overview-v2-mode-off-btn" no-caps>
    <q-tooltip class="tooltip-dark">Back to legacy view</q-tooltip>Go back to legacy view</q-btn
  >
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { alertUtil } from "@/utils/alert.util";
import { usePermissionStore } from "@/stores/permissions.store";
import { useAppStore } from "@/stores/app.store";

export default defineComponent({
  name: "overview-feature-toggle-buttons",
  data() {
    return {
      appStore: useAppStore(),
      permissionStore: usePermissionStore(),
    };
  },
  computed: {
    displayV2ModeOnBtn(): boolean {
      return !this.isOverviewV2ModeOn && this.hasOverviewV2ModePermission;
    },
    isOverviewV2ModeOn(): boolean {
      return this.appStore.isOverviewV2ModeOn;
    },
    hasOverviewV2ModePermission(): boolean {
      return this.permissionStore.hasOverviewV2ModePermission();
    },
  },
  methods: {
    setOverviewV2ModeOn(): void {
      this.appStore.setOverviewV2ModeOn();
      this.$q.notify(alertUtil.getSuccess("Switched to the new overview experience"));
    },
    setOverviewV2ModeOff(): void {
      this.appStore.setOverviewV2ModeOff();
      this.$q.notify(alertUtil.getSuccess("Switched to the legacy view"));
    },
  },
});
</script>

<style scoped lang="scss">
.overview-v2-mode-on-btn {
  background-color: $runai-pink;
  font-weight: 400;
}
.overview-v2-mode-off-btn {
  background-color: $grey-input-color;
  color: $grey-9;
  font-weight: 400;
}
</style>
