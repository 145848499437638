<template>
  <section class="runai-fallback-page text-center">
    <div class="text-subtitle1 q-pt-xl">
      <div>For some reason this page can't be displayed right now.</div>
      <div class="q-mb-lg">
        Try <a class="refresh-anchor" @click="refresh">refreshing the page</a> or contact your admin.
      </div>
      <section class="go-back-section">
        <div class="q-mb-sm">You can also</div>
        <q-btn color="primary" @click="back">{{ btnText }}</q-btn>
      </section>
    </div>
  </section>
</template>

<script lang="ts">
import { useAppStore } from "@/stores/app.store";
import { defineComponent } from "vue";

import { ENVIRONMENT_ROUTE_NAMES } from "@/router/environment.routes";
import { DATA_SOURCE_ROUTE_NAMES } from "@/router/data-source.routes";
import { DEPARTMENT_ROUTE_NAMES } from "@/router/department.routes";
import { WORKLOAD_TEMPLATE_ROUTE_NAMES } from "@/router/workload-template.routes/workload-template.routes.names";
import { COMPUTE_RESOURCE_ROUTE_NAMES } from "@/router/compute-resource.routes";

import { CREDENTIAL_ROUTE_NAMES } from "@/router/credential.routes";
import { NODE_ROUTE_NAMES } from "@/router/node.routes";
import { ROLE_ROUTE_NAMES } from "@/router/role.routes";
import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";
import { USER_ROUTES_NAMES } from "@/router/users.routes";
import { APPLICATIONS_ROUTE_NAMES } from "@/router/applications.routes";

const buttonRouteTexts: Record<string, string> = {
  [ENVIRONMENT_ROUTE_NAMES.ENVIRONMENT_INDEX]: "ENVIRONMENTS",
  [DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_INDEX]: "DATA SOURCES",
  [DEPARTMENT_ROUTE_NAMES.DEPARTMENT_INDEX]: "DEPARTMENTS",
  [PROJECT_ROUTE_NAMES.PROJECT_INDEX]: "PROJECTS",
  [WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_INDEX]: "TEMPLATES",
  [COMPUTE_RESOURCE_ROUTE_NAMES.COMPUTE_RESOURCE_INDEX]: "COMPUTE RESOURCES",
  [CREDENTIAL_ROUTE_NAMES.CREDENTIAL_INDEX]: "CREDENTIALS",
  [NODE_ROUTE_NAMES.NODE_INDEX]: "NODES",
  [NODE_ROUTE_NAMES.NODE_POOL_INDEX]: "NODE POOLS",
  [ROLE_ROUTE_NAMES.ROLE_INDEX]: "ROLES",
  [USER_ROUTES_NAMES.USER_INDEX]: "USERS",
  [APPLICATIONS_ROUTE_NAMES.APPLICATIONS_INDEX]: "APPLICATIONS",
};

export default defineComponent({
  data() {
    return {
      appStore: useAppStore(),
    };
  },
  computed: {
    backPageName(): string {
      return this.$route.meta.backPageName as string;
    },
    btnText(): string {
      const backPage: string = this.backPageName;
      if (!backPage || !buttonRouteTexts[this.backPageName]) return "GO BACK TO A SAFE PLACE";
      return `GO TO ${buttonRouteTexts[this.backPageName]} GRID`;
    },
  },
  methods: {
    refresh(): void {
      window.location.reload();
    },
    async back(): Promise<void> {
      await this.$router.push({ name: this.backPageName });
    },
  },
});
</script>
<style lang="scss" scoped>
.runai-fallback-page {
  .refresh-anchor:hover {
    cursor: pointer;
  }
}
</style>
