<template>
  <div class="setting-editable-field row justify-between no-wrap items-center q-pa-sm q-mt-md">
    <span class="ellipsis col-6">{{ label }}</span>
    <div class="buttons row no-wrap q-gutter-sm" v-if="!hideButtons">
      <slot name="start"></slot>
      <q-btn icon="fa-solid fa-pen" class="q-pa-sm icon" flat size="sm" rounded @click="editClicked">
        <q-tooltip>{{ editToolTip }}</q-tooltip>
      </q-btn>
      <q-btn
        icon="fa-solid fa-xmark"
        class="q-pa-sm icon"
        flat
        size="sm"
        rounded
        @click="isDeleteModalOpen = true"
        :disable="deleteDisabled"
      >
        <q-tooltip>{{ deleteToolTip }}</q-tooltip>
      </q-btn>
      <slot name="end"></slot>
    </div>
  </div>
  <setting-delete-modal
    v-if="isDeleteModalOpen"
    :delete-confirm-message="deleteToolTip"
    :delete-confirm-message-subtitle="deleteSubTitle"
    @cancel="isDeleteModalOpen = false"
    @delete="deleteSetting"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import SettingDeleteModal from "@/components/settings/setting-delete-modal/setting-delete-modal.vue";

export default defineComponent({
  name: "setting-editable-field",
  components: { SettingDeleteModal },
  emits: ["edit", "delete"],
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    editToolTip: {
      type: String as PropType<string>,
    },
    deleteDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    deleteToolTip: {
      type: String as PropType<string>,
    },
    deleteSubTitle: {
      type: String as PropType<string>,
      default: "",
    },
    hideButtons: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      isDeleteModalOpen: false as boolean,
      isBoxOpen: false as boolean,
    };
  },
  methods: {
    editClicked(): void {
      this.$emit("edit");
    },
    deleteSetting(): void {
      this.isDeleteModalOpen = false;
      this.$emit("delete");
    },
  },
});
</script>
<style lang="scss" scoped>
.setting-editable-field {
  border: 1px solid $black-12;
}
.icon {
  color: $black-54;
}
</style>
