/* tslint:disable */
/* eslint-disable */
/**
 * Trials
 * An API for creating trials.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface Trial
 */
export interface Trial {
    /**
     * 
     * @type {string}
     * @memberof Trial
     */
    'tenantDomain'?: string;
    /**
     * 
     * @type {string}
     * @memberof Trial
     */
    'tenantName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Trial
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Trial
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface TrialCreationRequest
 */
export interface TrialCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof TrialCreationRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof TrialCreationRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof TrialCreationRequest
     */
    'company': string;
    /**
     * 
     * @type {string}
     * @memberof TrialCreationRequest
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof TrialCreationRequest
     */
    'isSignedEula': boolean;
}
/**
 * 
 * @export
 * @interface TrialCreationResponse
 */
export interface TrialCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof TrialCreationResponse
     */
    'trialId': string;
}
/**
 * 
 * @export
 * @interface TrialState
 */
export interface TrialState {
    /**
     * 
     * @type {string}
     * @memberof TrialState
     */
    'trialId': string | null;
    /**
     * 
     * @type {string}
     * @memberof TrialState
     */
    'tenantName': string | null;
    /**
     * 
     * @type {string}
     * @memberof TrialState
     */
    'tenantDomain': string | null;
    /**
     * 
     * @type {string}
     * @memberof TrialState
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof TrialState
     */
    'company': string | null;
    /**
     * 
     * @type {string}
     * @memberof TrialState
     */
    'tenantId': string | null;
    /**
     * 
     * @type {string}
     * @memberof TrialState
     */
    'clusterId': string | null;
    /**
     * 
     * @type {string}
     * @memberof TrialState
     */
    'clusterStatus': string | null;
    /**
     * 
     * @type {string}
     * @memberof TrialState
     */
    'firstLogin': string | null;
}
/**
 * 
 * @export
 * @interface VerifyTrial200Response
 */
export interface VerifyTrial200Response {
    /**
     * 
     * @type {boolean}
     * @memberof VerifyTrial200Response
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface VerifyTrialRequest
 */
export interface VerifyTrialRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyTrialRequest
     */
    'password': string;
}

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrialsApi - axios parameter creator
 * @export
 */
export const TrialsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new trial
         * @param {TrialCreationRequest} trialCreationRequest The trial to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTrial: async (trialCreationRequest: TrialCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trialCreationRequest' is not null or undefined
            assertParamExists('createTrial', 'trialCreationRequest', trialCreationRequest)
            const localVarPath = `/api/v1/trials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trialCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get trials state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialState: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/trials/state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend a trial email
         * @param {string} trialId The id of the trial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendEmail: async (trialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trialId' is not null or undefined
            assertParamExists('resendEmail', 'trialId', trialId)
            const localVarPath = `/api/v1/trials/{trialId}/resend`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrialsApi - functional programming interface
 * @export
 */
export const TrialsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrialsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new trial
         * @param {TrialCreationRequest} trialCreationRequest The trial to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTrial(trialCreationRequest: TrialCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTrial(trialCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get trials state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrialState(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrialState>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrialState(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend a trial email
         * @param {string} trialId The id of the trial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendEmail(trialId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendEmail(trialId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrialsApi - factory interface
 * @export
 */
export const TrialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrialsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new trial
         * @param {TrialCreationRequest} trialCreationRequest The trial to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTrial(trialCreationRequest: TrialCreationRequest, options?: any): AxiosPromise<TrialCreationResponse> {
            return localVarFp.createTrial(trialCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get trials state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialState(options?: any): AxiosPromise<Array<TrialState>> {
            return localVarFp.getTrialState(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend a trial email
         * @param {string} trialId The id of the trial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendEmail(trialId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resendEmail(trialId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrialsApi - object-oriented interface
 * @export
 * @class TrialsApi
 * @extends {BaseAPI}
 */
export class TrialsApi extends BaseAPI {
    /**
     * 
     * @summary Create new trial
     * @param {TrialCreationRequest} trialCreationRequest The trial to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialsApi
     */
    public createTrial(trialCreationRequest: TrialCreationRequest, options?: AxiosRequestConfig) {
        return TrialsApiFp(this.configuration).createTrial(trialCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get trials state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialsApi
     */
    public getTrialState(options?: AxiosRequestConfig) {
        return TrialsApiFp(this.configuration).getTrialState(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend a trial email
     * @param {string} trialId The id of the trial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialsApi
     */
    public resendEmail(trialId: string, options?: AxiosRequestConfig) {
        return TrialsApiFp(this.configuration).resendEmail(trialId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ValidatedTrialsApi - axios parameter creator
 * @export
 */
export const ValidatedTrialsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get trial by id
         * @param {string} trialId The id of the trial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrial: async (trialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trialId' is not null or undefined
            assertParamExists('getTrial', 'trialId', trialId)
            const localVarPath = `/api/v1/trials/{trialId}`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify trial
         * @param {string} trialId The id of the trial
         * @param {VerifyTrialRequest} verifyTrialRequest Tenant data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTrial: async (trialId: string, verifyTrialRequest: VerifyTrialRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trialId' is not null or undefined
            assertParamExists('verifyTrial', 'trialId', trialId)
            // verify required parameter 'verifyTrialRequest' is not null or undefined
            assertParamExists('verifyTrial', 'verifyTrialRequest', verifyTrialRequest)
            const localVarPath = `/api/v1/trials/{trialId}/verify`
                .replace(`{${"trialId"}}`, encodeURIComponent(String(trialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyTrialRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ValidatedTrialsApi - functional programming interface
 * @export
 */
export const ValidatedTrialsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ValidatedTrialsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get trial by id
         * @param {string} trialId The id of the trial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrial(trialId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trial>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrial(trialId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify trial
         * @param {string} trialId The id of the trial
         * @param {VerifyTrialRequest} verifyTrialRequest Tenant data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyTrial(trialId: string, verifyTrialRequest: VerifyTrialRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyTrial200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyTrial(trialId, verifyTrialRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ValidatedTrialsApi - factory interface
 * @export
 */
export const ValidatedTrialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ValidatedTrialsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get trial by id
         * @param {string} trialId The id of the trial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrial(trialId: string, options?: any): AxiosPromise<Trial> {
            return localVarFp.getTrial(trialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify trial
         * @param {string} trialId The id of the trial
         * @param {VerifyTrialRequest} verifyTrialRequest Tenant data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTrial(trialId: string, verifyTrialRequest: VerifyTrialRequest, options?: any): AxiosPromise<VerifyTrial200Response> {
            return localVarFp.verifyTrial(trialId, verifyTrialRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ValidatedTrialsApi - object-oriented interface
 * @export
 * @class ValidatedTrialsApi
 * @extends {BaseAPI}
 */
export class ValidatedTrialsApi extends BaseAPI {
    /**
     * 
     * @summary Get trial by id
     * @param {string} trialId The id of the trial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValidatedTrialsApi
     */
    public getTrial(trialId: string, options?: AxiosRequestConfig) {
        return ValidatedTrialsApiFp(this.configuration).getTrial(trialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify trial
     * @param {string} trialId The id of the trial
     * @param {VerifyTrialRequest} verifyTrialRequest Tenant data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValidatedTrialsApi
     */
    public verifyTrial(trialId: string, verifyTrialRequest: VerifyTrialRequest, options?: AxiosRequestConfig) {
        return ValidatedTrialsApiFp(this.configuration).verifyTrial(trialId, verifyTrialRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


