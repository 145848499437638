<template>
  <runai-base-dialog
    class="model-license-modal"
    :model-value="true"
    @close="$emit('close')"
    size="width-sm"
    :no-padding="true"
  >
    <template #header>Terms of Use</template>

    <template #body>
      <section>
        <div class="explanation-container" v-dompurify-html="license"></div>
        <div class="approval-container">
          <q-form ref="formEl">
            <q-checkbox v-model="isChecked" @update:model-value="onAgreedChanged" label="I agree to the terms of use" />
            <q-field class="form-hint" :model-value="displayApprovalHint" :rules="[isUserApproved]"></q-field>
          </q-form>
        </div>
      </section>
    </template>

    <template #footer>
      <q-btn label="cancel" color="primary" flat @click="$emit('close')" />
      <q-btn label="next" color="primary" @click="onNext" />
    </template>
  </runai-base-dialog>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// cmps
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";

export default defineComponent({
  components: {
    RunaiBaseDialog,
  },
  emits: ["close", "approved"],
  props: {
    license: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      isChecked: false as boolean,
      displayApprovalHint: false as boolean,
    };
  },
  methods: {
    isUserApproved(): boolean | string {
      return this.isChecked || "Agree to the terms of use";
    },
    onAgreedChanged(): void {
      if (this.isChecked) {
        this.displayApprovalHint = false;
      } else {
        this.displayApprovalHint = true;
      }
    },
    async onNext(): Promise<void> {
      const isValid = await (this.$refs.formEl as HTMLFormElement).validate();
      if (!isValid) {
        this.displayApprovalHint = true;
        return;
      }
      this.$emit("approved");
    },
  },
});
</script>

<style lang="scss" scoped>
.model-license-modal {
  .explanation-container {
    padding: 18px 16px 0 16px;
  }

  .approval-container {
    margin-top: 10px;
    padding: 0 8px;
    .form-hint {
      margin-left: 40px;
    }
  }
}
</style>
