import type { ITableColumn } from "@/models/table.model";
import type { ISelectOption } from "@/models/global.model";
import type { IOrgTreeNodeId } from "@/models/org-tree.model";
import { ScopeType, SubjectType, type AccessRule } from "@/swagger-models/authorization-client";
import type { IUser } from "@/models/user.model";
import type { ResourceType } from "@/swagger-models/authorization-client";

export interface IAccessRuleRecords {
  accessRules: AccessRule[];
  displayRecords: number;
  totalRecords: number;
}

export enum ERoleName {
  SystemAdmin = "System administrator",
}
export const subjectTypeOptions: ISelectOption[] = [
  {
    label: "User",
    value: SubjectType.User,
  },
  {
    label: "Application",
    value: SubjectType.App,
  },
];
export const subjectTypeOptionsWithSSO: ISelectOption[] = [
  {
    label: "User",
    value: SubjectType.User,
  },
  {
    label: "SSO Group",
    value: SubjectType.Group,
  },
  {
    label: "Application",
    value: SubjectType.App,
  },
];

export interface IAccessRuleTableModalOptions {
  accessRules: AccessRule[];
  columns: Array<ITableColumn>;
  header: string;
  loading: boolean;
}

export enum EAccessRuleModalPage {
  Project = "project",
  Department = "department",
  User = "user",
  Application = "application",
}
export interface IAccessRuleManagementModalOptions {
  subjectId?: string;
  subjectType?: SubjectType;
  scopeType?: ScopeType;
  scopeName?: string;
  scopeId?: string;
  page: EAccessRuleModalPage;
  user?: IUser;
}

export interface IAccessRuleDisplayConfig {
  subject: boolean;
  users: boolean;
  roles: boolean;
  scope: boolean;
}

export interface ISubjectTypeOption extends ISelectOption {
  value: SubjectType;
}

export interface IRoleIdOption extends ISelectOption {
  value: number;
}
export interface IAccessRuleItem {
  subjectTypeOption: null | ISubjectTypeOption;
  roleOption: null | IRoleIdOption;
  scopeInput: null | IOrgTreeNodeId;
  scopeName?: string;
  scopeType?: ScopeType;
  subjectType?: SubjectType;
  roleName?: string;
  subjectId: string;
  accessRuleId?: number;
  saved: boolean;
  isInherited?: boolean;
}

export interface AccessRuleWithGroup extends AccessRule {
  group: string;
}

export type TRolesTableResourceTypes = ResourceType | "dataSource";

export const ResourceTypeText: Record<TRolesTableResourceTypes, string> = {
  department: "Department",
  tenant: "Account",
  project: "Project",
  cluster: "Cluster",
  "cluster-config": "Cluster config",
  nodepools: "Nodepools",
  nodes: "Nodes",
  settings: "Settings",
  users: "Users",
  groups: "Groups",
  apps: "Apps",
  "dashboards-overview": "Overview dashboard",
  "dashboards-analytics": "Analytics dashboard",
  "dashboards-consumption": "Consumption dashboard",
  roles: "Roles",
  access_rules: "Access rules",
  jobs: "Jobs",
  workloads: "Workloads",
  deployments: "Deployments",
  trainings: "Trainings",
  workspaces: "Workspaces",
  environments: "Environments",
  "pvc-assets": "PVC Assets",
  "git-assets": "Git Assets",
  "host-path-assets": "Host path assets",
  "nfs-assets": "NFS assets",
  "s3-assets": "S3 assets",
  "compute-resources": "Compute resources",
  "cm-volume-assets": "ConfigMap Assets",
  templates: "Templates",
  credentials: "Credentials",
  "events-history": "Events history",
  services: "Services",
  policies: "Policies",
  dataSource: "Data source",
};
