<template>
  <section class="workload-default-create-form">
    <workload-create-form
      ref="workloadCreateForm"
      :workload-type="workloadType"
      :workload="workload"
      :selected-project="selectedProject"
      :workload-config="workloadConfig"
      @workload-changed="$emit('workload-changed', $event)"
      @back-clicked="onBack"
      @leave-page="onLeavePage"
    >
      <template #error-message>
        <q-field class="col-4 form-hint flex-1" :model-value="displayFormHint" :rules="[isFormIncomplete]"></q-field>
      </template>

      <template #nav-btns>
        <q-btn flat color="primary" label="Cancel" class="q-mr-sm" @click="$emit('canceled')" />
        <q-btn color="primary" :label="submitBtnText" aid="submit-btn" :loading="submitting" @click="beforeSubmitting" />
      </template>
    </workload-create-form>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// cmps
import { WorkloadCreateForm } from "@/components/workload/workload-create/workload-create-form";

// models
import type { IWorkloadCreateFormConfig } from "src/components/workload/workload-create/workload-create-form";
import { EWorkloadFormType, type IUIWorkloadCreation } from "@/models/workload.model";
import type { IProject } from "@/models/project.model";

// constants
import { errorMessages } from "@/common/error-message.constant";
import { LEAVE_REASON } from "@/components/common/runai-form-wrapper";

// utils
import { getWorkloadConfig } from "./workload-default-create-form.util";
import { workloadCreateFormService } from "@/components/workload/workload-create/workload-create-form/workload-create-form.util";

export default defineComponent({
  components: {
    WorkloadCreateForm,
  },
  emits: ["workload-changed", "volume-changed", "canceled", "submit", "back-clicked"],
  props: {
    workloadType: {
      type: String as PropType<EWorkloadFormType>,
      required: true,
    },
    workload: {
      type: Object as PropType<IUIWorkloadCreation>,
      required: true,
    },
    selectedProject: {
      type: Object as PropType<IProject>,
      required: true,
    },
    submitBtnText: {
      type: String as PropType<string>,
      required: true,
    },
    submitting: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    workloadSectionsOptions: {
      type: Object as PropType<IWorkloadCreateFormConfig>,
      required: true,
    },
  },
  data() {
    return {
      workloadConfig: getWorkloadConfig() as IWorkloadCreateFormConfig,
      displayFormHint: false as boolean,
      timeOutId: null as ReturnType<typeof setTimeout> | null,
    };
  },
  created() {
    this.workloadConfig = workloadCreateFormService.getMergedWorkloadConfigs(
      this.workloadConfig,
      this.workloadSectionsOptions,
    );

    if (this.workloadType === EWorkloadFormType.Inference && this.workloadSectionsOptions.modelSummary) {
      this.workloadConfig.modelSummary = this.workloadSectionsOptions.modelSummary;
    }
  },
  methods: {
    isFormIncomplete(val: boolean): boolean | string {
      return !val ? true : errorMessages.FORM_INCOMPLETE;
    },
    async validate(): Promise<boolean> {
      const workloadCreateForm: HTMLFormElement | unknown = this.$refs["workloadCreateForm"];
      if (!workloadCreateForm) return false;

      return await (workloadCreateForm as HTMLFormElement).validate();
    },
    showHint(): void {
      this.displayFormHint = true;
      this.timeOutId && clearTimeout(this.timeOutId);
      this.timeOutId = setTimeout(() => (this.displayFormHint = false), 15000);
    },
    async beforeSubmitting(): Promise<void> {
      const success = await this.validate();
      if (!success) {
        this.showHint();
        return;
      }

      this.$emit("submit");
    },
    onBack(): void {
      (this.$refs.workloadCreateForm as HTMLFormElement).beforeLeave();
    },
    onLeavePage(leaveReason: LEAVE_REASON): void {
      if (leaveReason === LEAVE_REASON.LEAVE_PAGE) {
        this.$emit("back-clicked");
      }
    },
  },
  unmounted() {
    this.timeOutId && clearTimeout(this.timeOutId);
  },
});
</script>
