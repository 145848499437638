<template>
  <runai-base-dialog
    class="compliance-info-modal"
    :model-value="true"
    @close="$emit('close')"
    size="width-lg"
    no-padding
  >
    <template #header>{{ title }}</template>
    <template #body>
      <div class="compliance-info-modal-content">
        <div class="subtitle">
          {{ subtitle }}
        </div>
        <q-separator />
        <runai-table
          :rows="rows"
          :columns="columns"
          :loading="false"
          hide-pagination
          :filter-by="{ rowsPerPage: 0 }"
          no-shadow
          sticky-header
          :style="{ maxHeight: '500px' }"
          disable-selection
          :bordered="false"
        />
      </div>
    </template>
    <template #footer>
      <q-btn label="Close" color="primary" @click="$emit('close')" aid="compute-policy-info-modal-close-button" />
    </template>
  </runai-base-dialog>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "@vue/runtime-core";

// cmps
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";
import { RunaiTable } from "@/components/common/runai-table";

// models
import type { ComplianceInfo, ComplianceInfoReason, AssetKind } from "@/swagger-models/assets-service-client";
import type { IPolicyTableRow } from "@/models/policy.model";
import { allPreviewPolicyColumns, policyTypeColumn } from "@/table-models/policy.table-model";
import type { ITableColumn } from "@/models/table.model";

// utils
import { policyUtil } from "@/utils/policy.util";
import { stringUtil } from "@/utils/string.util";

export default defineComponent({
  components: {
    RunaiBaseDialog,
    RunaiTable,
  },
  emits: ["close"],
  props: {
    complianceInfo: {
      type: Object as PropType<ComplianceInfo>,
      required: true,
    },
    policy: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Object as PropType<any>,
      required: true,
    },
    field: {
      type: String as PropType<AssetKind>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    subtitle: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      complianceKeys: new Set<string>(),
    };
  },
  created() {
    if (!this.complianceInfo.reason) return;

    this.complianceKeys = new Set<string>(
      this.complianceInfo.reason
        .map((reason: ComplianceInfoReason) => (reason.field ? stringUtil.getStringAfterFirstDot(reason.field) : ""))
        .filter((field: string) => field),
    );
  },
  computed: {
    rows(): Array<Record<string, IPolicyTableRow>> {
      if (!this.currentPolicy[this.field]) return [];
      const allRules: Array<Record<string, IPolicyTableRow>> = Object.values(this.currentPolicy[this.field]);

      let filteredRules: Array<Record<string, IPolicyTableRow>> =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        allRules.filter((row: any) => this.complianceKeys.has(row.name));

      if (this.isDataSourceTable) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filteredRules = filteredRules.map((row: any) => ({ ...row, type: this.field }));
      }
      return filteredRules;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentPolicy(): any {
      return policyUtil.mapPolicyToTableRows(this.policy.effective);
    },
    columns(): Array<ITableColumn> {
      if (!this.isDataSourceTable) return allPreviewPolicyColumns;

      const dataSourceColumns = [...allPreviewPolicyColumns];
      dataSourceColumns.splice(1, 0, policyTypeColumn);
      return dataSourceColumns;
    },
    isDataSourceTable(): boolean {
      return this.field !== "compute" && this.field !== "environment";
    },
  },
});
</script>

<style lang="scss" scoped>
.compliance-info-modal-content {
  padding: 15px;
  .subtitle {
    margin-bottom: 15px;
    font-size: 12px;
  }
}
</style>
