import type { INodeWorkload } from "@/models/node-workload.model";

export interface INodePool {
  name: string;
  labelKey: string;
  labelValue: string;
  placementStrategy: {
    gpu: PlacementStrategy;
    cpu: PlacementStrategy;
  };
  id: number;
  clusterId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  status: string;
  statusMessage: string;
  nodes: string;
  createdBy: string;
  updatedBy: string;
  isDefault: boolean;
  projectsGpus: number | undefined;
  projectsCpus: number | undefined;
  projectsMemory: number | undefined;
  workloads?: Array<INodeWorkload>;
  overProvisioningRatio: number;
}

export interface IMetricNodePool {
  name: string;
  utilization: string;
  totalGpus: string;
  allocatedGpus: string;
  totalCpus: string;
  usedCpus: string;
  allocatedCpus: string;
  totalGpuMemory: string;
  usedGpuMemory: string;
  totalCpuMemory: string;
  usedCpuMemory: string;
  allocatedMemory: string;
  nodes: string;
}

export enum PlacementStrategy {
  Binpack = "binpack",
  Spread = "spread",
}

export const binPackLabel = "Bin-pack";
export const spreadLabel = "Spread";
export const PlacementOptions = {
  gpu: [
    {
      label: binPackLabel,
      value: PlacementStrategy.Binpack,
      toolTip:
        "Place as many workloads as possible in each GPU and node to use fewer resources and maximize GPU and node vacancy.",
    },
    {
      label: spreadLabel,
      value: PlacementStrategy.Spread,
      toolTip:
        "Spread workloads across as many GPUs and nodes as possible to minimize the load and maximize the available resources per workload.",
    },
  ],
  cpu: [
    {
      label: binPackLabel,
      value: PlacementStrategy.Binpack,
      toolTip:
        "Place as many workloads as possible in each CPU and node to use fewer resources and maximize CPU and node vacancy.",
    },
    {
      label: spreadLabel,
      value: PlacementStrategy.Spread,
      toolTip:
        "Spread workloads across as many CPUs and nodes as possible to minimize the load and maximize the available resources per workload.",
    },
  ],
};

export const NodePoolStatusMap = new Map([
  [
    "Creating",
    {
      displayLabel: "Creating...",
      isPending: true,
    },
  ],
  [
    "Updating",
    {
      displayLabel: "Updating...",
      isPending: true,
    },
  ],
  [
    "Deleting",
    {
      displayLabel: "Deleting...",
      isPending: true,
    },
  ],
]);

export const NodePoolStatusMessageMap = new Map([
  [
    "Error Deleting NodePool",
    {
      newMessage: "Node pool cannot be deleted, if the issue persists contact run:ai support",
    },
  ],
  [
    "Scheduler is not ready",
    {
      newMessage: "The Run:ai scheduler is not ready, if the issue persists contact run:ai support",
    },
  ],
  [
    "Nodes unavailable",
    {
      newMessage: "Nodes are unavailable, if the issue persists contact run:ai support",
    },
  ],
]);

export const NodePoolStatusMessagePrefix = [
  {
    originalMessage: "Scheduler is not ready, reason:",
    newMessage: "The Run:ai scheduler is not ready, because of the following:",
  },
  {
    originalMessage: "Unschedulable Nodes:",
    newMessage: "The following nodes within the node pool are not ready:",
  },
  {
    originalMessage: "Nodes Being Drained:",
    newMessage:
      "Seems there are workloads on the nodes under this node pool which are not associated with this node pool. Please delete all these running/failed workloads.\nThe nodes are:",
  },
];

export enum ChartDataKeys {
  totalGpus = "totalGpus",
  gpuAllocated = "gpuAllocated",
  gpuNotAllocated = "gpuNotAllocated",
  gpuPerNodeMetricInSlicedRanges = "gpuPerNodeMetricInSlicedRanges",
  gpuMemoryUtilization = "gpuMemoryUtilization",
  gpuUtilization = "gpuUtilization",
  cpuUtilization = "cpuUtilization",
  cpuMemoryUtilization = "cpuMemoryUtilization",
}
export interface INodePoolChartSettings {
  key: string;
  title: string;
  color: string | string[];
  container: string;
  chartType: ChartType;
  names?: string[];
  totalColor?: string;
  isGpuChart?: boolean;
}
export enum ChartType {
  line = "line",
  area = "area",
}

// function to create legend for GPU utilization distribution chart
// example: GPU with 0-25% utilization, GPU with 25-50% utilization, etc.
function createLegendUtilizationArray(min: number, max: number, step: number): string[] {
  return Array.from(
    { length: (max - min) / step },
    (_, i) => `GPU with ${min + i * step}-${min + (i + 1) * step}% utilization`,
  );
}

export const chartSettings: INodePoolChartSettings[] = [
  {
    key: ChartDataKeys.gpuPerNodeMetricInSlicedRanges,
    title: "GPU utilization distribution",
    color: ["#9B19F5", "#FFA300", "#E6D800", "#00BFA0"],
    container: "container-gpu-utilization-range",
    chartType: ChartType.area,
    names: createLegendUtilizationArray(0, 100, 25).reverse(),
    totalColor: "#003F5C",
    isGpuChart: true,
  },
  {
    key: ChartDataKeys.gpuAllocated,
    title: "Node GPU allocation",
    color: ["#9B19F5", "#00BFA0"],
    container: "container-gpu-allocation",
    chartType: ChartType.area,
    names: ["Allocated GPUs", "Unallocated GPUs"],
    totalColor: "#003F5C",
    isGpuChart: true,
  },
  {
    key: ChartDataKeys.gpuUtilization,
    title: "GPU utilization",
    color: "#9B19F5",
    container: "container-gpu-utilization",
    chartType: ChartType.line,
    isGpuChart: true,
  },
  {
    key: ChartDataKeys.gpuMemoryUtilization,
    title: "GPU memory utilization",
    color: "#9B19F5",
    container: "container-gpu-memory-utilization",
    chartType: ChartType.line,
    isGpuChart: true,
  },
  {
    key: ChartDataKeys.cpuUtilization,
    title: "CPU utilization",
    color: "#0BB4FF",
    container: "container-cpu-utilization",
    chartType: ChartType.line,
    isGpuChart: false,
  },
  {
    key: ChartDataKeys.cpuMemoryUtilization,
    title: "CPU memory utilization",
    color: "#0BB4FF",
    container: "container-cpu-memory-utilization",
    chartType: ChartType.line,
    isGpuChart: false,
  },
];

export const emptyNodePool: INodePool = {
  clusterId: "",
  createdAt: "",
  createdBy: "",
  deletedAt: "",
  id: 0,
  isDefault: false,
  labelKey: "",
  labelValue: "",
  placementStrategy: {
    gpu: PlacementStrategy.Binpack,
    cpu: PlacementStrategy.Binpack,
  },
  name: "",
  nodes: "",
  projectsCpus: undefined,
  projectsGpus: undefined,
  projectsMemory: undefined,
  status: "",
  statusMessage: "",
  updatedAt: "",
  updatedBy: "",
  workloads: undefined,
  overProvisioningRatio: 1,
};

export const nodePoolsDependentColumns = {
  placementStrategy: ["gpu-placement-strategy", "cpu-placement-strategy"],
  oldVersionColumns: [
    "name",
    "status",
    "label-key",
    "label-value",
    "gpu-project-quota",
    "cpu-project-quota",
    "memory-project-quota",
    "node-pool-updated-at",
    "node-pool-created-at",
  ],
  overProvisioningRatio: ["over-provisioning-ratio"],
};

export const MIN_OVER_PROVISIONING_RATIO = 1;
export const MAX_OVER_PROVISIONING_RATIO = 5;

export enum EOverProvisioningText {
  AsRequested = "As requested",
  NotEnforced = "Not enforced",
}
export const OVER_PROVISIONING_RATIO: { [index: string]: string } = {
  [MIN_OVER_PROVISIONING_RATIO]: EOverProvisioningText.AsRequested,
  2: "0.5",
  3: "0.33",
  4: "0.25",
  [MAX_OVER_PROVISIONING_RATIO]: "0.2",
};
