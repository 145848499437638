/* eslint-disable */

import type { DistributedFramework, Scope } from "@/swagger-models/assets-service-client";
import type { EWorkloadType } from "@/models/workload.model";

export interface IFilterModel {
  field: string | ((row: any) => any);
  name: string;
  term: string;
}

export interface IFilterOption {
  label: string;
  field: string | ((row: any) => any);
  name: string;
}

export interface ILabeledFilter {
  field: string | ((row: any) => any);
  term: string;
  label: string;
  name: string;
}

export interface IFilterBy {
  sortBy?: string;
  descending?: boolean;
  query?: string;
  page?: number;
  rowsPerPage?: number;
  columnFilters?: Array<IFilterModel>;
  searchTerm?: string;
  displayedColumns?: Array<string>;
  forceLoad?: boolean;
  clusterId?: string;
  /**
   * For server-side fetching only
   */
  rowsNumber?: number; // How many total database rows are there to be added to the table. If set, causes the QTable to emit @request when data is required.
}
export enum ESortOrder {
  Asc = "asc",
  Desc = "desc",
}
export interface IPaginationFilter {
  offset?: number;
  limit?: number;
  sortOrder?: ESortOrder;
  sortBy?: string;
  filterBy?: Array<string>;
}

export enum EFilterOperator {
  Equals = "==",
  NotEquals = "!=",
  LessThanOrEqual = "<=",
  GreaterThanOrEqual = ">=",
  Contains = "=@",
  DoesNotContain = "!@",
  StartsWith = "=^",
  EndsWith = "=$",
}

export interface IJobFilter extends IFilterBy {
  type?: string;
}
export interface IAssetsFilter extends IFilterBy {
  projectId?: number | null;
  departmentId?: string | null;
  scope?: Scope;
  workspaceId?: string;
  workloadTemplateId?: string;
  usageInfo?: boolean;
  complyToProject?: number;
  complyToWorkloadType?: EWorkloadType;
  isWorkspace?: boolean;
  isTraining?: boolean;
  isInference?: boolean;
  isDistributed?: boolean;
  distributedFramework?: DistributedFramework;
  clusterId?: string;
  statusInfo?: boolean;
}
export interface IPoliciesFilter extends IFilterBy {
  scope?: Scope;
  projectId?: number | null;
  departmentId?: string | null;
}

export enum EOrderDirection {
  ASC = "asc",
  DESC = "desc",
}

export const CLUSTER_COLUMN_FILTER_NAME = "cluster";
