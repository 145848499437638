<template>
  <div class="docker-registry-fields col-12">
    <div>
      <div class="row items-center q-mb-md">
        <span class="q-mr-md">Set Docker image registry</span>
        <runai-tooltip
          aid="tool-tip-docker-registry"
          tooltip-text="Set a Docker registry to enable autocomplete search for Docker images on workload submission forms."
          width="400px"
          tooltip-position="right"
        />
      </div>
      <div>
        <q-btn
          aid="add-registry-btn"
          label="+ REGISTRY"
          flat
          color="primary"
          class="registry-button"
          v-if="shouldShowAddButton"
          @click="addNewRegistry"
        />
      </div>
      <div>
        <setting-editable-field
          v-if="!editMode && registryData"
          delete-tool-tip="Remove registry"
          edit-tool-tip="Edit registry"
          delete-sub-title="The Docker image registry integration will be disabled."
          :label="`Docker registry: ${registryData?.url}`"
          @edit="editClicked"
          @delete="removeClicked"
        />
        <setting-wrapper-box
          v-if="editMode"
          :is-box-open="true"
          has-footer
          primary-button-label="save"
          secondary-button-label="cancel"
          @primary-button-clicked="savedClicked"
          @secondary-button-clicked="cancelClicked"
          :validation-message="validationMessage"
        >
          <q-input
            class="col-12 q-mb-lg"
            :model-value="updatedRegistryData?.url"
            placeholder="Enter URL"
            debounce="300"
            :rules="[isValidUrl]"
            no-error-icon
            stack-label
            label="Registry URL"
            @update:model-value="updateUrl"
            ref="registryUrlInput"
            autofocus
          />
          <div class="row col-12">
            <q-input
              class="col-5"
              :model-value="updatedRegistryData?.user"
              debounce="300"
              :rules="[isNotEmptyUsername]"
              no-error-icon
              stack-label
              label="Username"
              @update:model-value="updateUser"
              ref="registryUserInput"
            />
            <div class="col-1" />
            <q-input
              class="col-6 items-end"
              :model-value="updatedRegistryData?.password"
              debounce="300"
              :rules="[isNotEmptyPassword]"
              no-error-icon
              stack-label
              label="Password"
              :type="showPassword ? 'password' : 'text'"
              @update:model-value="updatePassword"
              ref="registryPasswordInput"
            >
              <template v-slot:append>
                <q-icon
                  :name="passwordIcon"
                  class="cursor-pointer password-icon"
                  @click="showPassword = !showPassword"
                />
              </template>
            </q-input>
          </div>
        </setting-wrapper-box>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

// components
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import SettingEditableField from "@/components/settings/setting-editable-field/setting-editable-field.vue";
import SettingWrapperBox from "@/components/settings/setting-wrapper-box/setting-wrapper-box.vue";
import type { QInput } from "quasar";

// stores
import { useRegistryStore } from "@/stores/registry.store";
import { useSettingStore } from "@/stores/setting.store";

// models
import type { IRegistryData } from "@/models/setting.model";
import { isNotEmpty, isValidUrl } from "@/common/form.validators";

// utils
import { isEqual } from "@/utils/common.util";
import { objectUtil } from "@/utils/object.util";

export default defineComponent({
  name: "docker-registry-fields",
  components: { SettingWrapperBox, SettingEditableField, RunaiTooltip },
  emits: ["updated", "deleted"],
  props: {
    registryData: {
      type: Object as PropType<IRegistryData>,
    },
  },
  data() {
    return {
      registryStore: useRegistryStore(),
      settingsStore: useSettingStore(),
      editMode: false,
      updatedRegistryData: { ...this.registryData } as IRegistryData,
      showPassword: true,
    };
  },
  computed: {
    readMode(): boolean {
      return !this.editMode && this.registryData !== undefined;
    },
    shouldShowAddButton(): boolean {
      return !this.readMode && !this.editMode;
    },
    validationMessage(): string {
      return this.isRegistryDataChanged ? "Unsaved changes" : "";
    },
    isRegistryDataChanged(): boolean {
      return !objectUtil.isEmpty(this.updatedRegistryData) && !isEqual(this.registryData, this.updatedRegistryData);
    },
    passwordIcon(): string {
      return `fa-solid ${this.showPassword ? "fa-eye-slash" : "fa-eye"}`;
    },
  },
  methods: {
    validateRegistryFields(): boolean | Promise<boolean> {
      return (
        (this.$refs.registryUrlInput as QInput).validate() &&
        (this.$refs.registryUserInput as QInput).validate() &&
        (this.$refs.registryPasswordInput as QInput).validate()
      );
    },
    changeToReadMode(): void {
      this.editMode = false;
    },
    isValidUrl(value: string): boolean | string {
      return isValidUrl(value) || "Enter a valid URL";
    },
    isNotEmptyUsername(value: string): boolean | string {
      return isNotEmpty(value) || "Enter username";
    },
    isNotEmptyPassword(value: string): boolean | string {
      return isNotEmpty(value) || "Enter a password";
    },
    editClicked(): void {
      this.editMode = true;
    },
    removeClicked(): void {
      this.$emit("deleted");
    },
    addNewRegistry(): void {
      this.editMode = true;
      this.updatedRegistryData = { ...this.registryData } as IRegistryData;
    },
    updateUrl(url: string): void {
      this.updatedRegistryData.url = url;
    },
    updateUser(user: string): void {
      this.updatedRegistryData.user = user;
    },
    updatePassword(password: string): void {
      this.updatedRegistryData.password = password;
    },
    savedClicked(): void {
      const isValid = this.validateRegistryFields();
      if (this.isRegistryDataChanged && isValid) {
        this.$emit("updated", this.updatedRegistryData);
      }
    },
    cancelClicked(): void {
      this.editMode = false;
      this.updatedRegistryData = { ...this.registryData } as IRegistryData;
    },
  },
});
</script>
<style lang="scss" scoped>
.password-icon {
  font-size: 20px;
}
</style>
