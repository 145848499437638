<template>
  <section class="resources-cost">
    <toggle-setting :setting="consumptionLocalSetting" @update-setting="updateSetting" class="q-mb-md" />
    <div class="q-my-md" v-if="consumptionLocalSetting.value">
      <span>Add pricing to each of the resources to view their cost calculation </span
      ><span class="text-italic">(optional)</span>
      <runai-tooltip
        aid="tooltip-consumptions"
        tooltip-text="A cost column is added to the Consumption dashboard for each resource."
      />
    </div>
    <transition name="fade" :duration="300">
      <div v-if="consumptionLocalSetting.value" class="column q-pb-md">
        <div class="input-with-label-wrapper row items-center q-mb-md q-mr-sm">
          <q-input
            label="GPU compute / Hour"
            :model-value="resourcesCost.gpu"
            @update:model-value="updateResourceCost('gpu', $event)"
            class="q-mb-sm cost-input"
            no-error-icon
            outlined
            type="number"
            :min="0"
            @blur="save"
          />
        </div>
        <div class="input-with-label-wrapper row items-center q-mb-md q-mr-sm">
          <q-input
            label="CPU compute / Hour"
            :model-value="resourcesCost.cpu"
            @update:model-value="updateResourceCost('cpu', $event)"
            class="q-mb-sm cost-input"
            no-error-icon
            outlined
            type="number"
            :min="0"
            @blur="save"
          />
        </div>

        <div class="input-with-label-wrapper row items-center q-mb-md q-mr-sm">
          <q-input
            label="CPU memory / Hour"
            :model-value="resourcesCost.memory"
            @update:model-value="updateResourceCost('memory', $event)"
            class="q-mb-sm cost-input"
            no-error-icon
            outlined
            type="number"
            :min="0"
            @blur="save"
          />
        </div>
      </div>
    </transition>
  </section>
</template>
<script lang="ts">
//models
import { defineComponent, type PropType } from "vue";
import { type IResourcesCost, SettingKeys } from "@/models/setting.model";
import type { Setting } from "@/swagger-models/backend-client";

// stores
import { useSettingStore } from "@/stores/setting.store";

//utils
import { ErrorAlert } from "@/utils/error-alert.util";
import { deepCopy } from "@/utils/common.util";
import { alertUtil } from "@/utils/alert.util";

// cmps
import ToggleSetting from "@/components/settings/toggle-setting/toggle-setting.vue";
import RunaiTooltip from "@/components/common/runai-tooltip/runai-tooltip.vue";

export default defineComponent({
  name: "dashborad-resource-cost",
  components: { RunaiTooltip, ToggleSetting },
  props: {
    consumptionSetting: {
      type: Object as PropType<Setting>,
      required: true,
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
      consumptionLocalSetting: deepCopy(this.consumptionSetting),
      resourcesCost: {
        gpu: null,
        cpu: null,
        memory: null,
      } as IResourcesCost,
      originalResourcesCost: null as IResourcesCost | null,
    };
  },
  created() {
    this.resourcesCost = { ...this.settingStore.resourcesCost };
    this.originalResourcesCost = { ...this.settingStore.resourcesCost };
  },
  methods: {
    updateSetting(key: string, value: string | boolean | number): void {
      this.consumptionLocalSetting.value = value as string;
    },
    updateResourceCost(key: string, value: string): void {
      const resourceCostKey = key as keyof typeof this.resourcesCost;
      if (value === "") {
        this.resourcesCost[resourceCostKey] = null;
      } else {
        this.resourcesCost[resourceCostKey] = parseFloat(value);
      }
    },
    async save(): Promise<void> {
      if (this.areResourcesCostEqual()) return;
      try {
        await this.settingStore.updateSetting(SettingKeys.ResourcesCost, this.resourcesCost);
        this.originalResourcesCost = { ...this.resourcesCost };
        this.$q.notify(alertUtil.getSuccess("Setting updated successfully"));
      } catch (error: unknown) {
        const errorAlert = new ErrorAlert({
          generalMessage: "Cannot update resources cost setting",
        });
        this.$q.notify(errorAlert.getNotification(error));
      }
    },
    areResourcesCostEqual(): boolean {
      if (this.originalResourcesCost === null) {
        return false;
      }

      return (
        this.resourcesCost.gpu === this.originalResourcesCost.gpu &&
        this.resourcesCost.cpu === this.originalResourcesCost.cpu &&
        this.resourcesCost.memory === this.originalResourcesCost.memory
      );
    },
  },
});
</script>

<style scoped lang="scss">
.input-with-label-wrapper {
  width: 300px;
  .label {
    min-width: 40%;
  }
  .cost-input {
    width: 300px;
  }
}
</style>
