import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import type { ITenant } from "@/models/tenant.model";
import { API, K8S_API } from "@/common/api.constant";

export const tenantService = {
  get,
  getTenantAuthProviderData,
  getTenantLogo,
  signEULA,
};

const ENDPOINT = `${K8S_API.v1}/tenant`;
const ENDPOINT_V2 = `${API.v1}`;

// api calls
async function get(): Promise<ITenant> {
  const tenant = await controlPlaneService.get(ENDPOINT);
  return tenant;
}

async function getTenantLogo(): Promise<Record<string, string>> {
  return controlPlaneService.get(`${ENDPOINT_V2}/logo`);
}

async function getTenantAuthProviderData(tenantName: string): Promise<object> {
  return controlPlaneService.get(`${ENDPOINT}/${tenantName}/auth`);
}

async function signEULA(): Promise<void> {
  await controlPlaneService.post(`${ENDPOINT}/sign-eula`);
}
