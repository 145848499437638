<template>
  <stat-widget-wrapper :options="options" @link-clicked="redirectToWorkloads" :loading="loading" :error="error">
    <template #data> {{ workloadsCount }}</template>
  </stat-widget-wrapper>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
//routes
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes";
import { workloadService } from "@/services/cluster/workload.service/workload.service";
//store
import { useClusterStore } from "@/stores/cluster.store";
//models
import { EWidgetEntity, type IWidgetWrapperOptions } from "@/models/chart.model";
import { HttpErrorResponse } from "@/models/http-response.model";
import { Phase, WorkloadSortFilterFields } from "@/swagger-models/workloads-service-client";
import { EFilterOperator, type IFilterBy, type IFilterModel } from "@/models/filter.model";
//cmps
import { StatWidgetWrapper } from "@/components/dashboard-v2/widgets/common/widget-wrapper/stat-widget-wrapper";
import { filterService } from "@/services/filter.service/filter.service";
import { ETableFilters } from "@/models/table.model";
import { workloadIndexColumns } from "@/table-models/workload.table-model";

export default defineComponent({
  name: "pending-workloads-widget",
  components: { StatWidgetWrapper },
  props: {
    filterBy: {
      type: Array as PropType<IFilterModel[]>,
      required: false,
    },
  },
  data() {
    return {
      options: {
        timeFrame: "Now",
        linkText: "All workloads",
        title: "Pending workloads",
        entityType: EWidgetEntity.Workload,
      } as IWidgetWrapperOptions,
      workloadsCount: 0 as number,
      loading: true as boolean,
      error: false as boolean,
    };
  },
  created() {
    this.getWorkloadsCount();
  },
  computed: {
    clusterId(): string {
      return useClusterStore().currentClusterId;
    },
    nodePoolFilter(): string | undefined {
      const nodePoolFilter = this.filterBy?.find((filter: IFilterModel) => filter.field === "nodepool");
      if (nodePoolFilter) {
        return nodePoolFilter.term;
      }
      return undefined;
    },
  },
  methods: {
    async getWorkloadsCount(): Promise<void> {
      try {
        const countResponse: { count: number } = await workloadService.getWorkloadsCount(
          this.clusterId,
          this.getFilterBy(),
        );
        this.workloadsCount = countResponse.count;
        this.error = false;
      } catch (error: unknown) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    getFilterBy(): string[] {
      const filterBy = [`${WorkloadSortFilterFields.Phase}${EFilterOperator.Equals}${Phase.Pending}`];
      if (this.nodePoolFilter) {
        filterBy.push(`${WorkloadSortFilterFields.Nodepool}${EFilterOperator.Equals}${this.nodePoolFilter}`);
      }
      return filterBy;
    },
    handleError(error: unknown): void {
      this.error = true;
      if (error instanceof HttpErrorResponse) {
        console.error(error.message);
      } else {
        console.error("Error fetching data", error);
      }
    },
    redirectToWorkloads(): void {
      this.setColumnFilter();
      this.$router.push({ name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX });
    },
    getWorkloadsFilterBy(): IFilterBy {
      const defaultFilters: IFilterBy = filterService.getDefaultFilters(
        WorkloadSortFilterFields.Phase,
        workloadIndexColumns,
      );
      return filterService.loadFilters(window.location, ETableFilters.WORKLOAD_MODAL_V2, defaultFilters, true);
    },
    setColumnFilter(): void {
      const workloadsFilterBy: IFilterBy = this.getWorkloadsFilterBy();

      filterService.setColumnFilter(
        workloadsFilterBy,
        Phase.Pending,
        WorkloadSortFilterFields.Phase,
        ETableFilters.WORKLOAD_V2,
      );
    },
  },
  watch: {
    filterBy: {
      async handler(): Promise<void> {
        this.loading = true;
        await this.getWorkloadsCount();
      },
      deep: true,
    },
  },
});
</script>

<style scoped lang="scss"></style>
