<template>
  <section>
    <node-pools-table-header
      :is-cpu-enabled="isCpuEnabled"
      :is-over-quota-priority-enabled="isOverQuotaPriorityEnabled"
      :is-only-default-node-pool="isOnlyDefaultNodePool"
    />
    <node-pools-table-body
      :is-only-default-node-pool="isOnlyDefaultNodePool"
      :is-over-quota-priority-enabled="isOverQuotaPriorityEnabled"
      :is-cpu-enabled="isCpuEnabled"
      :department-id="departmentId"
      :read-only="false"
      :node-pools-resources="nodePoolsResources"
      :node-pools-priorities="nodePoolsPriorities"
      @update:node-pools-resources="$emit('update:node-pools-resources', $event)"
      @update:node-pools-priorities="$emit('update:node-pools-priorities', $event)"
      @update:node-pools-resource="updateNodePoolResources"
    />
    <node-pools-table-summary
      v-if="!isOnlyDefaultNodePool"
      :is-over-quota-priority-enabled="isOverQuotaPriorityEnabled"
      :node-pools-resources="nodePoolsResources"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
// cmps

import { NodePoolsTableHeader } from "@/components/quota-management/node-pools-table/node-pools-table-header/";
import { NodePoolsTableBody } from "@/components/quota-management/node-pools-table/node-pools-table-body";
import { NodePoolsTableSummary } from "@/components/quota-management/node-pools-table/node-pools-table-summary";
// stores
import { useSettingStore } from "@/stores/setting.store";
// models
import { EResourceLabel } from "@/models/resource.model";
import type { INodePoolResources } from "@/models/project.model";
//utils
import { deepCopy } from "@/utils/common.util";
export default defineComponent({
  components: { NodePoolsTableHeader, NodePoolsTableBody, NodePoolsTableSummary },
  emits: ["update:node-pools-resources", "update:node-pools-priorities"],
  RESOURCE_LABEL: EResourceLabel,
  props: {
    departmentId: {
      type: Number as PropType<number>,
      default: -1,
    },
    nodePoolsResources: {
      type: Array as PropType<Array<INodePoolResources>>,
      required: true,
    },
    nodePoolsPriorities: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    isOnlyDefaultNodePool: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
    };
  },
  computed: {
    isOverQuotaPriorityEnabled(): boolean {
      return this.settingStore.isOverQuotaPriorityEnabled;
    },
    isCpuEnabled(): boolean {
      return this.settingStore.isCPUResourcesQuotaEnabled;
    },
  },
  methods: {
    updateNodePoolResources(nodePoolResources: INodePoolResources): void {
      const items = deepCopy(this.nodePoolsResources);
      items[0] = nodePoolResources;
      this.$emit("update:node-pools-resources", items);
    },
  },
});
</script>
