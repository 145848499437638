<template>
  <section class="delete-department-modal">
    <runai-base-dialog :model-value="modelValue" @close="$emit('close')" size="width-md" :no-padding="true">
      <template #header>Delete</template>

      <template #body>
        <project-options-step
          v-if="currentView === viewCmps.ProjectOptionsStep"
          :department="currentDepartment"
          :department-list="otherDepartments"
          v-model="projectDecisionModel"
        ></project-options-step>

        <div class="delete-department-body q-px-lg q-py-md column" v-else>
          <p class="delete-modal-msg q-mt-md">Permanently delete department {{ currentDepartment?.name }}?</p>
          <div class="delete-modal-info q-mt-lg q-mb-xl">Once a department is deleted, it cannot be restored.</div>
        </div>
      </template>

      <template #footer-left>
        <q-btn
          label="Back"
          color="primary"
          v-if="hasProjects && currentView === viewCmps.DeleteModalBody"
          flat
          @click="onBack"
          aid="delete-modal-back-button"
          class="q-mr-md"
        >
        </q-btn>
      </template>
      <template #footer>
        <q-btn label="Cancel" color="primary" flat @click="$emit('close')" aid="delete-modal-cancel-button" />
        <q-btn
          v-if="currentView === viewCmps.DeleteModalBody"
          label="Delete"
          color="primary"
          @click="onDelete"
          aid="delete-modal-delete-button"
        />
        <q-btn v-else label="Continue" color="primary" @click="onContinue" aid="delete-modal-continue-button" />
      </template>
    </runai-base-dialog>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "@vue/runtime-core";

// cmps
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";
import ProjectOptionsStep from "@/components/department/delete-department-modal/project-options-step.vue";

// models
import type { IDepartment } from "@/models/department.model";
import { EDeleteOrPass, type IProjectOptsModel } from "./delete-department.models";

export default defineComponent({
  components: {
    RunaiBaseDialog,
    ProjectOptionsStep,
  },
  emits: ["close", "remove-department"],
  props: {
    currentDepartment: {
      type: Object as PropType<IDepartment>,
      required: true,
    },
    departmentList: {
      type: Array as PropType<Array<IDepartment>>,
      required: true,
    },
  },
  data() {
    return {
      modelValue: true as boolean,
      currentView: "" as string,
      projectDecisionModel: {
        action: EDeleteOrPass.Delete,
      } as IProjectOptsModel,
      viewCmps: {
        ProjectOptionsStep: "ProjectOptionsStep",
        DeleteModalBody: "DeleteModalBody",
      },
    };
  },
  created() {
    this.currentView = this.hasProjects ? this.viewCmps.ProjectOptionsStep : this.viewCmps.DeleteModalBody;
  },
  computed: {
    otherDepartments(): Array<IDepartment> {
      return this.departmentList.filter((d) => d.id !== this.currentDepartment.id);
    },
    hasProjects(): boolean {
      return !!this.currentDepartment.projects?.length;
    },
  },
  methods: {
    onContinue(): void {
      this.currentView = this.viewCmps.DeleteModalBody;
    },
    onDelete(): void {
      const action = {
        action: this.projectDecisionModel?.action,
        toDepartmentId: this.projectDecisionModel?.departmentId,
        fromDepartmentId: this.currentDepartment.id,
      };
      this.$emit("remove-department", action);
    },
    onBack(): void {
      this.currentView = this.viewCmps.ProjectOptionsStep;
    },
  },
});
</script>

<style>
.delete-modal-msg {
  font-size: 16px;
}
.delete-modal-info {
  font-size: 14px;
}
</style>
