export const MIN_INFERENCE_AUTO_DELETE = "2.17.0";
export const MIN_DEPENDENCIES_VERSION = "2.16.0";
export const MIN_NODE_WORKLOAD_METRICS_VERSION = "2.9.0";
export const MIN_PLACEMENT_VERSION = "2.13.0";
export const MIN_TRAIN_TIME_LIMIT_VERSION = "2.11.0";
export const MIN_CREATE_PROJECT_WITH_NAMESPACE_VERSION = "2.12.0";
export const MIN_PROJECT_STATUS_VERSION = "2.12.0";
export const MIN_NEW_PROJECT_METRIC_VERSION = "2.9";
export const MIN_NEW_DEPARTMENT_METRIC_VERSION = "2.9";
export const MIN_DEPARTMENT_QUEUE_METRIC_VERSION = "2.16";
export const MIN_NEW_NODE_POOL_METRIC_VERSION = "2.9";
export const MIN_AMD_GPU_BASED_NODES_VERSION = "2.14";
export const MIN_CLUSTER_SERVICE_NODES_VERSION = "2.14";
export const MIN_TENANT_SCOPE_CREDENTIALS = "2.11";
export const MIN_DEPARTMENT_SCOPE_CREDENTIALS = "2.15";
export const MIN_CLUSTER_TELEMETRY_TYPE_VERSION = "2.15";
export const MIN_ClUSTER_SCOPE_ASSETS = "2.17";
export const MIN_CONFIGMAP_CLUSTER_VERSION = "2.17";
export const TEST_ENV_VERSION = "master";
export const MIN_WINDOWS_CLI_VERSION = "2.9.0";
export const FEATURE_BRANCH_VERSION = "run-";
export const MIN_CLI_VERSION = "2.5.0";
export const QUOTA_MANAGEMENT_MIN_VERSION = "2.11";

export const ASSET_BASED_WORKLOADS_MIN_CLUSTER_VERSION = "2.9.0";
export const MIN_OVER_PROVISIONING_RATIO_CLUSTER_VERSION = "2.16";
export const MIN_SERVING_PORT_CLUSTER_VERSION = "2.17";
export const MIN_VERSION_GIT_SYNC_ONE_PARAMERTER = "2.15.0";
export const MIN_VERSION_INFERENCE_WORKLOAD_URL = "2.17.0";
