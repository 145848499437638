import { ResourceType, Action } from "@/swagger-models/authorization-client";
import type { RunaiRouteRecord } from "vue-router";

export enum COMPUTE_RESOURCE_ROUTE_NAMES {
  COMPUTE_RESOURCE_INDEX = "compute-resource-index",
  COMPUTE_RESOURCE_NEW = "compute-resource-new",
}

export const computeResourceRoutes: RunaiRouteRecord = {
  path: "/compute-resources",
  // route level code-splitting
  // this generates a separate chunk (department.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import("../views/compute-resource/compute-resource-app.vue"),
  children: [
    {
      path: "",
      name: COMPUTE_RESOURCE_ROUTE_NAMES.COMPUTE_RESOURCE_INDEX,
      component: () => import("../views/compute-resource/compute-resource-index.vue"),
      meta: {
        pageTitle: "Compute resources",
        resourceType: ResourceType.ComputeResources,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
        hideClusterSelection: true,
      },
    },
    {
      path: "new",
      name: COMPUTE_RESOURCE_ROUTE_NAMES.COMPUTE_RESOURCE_NEW,
      component: () => import("../views/compute-resource/compute-resource-new.vue"),
      meta: {
        pageTitle: "New compute resource",
        fullWidth: true,
        requestToLeave: true,
        backPageName: COMPUTE_RESOURCE_ROUTE_NAMES.COMPUTE_RESOURCE_INDEX,
        resourceType: ResourceType.ComputeResources,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
      },
    },
  ],
};
