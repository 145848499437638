<template>
  <runai-expansion-item
    class="inference-type-section"
    aid="inference-type-section"
    label="Inference type"
    :section-invalid="sectionInvalid"
    :default-opened="isSelectable"
    :disable-opening="!isSelectable"
    :disable-closing="isSelectable"
    hide-expend-icon
  >
    <template #subheader>
      <div class="row items-center justify-between q-pr-sm">
        <span>{{ summary }}</span>
        <slot name="custom-button"></slot>
      </div>
    </template>

    <runai-radio-options
      title="Select to create either a custom or model-based inference"
      :model-value="typeModel"
      :options="inferenceTypesOptions"
      @update:model-value="selectionChanged"
    />
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// Components
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { RunaiRadioOptions } from "@/components/common/runai-radio-options";

// models
import type { ISelectOption } from "@/models/global.model";
import type { IInferenceType } from "./inference-type.model";

export default defineComponent({
  components: {
    RunaiExpansionItem,
    RunaiRadioOptions,
  },
  emits: ["update-section-model"],
  props: {
    inferenceTypeModel: {
      type: [Object, null] as PropType<IInferenceType | null>,
      required: false,
    },
    isSelectable: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      inferenceTypesOptions: [
        {
          value: false,
          label: "Custom",
          toolTip: "Create an infrence selecting environment, compute resource and data source(s)",
        },
        {
          value: true,
          label: "Model",
          toolTip: "Create an inference using an existing model",
        },
      ] as Array<ISelectOption>,
    };
  },
  computed: {
    sectionInvalid(): boolean {
      return false;
    },
    summary(): string {
      return this.inferenceTypeModel?.isFromModel ? "Model" : "Standard";
    },
    typeModel(): boolean {
      return this.inferenceTypeModel?.isFromModel || false;
    },
  },
  methods: {
    selectionChanged(isFromModel: boolean): void {
      this.$emit("update-section-model", { isFromModel });
    },
  },
});
</script>
<style lang="scss" scoped></style>
