/* tslint:disable */
/* eslint-disable */
/**
 * Assets
 * An API for managing assets
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessKey
 */
export interface AccessKey {
    /**
     * 
     * @type {AssetMeta}
     * @memberof AccessKey
     */
    'meta': AssetMeta;
    /**
     * 
     * @type {AccessKeySpec}
     * @memberof AccessKey
     */
    'spec': AccessKeySpec;
    /**
     * 
     * @type {AssetUsageInfo}
     * @memberof AccessKey
     */
    'usedBy'?: AssetUsageInfo | null;
    /**
     * 
     * @type {ComplianceInfo}
     * @memberof AccessKey
     */
    'compliance'?: ComplianceInfo | null;
    /**
     * 
     * @type {AssetClusterStatusInfo}
     * @memberof AccessKey
     */
    'status'?: AssetClusterStatusInfo | null;
}
/**
 * 
 * @export
 * @interface AccessKeyCreationRequest
 */
export interface AccessKeyCreationRequest {
    /**
     * 
     * @type {AssetCreationRequest}
     * @memberof AccessKeyCreationRequest
     */
    'meta': AssetCreationRequest;
    /**
     * 
     * @type {AccessKeyCreationSpec}
     * @memberof AccessKeyCreationRequest
     */
    'spec': AccessKeyCreationSpec;
}
/**
 * 
 * @export
 * @interface AccessKeyCreationSpec
 */
export interface AccessKeyCreationSpec {
    /**
     * optional name of existing secret in the cluster from which the access key id and secret should be taken. If omitted, you will have to provide those credentials in the accessKeyId and secretAccessKey fields. The provided credentials are encrypted into the control plane database and cloned to a kubernetes secret in the cluster.
     * @type {string}
     * @memberof AccessKeyCreationSpec
     */
    'existingSecretName'?: string | null;
    /**
     * The access key id of the S3-compatible bucket, required only when not using existing secret.
     * @type {string}
     * @memberof AccessKeyCreationSpec
     */
    'accessKeyId'?: string | null;
    /**
     * The secret access key of the S3-compatible bucket, required only when not using existing secret.
     * @type {string}
     * @memberof AccessKeyCreationSpec
     */
    'secretAccessKey'?: string | null;
}
/**
 * 
 * @export
 * @interface AccessKeyListResponse
 */
export interface AccessKeyListResponse {
    /**
     * 
     * @type {Array<AccessKey>}
     * @memberof AccessKeyListResponse
     */
    'entries': Array<AccessKey>;
}
/**
 * 
 * @export
 * @interface AccessKeySpec
 */
export interface AccessKeySpec {
    /**
     * optional name of existing secret in the cluster from which the access key id and secret should be taken. If omitted, you will have to provide those credentials in the accessKeyId and secretAccessKey fields. The provided credentials are encrypted into the control plane database and cloned to a kubernetes secret in the cluster.
     * @type {string}
     * @memberof AccessKeySpec
     */
    'existingSecretName'?: string | null;
    /**
     * The access key id of the S3-compatible bucket, required only when not using existing secret.
     * @type {string}
     * @memberof AccessKeySpec
     */
    'accessKeyId'?: string | null;
    /**
     * The secret access key of the S3-compatible bucket, required only when not using existing secret.
     * @type {string}
     * @memberof AccessKeySpec
     */
    'secretAccessKey'?: string | null;
    /**
     * id of the encryption key which has been used to encrypt an asset\'s data.
     * @type {number}
     * @memberof AccessKeySpec
     */
    'keyId': number;
}
/**
 * 
 * @export
 * @interface AccessKeyToAddOrUpdate
 */
export interface AccessKeyToAddOrUpdate {
    /**
     * The name of the asset.
     * @type {string}
     * @memberof AccessKeyToAddOrUpdate
     */
    'name': string;
    /**
     * 
     * @type {AccessKeySpec}
     * @memberof AccessKeyToAddOrUpdate
     */
    'spec': AccessKeySpec;
    /**
     * The checksum of the asset\'s spec.
     * @type {string}
     * @memberof AccessKeyToAddOrUpdate
     */
    'checksum': string;
}
/**
 * 
 * @export
 * @interface AccessKeyUpdateRequest
 */
export interface AccessKeyUpdateRequest {
    /**
     * 
     * @type {AssetUpdateRequest}
     * @memberof AccessKeyUpdateRequest
     */
    'meta': AssetUpdateRequest;
    /**
     * 
     * @type {AccessKeyUpdateSpec}
     * @memberof AccessKeyUpdateRequest
     */
    'spec'?: AccessKeyUpdateSpec | null;
}
/**
 * 
 * @export
 * @interface AccessKeyUpdateSpec
 */
export interface AccessKeyUpdateSpec {
    /**
     * optional name of existing secret in the cluster from which the access key id and secret should be taken. If omitted, you will have to provide those credentials in the accessKeyId and secretAccessKey fields. The provided credentials are encrypted into the control plane database and cloned to a kubernetes secret in the cluster.
     * @type {string}
     * @memberof AccessKeyUpdateSpec
     */
    'existingSecretName'?: string | null;
    /**
     * The access key id of the S3-compatible bucket, required only when not using existing secret.
     * @type {string}
     * @memberof AccessKeyUpdateSpec
     */
    'accessKeyId'?: string | null;
    /**
     * The secret access key of the S3-compatible bucket, required only when not using existing secret.
     * @type {string}
     * @memberof AccessKeyUpdateSpec
     */
    'secretAccessKey'?: string | null;
}
/**
 * A list of updates that needs to be carried out by the cluster in order to sync its access key assets with the control-plane.
 * @export
 * @interface AccessKeysSyncResponse
 */
export interface AccessKeysSyncResponse {
    /**
     * Access keys that needs to be deleted from the cluster.
     * @type {Array<string>}
     * @memberof AccessKeysSyncResponse
     */
    'toDelete'?: Array<string>;
    /**
     * Access keys that needs to be added to the cluster.
     * @type {Array<AccessKeyToAddOrUpdate>}
     * @memberof AccessKeysSyncResponse
     */
    'toAdd'?: Array<AccessKeyToAddOrUpdate>;
    /**
     * Access keys that needs to be updated at the cluster.
     * @type {Array<AccessKeyToAddOrUpdate>}
     * @memberof AccessKeysSyncResponse
     */
    'toUpdate'?: Array<AccessKeyToAddOrUpdate>;
}
/**
 * 
 * @export
 * @interface Advanced
 */
export interface Advanced {
    /**
     * Set of annotations to populate into the container running the workspace.
     * @type {Array<Annotation>}
     * @memberof Advanced
     */
    'annotations'?: Array<Annotation> | null;
    /**
     * Set of labels to populate into the container running the workspace.
     * @type {Array<Label>}
     * @memberof Advanced
     */
    'labels'?: Array<Label> | null;
    /**
     * Indicates whether the job should be terminated, by the system, after it has been preempted.
     * @type {boolean}
     * @memberof Advanced
     */
    'terminateAfterPreemption'?: boolean | null;
    /**
     * Specifies the duration after which a finished workload (Completed or Failed) will be automatically deleted.
     * @type {number}
     * @memberof Advanced
     */
    'autoDeletionTimeAfterCompletionSeconds'?: number | null;
    /**
     * Specifies the number of retries before marking a workload as failed.
     * @type {number}
     * @memberof Advanced
     */
    'backoffLimit'?: number | null;
}
/**
 * 
 * @export
 * @interface AllowOverQuota
 */
export interface AllowOverQuota {
    /**
     * Whether to allow the workload to exceed the quota of the project.
     * @type {boolean}
     * @memberof AllowOverQuota
     */
    'allowOverQuota'?: boolean | null;
}
/**
 * Details of an annotation which is populated into the container.
 * @export
 * @interface Annotation
 */
export interface Annotation {
    /**
     * The name of the annotation.
     * @type {string}
     * @memberof Annotation
     */
    'name': string;
    /**
     * The value to set the annotation to.
     * @type {string}
     * @memberof Annotation
     */
    'value': string;
    /**
     * Whether to exclude this annotation from the workload. This is necessary in case the annotation is inherited from the policy defaults and it is desired not to include it in this workload.
     * @type {boolean}
     * @memberof Annotation
     */
    'exclude'?: boolean;
}
/**
 * 
 * @export
 * @interface AssetClusterStatusInfo
 */
export interface AssetClusterStatusInfo {
    /**
     * 
     * @type {AssetSyncStatusEnum}
     * @memberof AssetClusterStatusInfo
     */
    'status'?: AssetSyncStatusEnum;
    /**
     * 
     * @type {Array<AssetClusterStatusIssue>}
     * @memberof AssetClusterStatusInfo
     */
    'issues'?: Array<AssetClusterStatusIssue>;
    /**
     * 
     * @type {string}
     * @memberof AssetClusterStatusInfo
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetClusterStatusInfo
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface AssetClusterStatusIssue
 */
export interface AssetClusterStatusIssue {
    /**
     * The id of the cluster, department or project, depending on the scope type.
     * @type {string}
     * @memberof AssetClusterStatusIssue
     */
    'scopeId': string;
    /**
     * 
     * @type {ScopeType}
     * @memberof AssetClusterStatusIssue
     */
    'scopeType': ScopeType;
    /**
     * 
     * @type {AssetClusterStatusIssueEnum}
     * @memberof AssetClusterStatusIssue
     */
    'issue': AssetClusterStatusIssueEnum;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AssetClusterStatusIssueEnum = {
    ReplicationError: 'ReplicationError',
    LostPhase: 'LostPhase',
    NotCreated: 'NotCreated'
} as const;

export type AssetClusterStatusIssueEnum = typeof AssetClusterStatusIssueEnum[keyof typeof AssetClusterStatusIssueEnum];


/**
 * 
 * @export
 * @interface AssetCountResponse
 */
export interface AssetCountResponse {
    /**
     * 
     * @type {number}
     * @memberof AssetCountResponse
     */
    'result': number;
}
/**
 * 
 * @export
 * @interface AssetCreationFields
 */
export interface AssetCreationFields {
    /**
     * 
     * @type {Scope}
     * @memberof AssetCreationFields
     */
    'scope': Scope;
    /**
     * The id of the cluster. It is obsolete for asset creation request, left here only for backward compatibility.
     * @type {string}
     * @memberof AssetCreationFields
     */
    'clusterId'?: string | null;
    /**
     * The id of the department. Must be specified for department scoped assets.
     * @type {string}
     * @memberof AssetCreationFields
     */
    'departmentId'?: string | null;
    /**
     * The id of the project. Must be specified for project scoped assets.
     * @type {number}
     * @memberof AssetCreationFields
     */
    'projectId'?: number | null;
    /**
     * The asset will be deleted automatically. This is intended for internal use.
     * @type {boolean}
     * @memberof AssetCreationFields
     */
    'autoDelete'?: boolean | null;
    /**
     * 
     * @type {WorkloadSupportedTypes}
     * @memberof AssetCreationFields
     */
    'workloadSupportedTypes'?: WorkloadSupportedTypes | null;
}
/**
 * 
 * @export
 * @interface AssetCreationRequest
 */
export interface AssetCreationRequest {
    /**
     * The name of the asset.
     * @type {string}
     * @memberof AssetCreationRequest
     */
    'name': string;
    /**
     * 
     * @type {Scope}
     * @memberof AssetCreationRequest
     */
    'scope': Scope;
    /**
     * The id of the cluster. It is obsolete for asset creation request, left here only for backward compatibility.
     * @type {string}
     * @memberof AssetCreationRequest
     */
    'clusterId'?: string | null;
    /**
     * The id of the department. Must be specified for department scoped assets.
     * @type {string}
     * @memberof AssetCreationRequest
     */
    'departmentId'?: string | null;
    /**
     * The id of the project. Must be specified for project scoped assets.
     * @type {number}
     * @memberof AssetCreationRequest
     */
    'projectId'?: number | null;
    /**
     * The asset will be deleted automatically. This is intended for internal use.
     * @type {boolean}
     * @memberof AssetCreationRequest
     */
    'autoDelete'?: boolean | null;
    /**
     * 
     * @type {WorkloadSupportedTypes}
     * @memberof AssetCreationRequest
     */
    'workloadSupportedTypes'?: WorkloadSupportedTypes | null;
}
/**
 * Asset id and its kind.
 * @export
 * @interface AssetIdAndKind
 */
export interface AssetIdAndKind {
    /**
     * Unique identifier of the asset.
     * @type {string}
     * @memberof AssetIdAndKind
     */
    'id': string;
    /**
     * 
     * @type {AssetKind}
     * @memberof AssetIdAndKind
     */
    'kind': AssetKind;
}
/**
 * The kind of the asset.
 * @export
 * @enum {string}
 */

export const AssetKind = {
    Compute: 'compute',
    Environment: 'environment',
    AccessKey: 'accessKey',
    DockerRegistry: 'dockerRegistry',
    Password: 'password',
    Registry: 'registry',
    S3: 's3',
    Git: 'git',
    Nfs: 'nfs',
    Pvc: 'pvc',
    HostPath: 'hostPath',
    WorkloadTemplate: 'workload-template',
    Model: 'model',
    ConfigMap: 'config-map'
} as const;

export type AssetKind = typeof AssetKind[keyof typeof AssetKind];


/**
 * 
 * @export
 * @interface AssetListByIdsResponse
 */
export interface AssetListByIdsResponse {
    /**
     * 
     * @type {Array<AssetMeta>}
     * @memberof AssetListByIdsResponse
     */
    'entries': Array<AssetMeta>;
}
/**
 * 
 * @export
 * @interface AssetMeta
 */
export interface AssetMeta {
    /**
     * The name of the asset.
     * @type {string}
     * @memberof AssetMeta
     */
    'name': string;
    /**
     * 
     * @type {Scope}
     * @memberof AssetMeta
     */
    'scope': Scope;
    /**
     * The id of the cluster. It is obsolete for asset creation request, left here only for backward compatibility.
     * @type {string}
     * @memberof AssetMeta
     */
    'clusterId'?: string | null;
    /**
     * The id of the department. Must be specified for department scoped assets.
     * @type {string}
     * @memberof AssetMeta
     */
    'departmentId'?: string | null;
    /**
     * The id of the project. Must be specified for project scoped assets.
     * @type {number}
     * @memberof AssetMeta
     */
    'projectId'?: number | null;
    /**
     * The asset will be deleted automatically. This is intended for internal use.
     * @type {boolean}
     * @memberof AssetMeta
     */
    'autoDelete'?: boolean | null;
    /**
     * 
     * @type {WorkloadSupportedTypes}
     * @memberof AssetMeta
     */
    'workloadSupportedTypes'?: WorkloadSupportedTypes | null;
    /**
     * Unique identifier of the asset.
     * @type {string}
     * @memberof AssetMeta
     */
    'id': string;
    /**
     * 
     * @type {AssetKind}
     * @memberof AssetMeta
     */
    'kind': AssetKind;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof AssetMeta
     */
    'tenantId'?: number | null;
    /**
     * The user who created the asset.
     * @type {string}
     * @memberof AssetMeta
     */
    'createdBy': string;
    /**
     * The time at which the asset were created
     * @type {string}
     * @memberof AssetMeta
     */
    'createdAt': string;
    /**
     * The user who updated the asset.
     * @type {string}
     * @memberof AssetMeta
     */
    'updatedBy': string;
    /**
     * The time at which the asset has been updated
     * @type {string}
     * @memberof AssetMeta
     */
    'updatedAt': string;
    /**
     * The name of the project that the asset is associated with, for project scoped assets.
     * @type {string}
     * @memberof AssetMeta
     */
    'projectName'?: string | null;
}
/**
 * The name of the asset and the checksum of its spec.
 * @export
 * @interface AssetNameAndChecksum
 */
export interface AssetNameAndChecksum {
    /**
     * The name of the asset.
     * @type {string}
     * @memberof AssetNameAndChecksum
     */
    'name': string;
    /**
     * The checksum of the asset\'s spec.
     * @type {string}
     * @memberof AssetNameAndChecksum
     */
    'checksum': string;
}
/**
 * 
 * @export
 * @interface AssetReadOnlyFields
 */
export interface AssetReadOnlyFields {
    /**
     * Unique identifier of the asset.
     * @type {string}
     * @memberof AssetReadOnlyFields
     */
    'id': string;
    /**
     * 
     * @type {AssetKind}
     * @memberof AssetReadOnlyFields
     */
    'kind': AssetKind;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof AssetReadOnlyFields
     */
    'tenantId'?: number | null;
    /**
     * The user who created the asset.
     * @type {string}
     * @memberof AssetReadOnlyFields
     */
    'createdBy': string;
    /**
     * The time at which the asset were created
     * @type {string}
     * @memberof AssetReadOnlyFields
     */
    'createdAt': string;
    /**
     * The user who updated the asset.
     * @type {string}
     * @memberof AssetReadOnlyFields
     */
    'updatedBy': string;
    /**
     * The time at which the asset has been updated
     * @type {string}
     * @memberof AssetReadOnlyFields
     */
    'updatedAt': string;
    /**
     * The name of the project that the asset is associated with, for project scoped assets.
     * @type {string}
     * @memberof AssetReadOnlyFields
     */
    'projectName'?: string | null;
}
/**
 * Reference information about an asset.
 * @export
 * @interface AssetRef
 */
export interface AssetRef {
    /**
     * Unique identifier of the asset.
     * @type {string}
     * @memberof AssetRef
     */
    'id': string;
    /**
     * The name of the asset.
     * @type {string}
     * @memberof AssetRef
     */
    'name': string;
}
/**
 * The status of the asset in the cluster.
 * @export
 * @enum {string}
 */

export const AssetStatus = {
    Ready: 'Ready',
    NotReady: 'Not ready'
} as const;

export type AssetStatus = typeof AssetStatus[keyof typeof AssetStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const AssetSyncStatusEnum = {
    IssuesFound: 'Issues found',
    NoIssuesFound: 'No issues found',
    NoStatus: 'No status'
} as const;

export type AssetSyncStatusEnum = typeof AssetSyncStatusEnum[keyof typeof AssetSyncStatusEnum];


/**
 * 
 * @export
 * @interface AssetUpdatableFields
 */
export interface AssetUpdatableFields {
    /**
     * The name of the asset.
     * @type {string}
     * @memberof AssetUpdatableFields
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AssetUpdateRequest
 */
export interface AssetUpdateRequest {
    /**
     * The name of the asset.
     * @type {string}
     * @memberof AssetUpdateRequest
     */
    'name': string;
}
/**
 * Details about resources that use the asset.
 * @export
 * @interface AssetUsageInfo
 */
export interface AssetUsageInfo {
    /**
     * workspaces that rely on this asset.
     * @type {Array<WorkloadRefAndStatus>}
     * @memberof AssetUsageInfo
     */
    'workspaces'?: Array<WorkloadRefAndStatus>;
    /**
     * trainings that rely on this asset.
     * @type {Array<WorkloadRefAndStatus>}
     * @memberof AssetUsageInfo
     */
    'trainings'?: Array<WorkloadRefAndStatus>;
    /**
     * inferences that rely on this asset.
     * @type {Array<WorkloadRefAndStatus>}
     * @memberof AssetUsageInfo
     */
    'inferences'?: Array<WorkloadRefAndStatus>;
    /**
     * templates that rely on this asset.
     * @type {Array<AssetRef>}
     * @memberof AssetUsageInfo
     */
    'templates'?: Array<AssetRef>;
    /**
     * 
     * @type {AssetsUsageRef}
     * @memberof AssetUsageInfo
     */
    'assets'?: AssetsUsageRef;
}
/**
 * Set of assets comprising a workspace or a workspace template.
 * @export
 * @interface AssetsIds
 */
export interface AssetsIds {
    /**
     * Unique identifier of the asset.
     * @type {string}
     * @memberof AssetsIds
     */
    'environment': string;
    /**
     * 
     * @type {string}
     * @memberof AssetsIds
     */
    'compute'?: string | null;
    /**
     * 
     * @type {Array<AssetIdAndKind>}
     * @memberof AssetsIds
     */
    'datasources'?: Array<AssetIdAndKind>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetsIds
     */
    'workloadVolumes'?: Array<string>;
}
/**
 * Reference information about a set of assets. used to describe - assets comprising a workspace - assets comprising a workspace template - assets that use other assets (e.g. s3 asset which uses access key).
 * @export
 * @interface AssetsRef
 */
export interface AssetsRef {
    /**
     * 
     * @type {AssetsRefEnvironment}
     * @memberof AssetsRef
     */
    'environment': AssetsRefEnvironment;
    /**
     * 
     * @type {AssetsRefCompute}
     * @memberof AssetsRef
     */
    'compute'?: AssetsRefCompute | null;
    /**
     * 
     * @type {Array<DatasourceRef>}
     * @memberof AssetsRef
     */
    'datasources'?: Array<DatasourceRef>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetsRef
     */
    'workloadVolumes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AssetsRefCompute
 */
export interface AssetsRefCompute {
    /**
     * Unique identifier of the asset.
     * @type {string}
     * @memberof AssetsRefCompute
     */
    'id': string;
    /**
     * The name of the asset.
     * @type {string}
     * @memberof AssetsRefCompute
     */
    'name': string;
}
/**
 * environment asset.
 * @export
 * @interface AssetsRefEnvironment
 */
export interface AssetsRefEnvironment {
    /**
     * Unique identifier of the asset.
     * @type {string}
     * @memberof AssetsRefEnvironment
     */
    'id': string;
    /**
     * The name of the asset.
     * @type {string}
     * @memberof AssetsRefEnvironment
     */
    'name': string;
    /**
     * the type of tools as defined in the connections of the environment asset.
     * @type {Array<ToolType>}
     * @memberof AssetsRefEnvironment
     */
    'toolTypes'?: Array<ToolType> | null;
}
/**
 * Reference information about usage of assets by other assets, for example datasources that uses credentials.
 * @export
 * @interface AssetsUsageRef
 */
export interface AssetsUsageRef {
    /**
     * 
     * @type {AssetsUsageRefEnvironment}
     * @memberof AssetsUsageRef
     */
    'environment'?: AssetsUsageRefEnvironment | null;
    /**
     * 
     * @type {AssetsRefCompute}
     * @memberof AssetsUsageRef
     */
    'compute'?: AssetsRefCompute | null;
    /**
     * 
     * @type {Array<DatasourceRef>}
     * @memberof AssetsUsageRef
     */
    'datasources'?: Array<DatasourceRef>;
}
/**
 * environment asset.
 * @export
 * @interface AssetsUsageRefEnvironment
 */
export interface AssetsUsageRefEnvironment {
    /**
     * Unique identifier of the asset.
     * @type {string}
     * @memberof AssetsUsageRefEnvironment
     */
    'id': string;
    /**
     * The name of the asset.
     * @type {string}
     * @memberof AssetsUsageRefEnvironment
     */
    'name': string;
    /**
     * the type of tools as defined in the connections of the environment asset.
     * @type {Array<ToolType>}
     * @memberof AssetsUsageRefEnvironment
     */
    'toolTypes'?: Array<ToolType> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Capability = {
    AuditControl: 'AUDIT_CONTROL',
    AuditRead: 'AUDIT_READ',
    AuditWrite: 'AUDIT_WRITE',
    BlockSuspend: 'BLOCK_SUSPEND',
    Chown: 'CHOWN',
    DacOverride: 'DAC_OVERRIDE',
    DacReadSearch: 'DAC_READ_SEARCH',
    Fowner: 'FOWNER',
    Fsetid: 'FSETID',
    IpcLock: 'IPC_LOCK',
    IpcOwner: 'IPC_OWNER',
    Kill: 'KILL',
    Lease: 'LEASE',
    LinuxImmutable: 'LINUX_IMMUTABLE',
    MacAdmin: 'MAC_ADMIN',
    MacOverride: 'MAC_OVERRIDE',
    Mknod: 'MKNOD',
    NetAdmin: 'NET_ADMIN',
    NetBindService: 'NET_BIND_SERVICE',
    NetBroadcast: 'NET_BROADCAST',
    NetRaw: 'NET_RAW',
    Setgid: 'SETGID',
    Setfcap: 'SETFCAP',
    Setpcap: 'SETPCAP',
    Setuid: 'SETUID',
    SysAdmin: 'SYS_ADMIN',
    SysBoot: 'SYS_BOOT',
    SysChroot: 'SYS_CHROOT',
    SysModule: 'SYS_MODULE',
    SysNice: 'SYS_NICE',
    SysPacct: 'SYS_PACCT',
    SysPtrace: 'SYS_PTRACE',
    SysRawio: 'SYS_RAWIO',
    SysResource: 'SYS_RESOURCE',
    SysTime: 'SYS_TIME',
    SysTtyConfig: 'SYS_TTY_CONFIG',
    Syslog: 'SYSLOG',
    WakeAlarm: 'WAKE_ALARM'
} as const;

export type Capability = typeof Capability[keyof typeof Capability];


/**
 * Claim information for the newly created PVC. The information should not be provided when attempting to use existing PVC.
 * @export
 * @interface ClaimInfo
 */
export interface ClaimInfo {
    /**
     * Requested size for the PVC. Mandatory when existingPvc is false.
     * @type {string}
     * @memberof ClaimInfo
     */
    'size': string | null;
    /**
     * Storage class name to associate with the PVC. This parameter may be omitted if there is a single storage class in the system, or you are using the default storage class. Further details at https://kubernetes.io/docs/concepts/storage/storage-classes.
     * @type {string}
     * @memberof ClaimInfo
     */
    'storageClass'?: string | null;
    /**
     * 
     * @type {PvcAccessModes}
     * @memberof ClaimInfo
     */
    'accessModes'?: PvcAccessModes | null;
    /**
     * The volume mode required by the claim, either Filesystem (default) or Block.
     * @type {string}
     * @memberof ClaimInfo
     */
    'volumeMode'?: ClaimInfoVolumeModeEnum;
}

export const ClaimInfoVolumeModeEnum = {
    Filesystem: 'Filesystem',
    Block: 'Block'
} as const;

export type ClaimInfoVolumeModeEnum = typeof ClaimInfoVolumeModeEnum[keyof typeof ClaimInfoVolumeModeEnum];

/**
 * Compliance of the asset with creation of workloads in the given project.
 * @export
 * @interface ComplianceInfo
 */
export interface ComplianceInfo {
    /**
     * Whether the asset is imposed on created workloads by the administrator.
     * @type {boolean}
     * @memberof ComplianceInfo
     */
    'imposed'?: boolean;
    /**
     * Whether the asset info complies with creation of workloads in the project.
     * @type {boolean}
     * @memberof ComplianceInfo
     */
    'compliance'?: boolean;
    /**
     * For asset that does not comply, specify one or more reason why.
     * @type {Array<ComplianceInfoReason>}
     * @memberof ComplianceInfo
     */
    'reason'?: Array<ComplianceInfoReason>;
}
/**
 * A reason explaining why the asset does not comply with workloads creation for a given project.
 * @export
 * @interface ComplianceInfoReason
 */
export interface ComplianceInfoReason {
    /**
     * for policy related issues, includes a json path to the field in the policy for which the message applies.
     * @type {string}
     * @memberof ComplianceInfoReason
     */
    'field'?: string | null;
    /**
     * explanation of the reason for rejecting the asset.
     * @type {string}
     * @memberof ComplianceInfoReason
     */
    'details': string;
}
/**
 * 
 * @export
 * @interface ComputeAsset
 */
export interface ComputeAsset {
    /**
     * 
     * @type {AssetMeta}
     * @memberof ComputeAsset
     */
    'meta': AssetMeta;
    /**
     * 
     * @type {ComputeAssetSpec}
     * @memberof ComputeAsset
     */
    'spec': ComputeAssetSpec;
    /**
     * 
     * @type {AssetUsageInfo}
     * @memberof ComputeAsset
     */
    'usedBy'?: AssetUsageInfo | null;
    /**
     * 
     * @type {UsageTimesInfo}
     * @memberof ComputeAsset
     */
    'usageTimes'?: UsageTimesInfo | null;
    /**
     * 
     * @type {ComplianceInfo}
     * @memberof ComputeAsset
     */
    'compliance'?: ComplianceInfo | null;
}
/**
 * 
 * @export
 * @interface ComputeAssetSpec
 */
export interface ComputeAssetSpec {
    /**
     * Requested number of GPU devices. Currently if more than one device is requested, it is not possible to provide values for gpuMemory/migProfile/gpuPortion.
     * @type {number}
     * @memberof ComputeAssetSpec
     */
    'gpuDevicesRequest'?: number | null;
    /**
     * 
     * @type {GpuRequestType}
     * @memberof ComputeAssetSpec
     */
    'gpuRequestType'?: GpuRequestType | null;
    /**
     * Required if and only if gpuRequestType is portion. States the portion of the GPU to allocate for the created workload, per GPU device, between 0 and 1. The default is no allocated GPUs.
     * @type {number}
     * @memberof ComputeAssetSpec
     */
    'gpuPortionRequest'?: number | null;
    /**
     * Limitations on the portion consumed by the workload, per GPU device. The system guarantees The gpuPotionLimit must be no less than the gpuPortionRequest.
     * @type {number}
     * @memberof ComputeAssetSpec
     */
    'gpuPortionLimit'?: number | null;
    /**
     * Required if and only if gpuRequestType is memory. States the GPU memory to allocate for the created workload, per GPU device. Note that the workload will not be scheduled unless the system can guarantee this amount of GPU memory to the workload.
     * @type {string}
     * @memberof ComputeAssetSpec
     */
    'gpuMemoryRequest'?: string | null;
    /**
     * Limitation on the memory consumed by the workload, per GPU device. The system guarantees The gpuMemoryLimit must be no less than gpuMemoryRequest.
     * @type {string}
     * @memberof ComputeAssetSpec
     */
    'gpuMemoryLimit'?: string | null;
    /**
     * 
     * @type {MigProfile}
     * @memberof ComputeAssetSpec
     */
    'migProfile'?: MigProfile | null;
    /**
     * CPU units to allocate for the created workload (0.5, 1, .etc). The workload will receive at least this amount of CPU. Note that the workload will not be scheduled unless the system can guarantee this amount of CPUs to the workload.
     * @type {number}
     * @memberof ComputeAssetSpec
     */
    'cpuCoreRequest'?: number | null;
    /**
     * Limitations on the number of CPUs consumed by the workload (0.5, 1, .etc). The system guarantees that this workload will not be able to consume more than this amount of CPUs.
     * @type {number}
     * @memberof ComputeAssetSpec
     */
    'cpuCoreLimit'?: number | null;
    /**
     * The amount of CPU memory to allocate for this workload (1G, 20M, .etc). The workload will receive at least this amount of memory. Note that the workload will not be scheduled unless the system can guarantee this amount of memory to the workload
     * @type {string}
     * @memberof ComputeAssetSpec
     */
    'cpuMemoryRequest'?: string | null;
    /**
     * Limitations on the CPU memory to allocate for this workload (1G, 20M, .etc). The system guarantees that this workload will not be able to consume more than this amount of memory. The workload will receive an error when trying to allocate more memory than this limit.
     * @type {string}
     * @memberof ComputeAssetSpec
     */
    'cpuMemoryLimit'?: string | null;
    /**
     * A large /dev/shm device to mount into a container running the created workload. An shm is a shared file system mounted on RAM.
     * @type {boolean}
     * @memberof ComputeAssetSpec
     */
    'largeShmRequest'?: boolean | null;
    /**
     * Set of extended resources with their quantity
     * @type {Array<ExtendedResource>}
     * @memberof ComputeAssetSpec
     */
    'extendedResources'?: Array<ExtendedResource> | null;
}
/**
 * 
 * @export
 * @interface ComputeCreationRequest
 */
export interface ComputeCreationRequest {
    /**
     * 
     * @type {AssetCreationRequest}
     * @memberof ComputeCreationRequest
     */
    'meta': AssetCreationRequest;
    /**
     * 
     * @type {ComputeAssetSpec}
     * @memberof ComputeCreationRequest
     */
    'spec': ComputeAssetSpec;
}
/**
 * 
 * @export
 * @interface ComputeFields
 */
export interface ComputeFields {
    /**
     * Requested number of GPU devices. Currently if more than one device is requested, it is not possible to provide values for gpuMemory/migProfile/gpuPortion.
     * @type {number}
     * @memberof ComputeFields
     */
    'gpuDevicesRequest'?: number | null;
    /**
     * 
     * @type {GpuRequestType}
     * @memberof ComputeFields
     */
    'gpuRequestType'?: GpuRequestType | null;
    /**
     * Required if and only if gpuRequestType is portion. States the portion of the GPU to allocate for the created workload, per GPU device, between 0 and 1. The default is no allocated GPUs.
     * @type {number}
     * @memberof ComputeFields
     */
    'gpuPortionRequest'?: number | null;
    /**
     * Limitations on the portion consumed by the workload, per GPU device. The system guarantees The gpuPotionLimit must be no less than the gpuPortionRequest.
     * @type {number}
     * @memberof ComputeFields
     */
    'gpuPortionLimit'?: number | null;
    /**
     * Required if and only if gpuRequestType is memory. States the GPU memory to allocate for the created workload, per GPU device. Note that the workload will not be scheduled unless the system can guarantee this amount of GPU memory to the workload.
     * @type {string}
     * @memberof ComputeFields
     */
    'gpuMemoryRequest'?: string | null;
    /**
     * Limitation on the memory consumed by the workload, per GPU device. The system guarantees The gpuMemoryLimit must be no less than gpuMemoryRequest.
     * @type {string}
     * @memberof ComputeFields
     */
    'gpuMemoryLimit'?: string | null;
    /**
     * 
     * @type {MigProfile}
     * @memberof ComputeFields
     */
    'migProfile'?: MigProfile | null;
    /**
     * CPU units to allocate for the created workload (0.5, 1, .etc). The workload will receive at least this amount of CPU. Note that the workload will not be scheduled unless the system can guarantee this amount of CPUs to the workload.
     * @type {number}
     * @memberof ComputeFields
     */
    'cpuCoreRequest'?: number | null;
    /**
     * Limitations on the number of CPUs consumed by the workload (0.5, 1, .etc). The system guarantees that this workload will not be able to consume more than this amount of CPUs.
     * @type {number}
     * @memberof ComputeFields
     */
    'cpuCoreLimit'?: number | null;
    /**
     * The amount of CPU memory to allocate for this workload (1G, 20M, .etc). The workload will receive at least this amount of memory. Note that the workload will not be scheduled unless the system can guarantee this amount of memory to the workload
     * @type {string}
     * @memberof ComputeFields
     */
    'cpuMemoryRequest'?: string | null;
    /**
     * Limitations on the CPU memory to allocate for this workload (1G, 20M, .etc). The system guarantees that this workload will not be able to consume more than this amount of memory. The workload will receive an error when trying to allocate more memory than this limit.
     * @type {string}
     * @memberof ComputeFields
     */
    'cpuMemoryLimit'?: string | null;
    /**
     * A large /dev/shm device to mount into a container running the created workload. An shm is a shared file system mounted on RAM.
     * @type {boolean}
     * @memberof ComputeFields
     */
    'largeShmRequest'?: boolean | null;
    /**
     * Set of extended resources with their quantity
     * @type {Array<ExtendedResource>}
     * @memberof ComputeFields
     */
    'extendedResources'?: Array<ExtendedResource> | null;
}
/**
 * 
 * @export
 * @interface ComputeListResponse
 */
export interface ComputeListResponse {
    /**
     * 
     * @type {Array<ComputeAsset>}
     * @memberof ComputeListResponse
     */
    'entries': Array<ComputeAsset>;
}
/**
 * 
 * @export
 * @interface ComputeUpdateRequest
 */
export interface ComputeUpdateRequest {
    /**
     * 
     * @type {AssetUpdateRequest}
     * @memberof ComputeUpdateRequest
     */
    'meta': AssetUpdateRequest;
    /**
     * 
     * @type {ComputeAssetSpec}
     * @memberof ComputeUpdateRequest
     */
    'spec': ComputeAssetSpec;
}
/**
 * 
 * @export
 * @interface ConfigMap
 */
export interface ConfigMap {
    /**
     * The name of the ConfigMap resource (mandatory)
     * @type {string}
     * @memberof ConfigMap
     */
    'configMap': string | null;
    /**
     * Local path within the workspace to which the ConfigMap will be mapped to (mandatory)
     * @type {string}
     * @memberof ConfigMap
     */
    'mountPath': string | null;
}
/**
 * 
 * @export
 * @interface ConfigMapAsset
 */
export interface ConfigMapAsset {
    /**
     * 
     * @type {AssetMeta}
     * @memberof ConfigMapAsset
     */
    'meta': AssetMeta;
    /**
     * 
     * @type {ConfigMapAssetSpec}
     * @memberof ConfigMapAsset
     */
    'spec': ConfigMapAssetSpec;
    /**
     * 
     * @type {AssetUsageInfo}
     * @memberof ConfigMapAsset
     */
    'usedBy'?: AssetUsageInfo | null;
    /**
     * 
     * @type {UsageTimesInfo}
     * @memberof ConfigMapAsset
     */
    'usageTimes'?: UsageTimesInfo | null;
    /**
     * 
     * @type {ComplianceInfo}
     * @memberof ConfigMapAsset
     */
    'compliance'?: ComplianceInfo | null;
    /**
     * 
     * @type {AssetClusterStatusInfo}
     * @memberof ConfigMapAsset
     */
    'status'?: AssetClusterStatusInfo | null;
}
/**
 * 
 * @export
 * @interface ConfigMapAssetSpec
 */
export interface ConfigMapAssetSpec {
    /**
     * The name of the ConfigMap resource (mandatory)
     * @type {string}
     * @memberof ConfigMapAssetSpec
     */
    'configMap': string | null;
    /**
     * Local path within the workspace to which the ConfigMap will be mapped to (mandatory)
     * @type {string}
     * @memberof ConfigMapAssetSpec
     */
    'mountPath': string | null;
}
/**
 * 
 * @export
 * @interface ConfigMapCreationRequest
 */
export interface ConfigMapCreationRequest {
    /**
     * 
     * @type {AssetCreationRequest}
     * @memberof ConfigMapCreationRequest
     */
    'meta': AssetCreationRequest;
    /**
     * 
     * @type {ConfigMapAssetSpec}
     * @memberof ConfigMapCreationRequest
     */
    'spec': ConfigMapAssetSpec;
}
/**
 * 
 * @export
 * @interface ConfigMapListResponse
 */
export interface ConfigMapListResponse {
    /**
     * 
     * @type {Array<ConfigMapAsset>}
     * @memberof ConfigMapListResponse
     */
    'entries': Array<ConfigMapAsset>;
}
/**
 * 
 * @export
 * @interface ConfigMapUpdateRequest
 */
export interface ConfigMapUpdateRequest {
    /**
     * 
     * @type {AssetUpdateRequest}
     * @memberof ConfigMapUpdateRequest
     */
    'meta': AssetUpdateRequest;
    /**
     * 
     * @type {ConfigMapAssetSpec}
     * @memberof ConfigMapUpdateRequest
     */
    'spec': ConfigMapAssetSpec;
}
/**
 * 
 * @export
 * @interface Connection
 */
export interface Connection {
    /**
     * A unique name of this connection. This name correlates between the connection information specified at the environment asset, to the information about the connection as specified in SpecificEnv for a specific workspace.
     * @type {string}
     * @memberof Connection
     */
    'name': string;
    /**
     * Internal tools (isExternal=false) are tools that run as part of the container. External tools (isExternal=true) run outside the container, typically in the cloud.
     * @type {boolean}
     * @memberof Connection
     */
    'isExternal'?: boolean;
    /**
     * 
     * @type {InternalToolInfo}
     * @memberof Connection
     */
    'internalToolInfo'?: InternalToolInfo | null;
    /**
     * 
     * @type {ExternalToolInfo}
     * @memberof Connection
     */
    'externalToolInfo'?: ExternalToolInfo | null;
}
/**
 * Container related fields. When used in assets context, those are environment asset fields that can be override in the submit workload request.
 * @export
 * @interface ContainerOverridable
 */
export interface ContainerOverridable {
    /**
     * A command to server as the entry point of the container running the workspace.
     * @type {string}
     * @memberof ContainerOverridable
     */
    'command'?: string | null;
    /**
     * Arguments to the command that the container running the workspace executes.
     * @type {string}
     * @memberof ContainerOverridable
     */
    'args'?: string | null;
    /**
     * Set of environment variables to populate into the container running the workspace.
     * @type {Array<EnvironmentVariable>}
     * @memberof ContainerOverridable
     */
    'environmentVariables'?: Array<EnvironmentVariable> | null;
}
/**
 * The kind of credential used.
 * @export
 * @enum {string}
 */

export const CredentialKind = {
    Password: 'password'
} as const;

export type CredentialKind = typeof CredentialKind[keyof typeof CredentialKind];


/**
 * 
 * @export
 * @interface CredentialsListResponse
 */
export interface CredentialsListResponse {
    /**
     * 
     * @type {Array<CredentialsListResponseEntry>}
     * @memberof CredentialsListResponse
     */
    'entries': Array<CredentialsListResponseEntry>;
}
/**
 * 
 * @export
 * @interface CredentialsListResponseAssetSpec
 */
export interface CredentialsListResponseAssetSpec {
    /**
     * 
     * @type {CredentialsListResponseAssetSpecAccessKey}
     * @memberof CredentialsListResponseAssetSpec
     */
    'accessKey'?: CredentialsListResponseAssetSpecAccessKey | null;
    /**
     * 
     * @type {CredentialsListResponseAssetSpecPassword}
     * @memberof CredentialsListResponseAssetSpec
     */
    'password'?: CredentialsListResponseAssetSpecPassword | null;
    /**
     * 
     * @type {CredentialsListResponseAssetSpecDockerRegistry}
     * @memberof CredentialsListResponseAssetSpec
     */
    'dockerRegistry'?: CredentialsListResponseAssetSpecDockerRegistry | null;
}
/**
 * 
 * @export
 * @interface CredentialsListResponseAssetSpecAccessKey
 */
export interface CredentialsListResponseAssetSpecAccessKey {
    /**
     * optional name of existing secret in the cluster from which the access key id and secret should be taken. If omitted, you will have to provide those credentials in the accessKeyId and secretAccessKey fields. The provided credentials are encrypted into the control plane database and cloned to a kubernetes secret in the cluster.
     * @type {string}
     * @memberof CredentialsListResponseAssetSpecAccessKey
     */
    'existingSecretName'?: string | null;
    /**
     * The access key id of the S3-compatible bucket, required only when not using existing secret.
     * @type {string}
     * @memberof CredentialsListResponseAssetSpecAccessKey
     */
    'accessKeyId'?: string | null;
    /**
     * The secret access key of the S3-compatible bucket, required only when not using existing secret.
     * @type {string}
     * @memberof CredentialsListResponseAssetSpecAccessKey
     */
    'secretAccessKey'?: string | null;
    /**
     * id of the encryption key which has been used to encrypt an asset\'s data.
     * @type {number}
     * @memberof CredentialsListResponseAssetSpecAccessKey
     */
    'keyId': number;
}
/**
 * 
 * @export
 * @interface CredentialsListResponseAssetSpecDockerRegistry
 */
export interface CredentialsListResponseAssetSpecDockerRegistry {
    /**
     * optional name of existing secret in the cluster from which the docker registry details should be taken. If omitted, you will have to provide those credentials and url in relevant fields. The provided credentials are encrypted into the control plane database and cloned to a kubernetes secret in the cluster, along with the url.
     * @type {string}
     * @memberof CredentialsListResponseAssetSpecDockerRegistry
     */
    'existingSecretName'?: string | null;
    /**
     * The name of the user, required only when not using existing secret.
     * @type {string}
     * @memberof CredentialsListResponseAssetSpecDockerRegistry
     */
    'user'?: string | null;
    /**
     * The password, required only when not using existing secret.
     * @type {string}
     * @memberof CredentialsListResponseAssetSpecDockerRegistry
     */
    'password'?: string | null;
    /**
     * The url, required only when not using existing secret.
     * @type {string}
     * @memberof CredentialsListResponseAssetSpecDockerRegistry
     */
    'url'?: string | null;
    /**
     * id of the encryption key which has been used to encrypt an asset\'s data.
     * @type {number}
     * @memberof CredentialsListResponseAssetSpecDockerRegistry
     */
    'keyId': number;
}
/**
 * 
 * @export
 * @interface CredentialsListResponseAssetSpecPassword
 */
export interface CredentialsListResponseAssetSpecPassword {
    /**
     * optional name of existing secret in the cluster from which the user name and password should be taken. If omitted, you will have to provide those credentials in the user and password fields. The provided credentials are encrypted into the control plane database and cloned to a kubernetes secret in the cluster.
     * @type {string}
     * @memberof CredentialsListResponseAssetSpecPassword
     */
    'existingSecretName'?: string | null;
    /**
     * The name of the user, required only when not using existing secret.
     * @type {string}
     * @memberof CredentialsListResponseAssetSpecPassword
     */
    'user'?: string | null;
    /**
     * The password, required only when not using existing secret.
     * @type {string}
     * @memberof CredentialsListResponseAssetSpecPassword
     */
    'password'?: string | null;
    /**
     * id of the encryption key which has been used to encrypt an asset\'s data.
     * @type {number}
     * @memberof CredentialsListResponseAssetSpecPassword
     */
    'keyId': number;
}
/**
 * 
 * @export
 * @interface CredentialsListResponseEntry
 */
export interface CredentialsListResponseEntry {
    /**
     * 
     * @type {AssetMeta}
     * @memberof CredentialsListResponseEntry
     */
    'meta': AssetMeta;
    /**
     * 
     * @type {CredentialsListResponseAssetSpec}
     * @memberof CredentialsListResponseEntry
     */
    'spec': CredentialsListResponseAssetSpec;
    /**
     * 
     * @type {AssetUsageInfo}
     * @memberof CredentialsListResponseEntry
     */
    'usedBy'?: AssetUsageInfo | null;
    /**
     * 
     * @type {AssetClusterStatusInfo}
     * @memberof CredentialsListResponseEntry
     */
    'status'?: AssetClusterStatusInfo | null;
}
/**
 * 
 * @export
 * @interface DatasourceListResponse
 */
export interface DatasourceListResponse {
    /**
     * 
     * @type {Array<DatasourceListResponseEntry>}
     * @memberof DatasourceListResponse
     */
    'entries': Array<DatasourceListResponseEntry>;
}
/**
 * 
 * @export
 * @interface DatasourceListResponseAssetSpec
 */
export interface DatasourceListResponseAssetSpec {
    /**
     * 
     * @type {DatasourceListResponseAssetSpecHostPath}
     * @memberof DatasourceListResponseAssetSpec
     */
    'hostPath'?: DatasourceListResponseAssetSpecHostPath | null;
    /**
     * 
     * @type {DatasourceListResponseAssetSpecNfs}
     * @memberof DatasourceListResponseAssetSpec
     */
    'nfs'?: DatasourceListResponseAssetSpecNfs | null;
    /**
     * 
     * @type {DatasourceListResponseAssetSpecPvc}
     * @memberof DatasourceListResponseAssetSpec
     */
    'pvc'?: DatasourceListResponseAssetSpecPvc | null;
    /**
     * 
     * @type {DatasourceListResponseAssetSpecGit}
     * @memberof DatasourceListResponseAssetSpec
     */
    'git'?: DatasourceListResponseAssetSpecGit | null;
    /**
     * 
     * @type {DatasourceListResponseAssetSpecS3}
     * @memberof DatasourceListResponseAssetSpec
     */
    's3'?: DatasourceListResponseAssetSpecS3 | null;
    /**
     * 
     * @type {DatasourceListResponseAssetSpecConfigMap}
     * @memberof DatasourceListResponseAssetSpec
     */
    'config_map'?: DatasourceListResponseAssetSpecConfigMap | null;
}
/**
 * 
 * @export
 * @interface DatasourceListResponseAssetSpecConfigMap
 */
export interface DatasourceListResponseAssetSpecConfigMap {
    /**
     * The name of the ConfigMap resource (mandatory)
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecConfigMap
     */
    'configMap': string | null;
    /**
     * Local path within the workspace to which the ConfigMap will be mapped to (mandatory)
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecConfigMap
     */
    'mountPath': string | null;
}
/**
 * 
 * @export
 * @interface DatasourceListResponseAssetSpecGit
 */
export interface DatasourceListResponseAssetSpecGit {
    /**
     * URL to a remote git repository. The content of this repository will be mapped to the container running the workload (mandatory)
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecGit
     */
    'repository': string | null;
    /**
     * Specific branch to synchronize the repository from.
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecGit
     */
    'branch'?: string | null;
    /**
     * Specific revision to synchronize the repository from.
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecGit
     */
    'revision'?: string | null;
    /**
     * Local path within the workspace to which the S3 bucket will be mapped (mandatory).
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecGit
     */
    'path': string | null;
    /**
     * ID of credentials asset of type password. Needed for non public repository which requires authentication.
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecGit
     */
    'passwordAssetId'?: string | null;
}
/**
 * 
 * @export
 * @interface DatasourceListResponseAssetSpecHostPath
 */
export interface DatasourceListResponseAssetSpecHostPath {
    /**
     * Local path within the controller to which the host volume will be mapped (mandatory)
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecHostPath
     */
    'path': string | null;
    /**
     * Whether to force the volume to be mounted with read-only permissions. Defaults to false.
     * @type {boolean}
     * @memberof DatasourceListResponseAssetSpecHostPath
     */
    'readOnly'?: boolean | null;
    /**
     * The path that the host volume will be mounted to when in use (mandatory)
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecHostPath
     */
    'mountPath': string | null;
}
/**
 * 
 * @export
 * @interface DatasourceListResponseAssetSpecNfs
 */
export interface DatasourceListResponseAssetSpecNfs {
    /**
     * Path that is exported by the NFS server (mandatory). More info at https://kubernetes.io/docs/concepts/storage/volumes#nfs
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecNfs
     */
    'path': string | null;
    /**
     * Whether to force the NFS export to be mounted with read-only permissions.
     * @type {boolean}
     * @memberof DatasourceListResponseAssetSpecNfs
     */
    'readOnly'?: boolean | null;
    /**
     * The hostname or IP address of the NFS server (mandatory).
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecNfs
     */
    'server': string | null;
    /**
     * The path that the NFS volume will be mounted to when in use (mandatory).
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecNfs
     */
    'mountPath': string | null;
}
/**
 * 
 * @export
 * @interface DatasourceListResponseAssetSpecPvc
 */
export interface DatasourceListResponseAssetSpecPvc {
    /**
     * Whether to assume that the PVC exists. If set to true, PVC is assumed to exist. If set to false, the PVC will be created if it does not exist.
     * @type {boolean}
     * @memberof DatasourceListResponseAssetSpecPvc
     */
    'existingPvc'?: boolean | null;
    /**
     * A given name for the PVC. Allowed referencing it across workspaces (mandatory)
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecPvc
     */
    'claimName': string | null;
    /**
     * Local path within the workspace to which the PVC bucket will be mapped (mandatory)
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecPvc
     */
    'path': string | null;
    /**
     * Whether the path to the PVC permits only read access.
     * @type {boolean}
     * @memberof DatasourceListResponseAssetSpecPvc
     */
    'readOnly'?: boolean | null;
    /**
     * Whether the PVC is ephemeral. If set to true, the PVC will be deleted when the workspace is stopped.
     * @type {boolean}
     * @memberof DatasourceListResponseAssetSpecPvc
     */
    'ephemeral'?: boolean | null;
    /**
     * 
     * @type {ClaimInfo}
     * @memberof DatasourceListResponseAssetSpecPvc
     */
    'claimInfo'?: ClaimInfo | null;
}
/**
 * 
 * @export
 * @interface DatasourceListResponseAssetSpecS3
 */
export interface DatasourceListResponseAssetSpecS3 {
    /**
     * The name of the bucket (mandatory)
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecS3
     */
    'bucket': string | null;
    /**
     * Local path within the workspace to which the S3 bucket will be mapped (mandatory)
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecS3
     */
    'path': string | null;
    /**
     * The url of the S3 service provider. The default is the URL of the Amazon AWS S3 service.
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecS3
     */
    'url'?: string | null;
    /**
     * ID of credentials asset of type access-key, for private S3 buckets.
     * @type {string}
     * @memberof DatasourceListResponseAssetSpecS3
     */
    'accessKeyAssetId'?: string | null;
}
/**
 * 
 * @export
 * @interface DatasourceListResponseEntry
 */
export interface DatasourceListResponseEntry {
    /**
     * 
     * @type {AssetMeta}
     * @memberof DatasourceListResponseEntry
     */
    'meta': AssetMeta;
    /**
     * 
     * @type {DatasourceListResponseAssetSpec}
     * @memberof DatasourceListResponseEntry
     */
    'spec': DatasourceListResponseAssetSpec;
    /**
     * 
     * @type {AssetUsageInfo}
     * @memberof DatasourceListResponseEntry
     */
    'usedBy'?: AssetUsageInfo | null;
    /**
     * 
     * @type {UsageTimesInfo}
     * @memberof DatasourceListResponseEntry
     */
    'usageTimes'?: UsageTimesInfo | null;
    /**
     * 
     * @type {ComplianceInfo}
     * @memberof DatasourceListResponseEntry
     */
    'compliance'?: ComplianceInfo | null;
    /**
     * 
     * @type {AssetClusterStatusInfo}
     * @memberof DatasourceListResponseEntry
     */
    'status'?: AssetClusterStatusInfo | null;
}
/**
 * Reference information about a datasource asset.
 * @export
 * @interface DatasourceRef
 */
export interface DatasourceRef {
    /**
     * Unique identifier of the asset.
     * @type {string}
     * @memberof DatasourceRef
     */
    'id': string;
    /**
     * The name of the asset.
     * @type {string}
     * @memberof DatasourceRef
     */
    'name': string;
    /**
     * 
     * @type {AssetKind}
     * @memberof DatasourceRef
     */
    'kind': AssetKind;
}
/**
 * 
 * @export
 * @interface DisplayedJob
 */
export interface DisplayedJob {
    /**
     * Unique identifier of the job.
     * @type {string}
     * @memberof DisplayedJob
     */
    'JobId'?: string;
    /**
     * Unique identifier of the pod group.
     * @type {string}
     * @memberof DisplayedJob
     */
    'podGroupId'?: string;
    /**
     * The name of the job.
     * @type {string}
     * @memberof DisplayedJob
     */
    'jobName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'jobType'?: string;
    /**
     * Unique identifier of the cluster.
     * @type {string}
     * @memberof DisplayedJob
     */
    'clusterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'statusTooltip'?: string;
    /**
     * The name of the image executed by the pod.
     * @type {string}
     * @memberof DisplayedJob
     */
    'imageName'?: string;
    /**
     * The owner of the job.
     * @type {string}
     * @memberof DisplayedJob
     */
    'user'?: string;
    /**
     * The project that the pod group belongs to.
     * @type {string}
     * @memberof DisplayedJob
     */
    'project'?: string;
    /**
     * Unique identifier of the node.
     * @type {string}
     * @memberof DisplayedJob
     */
    'nodeId'?: string;
    /**
     * Creation time of the job.
     * @type {string}
     * @memberof DisplayedJob
     */
    'creationTime'?: string;
    /**
     * Completion time of the job.
     * @type {string}
     * @memberof DisplayedJob
     */
    'completionTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'totalRuntime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'totalWaitTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'lastStatusUpdateTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'msSinceLastStatusUpdate'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'pending'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'running'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'parallelism'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'completions'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'failed'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'succeeded'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'currentAllocatedGPUs'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'currentAllocatedGPUsMemory'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'currentRequestedGPUs'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'totalRequestedGPUs'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'requestedGPUsPerPodGroup'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'requestedGPUsMemoryPerPodGroup'?: number;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'parentWorkloadName'?: string;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'totalRequestedMemory'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'totalRequestedCPU'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'totalLimitCPU'?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayedJob
     */
    'totalLimitMemory'?: number;
    /**
     * Specifies the kind of k8s resource that owns the pod group.
     * @type {number}
     * @memberof DisplayedJob
     */
    'workloadKind'?: number;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'cliCommand'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'requestedMigDevices'?: string;
    /**
     * 
     * @type {object}
     * @memberof DisplayedJob
     */
    'dynamicData'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof DisplayedJob
     */
    'existsInCluster'?: boolean;
    /**
     * If true, it indicates that the pod group runs jupyter notebook.
     * @type {boolean}
     * @memberof DisplayedJob
     */
    'isJupyter'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DisplayedJob
     */
    'jobUrl'?: string;
    /**
     * The node pool of the job.
     * @type {string}
     * @memberof DisplayedJob
     */
    'nodePool'?: string;
}
/**
 * Parameters which apply to the master in a distributed training. 
 * @export
 * @interface DistMaster
 */
export interface DistMaster {
    /**
     * 
     * @type {AssetsIds}
     * @memberof DistMaster
     */
    'assets'?: AssetsIds;
    /**
     * 
     * @type {SpecificRunParams}
     * @memberof DistMaster
     */
    'specificEnv'?: SpecificRunParams | null;
}
/**
 * Parameters which apply to distributed training. 
 * @export
 * @interface Distributed
 */
export interface Distributed {
    /**
     * 
     * @type {number}
     * @memberof Distributed
     */
    'numWorkers'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Distributed
     */
    'noMaster'?: boolean | null;
    /**
     * 
     * @type {DistributedFramework}
     * @memberof Distributed
     */
    'distFramework'?: DistributedFramework;
    /**
     * 
     * @type {DistMaster}
     * @memberof Distributed
     */
    'master'?: DistMaster | null;
}
/**
 * 
 * @export
 * @interface DistributedAllOf
 */
export interface DistributedAllOf {
    /**
     * 
     * @type {number}
     * @memberof DistributedAllOf
     */
    'numWorkers'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DistributedAllOf
     */
    'noMaster'?: boolean | null;
    /**
     * 
     * @type {DistributedFramework}
     * @memberof DistributedAllOf
     */
    'distFramework'?: DistributedFramework;
    /**
     * 
     * @type {DistMaster}
     * @memberof DistributedAllOf
     */
    'master'?: DistMaster | null;
}
/**
 * The distributed training framework used in the workload.
 * @export
 * @enum {string}
 */

export const DistributedFramework = {
    Mpi: 'MPI',
    PyTorch: 'PyTorch',
    Tf: 'TF',
    XgBoost: 'XGBoost'
} as const;

export type DistributedFramework = typeof DistributedFramework[keyof typeof DistributedFramework];


/**
 * 
 * @export
 * @interface DockerRegistry
 */
export interface DockerRegistry {
    /**
     * 
     * @type {AssetMeta}
     * @memberof DockerRegistry
     */
    'meta': AssetMeta;
    /**
     * 
     * @type {DockerRegistrySpec}
     * @memberof DockerRegistry
     */
    'spec': DockerRegistrySpec;
    /**
     * 
     * @type {AssetUsageInfo}
     * @memberof DockerRegistry
     */
    'usedBy'?: AssetUsageInfo | null;
    /**
     * 
     * @type {ComplianceInfo}
     * @memberof DockerRegistry
     */
    'compliance'?: ComplianceInfo | null;
    /**
     * 
     * @type {AssetClusterStatusInfo}
     * @memberof DockerRegistry
     */
    'status'?: AssetClusterStatusInfo | null;
}
/**
 * 
 * @export
 * @interface DockerRegistryCreationRequest
 */
export interface DockerRegistryCreationRequest {
    /**
     * 
     * @type {AssetCreationRequest}
     * @memberof DockerRegistryCreationRequest
     */
    'meta': AssetCreationRequest;
    /**
     * 
     * @type {DockerRegistryCreationSpec}
     * @memberof DockerRegistryCreationRequest
     */
    'spec': DockerRegistryCreationSpec;
}
/**
 * 
 * @export
 * @interface DockerRegistryCreationSpec
 */
export interface DockerRegistryCreationSpec {
    /**
     * optional name of existing secret in the cluster from which the docker registry details should be taken. If omitted, you will have to provide those credentials and url in relevant fields. The provided credentials are encrypted into the control plane database and cloned to a kubernetes secret in the cluster, along with the url.
     * @type {string}
     * @memberof DockerRegistryCreationSpec
     */
    'existingSecretName'?: string | null;
    /**
     * The name of the user, required only when not using existing secret.
     * @type {string}
     * @memberof DockerRegistryCreationSpec
     */
    'user'?: string | null;
    /**
     * The password, required only when not using existing secret.
     * @type {string}
     * @memberof DockerRegistryCreationSpec
     */
    'password'?: string | null;
    /**
     * The url, required only when not using existing secret.
     * @type {string}
     * @memberof DockerRegistryCreationSpec
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface DockerRegistryListResponse
 */
export interface DockerRegistryListResponse {
    /**
     * 
     * @type {Array<DockerRegistry>}
     * @memberof DockerRegistryListResponse
     */
    'entries': Array<DockerRegistry>;
}
/**
 * 
 * @export
 * @interface DockerRegistrySpec
 */
export interface DockerRegistrySpec {
    /**
     * optional name of existing secret in the cluster from which the docker registry details should be taken. If omitted, you will have to provide those credentials and url in relevant fields. The provided credentials are encrypted into the control plane database and cloned to a kubernetes secret in the cluster, along with the url.
     * @type {string}
     * @memberof DockerRegistrySpec
     */
    'existingSecretName'?: string | null;
    /**
     * The name of the user, required only when not using existing secret.
     * @type {string}
     * @memberof DockerRegistrySpec
     */
    'user'?: string | null;
    /**
     * The password, required only when not using existing secret.
     * @type {string}
     * @memberof DockerRegistrySpec
     */
    'password'?: string | null;
    /**
     * The url, required only when not using existing secret.
     * @type {string}
     * @memberof DockerRegistrySpec
     */
    'url'?: string | null;
    /**
     * id of the encryption key which has been used to encrypt an asset\'s data.
     * @type {number}
     * @memberof DockerRegistrySpec
     */
    'keyId': number;
}
/**
 * A list of updates that needs to be carried out by the cluster in order to sync its docker registry assets with the control-plane.
 * @export
 * @interface DockerRegistrySyncResponse
 */
export interface DockerRegistrySyncResponse {
    /**
     * Docker registries that needs to be deleted from the cluster.
     * @type {Array<string>}
     * @memberof DockerRegistrySyncResponse
     */
    'toDelete'?: Array<string>;
    /**
     * Docker registries that needs to be added to the cluster.
     * @type {Array<DockerRegistryToAddOrUpdate>}
     * @memberof DockerRegistrySyncResponse
     */
    'toAdd'?: Array<DockerRegistryToAddOrUpdate>;
    /**
     * Docker registries that needs to be updated at the cluster.
     * @type {Array<DockerRegistryToAddOrUpdate>}
     * @memberof DockerRegistrySyncResponse
     */
    'toUpdate'?: Array<DockerRegistryToAddOrUpdate>;
}
/**
 * 
 * @export
 * @interface DockerRegistryToAddOrUpdate
 */
export interface DockerRegistryToAddOrUpdate {
    /**
     * The name of the asset.
     * @type {string}
     * @memberof DockerRegistryToAddOrUpdate
     */
    'name': string;
    /**
     * 
     * @type {DockerRegistrySpec}
     * @memberof DockerRegistryToAddOrUpdate
     */
    'spec': DockerRegistrySpec;
    /**
     * The checksum of the asset\'s spec.
     * @type {string}
     * @memberof DockerRegistryToAddOrUpdate
     */
    'checksum': string;
}
/**
 * 
 * @export
 * @interface DockerRegistryUpdateRequest
 */
export interface DockerRegistryUpdateRequest {
    /**
     * 
     * @type {AssetUpdateRequest}
     * @memberof DockerRegistryUpdateRequest
     */
    'meta': AssetUpdateRequest;
    /**
     * 
     * @type {DockerRegistryUpdateSpec}
     * @memberof DockerRegistryUpdateRequest
     */
    'spec'?: DockerRegistryUpdateSpec | null;
}
/**
 * 
 * @export
 * @interface DockerRegistryUpdateSpec
 */
export interface DockerRegistryUpdateSpec {
    /**
     * optional name of existing secret in the cluster from which the user name and password should be taken. If omitted, you will have to provide those credentials in the user and password fields. The provided credentials are encrypted into the control plane database and cloned to a kubernetes secret in the cluster.
     * @type {string}
     * @memberof DockerRegistryUpdateSpec
     */
    'existingSecretName'?: string | null;
    /**
     * The name of the user, required only when not using existing secret.
     * @type {string}
     * @memberof DockerRegistryUpdateSpec
     */
    'user'?: string | null;
    /**
     * The password, required only when not using existing secret.
     * @type {string}
     * @memberof DockerRegistryUpdateSpec
     */
    'password'?: string | null;
    /**
     * The url, required only when not using existing secret.
     * @type {string}
     * @memberof DockerRegistryUpdateSpec
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface EncryptionKeyId
 */
export interface EncryptionKeyId {
    /**
     * id of the encryption key which has been used to encrypt an asset\'s data.
     * @type {number}
     * @memberof EncryptionKeyId
     */
    'keyId': number;
}
/**
 * 
 * @export
 * @interface EnvironmentAsset
 */
export interface EnvironmentAsset {
    /**
     * 
     * @type {AssetMeta}
     * @memberof EnvironmentAsset
     */
    'meta': AssetMeta;
    /**
     * 
     * @type {EnvironmentAssetSpec}
     * @memberof EnvironmentAsset
     */
    'spec': EnvironmentAssetSpec;
    /**
     * 
     * @type {AssetUsageInfo}
     * @memberof EnvironmentAsset
     */
    'usedBy'?: AssetUsageInfo | null;
    /**
     * 
     * @type {UsageTimesInfo}
     * @memberof EnvironmentAsset
     */
    'usageTimes'?: UsageTimesInfo | null;
    /**
     * 
     * @type {ComplianceInfo}
     * @memberof EnvironmentAsset
     */
    'compliance'?: ComplianceInfo | null;
}
/**
 * Reference information about environment asset.
 * @export
 * @interface EnvironmentAssetRef
 */
export interface EnvironmentAssetRef {
    /**
     * Unique identifier of the asset.
     * @type {string}
     * @memberof EnvironmentAssetRef
     */
    'id': string;
    /**
     * The name of the asset.
     * @type {string}
     * @memberof EnvironmentAssetRef
     */
    'name': string;
    /**
     * the type of tools as defined in the connections of the environment asset.
     * @type {Array<ToolType>}
     * @memberof EnvironmentAssetRef
     */
    'toolTypes'?: Array<ToolType> | null;
}
/**
 * 
 * @export
 * @interface EnvironmentAssetSpec
 */
export interface EnvironmentAssetSpec {
    /**
     * A command to server as the entry point of the container running the workspace.
     * @type {string}
     * @memberof EnvironmentAssetSpec
     */
    'command'?: string | null;
    /**
     * Arguments to the command that the container running the workspace executes.
     * @type {string}
     * @memberof EnvironmentAssetSpec
     */
    'args'?: string | null;
    /**
     * Set of environment variables to populate into the container running the workspace.
     * @type {Array<EnvironmentVariable>}
     * @memberof EnvironmentAssetSpec
     */
    'environmentVariables'?: Array<EnvironmentVariable> | null;
    /**
     * The user id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsUid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof EnvironmentAssetSpec
     */
    'runAsUid'?: number | null;
    /**
     * The group id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsGid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof EnvironmentAssetSpec
     */
    'runAsGid'?: number | null;
    /**
     * Comma seperated list of groups that the user running the container belongs to, in addition to the group indicated by runAsGid. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled. Empty string implies reverting to the supplementary groups of the image.
     * @type {string}
     * @memberof EnvironmentAssetSpec
     */
    'supplementalGroups'?: string | null;
    /**
     * Docker image name. More info at https://kubernetes.io/docs/concepts/containers/images Image name is mandatory for creating a workspace.
     * @type {string}
     * @memberof EnvironmentAssetSpec
     */
    'image'?: string | null;
    /**
     * 
     * @type {ImagePullPolicy}
     * @memberof EnvironmentAssetSpec
     */
    'imagePullPolicy'?: ImagePullPolicy | null;
    /**
     * Container\'s working directory. If not specified, the container runtime default will be used, which might be configured in the container image.
     * @type {string}
     * @memberof EnvironmentAssetSpec
     */
    'workingDir'?: string | null;
    /**
     * Whether to enable host IPC. Defaults to false.
     * @type {boolean}
     * @memberof EnvironmentAssetSpec
     */
    'hostIpc'?: boolean | null;
    /**
     * Whether to enable host networking. Default to false.
     * @type {boolean}
     * @memberof EnvironmentAssetSpec
     */
    'hostNetwork'?: boolean | null;
    /**
     * List of connections that either expose ports from the container (each port is associated with a tool that the container runs), or URL\'s to be used for connecting to an external tool that is related to the action of the container (such as Weights & Biases).
     * @type {Array<Connection>}
     * @memberof EnvironmentAssetSpec
     */
    'connections'?: Array<Connection>;
    /**
     * Whether to create a home directory for the container.
     * @type {boolean}
     * @memberof EnvironmentAssetSpec
     */
    'createHomeDir'?: boolean | null;
    /**
     * Allow the container running the workload and all launched processes to gain additional privileges after the workload starts. For more information consult the User Identity in Container guide at https://docs.run.ai/admin/runai-setup/config/non-root-containers/
     * @type {boolean}
     * @memberof EnvironmentAssetSpec
     */
    'allowPrivilegeEscalation'?: boolean | null;
    /**
     * Indicate the way to determine the user and group ids of the container. The options are a. fromTheImage - user and group ids are determined by the docker image that the container runs. this is the default option. b. custom - user and group ids can be specified in the environment asset and/or the workspace creation request. c. idpToken - user and group ids are determined according to the identity provider (idp) access token. This option is intended for internal use of the environment UI form. For further details consult the User Identity guide at https://docs.run.ai/admin/runai-setup/config/non-root-containers/
     * @type {string}
     * @memberof EnvironmentAssetSpec
     */
    'uidGidSource'?: EnvironmentAssetSpecUidGidSourceEnum;
    /**
     * Allow specifying uid/gid as part of create workspace. This is relevant only for custom uigGidSource.
     * @type {boolean}
     * @memberof EnvironmentAssetSpec
     */
    'overrideUidGidInWorkspace'?: boolean;
    /**
     * The POSIX capabilities to add when running containers. Defaults to the default set of capabilities granted by the container runtime.
     * @type {Array<Capability>}
     * @memberof EnvironmentAssetSpec
     */
    'capabilities'?: Array<Capability> | null;
    /**
     * Indicates which kind of seccomp profile will be applied to the container. The options are a. RuntimeDefault - the container runtime default profile should be used. b. Unconfined - no profile should be applied. c. Localhost is not yet supported by Run:ai.
     * @type {string}
     * @memberof EnvironmentAssetSpec
     */
    'seccompProfileType'?: EnvironmentAssetSpecSeccompProfileTypeEnum;
    /**
     * Indicates that the container must run as a non-root user.
     * @type {boolean}
     * @memberof EnvironmentAssetSpec
     */
    'runAsNonRoot'?: boolean | null;
    /**
     * 
     * @type {Probes}
     * @memberof EnvironmentAssetSpec
     */
    'probes'?: Probes | null;
}

export const EnvironmentAssetSpecUidGidSourceEnum = {
    FromTheImage: 'fromTheImage',
    FromIdpToken: 'fromIdpToken',
    Custom: 'custom'
} as const;

export type EnvironmentAssetSpecUidGidSourceEnum = typeof EnvironmentAssetSpecUidGidSourceEnum[keyof typeof EnvironmentAssetSpecUidGidSourceEnum];
export const EnvironmentAssetSpecSeccompProfileTypeEnum = {
    RuntimeDefault: 'RuntimeDefault',
    Unconfined: 'Unconfined',
    Localhost: 'Localhost'
} as const;

export type EnvironmentAssetSpecSeccompProfileTypeEnum = typeof EnvironmentAssetSpecSeccompProfileTypeEnum[keyof typeof EnvironmentAssetSpecSeccompProfileTypeEnum];

/**
 * 
 * @export
 * @interface EnvironmentCreationRequest
 */
export interface EnvironmentCreationRequest {
    /**
     * 
     * @type {AssetCreationRequest}
     * @memberof EnvironmentCreationRequest
     */
    'meta': AssetCreationRequest;
    /**
     * 
     * @type {EnvironmentAssetSpec}
     * @memberof EnvironmentCreationRequest
     */
    'spec': EnvironmentAssetSpec;
}
/**
 * 
 * @export
 * @interface EnvironmentListResponse
 */
export interface EnvironmentListResponse {
    /**
     * 
     * @type {Array<EnvironmentAsset>}
     * @memberof EnvironmentListResponse
     */
    'entries': Array<EnvironmentAsset>;
}
/**
 * 
 * @export
 * @interface EnvironmentUpdateRequest
 */
export interface EnvironmentUpdateRequest {
    /**
     * 
     * @type {AssetUpdateRequest}
     * @memberof EnvironmentUpdateRequest
     */
    'meta': AssetUpdateRequest;
    /**
     * 
     * @type {EnvironmentAssetSpec}
     * @memberof EnvironmentUpdateRequest
     */
    'spec': EnvironmentAssetSpec;
}
/**
 * Details of an environment variable which is populated into the container.
 * @export
 * @interface EnvironmentVariable
 */
export interface EnvironmentVariable {
    /**
     * The name of the environment variable.
     * @type {string}
     * @memberof EnvironmentVariable
     */
    'name': string;
    /**
     * The value to set the environment variable to.
     * @type {string}
     * @memberof EnvironmentVariable
     */
    'value': string;
    /**
     * Whether to exclude this variable from the workload. This is necessary in case the variable is inherited from the policy defaults and it is desired not to include it in this workload.
     * @type {boolean}
     * @memberof EnvironmentVariable
     */
    'exclude'?: boolean;
}
/**
 * Quantity of an extended resource.
 * @export
 * @interface ExtendedResource
 */
export interface ExtendedResource {
    /**
     * The name of the extended resource.
     * @type {string}
     * @memberof ExtendedResource
     */
    'resource': string;
    /**
     * The requested quantity for the given resource.
     * @type {string}
     * @memberof ExtendedResource
     */
    'quantity': string;
    /**
     * Whether to exclude this extended resource from the workload. This is necessary in case the extended resource is inherited from the policy defaults and it is desired not to include it in this workload.
     * @type {boolean}
     * @memberof ExtendedResource
     */
    'exclude'?: boolean;
}
/**
 * Information of the external tool.
 * @export
 * @interface ExternalToolInfo
 */
export interface ExternalToolInfo {
    /**
     * 
     * @type {ExternalToolType}
     * @memberof ExternalToolInfo
     */
    'toolType': ExternalToolType;
    /**
     * The external url for connecting to the external tool. The url can include environment variables that will be replaced with the value provided for those variables when the workspace is created.
     * @type {string}
     * @memberof ExternalToolInfo
     */
    'externalUrl': string;
}
/**
 * The type of external tool that is associated with the connection. External tool typically runs in the cloud and require external url for connecting to it.
 * @export
 * @enum {string}
 */

export const ExternalToolType = {
    Wandb: 'wandb',
    Comet: 'comet'
} as const;

export type ExternalToolType = typeof ExternalToolType[keyof typeof ExternalToolType];


/**
 * 
 * @export
 * @interface ExternalUrlInfo
 */
export interface ExternalUrlInfo {
    /**
     * Whether external url is provided in the specific env of the workspace. Default is false, and in this case the external url is auto generated by the system.
     * @type {boolean}
     * @memberof ExternalUrlInfo
     */
    'isCustomUrl'?: boolean;
    /**
     * Default value for the external url. You can override it in the specific env of the workspace. Should be specified for ExternalUrl only.
     * @type {string}
     * @memberof ExternalUrlInfo
     */
    'externalUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface GitAsset
 */
export interface GitAsset {
    /**
     * 
     * @type {AssetMeta}
     * @memberof GitAsset
     */
    'meta': AssetMeta;
    /**
     * 
     * @type {GitAssetSpec}
     * @memberof GitAsset
     */
    'spec': GitAssetSpec;
    /**
     * 
     * @type {AssetUsageInfo}
     * @memberof GitAsset
     */
    'usedBy'?: AssetUsageInfo | null;
    /**
     * 
     * @type {UsageTimesInfo}
     * @memberof GitAsset
     */
    'usageTimes'?: UsageTimesInfo | null;
    /**
     * 
     * @type {ComplianceInfo}
     * @memberof GitAsset
     */
    'compliance'?: ComplianceInfo | null;
    /**
     * 
     * @type {AssetClusterStatusInfo}
     * @memberof GitAsset
     */
    'status'?: AssetClusterStatusInfo | null;
}
/**
 * 
 * @export
 * @interface GitAssetSpec
 */
export interface GitAssetSpec {
    /**
     * URL to a remote git repository. The content of this repository will be mapped to the container running the workload (mandatory)
     * @type {string}
     * @memberof GitAssetSpec
     */
    'repository': string | null;
    /**
     * Specific branch to synchronize the repository from.
     * @type {string}
     * @memberof GitAssetSpec
     */
    'branch'?: string | null;
    /**
     * Specific revision to synchronize the repository from.
     * @type {string}
     * @memberof GitAssetSpec
     */
    'revision'?: string | null;
    /**
     * Local path within the workspace to which the S3 bucket will be mapped (mandatory).
     * @type {string}
     * @memberof GitAssetSpec
     */
    'path': string | null;
    /**
     * ID of credentials asset of type password. Needed for non public repository which requires authentication.
     * @type {string}
     * @memberof GitAssetSpec
     */
    'passwordAssetId'?: string | null;
}
/**
 * 
 * @export
 * @interface GitCommon
 */
export interface GitCommon {
    /**
     * URL to a remote git repository. The content of this repository will be mapped to the container running the workload (mandatory)
     * @type {string}
     * @memberof GitCommon
     */
    'repository': string | null;
    /**
     * Specific branch to synchronize the repository from.
     * @type {string}
     * @memberof GitCommon
     */
    'branch'?: string | null;
    /**
     * Specific revision to synchronize the repository from.
     * @type {string}
     * @memberof GitCommon
     */
    'revision'?: string | null;
    /**
     * Local path within the workspace to which the S3 bucket will be mapped (mandatory).
     * @type {string}
     * @memberof GitCommon
     */
    'path': string | null;
}
/**
 * 
 * @export
 * @interface GitCreationRequest
 */
export interface GitCreationRequest {
    /**
     * 
     * @type {AssetCreationRequest}
     * @memberof GitCreationRequest
     */
    'meta': AssetCreationRequest;
    /**
     * 
     * @type {GitAssetSpec}
     * @memberof GitCreationRequest
     */
    'spec': GitAssetSpec;
}
/**
 * 
 * @export
 * @interface GitListResponse
 */
export interface GitListResponse {
    /**
     * 
     * @type {Array<GitAsset>}
     * @memberof GitListResponse
     */
    'entries': Array<GitAsset>;
}
/**
 * 
 * @export
 * @interface GitPasswordAsset
 */
export interface GitPasswordAsset {
    /**
     * ID of credentials asset of type password. Needed for non public repository which requires authentication.
     * @type {string}
     * @memberof GitPasswordAsset
     */
    'passwordAssetId'?: string | null;
}
/**
 * 
 * @export
 * @interface GitUpdateRequest
 */
export interface GitUpdateRequest {
    /**
     * 
     * @type {AssetUpdateRequest}
     * @memberof GitUpdateRequest
     */
    'meta': AssetUpdateRequest;
    /**
     * 
     * @type {GitAssetSpec}
     * @memberof GitUpdateRequest
     */
    'spec': GitAssetSpec;
}
/**
 * Whether the request for GPU resources is stated in terms of portion, memory or mig profile. If gpuDevicesRequest > 1, only portion with gpuPortionRequest 1 is supported. If gpuDeviceRequest = 1, request type can be stated as portion, memory or migProfile.
 * @export
 * @enum {string}
 */

export const GpuRequestType = {
    Portion: 'portion',
    Memory: 'memory',
    MigProfile: 'migProfile'
} as const;

export type GpuRequestType = typeof GpuRequestType[keyof typeof GpuRequestType];


/**
 * 
 * @export
 * @interface HostPath
 */
export interface HostPath {
    /**
     * Local path within the controller to which the host volume will be mapped (mandatory)
     * @type {string}
     * @memberof HostPath
     */
    'path': string | null;
    /**
     * Whether to force the volume to be mounted with read-only permissions. Defaults to false.
     * @type {boolean}
     * @memberof HostPath
     */
    'readOnly'?: boolean | null;
    /**
     * The path that the host volume will be mounted to when in use (mandatory)
     * @type {string}
     * @memberof HostPath
     */
    'mountPath': string | null;
}
/**
 * 
 * @export
 * @interface HostPathAsset
 */
export interface HostPathAsset {
    /**
     * 
     * @type {AssetMeta}
     * @memberof HostPathAsset
     */
    'meta': AssetMeta;
    /**
     * 
     * @type {HostPathSpec}
     * @memberof HostPathAsset
     */
    'spec': HostPathSpec;
    /**
     * 
     * @type {AssetUsageInfo}
     * @memberof HostPathAsset
     */
    'usedBy'?: AssetUsageInfo | null;
    /**
     * 
     * @type {UsageTimesInfo}
     * @memberof HostPathAsset
     */
    'usageTimes'?: UsageTimesInfo | null;
    /**
     * 
     * @type {ComplianceInfo}
     * @memberof HostPathAsset
     */
    'compliance'?: ComplianceInfo | null;
}
/**
 * 
 * @export
 * @interface HostPathCreationRequest
 */
export interface HostPathCreationRequest {
    /**
     * 
     * @type {AssetCreationRequest}
     * @memberof HostPathCreationRequest
     */
    'meta': AssetCreationRequest;
    /**
     * 
     * @type {HostPathSpec}
     * @memberof HostPathCreationRequest
     */
    'spec': HostPathSpec;
}
/**
 * 
 * @export
 * @interface HostPathListResponse
 */
export interface HostPathListResponse {
    /**
     * 
     * @type {Array<HostPathAsset>}
     * @memberof HostPathListResponse
     */
    'entries': Array<HostPathAsset>;
}
/**
 * 
 * @export
 * @interface HostPathSpec
 */
export interface HostPathSpec {
    /**
     * Local path within the controller to which the host volume will be mapped (mandatory)
     * @type {string}
     * @memberof HostPathSpec
     */
    'path': string | null;
    /**
     * Whether to force the volume to be mounted with read-only permissions. Defaults to false.
     * @type {boolean}
     * @memberof HostPathSpec
     */
    'readOnly'?: boolean | null;
    /**
     * The path that the host volume will be mounted to when in use (mandatory)
     * @type {string}
     * @memberof HostPathSpec
     */
    'mountPath': string | null;
}
/**
 * 
 * @export
 * @interface HostPathUpdateRequest
 */
export interface HostPathUpdateRequest {
    /**
     * 
     * @type {AssetUpdateRequest}
     * @memberof HostPathUpdateRequest
     */
    'meta': AssetUpdateRequest;
    /**
     * 
     * @type {HostPathSpec}
     * @memberof HostPathUpdateRequest
     */
    'spec': HostPathSpec;
}
/**
 * 
 * @export
 * @interface HttpResponse
 */
export interface HttpResponse {
    /**
     * 
     * @type {number}
     * @memberof HttpResponse
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof HttpResponse
     */
    'message': string;
}
/**
 * Image pull policy. Defaults to Always if :latest tag is specified, or IfNotPresent otherwise.
 * @export
 * @enum {string}
 */

export const ImagePullPolicy = {
    Always: 'Always',
    Never: 'Never',
    IfNotPresent: 'IfNotPresent'
} as const;

export type ImagePullPolicy = typeof ImagePullPolicy[keyof typeof ImagePullPolicy];


/**
 * 
 * @export
 * @interface Inference
 */
export interface Inference {
    /**
     * 
     * @type {WorkloadMetaV1}
     * @memberof Inference
     */
    'meta': WorkloadMetaV1;
    /**
     * 
     * @type {SpecificRunInfoFields}
     * @memberof Inference
     */
    'spec': SpecificRunInfoFields;
    /**
     * An inference workload ready for submission at the cluster. Further details at https://github.com/run-ai/runai-operator/tree/master/workload-controller/config/crd\"
     * @type {object}
     * @memberof Inference
     */
    'workload'?: object;
    /**
     * 
     * @type {DisplayedJob}
     * @memberof Inference
     */
    'job'?: DisplayedJob;
}
/**
 * Parameters which apply to the master in a distributed training. 
 * @export
 * @interface InfoDistMaster
 */
export interface InfoDistMaster {
    /**
     * 
     * @type {AssetsRef}
     * @memberof InfoDistMaster
     */
    'assets'?: AssetsRef;
    /**
     * 
     * @type {SpecificRunParams}
     * @memberof InfoDistMaster
     */
    'specificEnv'?: SpecificRunParams | null;
}
/**
 * Parameters which apply to distributed training. 
 * @export
 * @interface InfoDistributed
 */
export interface InfoDistributed {
    /**
     * 
     * @type {number}
     * @memberof InfoDistributed
     */
    'numWorkers'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InfoDistributed
     */
    'noMaster'?: boolean | null;
    /**
     * 
     * @type {DistributedFramework}
     * @memberof InfoDistributed
     */
    'distFramework'?: DistributedFramework;
    /**
     * 
     * @type {InfoDistMaster}
     * @memberof InfoDistributed
     */
    'master'?: InfoDistMaster | null;
}
/**
 * 
 * @export
 * @interface InfoDistributedAllOf
 */
export interface InfoDistributedAllOf {
    /**
     * 
     * @type {number}
     * @memberof InfoDistributedAllOf
     */
    'numWorkers'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InfoDistributedAllOf
     */
    'noMaster'?: boolean | null;
    /**
     * 
     * @type {DistributedFramework}
     * @memberof InfoDistributedAllOf
     */
    'distFramework'?: DistributedFramework;
    /**
     * 
     * @type {InfoDistMaster}
     * @memberof InfoDistributedAllOf
     */
    'master'?: InfoDistMaster | null;
}
/**
 * The type of connection that exposes the container port.
 * @export
 * @enum {string}
 */

export const InternalConnectionType = {
    LoadBalancer: 'LoadBalancer',
    NodePort: 'NodePort',
    ExternalUrl: 'ExternalUrl',
    ServingPort: 'ServingPort'
} as const;

export type InternalConnectionType = typeof InternalConnectionType[keyof typeof InternalConnectionType];


/**
 * Information of the internal tool.
 * @export
 * @interface InternalToolInfo
 */
export interface InternalToolInfo {
    /**
     * 
     * @type {InternalToolType}
     * @memberof InternalToolInfo
     */
    'toolType': InternalToolType;
    /**
     * 
     * @type {InternalConnectionType}
     * @memberof InternalToolInfo
     */
    'connectionType': InternalConnectionType;
    /**
     * The port within the container that the connection exposes.
     * @type {number}
     * @memberof InternalToolInfo
     */
    'containerPort': number;
    /**
     * 
     * @type {NodePortInfo}
     * @memberof InternalToolInfo
     */
    'nodePortInfo'?: NodePortInfo | null;
    /**
     * 
     * @type {ExternalUrlInfo}
     * @memberof InternalToolInfo
     */
    'externalUrlInfo'?: ExternalUrlInfo | null;
    /**
     * 
     * @type {ServingPortInfo}
     * @memberof InternalToolInfo
     */
    'servingPortInfo'?: ServingPortInfo | null;
}
/**
 * The type of the internal tool. Internal tool runs within the container and exposes ports associated with the tool using NodePort, LoadBalancer or ExternalUrl.
 * @export
 * @enum {string}
 */

export const InternalToolType = {
    JupyterNotebook: 'jupyter-notebook',
    Pycharm: 'pycharm',
    VisualStudioCode: 'visual-studio-code',
    Tensorboard: 'tensorboard',
    Rstudio: 'rstudio',
    Mlflow: 'mlflow',
    Custom: 'custom',
    Matlab: 'matlab',
    ChatbotUi: 'chatbot-ui'
} as const;

export type InternalToolType = typeof InternalToolType[keyof typeof InternalToolType];


/**
 * Details of a label which is populated into the container.
 * @export
 * @interface Label
 */
export interface Label {
    /**
     * The name of the label.
     * @type {string}
     * @memberof Label
     */
    'name': string;
    /**
     * The value to set the label to.
     * @type {string}
     * @memberof Label
     */
    'value': string;
    /**
     * Whether to exclude this label from the workload. This is necessary in case the label is inherited from the policy defaults and it is desired not to include it in this workload.
     * @type {boolean}
     * @memberof Label
     */
    'exclude'?: boolean;
}
/**
 * Required if and only if gpuRequestType is migProfile. States the memory profile to be used for workload running on NVIDIA Multi-Instance GPU (MIG) technology.
 * @export
 * @enum {string}
 */

export const MigProfile = {
    _1g5gb: '1g.5gb',
    _1g10gb: '1g.10gb',
    _2g10gb: '2g.10gb',
    _2g20gb: '2g.20gb',
    _3g20gb: '3g.20gb',
    _3g40gb: '3g.40gb',
    _4g20gb: '4g.20gb',
    _4g40gb: '4g.40gb',
    _7g40gb: '7g.40gb',
    _7g80gb: '7g.80gb'
} as const;

export type MigProfile = typeof MigProfile[keyof typeof MigProfile];


/**
 * 
 * @export
 * @interface ModelAsset
 */
export interface ModelAsset {
    /**
     * 
     * @type {AssetMeta}
     * @memberof ModelAsset
     */
    'meta': AssetMeta;
    /**
     * 
     * @type {ModelAssetSpec}
     * @memberof ModelAsset
     */
    'spec': ModelAssetSpec;
    /**
     * 
     * @type {AssetUsageInfo}
     * @memberof ModelAsset
     */
    'usedBy'?: AssetUsageInfo | null;
    /**
     * 
     * @type {UsageTimesInfo}
     * @memberof ModelAsset
     */
    'usageTimes'?: UsageTimesInfo | null;
    /**
     * 
     * @type {ComplianceInfo}
     * @memberof ModelAsset
     */
    'compliance'?: ComplianceInfo | null;
}
/**
 * 
 * @export
 * @interface ModelAssetSpec
 */
export interface ModelAssetSpec {
    /**
     * the title of the model that will be displayed in the UI
     * @type {string}
     * @memberof ModelAssetSpec
     */
    'title'?: string;
    /**
     * the description of the model that will be displayed in the UI
     * @type {string}
     * @memberof ModelAssetSpec
     */
    'description'?: string | null;
    /**
     * the thumbnail of the model that will be displayed in the UI
     * @type {string}
     * @memberof ModelAssetSpec
     */
    'thumbnail'?: string | null;
    /**
     * the huggingface id of the model
     * @type {string}
     * @memberof ModelAssetSpec
     */
    'huggingfaceId'?: string | null;
    /**
     * 
     * @type {AssetsIds}
     * @memberof ModelAssetSpec
     */
    'assets'?: AssetsIds;
    /**
     * the license of the model
     * @type {string}
     * @memberof ModelAssetSpec
     */
    'license'?: string | null;
}
/**
 * 
 * @export
 * @interface ModelCreationRequest
 */
export interface ModelCreationRequest {
    /**
     * 
     * @type {AssetCreationRequest}
     * @memberof ModelCreationRequest
     */
    'meta': AssetCreationRequest;
    /**
     * 
     * @type {ModelAssetSpec}
     * @memberof ModelCreationRequest
     */
    'spec': ModelAssetSpec;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface ModelInference
 */
export interface ModelInference {
    /**
     * 
     * @type {WorkloadMetaV1}
     * @memberof ModelInference
     */
    'meta': WorkloadMetaV1;
    /**
     * 
     * @type {SpecificRunInfoFields}
     * @memberof ModelInference
     */
    'spec': SpecificRunInfoFields;
    /**
     * An inference workload ready for submission at the cluster. Further details at https://github.com/run-ai/runai-operator/tree/master/workload-controller/config/crd\"
     * @type {object}
     * @memberof ModelInference
     */
    'workload'?: object;
    /**
     * 
     * @type {DisplayedJob}
     * @memberof ModelInference
     */
    'job'?: DisplayedJob;
}
/**
 * 
 * @export
 * @interface ModelInferenceCreationRequest
 */
export interface ModelInferenceCreationRequest {
    /**
     * The name of the workload.
     * @type {string}
     * @memberof ModelInferenceCreationRequest
     */
    'name': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof ModelInferenceCreationRequest
     */
    'clusterId': string;
    /**
     * The id of the project.
     * @type {number}
     * @memberof ModelInferenceCreationRequest
     */
    'projectId': number;
    /**
     * Namespace of the project in which the workload should be created.
     * @type {string}
     * @memberof ModelInferenceCreationRequest
     */
    'namespace': string;
    /**
     * The id of the model.
     * @type {string}
     * @memberof ModelInferenceCreationRequest
     */
    'modelId': string;
    /**
     * 
     * @type {SpecificRunParams}
     * @memberof ModelInferenceCreationRequest
     */
    'specificEnv'?: SpecificRunParams | null;
}
/**
 * 
 * @export
 * @interface ModelListResponse
 */
export interface ModelListResponse {
    /**
     * 
     * @type {Array<ModelAsset>}
     * @memberof ModelListResponse
     */
    'entries': Array<ModelAsset>;
}
/**
 * 
 * @export
 * @interface ModelUpdateRequest
 */
export interface ModelUpdateRequest {
    /**
     * 
     * @type {AssetUpdateRequest}
     * @memberof ModelUpdateRequest
     */
    'meta': AssetUpdateRequest;
    /**
     * 
     * @type {ModelAssetSpec}
     * @memberof ModelUpdateRequest
     */
    'spec': ModelAssetSpec;
}
/**
 * 
 * @export
 * @interface NFSAsset
 */
export interface NFSAsset {
    /**
     * 
     * @type {AssetMeta}
     * @memberof NFSAsset
     */
    'meta': AssetMeta;
    /**
     * 
     * @type {NFSAssetSpec}
     * @memberof NFSAsset
     */
    'spec': NFSAssetSpec;
    /**
     * 
     * @type {AssetUsageInfo}
     * @memberof NFSAsset
     */
    'usedBy'?: AssetUsageInfo | null;
    /**
     * 
     * @type {UsageTimesInfo}
     * @memberof NFSAsset
     */
    'usageTimes'?: UsageTimesInfo | null;
    /**
     * 
     * @type {ComplianceInfo}
     * @memberof NFSAsset
     */
    'compliance'?: ComplianceInfo | null;
}
/**
 * 
 * @export
 * @interface NFSAssetSpec
 */
export interface NFSAssetSpec {
    /**
     * Path that is exported by the NFS server (mandatory). More info at https://kubernetes.io/docs/concepts/storage/volumes#nfs
     * @type {string}
     * @memberof NFSAssetSpec
     */
    'path': string | null;
    /**
     * Whether to force the NFS export to be mounted with read-only permissions.
     * @type {boolean}
     * @memberof NFSAssetSpec
     */
    'readOnly'?: boolean | null;
    /**
     * The hostname or IP address of the NFS server (mandatory).
     * @type {string}
     * @memberof NFSAssetSpec
     */
    'server': string | null;
    /**
     * The path that the NFS volume will be mounted to when in use (mandatory).
     * @type {string}
     * @memberof NFSAssetSpec
     */
    'mountPath': string | null;
}
/**
 * 
 * @export
 * @interface NFSCreationRequest
 */
export interface NFSCreationRequest {
    /**
     * 
     * @type {AssetCreationRequest}
     * @memberof NFSCreationRequest
     */
    'meta': AssetCreationRequest;
    /**
     * 
     * @type {NFSAssetSpec}
     * @memberof NFSCreationRequest
     */
    'spec': NFSAssetSpec;
}
/**
 * 
 * @export
 * @interface NFSListResponse
 */
export interface NFSListResponse {
    /**
     * 
     * @type {Array<NFSAsset>}
     * @memberof NFSListResponse
     */
    'entries': Array<NFSAsset>;
}
/**
 * 
 * @export
 * @interface NFSUpdateRequest
 */
export interface NFSUpdateRequest {
    /**
     * 
     * @type {AssetUpdateRequest}
     * @memberof NFSUpdateRequest
     */
    'meta': AssetUpdateRequest;
    /**
     * 
     * @type {NFSAssetSpec}
     * @memberof NFSUpdateRequest
     */
    'spec': NFSAssetSpec;
}
/**
 * 
 * @export
 * @interface Nfs
 */
export interface Nfs {
    /**
     * Path that is exported by the NFS server (mandatory). More info at https://kubernetes.io/docs/concepts/storage/volumes#nfs
     * @type {string}
     * @memberof Nfs
     */
    'path': string | null;
    /**
     * Whether to force the NFS export to be mounted with read-only permissions.
     * @type {boolean}
     * @memberof Nfs
     */
    'readOnly'?: boolean | null;
    /**
     * The hostname or IP address of the NFS server (mandatory).
     * @type {string}
     * @memberof Nfs
     */
    'server': string | null;
    /**
     * The path that the NFS volume will be mounted to when in use (mandatory).
     * @type {string}
     * @memberof Nfs
     */
    'mountPath': string | null;
}
/**
 * 
 * @export
 * @interface NodePortInfo
 */
export interface NodePortInfo {
    /**
     * Whether the node port is provided in the specific env of the workspace. Default is false, and in this case the node port is auto generated by the system.
     * @type {boolean}
     * @memberof NodePortInfo
     */
    'isCustomPort'?: boolean;
}
/**
 * Fields of environment spec that cannot be overridden when creating a workload.
 * @export
 * @interface NonOverridableSpecFields
 */
export interface NonOverridableSpecFields {
    /**
     * Docker image name. More info at https://kubernetes.io/docs/concepts/containers/images Image name is mandatory for creating a workspace.
     * @type {string}
     * @memberof NonOverridableSpecFields
     */
    'image'?: string | null;
    /**
     * 
     * @type {ImagePullPolicy}
     * @memberof NonOverridableSpecFields
     */
    'imagePullPolicy'?: ImagePullPolicy | null;
    /**
     * Container\'s working directory. If not specified, the container runtime default will be used, which might be configured in the container image.
     * @type {string}
     * @memberof NonOverridableSpecFields
     */
    'workingDir'?: string | null;
    /**
     * Whether to enable host IPC. Defaults to false.
     * @type {boolean}
     * @memberof NonOverridableSpecFields
     */
    'hostIpc'?: boolean | null;
    /**
     * Whether to enable host networking. Default to false.
     * @type {boolean}
     * @memberof NonOverridableSpecFields
     */
    'hostNetwork'?: boolean | null;
    /**
     * List of connections that either expose ports from the container (each port is associated with a tool that the container runs), or URL\'s to be used for connecting to an external tool that is related to the action of the container (such as Weights & Biases).
     * @type {Array<Connection>}
     * @memberof NonOverridableSpecFields
     */
    'connections'?: Array<Connection>;
    /**
     * Whether to create a home directory for the container.
     * @type {boolean}
     * @memberof NonOverridableSpecFields
     */
    'createHomeDir'?: boolean | null;
    /**
     * Allow the container running the workload and all launched processes to gain additional privileges after the workload starts. For more information consult the User Identity in Container guide at https://docs.run.ai/admin/runai-setup/config/non-root-containers/
     * @type {boolean}
     * @memberof NonOverridableSpecFields
     */
    'allowPrivilegeEscalation'?: boolean | null;
    /**
     * Indicate the way to determine the user and group ids of the container. The options are a. fromTheImage - user and group ids are determined by the docker image that the container runs. this is the default option. b. custom - user and group ids can be specified in the environment asset and/or the workspace creation request. c. idpToken - user and group ids are determined according to the identity provider (idp) access token. This option is intended for internal use of the environment UI form. For further details consult the User Identity guide at https://docs.run.ai/admin/runai-setup/config/non-root-containers/
     * @type {string}
     * @memberof NonOverridableSpecFields
     */
    'uidGidSource'?: NonOverridableSpecFieldsUidGidSourceEnum;
    /**
     * Allow specifying uid/gid as part of create workspace. This is relevant only for custom uigGidSource.
     * @type {boolean}
     * @memberof NonOverridableSpecFields
     */
    'overrideUidGidInWorkspace'?: boolean;
    /**
     * The POSIX capabilities to add when running containers. Defaults to the default set of capabilities granted by the container runtime.
     * @type {Array<Capability>}
     * @memberof NonOverridableSpecFields
     */
    'capabilities'?: Array<Capability> | null;
    /**
     * Indicates which kind of seccomp profile will be applied to the container. The options are a. RuntimeDefault - the container runtime default profile should be used. b. Unconfined - no profile should be applied. c. Localhost is not yet supported by Run:ai.
     * @type {string}
     * @memberof NonOverridableSpecFields
     */
    'seccompProfileType'?: NonOverridableSpecFieldsSeccompProfileTypeEnum;
    /**
     * Indicates that the container must run as a non-root user.
     * @type {boolean}
     * @memberof NonOverridableSpecFields
     */
    'runAsNonRoot'?: boolean | null;
    /**
     * 
     * @type {Probes}
     * @memberof NonOverridableSpecFields
     */
    'probes'?: Probes | null;
}

export const NonOverridableSpecFieldsUidGidSourceEnum = {
    FromTheImage: 'fromTheImage',
    FromIdpToken: 'fromIdpToken',
    Custom: 'custom'
} as const;

export type NonOverridableSpecFieldsUidGidSourceEnum = typeof NonOverridableSpecFieldsUidGidSourceEnum[keyof typeof NonOverridableSpecFieldsUidGidSourceEnum];
export const NonOverridableSpecFieldsSeccompProfileTypeEnum = {
    RuntimeDefault: 'RuntimeDefault',
    Unconfined: 'Unconfined',
    Localhost: 'Localhost'
} as const;

export type NonOverridableSpecFieldsSeccompProfileTypeEnum = typeof NonOverridableSpecFieldsSeccompProfileTypeEnum[keyof typeof NonOverridableSpecFieldsSeccompProfileTypeEnum];

/**
 * Fields of environment spec that can be overridden when creating a workload.
 * @export
 * @interface OverridableSpecFields
 */
export interface OverridableSpecFields {
    /**
     * A command to server as the entry point of the container running the workspace.
     * @type {string}
     * @memberof OverridableSpecFields
     */
    'command'?: string | null;
    /**
     * Arguments to the command that the container running the workspace executes.
     * @type {string}
     * @memberof OverridableSpecFields
     */
    'args'?: string | null;
    /**
     * Set of environment variables to populate into the container running the workspace.
     * @type {Array<EnvironmentVariable>}
     * @memberof OverridableSpecFields
     */
    'environmentVariables'?: Array<EnvironmentVariable> | null;
    /**
     * The user id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsUid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof OverridableSpecFields
     */
    'runAsUid'?: number | null;
    /**
     * The group id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsGid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof OverridableSpecFields
     */
    'runAsGid'?: number | null;
    /**
     * Comma seperated list of groups that the user running the container belongs to, in addition to the group indicated by runAsGid. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled. Empty string implies reverting to the supplementary groups of the image.
     * @type {string}
     * @memberof OverridableSpecFields
     */
    'supplementalGroups'?: string | null;
}
/**
 * 
 * @export
 * @interface PVCAsset
 */
export interface PVCAsset {
    /**
     * 
     * @type {AssetMeta}
     * @memberof PVCAsset
     */
    'meta': AssetMeta;
    /**
     * 
     * @type {PVCAssetSpec}
     * @memberof PVCAsset
     */
    'spec': PVCAssetSpec;
    /**
     * 
     * @type {AssetUsageInfo}
     * @memberof PVCAsset
     */
    'usedBy'?: AssetUsageInfo | null;
    /**
     * 
     * @type {UsageTimesInfo}
     * @memberof PVCAsset
     */
    'usageTimes'?: UsageTimesInfo | null;
    /**
     * 
     * @type {ComplianceInfo}
     * @memberof PVCAsset
     */
    'compliance'?: ComplianceInfo | null;
    /**
     * 
     * @type {AssetClusterStatusInfo}
     * @memberof PVCAsset
     */
    'status'?: AssetClusterStatusInfo | null;
}
/**
 * 
 * @export
 * @interface PVCAssetSpec
 */
export interface PVCAssetSpec {
    /**
     * Whether to assume that the PVC exists. If set to true, PVC is assumed to exist. If set to false, the PVC will be created if it does not exist.
     * @type {boolean}
     * @memberof PVCAssetSpec
     */
    'existingPvc'?: boolean | null;
    /**
     * A given name for the PVC. Allowed referencing it across workspaces (mandatory)
     * @type {string}
     * @memberof PVCAssetSpec
     */
    'claimName': string | null;
    /**
     * Local path within the workspace to which the PVC bucket will be mapped (mandatory)
     * @type {string}
     * @memberof PVCAssetSpec
     */
    'path': string | null;
    /**
     * Whether the path to the PVC permits only read access.
     * @type {boolean}
     * @memberof PVCAssetSpec
     */
    'readOnly'?: boolean | null;
    /**
     * Whether the PVC is ephemeral. If set to true, the PVC will be deleted when the workspace is stopped.
     * @type {boolean}
     * @memberof PVCAssetSpec
     */
    'ephemeral'?: boolean | null;
    /**
     * 
     * @type {ClaimInfo}
     * @memberof PVCAssetSpec
     */
    'claimInfo'?: ClaimInfo | null;
}
/**
 * 
 * @export
 * @interface PVCCreationRequest
 */
export interface PVCCreationRequest {
    /**
     * 
     * @type {AssetCreationRequest}
     * @memberof PVCCreationRequest
     */
    'meta': AssetCreationRequest;
    /**
     * 
     * @type {PVCAssetSpec}
     * @memberof PVCCreationRequest
     */
    'spec': PVCAssetSpec;
}
/**
 * 
 * @export
 * @interface PVCListResponse
 */
export interface PVCListResponse {
    /**
     * 
     * @type {Array<PVCAsset>}
     * @memberof PVCListResponse
     */
    'entries': Array<PVCAsset>;
}
/**
 * A list of pvcs statuses in the cluster
 * @export
 * @interface PVCStatusRequest
 */
export interface PVCStatusRequest {
    /**
     * List of resources which exist in the cluster and their status
     * @type {Array<PVCStatusRequestItem>}
     * @memberof PVCStatusRequest
     */
    'pvcs'?: Array<PVCStatusRequestItem>;
}
/**
 * A pvc status with its identifier in the cluster
 * @export
 * @interface PVCStatusRequestItem
 */
export interface PVCStatusRequestItem {
    /**
     * ID of the cluster who sent the request
     * @type {string}
     * @memberof PVCStatusRequestItem
     */
    'clusterId': string;
    /**
     * The name of the pvc in the cluster
     * @type {string}
     * @memberof PVCStatusRequestItem
     */
    'claimName': string;
    /**
     * 
     * @type {PVCStatusSyncStatusInfo}
     * @memberof PVCStatusRequestItem
     */
    'statusInfo': PVCStatusSyncStatusInfo;
    /**
     * 
     * @type {Scope}
     * @memberof PVCStatusRequestItem
     */
    'scopeType': Scope;
    /**
     * The ID of the scope the asset is belong to
     * @type {string}
     * @memberof PVCStatusRequestItem
     */
    'scopeId': string;
}
/**
 * A phase and phase message of the pvc in the cluster
 * @export
 * @interface PVCStatusSyncStatusInfo
 */
export interface PVCStatusSyncStatusInfo {
    /**
     * 
     * @type {AssetStatus}
     * @memberof PVCStatusSyncStatusInfo
     */
    'status'?: AssetStatus | null;
    /**
     * An error message if something wrong happened in the cluster
     * @type {string}
     * @memberof PVCStatusSyncStatusInfo
     */
    'errorMessage'?: string;
}
/**
 * 
 * @export
 * @interface PVCSyncDetails
 */
export interface PVCSyncDetails {
    /**
     * Size is the size of the PVC.
     * @type {string}
     * @memberof PVCSyncDetails
     */
    'size': string;
    /**
     * StorageClass is the name of the StorageClass required by the claim. If not specified, the default StorageClass will be used.
     * @type {string}
     * @memberof PVCSyncDetails
     */
    'storageClass': string;
    /**
     * 
     * @type {PvcAccessModes}
     * @memberof PVCSyncDetails
     */
    'accessModes'?: PvcAccessModes | null;
    /**
     * The volume mode required by the claim, either Filesystem (default) or Block.
     * @type {string}
     * @memberof PVCSyncDetails
     */
    'volumeMode'?: PVCSyncDetailsVolumeModeEnum;
    /**
     * Whether to assume that the PVC exists. If set to true, PVC is assumed to exist. If set to false, the PVC will be create if it does not exist.
     * @type {boolean}
     * @memberof PVCSyncDetails
     */
    'existingPvc'?: boolean | null;
    /**
     * A given name for the PVC. Allowed referencing it across workspaces. ClaimName is mandatory for creating a pvc.
     * @type {string}
     * @memberof PVCSyncDetails
     */
    'claimName': string;
    /**
     * Whether the path to the PVC permits only read access.
     * @type {boolean}
     * @memberof PVCSyncDetails
     */
    'readOnly'?: boolean | null;
}

export const PVCSyncDetailsVolumeModeEnum = {
    Filesystem: 'Filesystem',
    Block: 'Block'
} as const;

export type PVCSyncDetailsVolumeModeEnum = typeof PVCSyncDetailsVolumeModeEnum[keyof typeof PVCSyncDetailsVolumeModeEnum];

/**
 * 
 * @export
 * @interface PVCToAddOrUpdate
 */
export interface PVCToAddOrUpdate {
    /**
     * 
     * @type {PVCSyncDetails}
     * @memberof PVCToAddOrUpdate
     */
    'spec': PVCSyncDetails;
    /**
     * The checksum of the asset\'s spec.
     * @type {string}
     * @memberof PVCToAddOrUpdate
     */
    'checksum': string;
}
/**
 * 
 * @export
 * @interface PVCUpdateRequest
 */
export interface PVCUpdateRequest {
    /**
     * 
     * @type {AssetUpdateRequest}
     * @memberof PVCUpdateRequest
     */
    'meta': AssetUpdateRequest;
    /**
     * 
     * @type {PVCAssetSpec}
     * @memberof PVCUpdateRequest
     */
    'spec': PVCAssetSpec;
}
/**
 * A list of updates that needs to be carried out by the cluster in order to sync its pvc assets with the control-plane.
 * @export
 * @interface PVCsSyncResponse
 */
export interface PVCsSyncResponse {
    /**
     * PVCs that need to be deleted from the cluster.
     * @type {Array<string>}
     * @memberof PVCsSyncResponse
     */
    'toDelete'?: Array<string>;
    /**
     * PVCs that needs to be added to the cluster.
     * @type {Array<PVCToAddOrUpdate>}
     * @memberof PVCsSyncResponse
     */
    'toAdd'?: Array<PVCToAddOrUpdate>;
    /**
     * PVCs that needs to be updated at the cluster.
     * @type {Array<PVCToAddOrUpdate>}
     * @memberof PVCsSyncResponse
     */
    'toUpdate'?: Array<PVCToAddOrUpdate>;
}
/**
 * 
 * @export
 * @interface Password
 */
export interface Password {
    /**
     * 
     * @type {AssetMeta}
     * @memberof Password
     */
    'meta': AssetMeta;
    /**
     * 
     * @type {PasswordSpec}
     * @memberof Password
     */
    'spec': PasswordSpec;
    /**
     * 
     * @type {AssetUsageInfo}
     * @memberof Password
     */
    'usedBy'?: AssetUsageInfo | null;
    /**
     * 
     * @type {ComplianceInfo}
     * @memberof Password
     */
    'compliance'?: ComplianceInfo | null;
    /**
     * 
     * @type {AssetClusterStatusInfo}
     * @memberof Password
     */
    'status'?: AssetClusterStatusInfo | null;
}
/**
 * 
 * @export
 * @interface PasswordCreationRequest
 */
export interface PasswordCreationRequest {
    /**
     * 
     * @type {AssetCreationRequest}
     * @memberof PasswordCreationRequest
     */
    'meta': AssetCreationRequest;
    /**
     * 
     * @type {PasswordCreationSpec}
     * @memberof PasswordCreationRequest
     */
    'spec': PasswordCreationSpec;
}
/**
 * 
 * @export
 * @interface PasswordCreationSpec
 */
export interface PasswordCreationSpec {
    /**
     * optional name of existing secret in the cluster from which the user name and password should be taken. If omitted, you will have to provide those credentials in the user and password fields. The provided credentials are encrypted into the control plane database and cloned to a kubernetes secret in the cluster.
     * @type {string}
     * @memberof PasswordCreationSpec
     */
    'existingSecretName'?: string | null;
    /**
     * The name of the user, required only when not using existing secret.
     * @type {string}
     * @memberof PasswordCreationSpec
     */
    'user'?: string | null;
    /**
     * The password, required only when not using existing secret.
     * @type {string}
     * @memberof PasswordCreationSpec
     */
    'password'?: string | null;
}
/**
 * 
 * @export
 * @interface PasswordListResponse
 */
export interface PasswordListResponse {
    /**
     * 
     * @type {Array<Password>}
     * @memberof PasswordListResponse
     */
    'entries': Array<Password>;
}
/**
 * 
 * @export
 * @interface PasswordSpec
 */
export interface PasswordSpec {
    /**
     * optional name of existing secret in the cluster from which the user name and password should be taken. If omitted, you will have to provide those credentials in the user and password fields. The provided credentials are encrypted into the control plane database and cloned to a kubernetes secret in the cluster.
     * @type {string}
     * @memberof PasswordSpec
     */
    'existingSecretName'?: string | null;
    /**
     * The name of the user, required only when not using existing secret.
     * @type {string}
     * @memberof PasswordSpec
     */
    'user'?: string | null;
    /**
     * The password, required only when not using existing secret.
     * @type {string}
     * @memberof PasswordSpec
     */
    'password'?: string | null;
    /**
     * id of the encryption key which has been used to encrypt an asset\'s data.
     * @type {number}
     * @memberof PasswordSpec
     */
    'keyId': number;
}
/**
 * 
 * @export
 * @interface PasswordToAddOrUpdate
 */
export interface PasswordToAddOrUpdate {
    /**
     * The name of the asset.
     * @type {string}
     * @memberof PasswordToAddOrUpdate
     */
    'name': string;
    /**
     * 
     * @type {PasswordSpec}
     * @memberof PasswordToAddOrUpdate
     */
    'spec': PasswordSpec;
    /**
     * The checksum of the asset\'s spec.
     * @type {string}
     * @memberof PasswordToAddOrUpdate
     */
    'checksum': string;
}
/**
 * 
 * @export
 * @interface PasswordUpdateRequest
 */
export interface PasswordUpdateRequest {
    /**
     * 
     * @type {AssetUpdateRequest}
     * @memberof PasswordUpdateRequest
     */
    'meta': AssetUpdateRequest;
    /**
     * 
     * @type {PasswordUpdateSpec}
     * @memberof PasswordUpdateRequest
     */
    'spec'?: PasswordUpdateSpec | null;
}
/**
 * 
 * @export
 * @interface PasswordUpdateSpec
 */
export interface PasswordUpdateSpec {
    /**
     * optional name of existing secret in the cluster from which the user name and password should be taken. If omitted, you will have to provide those credentials in the user and password fields. The provided credentials are encrypted into the control plane database and cloned to a kubernetes secret in the cluster.
     * @type {string}
     * @memberof PasswordUpdateSpec
     */
    'existingSecretName'?: string | null;
    /**
     * The name of the user, required only when not using existing secret.
     * @type {string}
     * @memberof PasswordUpdateSpec
     */
    'user'?: string | null;
    /**
     * The password, required only when not using existing secret.
     * @type {string}
     * @memberof PasswordUpdateSpec
     */
    'password'?: string | null;
}
/**
 * A list of updates that needs to be carried out by the cluster in order to sync its password assets with the control-plane.
 * @export
 * @interface PasswordsSyncResponse
 */
export interface PasswordsSyncResponse {
    /**
     * Passwords that need to be deleted from the cluster.
     * @type {Array<string>}
     * @memberof PasswordsSyncResponse
     */
    'toDelete'?: Array<string>;
    /**
     * Passwords that needs to be added to the cluster.
     * @type {Array<PasswordToAddOrUpdate>}
     * @memberof PasswordsSyncResponse
     */
    'toAdd'?: Array<PasswordToAddOrUpdate>;
    /**
     * Passwords that needs to be updated at the cluster.
     * @type {Array<PasswordToAddOrUpdate>}
     * @memberof PasswordsSyncResponse
     */
    'toUpdate'?: Array<PasswordToAddOrUpdate>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Phase = {
    Creating: 'Creating',
    Initializing: 'Initializing',
    Resuming: 'Resuming',
    Pending: 'Pending',
    Deleting: 'Deleting',
    Running: 'Running',
    Updating: 'Updating',
    Stopped: 'Stopped',
    Stopping: 'Stopping',
    Degraded: 'Degraded',
    Failed: 'Failed',
    Completed: 'Completed',
    Terminating: 'Terminating',
    Unknown: 'Unknown'
} as const;

export type Phase = typeof Phase[keyof typeof Phase];


/**
 * 
 * @export
 * @interface Probe
 */
export interface Probe {
    /**
     * Number of seconds after the container has started before liveness or readiness probes are initiated.
     * @type {number}
     * @memberof Probe
     */
    'initialDelaySeconds'?: number | null;
    /**
     * How often (in seconds) to perform the probe.
     * @type {number}
     * @memberof Probe
     */
    'periodSeconds'?: number | null;
    /**
     * Number of seconds after which the probe times out.
     * @type {number}
     * @memberof Probe
     */
    'timeoutSeconds'?: number | null;
    /**
     * Minimum consecutive successes for the probe to be considered successful after having failed.
     * @type {number}
     * @memberof Probe
     */
    'successThreshold'?: number | null;
    /**
     * When a probe fails, the number of times to try before giving up.
     * @type {number}
     * @memberof Probe
     */
    'failureThreshold'?: number | null;
    /**
     * 
     * @type {ProbeHandler}
     * @memberof Probe
     */
    'handler'?: ProbeHandler;
}
/**
 * The action taken to determine the health of the container.
 * @export
 * @interface ProbeHandler
 */
export interface ProbeHandler {
    /**
     * 
     * @type {ProbeHandlerHttpGet}
     * @memberof ProbeHandler
     */
    'httpGet'?: ProbeHandlerHttpGet;
}
/**
 * An action based on HTTP Get requests.
 * @export
 * @interface ProbeHandlerHttpGet
 */
export interface ProbeHandlerHttpGet {
    /**
     * Path to access on the HTTP server.
     * @type {string}
     * @memberof ProbeHandlerHttpGet
     */
    'path'?: string | null;
    /**
     * Number of the port to access on the container.
     * @type {number}
     * @memberof ProbeHandlerHttpGet
     */
    'port'?: number;
    /**
     * Host name to connect to, defaults to the pod IP.
     * @type {string}
     * @memberof ProbeHandlerHttpGet
     */
    'host'?: string | null;
    /**
     * Scheme to use for connecting to the host.
     * @type {string}
     * @memberof ProbeHandlerHttpGet
     */
    'scheme'?: ProbeHandlerHttpGetSchemeEnum;
}

export const ProbeHandlerHttpGetSchemeEnum = {
    Http: 'HTTP',
    Https: 'HTTPS'
} as const;

export type ProbeHandlerHttpGetSchemeEnum = typeof ProbeHandlerHttpGetSchemeEnum[keyof typeof ProbeHandlerHttpGetSchemeEnum];

/**
 * Probes are used to determine if the container is healthy and ready to accept traffic.
 * @export
 * @interface Probes
 */
export interface Probes {
    /**
     * 
     * @type {Probe}
     * @memberof Probes
     */
    'readiness'?: Probe | null;
}
/**
 * 
 * @export
 * @interface Pvc
 */
export interface Pvc {
    /**
     * Whether to assume that the PVC exists. If set to true, PVC is assumed to exist. If set to false, the PVC will be created if it does not exist.
     * @type {boolean}
     * @memberof Pvc
     */
    'existingPvc'?: boolean | null;
    /**
     * A given name for the PVC. Allowed referencing it across workspaces (mandatory)
     * @type {string}
     * @memberof Pvc
     */
    'claimName': string | null;
    /**
     * Local path within the workspace to which the PVC bucket will be mapped (mandatory)
     * @type {string}
     * @memberof Pvc
     */
    'path': string | null;
    /**
     * Whether the path to the PVC permits only read access.
     * @type {boolean}
     * @memberof Pvc
     */
    'readOnly'?: boolean | null;
    /**
     * Whether the PVC is ephemeral. If set to true, the PVC will be deleted when the workspace is stopped.
     * @type {boolean}
     * @memberof Pvc
     */
    'ephemeral'?: boolean | null;
    /**
     * 
     * @type {ClaimInfo}
     * @memberof Pvc
     */
    'claimInfo'?: ClaimInfo | null;
}
/**
 * Requested access mode(s) for the newly created PVC.
 * @export
 * @interface PvcAccessModes
 */
export interface PvcAccessModes {
    /**
     * Requesting claim that can be mounted in read/write mode to exactly one host. This is the default access mode.
     * @type {boolean}
     * @memberof PvcAccessModes
     */
    'readWriteOnce'?: boolean | null;
    /**
     * Requesting claim that can be mounted in read-only mode to many hosts.
     * @type {boolean}
     * @memberof PvcAccessModes
     */
    'readOnlyMany'?: boolean | null;
    /**
     * Requesting claim that can be mounted in read/write mode to many hosts.
     * @type {boolean}
     * @memberof PvcAccessModes
     */
    'readWriteMany'?: boolean | null;
}
/**
 * 
 * @export
 * @interface Registry
 */
export interface Registry {
    /**
     * 
     * @type {AssetMeta}
     * @memberof Registry
     */
    'meta': AssetMeta;
    /**
     * 
     * @type {RegistrySpec}
     * @memberof Registry
     */
    'spec': RegistrySpec;
}
/**
 * 
 * @export
 * @interface RegistryCreationRequest
 */
export interface RegistryCreationRequest {
    /**
     * 
     * @type {AssetCreationRequest}
     * @memberof RegistryCreationRequest
     */
    'meta': AssetCreationRequest;
    /**
     * 
     * @type {RegistrySpec}
     * @memberof RegistryCreationRequest
     */
    'spec': RegistrySpec;
}
/**
 * 
 * @export
 * @interface RegistryListResponse
 */
export interface RegistryListResponse {
    /**
     * 
     * @type {Array<Registry>}
     * @memberof RegistryListResponse
     */
    'entries': Array<Registry>;
}
/**
 * 
 * @export
 * @interface RegistrySpec
 */
export interface RegistrySpec {
    /**
     * The registry base url.
     * @type {string}
     * @memberof RegistrySpec
     */
    'url': string;
    /**
     * 
     * @type {CredentialKind}
     * @memberof RegistrySpec
     */
    'credentialKind': CredentialKind;
    /**
     * The name of the user.
     * @type {string}
     * @memberof RegistrySpec
     */
    'user': string;
    /**
     * The password.
     * @type {string}
     * @memberof RegistrySpec
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface RegistryUpdateRequest
 */
export interface RegistryUpdateRequest {
    /**
     * 
     * @type {AssetUpdateRequest}
     * @memberof RegistryUpdateRequest
     */
    'meta': AssetUpdateRequest;
    /**
     * 
     * @type {RegistrySpec}
     * @memberof RegistryUpdateRequest
     */
    'spec': RegistrySpec;
}
/**
 * 
 * @export
 * @interface Repositories
 */
export interface Repositories {
    /**
     * 
     * @type {Array<string>}
     * @memberof Repositories
     */
    'repositories': Array<string>;
}
/**
 * 
 * @export
 * @interface RepositoryTags
 */
export interface RepositoryTags {
    /**
     * 
     * @type {string}
     * @memberof RepositoryTags
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepositoryTags
     */
    'tags': Array<string>;
}
/**
 * Resource related parameters.
 * @export
 * @interface Resources
 */
export interface Resources {
    /**
     * Nodes (machines) or a group of nodes on which the workload will run. To use this feature, your Administrator will need to label nodes as explained in the Group Nodes guide at https://docs.run.ai/admin/researcher-setup/limit-to-node-group. This flag can be used in conjunction with Project-based affinity. In this case, the flag is used to refine the list of allowable node groups set in the Project. For more information consult the Projects guide at https://docs.run.ai/admin/admin-ui-setup/project-setup.
     * @type {string}
     * @memberof Resources
     */
    'nodeType'?: string | null;
    /**
     * A prioritize list of node pools for the scheduler to run the workspace on. The scheduler will always try to use the first node pool before moving to the next one when the fist is not available.
     * @type {Array<string>}
     * @memberof Resources
     */
    'nodePools'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface S3AccessKeyAsset
 */
export interface S3AccessKeyAsset {
    /**
     * ID of credentials asset of type access-key, for private S3 buckets.
     * @type {string}
     * @memberof S3AccessKeyAsset
     */
    'accessKeyAssetId'?: string | null;
}
/**
 * 
 * @export
 * @interface S3Asset
 */
export interface S3Asset {
    /**
     * 
     * @type {AssetMeta}
     * @memberof S3Asset
     */
    'meta': AssetMeta;
    /**
     * 
     * @type {S3AssetSpec}
     * @memberof S3Asset
     */
    'spec': S3AssetSpec;
    /**
     * 
     * @type {AssetUsageInfo}
     * @memberof S3Asset
     */
    'usedBy'?: AssetUsageInfo | null;
    /**
     * 
     * @type {UsageTimesInfo}
     * @memberof S3Asset
     */
    'usageTimes'?: UsageTimesInfo | null;
    /**
     * 
     * @type {ComplianceInfo}
     * @memberof S3Asset
     */
    'compliance'?: ComplianceInfo | null;
    /**
     * 
     * @type {AssetClusterStatusInfo}
     * @memberof S3Asset
     */
    'status'?: AssetClusterStatusInfo | null;
}
/**
 * 
 * @export
 * @interface S3AssetSpec
 */
export interface S3AssetSpec {
    /**
     * The name of the bucket (mandatory)
     * @type {string}
     * @memberof S3AssetSpec
     */
    'bucket': string | null;
    /**
     * Local path within the workspace to which the S3 bucket will be mapped (mandatory)
     * @type {string}
     * @memberof S3AssetSpec
     */
    'path': string | null;
    /**
     * The url of the S3 service provider. The default is the URL of the Amazon AWS S3 service.
     * @type {string}
     * @memberof S3AssetSpec
     */
    'url'?: string | null;
    /**
     * ID of credentials asset of type access-key, for private S3 buckets.
     * @type {string}
     * @memberof S3AssetSpec
     */
    'accessKeyAssetId'?: string | null;
}
/**
 * 
 * @export
 * @interface S3Common
 */
export interface S3Common {
    /**
     * The name of the bucket (mandatory)
     * @type {string}
     * @memberof S3Common
     */
    'bucket': string | null;
    /**
     * Local path within the workspace to which the S3 bucket will be mapped (mandatory)
     * @type {string}
     * @memberof S3Common
     */
    'path': string | null;
    /**
     * The url of the S3 service provider. The default is the URL of the Amazon AWS S3 service.
     * @type {string}
     * @memberof S3Common
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface S3CreationRequest
 */
export interface S3CreationRequest {
    /**
     * 
     * @type {AssetCreationRequest}
     * @memberof S3CreationRequest
     */
    'meta': AssetCreationRequest;
    /**
     * 
     * @type {S3AssetSpec}
     * @memberof S3CreationRequest
     */
    'spec': S3AssetSpec;
}
/**
 * 
 * @export
 * @interface S3ListResponse
 */
export interface S3ListResponse {
    /**
     * 
     * @type {Array<S3Asset>}
     * @memberof S3ListResponse
     */
    'entries': Array<S3Asset>;
}
/**
 * 
 * @export
 * @interface S3UpdateRequest
 */
export interface S3UpdateRequest {
    /**
     * 
     * @type {AssetUpdateRequest}
     * @memberof S3UpdateRequest
     */
    'meta': AssetUpdateRequest;
    /**
     * 
     * @type {S3AssetSpec}
     * @memberof S3UpdateRequest
     */
    'spec': S3AssetSpec;
}
/**
 * The scope in which an asset can be used. The highest scope, system, is intended for internal purposes only.
 * @export
 * @enum {string}
 */

export const Scope = {
    System: 'system',
    Tenant: 'tenant',
    Cluster: 'cluster',
    Department: 'department',
    Project: 'project'
} as const;

export type Scope = typeof Scope[keyof typeof Scope];


/**
 * 
 * @export
 * @enum {string}
 */

export const ScopeType = {
    Tenant: 'tenant',
    Department: 'department',
    Project: 'project',
    Cluster: 'cluster'
} as const;

export type ScopeType = typeof ScopeType[keyof typeof ScopeType];


/**
 * Security related fields. When used in assets context, those are environment asset fields that can be override in the submit workload request.
 * @export
 * @interface SecurityOverridable
 */
export interface SecurityOverridable {
    /**
     * The user id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsUid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof SecurityOverridable
     */
    'runAsUid'?: number | null;
    /**
     * The group id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsGid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof SecurityOverridable
     */
    'runAsGid'?: number | null;
    /**
     * Comma seperated list of groups that the user running the container belongs to, in addition to the group indicated by runAsGid. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled. Empty string implies reverting to the supplementary groups of the image.
     * @type {string}
     * @memberof SecurityOverridable
     */
    'supplementalGroups'?: string | null;
}
/**
 * 
 * @export
 * @interface ServingPortInfo
 */
export interface ServingPortInfo {
    /**
     * 
     * @type {string}
     * @memberof ServingPortInfo
     */
    'protocol'?: ServingPortInfoProtocolEnum;
}

export const ServingPortInfoProtocolEnum = {
    Grpc: 'grpc',
    Http: 'http'
} as const;

export type ServingPortInfoProtocolEnum = typeof ServingPortInfoProtocolEnum[keyof typeof ServingPortInfoProtocolEnum];

/**
 * 
 * @export
 * @interface SpecificRunAutoScaling
 */
export interface SpecificRunAutoScaling {
    /**
     * 
     * @type {SpecificRunAutoScalingAutoScaling}
     * @memberof SpecificRunAutoScaling
     */
    'autoScaling'?: SpecificRunAutoScalingAutoScaling | null;
}
/**
 * Auto scaling specifications. Relevant only for Inference workloads.
 * @export
 * @interface SpecificRunAutoScalingAutoScaling
 */
export interface SpecificRunAutoScalingAutoScaling {
    /**
     * The minimum number of replicas for autoscaling
     * @type {number}
     * @memberof SpecificRunAutoScalingAutoScaling
     */
    'minReplicas'?: number | null;
    /**
     * The maximum number of replicas for autoscaling
     * @type {number}
     * @memberof SpecificRunAutoScalingAutoScaling
     */
    'maxReplicas'?: number | null;
    /**
     * The predefined metric to use for autoscaling
     * @type {string}
     * @memberof SpecificRunAutoScalingAutoScaling
     */
    'thresholdMetric'?: SpecificRunAutoScalingAutoScalingThresholdMetricEnum;
    /**
     * The threshold value to use with the specified metric for autoscaling
     * @type {number}
     * @memberof SpecificRunAutoScalingAutoScaling
     */
    'thresholdValue'?: number | null;
}

export const SpecificRunAutoScalingAutoScalingThresholdMetricEnum = {
    Throughput: 'throughput',
    Concurrency: 'concurrency'
} as const;

export type SpecificRunAutoScalingAutoScalingThresholdMetricEnum = typeof SpecificRunAutoScalingAutoScalingThresholdMetricEnum[keyof typeof SpecificRunAutoScalingAutoScalingThresholdMetricEnum];

/**
 * 
 * @export
 * @interface SpecificRunConnectionInfo
 */
export interface SpecificRunConnectionInfo {
    /**
     * Unique identifier of the connection. Will be used to correlate between the information given here and the information provided for the connection in the environment asset.
     * @type {string}
     * @memberof SpecificRunConnectionInfo
     */
    'name'?: string;
    /**
     * Port number of the host that will be connected with the container port. Required if and only if isCustomPort is set to true in the environment asset.
     * @type {number}
     * @memberof SpecificRunConnectionInfo
     */
    'nodePort'?: number | null;
    /**
     * URL to associated with the container port. Required if and only if isCustomExternalUrl is set to true in the environment asset.
     * @type {string}
     * @memberof SpecificRunConnectionInfo
     */
    'externalUrl'?: string | null;
    /**
     * Specifies the emails of those users that are allowed to access the connection. if empty, the connection can be visible by any user.
     * @type {Array<string>}
     * @memberof SpecificRunConnectionInfo
     */
    'authorizedUsers'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface SpecificRunConnections
 */
export interface SpecificRunConnections {
    /**
     * 
     * @type {Array<SpecificRunConnectionInfo>}
     * @memberof SpecificRunConnections
     */
    'connections'?: Array<SpecificRunConnectionInfo> | null;
}
/**
 * The information needed for creating az template - a set of assets and an optional set of specific environment parameters.
 * @export
 * @interface SpecificRunCreationFields
 */
export interface SpecificRunCreationFields {
    /**
     * 
     * @type {AssetsIds}
     * @memberof SpecificRunCreationFields
     */
    'assets': AssetsIds;
    /**
     * 
     * @type {SpecificRunParams}
     * @memberof SpecificRunCreationFields
     */
    'specificEnv'?: SpecificRunParams | null;
}
/**
 * Information about the set of assets which comprises a workspace, training or template, and an optional set of specific run parameters.
 * @export
 * @interface SpecificRunInfoFields
 */
export interface SpecificRunInfoFields {
    /**
     * 
     * @type {AssetsRef}
     * @memberof SpecificRunInfoFields
     */
    'assets': AssetsRef;
    /**
     * 
     * @type {SpecificRunParams}
     * @memberof SpecificRunInfoFields
     */
    'specificEnv'?: SpecificRunParams | null;
    /**
     * 
     * @type {InfoDistributed}
     * @memberof SpecificRunInfoFields
     */
    'distributed'?: InfoDistributed | null;
}
/**
 * Parameters which apply to a specific workload. They complement the information gathered from the assets comprising the workload. 
 * @export
 * @interface SpecificRunParams
 */
export interface SpecificRunParams {
    /**
     * A command to server as the entry point of the container running the workspace.
     * @type {string}
     * @memberof SpecificRunParams
     */
    'command'?: string | null;
    /**
     * Arguments to the command that the container running the workspace executes.
     * @type {string}
     * @memberof SpecificRunParams
     */
    'args'?: string | null;
    /**
     * Set of environment variables to populate into the container running the workspace.
     * @type {Array<EnvironmentVariable>}
     * @memberof SpecificRunParams
     */
    'environmentVariables'?: Array<EnvironmentVariable> | null;
    /**
     * The user id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsUid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof SpecificRunParams
     */
    'runAsUid'?: number | null;
    /**
     * The group id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsGid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof SpecificRunParams
     */
    'runAsGid'?: number | null;
    /**
     * Comma seperated list of groups that the user running the container belongs to, in addition to the group indicated by runAsGid. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled. Empty string implies reverting to the supplementary groups of the image.
     * @type {string}
     * @memberof SpecificRunParams
     */
    'supplementalGroups'?: string | null;
    /**
     * Nodes (machines) or a group of nodes on which the workload will run. To use this feature, your Administrator will need to label nodes as explained in the Group Nodes guide at https://docs.run.ai/admin/researcher-setup/limit-to-node-group. This flag can be used in conjunction with Project-based affinity. In this case, the flag is used to refine the list of allowable node groups set in the Project. For more information consult the Projects guide at https://docs.run.ai/admin/admin-ui-setup/project-setup.
     * @type {string}
     * @memberof SpecificRunParams
     */
    'nodeType'?: string | null;
    /**
     * A prioritize list of node pools for the scheduler to run the workspace on. The scheduler will always try to use the first node pool before moving to the next one when the fist is not available.
     * @type {Array<string>}
     * @memberof SpecificRunParams
     */
    'nodePools'?: Array<string> | null;
    /**
     * Set of annotations to populate into the container running the workspace.
     * @type {Array<Annotation>}
     * @memberof SpecificRunParams
     */
    'annotations'?: Array<Annotation> | null;
    /**
     * Set of labels to populate into the container running the workspace.
     * @type {Array<Label>}
     * @memberof SpecificRunParams
     */
    'labels'?: Array<Label> | null;
    /**
     * Indicates whether the job should be terminated, by the system, after it has been preempted.
     * @type {boolean}
     * @memberof SpecificRunParams
     */
    'terminateAfterPreemption'?: boolean | null;
    /**
     * Specifies the duration after which a finished workload (Completed or Failed) will be automatically deleted.
     * @type {number}
     * @memberof SpecificRunParams
     */
    'autoDeletionTimeAfterCompletionSeconds'?: number | null;
    /**
     * Specifies the number of retries before marking a workload as failed.
     * @type {number}
     * @memberof SpecificRunParams
     */
    'backoffLimit'?: number | null;
    /**
     * 
     * @type {Array<SpecificRunConnectionInfo>}
     * @memberof SpecificRunParams
     */
    'connections'?: Array<SpecificRunConnectionInfo> | null;
    /**
     * Whether to allow the workload to exceed the quota of the project.
     * @type {boolean}
     * @memberof SpecificRunParams
     */
    'allowOverQuota'?: boolean | null;
    /**
     * 
     * @type {SpecificRunAutoScalingAutoScaling}
     * @memberof SpecificRunParams
     */
    'autoScaling'?: SpecificRunAutoScalingAutoScaling | null;
}
/**
 * 
 * @export
 * @interface SpecificRunParamsCommon
 */
export interface SpecificRunParamsCommon {
    /**
     * A command to server as the entry point of the container running the workspace.
     * @type {string}
     * @memberof SpecificRunParamsCommon
     */
    'command'?: string | null;
    /**
     * Arguments to the command that the container running the workspace executes.
     * @type {string}
     * @memberof SpecificRunParamsCommon
     */
    'args'?: string | null;
    /**
     * Set of environment variables to populate into the container running the workspace.
     * @type {Array<EnvironmentVariable>}
     * @memberof SpecificRunParamsCommon
     */
    'environmentVariables'?: Array<EnvironmentVariable> | null;
    /**
     * The user id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsUid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof SpecificRunParamsCommon
     */
    'runAsUid'?: number | null;
    /**
     * The group id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsGid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof SpecificRunParamsCommon
     */
    'runAsGid'?: number | null;
    /**
     * Comma seperated list of groups that the user running the container belongs to, in addition to the group indicated by runAsGid. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled. Empty string implies reverting to the supplementary groups of the image.
     * @type {string}
     * @memberof SpecificRunParamsCommon
     */
    'supplementalGroups'?: string | null;
    /**
     * Nodes (machines) or a group of nodes on which the workload will run. To use this feature, your Administrator will need to label nodes as explained in the Group Nodes guide at https://docs.run.ai/admin/researcher-setup/limit-to-node-group. This flag can be used in conjunction with Project-based affinity. In this case, the flag is used to refine the list of allowable node groups set in the Project. For more information consult the Projects guide at https://docs.run.ai/admin/admin-ui-setup/project-setup.
     * @type {string}
     * @memberof SpecificRunParamsCommon
     */
    'nodeType'?: string | null;
    /**
     * A prioritize list of node pools for the scheduler to run the workspace on. The scheduler will always try to use the first node pool before moving to the next one when the fist is not available.
     * @type {Array<string>}
     * @memberof SpecificRunParamsCommon
     */
    'nodePools'?: Array<string> | null;
    /**
     * Set of annotations to populate into the container running the workspace.
     * @type {Array<Annotation>}
     * @memberof SpecificRunParamsCommon
     */
    'annotations'?: Array<Annotation> | null;
    /**
     * Set of labels to populate into the container running the workspace.
     * @type {Array<Label>}
     * @memberof SpecificRunParamsCommon
     */
    'labels'?: Array<Label> | null;
    /**
     * Indicates whether the job should be terminated, by the system, after it has been preempted.
     * @type {boolean}
     * @memberof SpecificRunParamsCommon
     */
    'terminateAfterPreemption'?: boolean | null;
    /**
     * Specifies the duration after which a finished workload (Completed or Failed) will be automatically deleted.
     * @type {number}
     * @memberof SpecificRunParamsCommon
     */
    'autoDeletionTimeAfterCompletionSeconds'?: number | null;
    /**
     * Specifies the number of retries before marking a workload as failed.
     * @type {number}
     * @memberof SpecificRunParamsCommon
     */
    'backoffLimit'?: number | null;
    /**
     * 
     * @type {Array<SpecificRunConnectionInfo>}
     * @memberof SpecificRunParamsCommon
     */
    'connections'?: Array<SpecificRunConnectionInfo> | null;
}
/**
 * Information about control-plane changes of sync related assets.
 * @export
 * @interface SyncInfoResponse
 */
export interface SyncInfoResponse {
    /**
     * 
     * @type {Array<SyncInfoResponseItem>}
     * @memberof SyncInfoResponse
     */
    'changes'?: Array<SyncInfoResponseItem>;
}
/**
 * Information about control-plane changes of sync related assets of a given project.
 * @export
 * @interface SyncInfoResponseItem
 */
export interface SyncInfoResponseItem {
    /**
     * The name of the project in which the assets reside. If empty - tenant scoped assets.
     * @type {string}
     * @memberof SyncInfoResponseItem
     */
    'projectName'?: string;
    /**
     * 
     * @type {number}
     * @memberof SyncInfoResponseItem
     */
    'secondsFromLatestChange': number;
}
/**
 * Information about control-plane changes of sync related assets of a given project.
 * @export
 * @interface SyncInfoResponseItemV2
 */
export interface SyncInfoResponseItemV2 {
    /**
     * 
     * @type {Scope}
     * @memberof SyncInfoResponseItemV2
     */
    'scope'?: Scope;
    /**
     * the id of the scope resource, empty for tenant scope, the id of the department for department scope and the project name for project scope.
     * @type {string}
     * @memberof SyncInfoResponseItemV2
     */
    'scopeResourceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof SyncInfoResponseItemV2
     */
    'secondsFromLatestChange': number;
}
/**
 * Information about control-plane changes of sync related assets.
 * @export
 * @interface SyncInfoResponseV2
 */
export interface SyncInfoResponseV2 {
    /**
     * 
     * @type {Array<SyncInfoResponseItemV2>}
     * @memberof SyncInfoResponseV2
     */
    'changes'?: Array<SyncInfoResponseItemV2>;
}
/**
 * A list of all the assets of a given kind that exist in a project.
 * @export
 * @interface SyncRequest
 */
export interface SyncRequest {
    /**
     * The id of the cluster. If empty - tenant scoped assets.
     * @type {string}
     * @memberof SyncRequest
     */
    'clusterId'?: string;
    /**
     * The name of the project in which the assets reside. If empty - tenant scoped assets.
     * @type {string}
     * @memberof SyncRequest
     */
    'projectName'?: string;
    /**
     * List of the assets which exist in the cluster
     * @type {Array<AssetNameAndChecksum>}
     * @memberof SyncRequest
     */
    'assets'?: Array<AssetNameAndChecksum>;
}
/**
 * A list of all the assets of a given kind that exist in a given scope with a specific scope resource id
 * @export
 * @interface SyncRequestV2
 */
export interface SyncRequestV2 {
    /**
     * The id of the cluster. If empty - tenant scoped assets.
     * @type {string}
     * @memberof SyncRequestV2
     */
    'clusterId'?: string;
    /**
     * 
     * @type {Scope}
     * @memberof SyncRequestV2
     */
    'scope'?: Scope;
    /**
     * the id of the scope resource, empty for tenant scope, the id of the department for department scope and the project name for project scope.
     * @type {string}
     * @memberof SyncRequestV2
     */
    'scopeResourceId'?: string;
    /**
     * List of the assets which exist in the cluster
     * @type {Array<AssetNameAndChecksum>}
     * @memberof SyncRequestV2
     */
    'assets'?: Array<AssetNameAndChecksum>;
}
/**
 * Super set of Internal and External tool types.
 * @export
 * @enum {string}
 */

export const ToolType = {
    JupyterNotebook: 'jupyter-notebook',
    Pycharm: 'pycharm',
    VisualStudioCode: 'visual-studio-code',
    Tensorboard: 'tensorboard',
    Rstudio: 'rstudio',
    Mlflow: 'mlflow',
    Custom: 'custom',
    Wandb: 'wandb',
    Matlab: 'matlab',
    Comet: 'comet',
    ChatbotUi: 'chatbot-ui'
} as const;

export type ToolType = typeof ToolType[keyof typeof ToolType];


/**
 * 
 * @export
 * @interface Training
 */
export interface Training {
    /**
     * 
     * @type {WorkloadMetaV1}
     * @memberof Training
     */
    'meta': WorkloadMetaV1;
    /**
     * 
     * @type {SpecificRunInfoFields}
     * @memberof Training
     */
    'spec': SpecificRunInfoFields;
    /**
     * A training workload ready for submission at the cluster. Further details at https://github.com/run-ai/runai-operator/tree/master/workload-controller/config/crd\"
     * @type {object}
     * @memberof Training
     */
    'workload'?: object;
    /**
     * 
     * @type {DisplayedJob}
     * @memberof Training
     */
    'job'?: DisplayedJob;
}
/**
 * Request to submit a training from a set of assets and an optional set of specific env parameters.
 * @export
 * @interface TrainingCreationRequestV2
 */
export interface TrainingCreationRequestV2 {
    /**
     * 
     * @type {WorkloadCreationMeta}
     * @memberof TrainingCreationRequestV2
     */
    'meta': WorkloadCreationMeta;
    /**
     * 
     * @type {AssetsIds}
     * @memberof TrainingCreationRequestV2
     */
    'assets': AssetsIds;
    /**
     * 
     * @type {TrainingSpecificRunParams}
     * @memberof TrainingCreationRequestV2
     */
    'specificRunParams'?: TrainingSpecificRunParams | null;
}
/**
 * 
 * @export
 * @interface TrainingListResponse
 */
export interface TrainingListResponse {
    /**
     * 
     * @type {Array<Training>}
     * @memberof TrainingListResponse
     */
    'entries': Array<Training>;
}
/**
 * 
 * @export
 * @interface TrainingSpecificRunParams
 */
export interface TrainingSpecificRunParams {
    /**
     * A command to server as the entry point of the container running the workspace.
     * @type {string}
     * @memberof TrainingSpecificRunParams
     */
    'command'?: string | null;
    /**
     * Arguments to the command that the container running the workspace executes.
     * @type {string}
     * @memberof TrainingSpecificRunParams
     */
    'args'?: string | null;
    /**
     * Set of environment variables to populate into the container running the workspace.
     * @type {Array<EnvironmentVariable>}
     * @memberof TrainingSpecificRunParams
     */
    'environmentVariables'?: Array<EnvironmentVariable> | null;
    /**
     * The user id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsUid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof TrainingSpecificRunParams
     */
    'runAsUid'?: number | null;
    /**
     * The group id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsGid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof TrainingSpecificRunParams
     */
    'runAsGid'?: number | null;
    /**
     * Comma seperated list of groups that the user running the container belongs to, in addition to the group indicated by runAsGid. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled. Empty string implies reverting to the supplementary groups of the image.
     * @type {string}
     * @memberof TrainingSpecificRunParams
     */
    'supplementalGroups'?: string | null;
    /**
     * Nodes (machines) or a group of nodes on which the workload will run. To use this feature, your Administrator will need to label nodes as explained in the Group Nodes guide at https://docs.run.ai/admin/researcher-setup/limit-to-node-group. This flag can be used in conjunction with Project-based affinity. In this case, the flag is used to refine the list of allowable node groups set in the Project. For more information consult the Projects guide at https://docs.run.ai/admin/admin-ui-setup/project-setup.
     * @type {string}
     * @memberof TrainingSpecificRunParams
     */
    'nodeType'?: string | null;
    /**
     * A prioritize list of node pools for the scheduler to run the workspace on. The scheduler will always try to use the first node pool before moving to the next one when the fist is not available.
     * @type {Array<string>}
     * @memberof TrainingSpecificRunParams
     */
    'nodePools'?: Array<string> | null;
    /**
     * Set of annotations to populate into the container running the workspace.
     * @type {Array<Annotation>}
     * @memberof TrainingSpecificRunParams
     */
    'annotations'?: Array<Annotation> | null;
    /**
     * Set of labels to populate into the container running the workspace.
     * @type {Array<Label>}
     * @memberof TrainingSpecificRunParams
     */
    'labels'?: Array<Label> | null;
    /**
     * Indicates whether the job should be terminated, by the system, after it has been preempted.
     * @type {boolean}
     * @memberof TrainingSpecificRunParams
     */
    'terminateAfterPreemption'?: boolean | null;
    /**
     * Specifies the duration after which a finished workload (Completed or Failed) will be automatically deleted.
     * @type {number}
     * @memberof TrainingSpecificRunParams
     */
    'autoDeletionTimeAfterCompletionSeconds'?: number | null;
    /**
     * Specifies the number of retries before marking a workload as failed.
     * @type {number}
     * @memberof TrainingSpecificRunParams
     */
    'backoffLimit'?: number | null;
    /**
     * 
     * @type {Array<SpecificRunConnectionInfo>}
     * @memberof TrainingSpecificRunParams
     */
    'connections'?: Array<SpecificRunConnectionInfo> | null;
    /**
     * Whether to allow the workload to exceed the quota of the project.
     * @type {boolean}
     * @memberof TrainingSpecificRunParams
     */
    'allowOverQuota'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TrainingSpecificRunParams
     */
    'numWorkers'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TrainingSpecificRunParams
     */
    'noMaster'?: boolean | null;
    /**
     * 
     * @type {DistributedFramework}
     * @memberof TrainingSpecificRunParams
     */
    'distFramework'?: DistributedFramework;
    /**
     * 
     * @type {InfoDistMaster}
     * @memberof TrainingSpecificRunParams
     */
    'master'?: InfoDistMaster | null;
}
/**
 * information about a workspace created based on assets.
 * @export
 * @interface TrainingV2
 */
export interface TrainingV2 {
    /**
     * 
     * @type {WorkloadMeta}
     * @memberof TrainingV2
     */
    'meta': WorkloadMeta;
    /**
     * 
     * @type {AssetsIds}
     * @memberof TrainingV2
     */
    'assets': AssetsIds;
    /**
     * 
     * @type {TrainingSpecificRunParams}
     * @memberof TrainingV2
     */
    'specificRunParams'?: TrainingSpecificRunParams | null;
}
/**
 * Details about times the asset has been used by workloads, etc.
 * @export
 * @interface UsageTimesInfo
 */
export interface UsageTimesInfo {
    /**
     * The time at which the asset were last used by a workload of given type and project. This field requires complyToProject and complyToWorkload types flags of the API, to specify the workload type and project id.
     * @type {string}
     * @memberof UsageTimesInfo
     */
    'lastUsedByWorkload'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkloadCreationMeta
 */
export interface WorkloadCreationMeta {
    /**
     * The name of the workload.
     * @type {string}
     * @memberof WorkloadCreationMeta
     */
    'name': string;
    /**
     * If true, the requested name will be treated as a prefix. The final name of the workload will be composed of the name followed by a random set of characters.
     * @type {boolean}
     * @memberof WorkloadCreationMeta
     */
    'useGivenNameAsPrefix'?: boolean;
    /**
     * The id of the project.
     * @type {string}
     * @memberof WorkloadCreationMeta
     */
    'projectId': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof WorkloadCreationMeta
     */
    'clusterId': string;
}
/**
 * Request to create a workload from a set of assets and an optional set of specific env parameters.
 * @export
 * @interface WorkloadCreationRequest
 */
export interface WorkloadCreationRequest {
    /**
     * The name of the workload.
     * @type {string}
     * @memberof WorkloadCreationRequest
     */
    'name': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof WorkloadCreationRequest
     */
    'clusterId': string;
    /**
     * The id of the project.
     * @type {number}
     * @memberof WorkloadCreationRequest
     */
    'projectId': number;
    /**
     * Namespace of the project in which the workload should be created.
     * @type {string}
     * @memberof WorkloadCreationRequest
     */
    'namespace': string;
    /**
     * 
     * @type {AssetsIds}
     * @memberof WorkloadCreationRequest
     */
    'assets': AssetsIds;
    /**
     * 
     * @type {SpecificRunParams}
     * @memberof WorkloadCreationRequest
     */
    'specificEnv'?: SpecificRunParams | null;
    /**
     * 
     * @type {Distributed}
     * @memberof WorkloadCreationRequest
     */
    'distributed'?: Distributed | null;
}
/**
 * The desired phase of the workload.
 * @export
 * @enum {string}
 */

export const WorkloadDesiredPhase = {
    Running: 'Running',
    Stopped: 'Stopped',
    Deleted: 'Deleted'
} as const;

export type WorkloadDesiredPhase = typeof WorkloadDesiredPhase[keyof typeof WorkloadDesiredPhase];


/**
 * 
 * @export
 * @interface WorkloadMeta
 */
export interface WorkloadMeta {
    /**
     * The name of the workload.
     * @type {string}
     * @memberof WorkloadMeta
     */
    'name': string;
    /**
     * The name as was requested for the workload. If useGivenNameAsPrefix, in the creation request, is false, name and requestedName should be identical. Otherwise, name should be composed of requestedName followed by a suffix of random characters.
     * @type {string}
     * @memberof WorkloadMeta
     */
    'requestedName': string;
    /**
     * A unique ID of the workload.
     * @type {string}
     * @memberof WorkloadMeta
     */
    'workloadId': string;
    /**
     * The id of the project.
     * @type {string}
     * @memberof WorkloadMeta
     */
    'projectId': string;
    /**
     * The id of the department.
     * @type {string}
     * @memberof WorkloadMeta
     */
    'departmentId'?: string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof WorkloadMeta
     */
    'clusterId': string;
    /**
     * The user who created the workload
     * @type {string}
     * @memberof WorkloadMeta
     */
    'createdBy': string;
    /**
     * The creation time of the workload.
     * @type {string}
     * @memberof WorkloadMeta
     */
    'createdAt': string;
    /**
     * 
     * @type {WorkloadDesiredPhase}
     * @memberof WorkloadMeta
     */
    'desiredPhase': WorkloadDesiredPhase;
    /**
     * 
     * @type {Phase}
     * @memberof WorkloadMeta
     */
    'actualPhase'?: Phase;
}
/**
 * Metadata of the workspace and training.
 * @export
 * @interface WorkloadMetaV1
 */
export interface WorkloadMetaV1 {
    /**
     * A unique ID of the workload.
     * @type {string}
     * @memberof WorkloadMetaV1
     */
    'id': string;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof WorkloadMetaV1
     */
    'tenantId': number;
    /**
     * The id of the project.
     * @type {number}
     * @memberof WorkloadMetaV1
     */
    'projectId': number;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof WorkloadMetaV1
     */
    'clusterId': string;
    /**
     * The name of the workload.
     * @type {string}
     * @memberof WorkloadMetaV1
     */
    'name': string;
    /**
     * 
     * @type {WorkloadState}
     * @memberof WorkloadMetaV1
     */
    'desiredState': WorkloadState;
    /**
     * 
     * @type {WorkloadState}
     * @memberof WorkloadMetaV1
     */
    'currentState': WorkloadState;
    /**
     * The user who created the workspace or training.
     * @type {string}
     * @memberof WorkloadMetaV1
     */
    'createdBy': string;
    /**
     * The recent user who updated the workspace or training.
     * @type {string}
     * @memberof WorkloadMetaV1
     */
    'updatedBy': string;
}
/**
 * Id, name and status of a workspace or training
 * @export
 * @interface WorkloadRefAndStatus
 */
export interface WorkloadRefAndStatus {
    /**
     * A unique ID of the workload.
     * @type {string}
     * @memberof WorkloadRefAndStatus
     */
    'id': string;
    /**
     * The name of the workload.
     * @type {string}
     * @memberof WorkloadRefAndStatus
     */
    'name': string;
    /**
     * The status of the workspace or training.
     * @type {string}
     * @memberof WorkloadRefAndStatus
     */
    'status'?: string;
}
/**
 * Reflects the status of the workload created for a workspace or a training. It does not reflect the status of the job created from the workload.
 * @export
 * @enum {string}
 */

export const WorkloadState = {
    Empty: '',
    Submitted: 'Submitted',
    Failed: 'Failed'
} as const;

export type WorkloadState = typeof WorkloadState[keyof typeof WorkloadState];


/**
 * 
 * @export
 * @interface WorkloadSupportedTypes
 */
export interface WorkloadSupportedTypes {
    /**
     * Is inference a supported workload type.
     * @type {boolean}
     * @memberof WorkloadSupportedTypes
     */
    'inference'?: boolean | null;
    /**
     * Is workspace a supported workload type.
     * @type {boolean}
     * @memberof WorkloadSupportedTypes
     */
    'workspace'?: boolean | null;
    /**
     * Is training a supported workload type.
     * @type {boolean}
     * @memberof WorkloadSupportedTypes
     */
    'training'?: boolean | null;
    /**
     * Is distributed a supported workload type.
     * @type {boolean}
     * @memberof WorkloadSupportedTypes
     */
    'distributed'?: boolean | null;
    /**
     * The distributed training framework used in the workload.
     * @type {string}
     * @memberof WorkloadSupportedTypes
     */
    'distFramework'?: WorkloadSupportedTypesDistFrameworkEnum;
}

export const WorkloadSupportedTypesDistFrameworkEnum = {
    Mpi: 'MPI',
    PyTorch: 'PyTorch',
    Tf: 'TF',
    XgBoost: 'XGBoost'
} as const;

export type WorkloadSupportedTypesDistFrameworkEnum = typeof WorkloadSupportedTypesDistFrameworkEnum[keyof typeof WorkloadSupportedTypesDistFrameworkEnum];

/**
 * 
 * @export
 * @interface WorkloadTemplate
 */
export interface WorkloadTemplate {
    /**
     * 
     * @type {AssetMeta}
     * @memberof WorkloadTemplate
     */
    'meta': AssetMeta;
    /**
     * 
     * @type {SpecificRunInfoFields}
     * @memberof WorkloadTemplate
     */
    'spec': SpecificRunInfoFields;
}
/**
 * 
 * @export
 * @interface WorkloadTemplateCreationRequest
 */
export interface WorkloadTemplateCreationRequest {
    /**
     * 
     * @type {AssetCreationRequest}
     * @memberof WorkloadTemplateCreationRequest
     */
    'meta': AssetCreationRequest;
    /**
     * 
     * @type {SpecificRunCreationFields}
     * @memberof WorkloadTemplateCreationRequest
     */
    'spec': SpecificRunCreationFields;
}
/**
 * 
 * @export
 * @interface WorkloadTemplateListResponse
 */
export interface WorkloadTemplateListResponse {
    /**
     * 
     * @type {Array<WorkloadTemplate>}
     * @memberof WorkloadTemplateListResponse
     */
    'entries': Array<WorkloadTemplate>;
}
/**
 * 
 * @export
 * @interface WorkloadTemplateUpdateRequest
 */
export interface WorkloadTemplateUpdateRequest {
    /**
     * 
     * @type {AssetUpdateRequest}
     * @memberof WorkloadTemplateUpdateRequest
     */
    'meta': AssetUpdateRequest;
    /**
     * 
     * @type {SpecificRunCreationFields}
     * @memberof WorkloadTemplateUpdateRequest
     */
    'spec': SpecificRunCreationFields;
}
/**
 * 
 * @export
 * @interface Workspace
 */
export interface Workspace {
    /**
     * 
     * @type {WorkloadMetaV1}
     * @memberof Workspace
     */
    'meta': WorkloadMetaV1;
    /**
     * 
     * @type {SpecificRunInfoFields}
     * @memberof Workspace
     */
    'spec': SpecificRunInfoFields;
    /**
     * An interactive workload ready for submission at the cluster. Further details at https://github.com/run-ai/runai-operator/tree/master/workload-controller/config/crd\"
     * @type {object}
     * @memberof Workspace
     */
    'workload'?: object;
    /**
     * 
     * @type {DisplayedJob}
     * @memberof Workspace
     */
    'job'?: DisplayedJob;
}
/**
 * Request to submit a workload from a set of assets and an optional set of specific env parameters.
 * @export
 * @interface WorkspaceCreationRequestV2
 */
export interface WorkspaceCreationRequestV2 {
    /**
     * 
     * @type {WorkloadCreationMeta}
     * @memberof WorkspaceCreationRequestV2
     */
    'meta': WorkloadCreationMeta;
    /**
     * 
     * @type {AssetsIds}
     * @memberof WorkspaceCreationRequestV2
     */
    'assets': AssetsIds;
    /**
     * 
     * @type {WorkspaceSpecificRunParams}
     * @memberof WorkspaceCreationRequestV2
     */
    'specificRunParams'?: WorkspaceSpecificRunParams | null;
}
/**
 * 
 * @export
 * @interface WorkspaceListResponse
 */
export interface WorkspaceListResponse {
    /**
     * 
     * @type {Array<Workspace>}
     * @memberof WorkspaceListResponse
     */
    'entries': Array<Workspace>;
}
/**
 * 
 * @export
 * @interface WorkspaceSpecificRunParams
 */
export interface WorkspaceSpecificRunParams {
    /**
     * A command to server as the entry point of the container running the workspace.
     * @type {string}
     * @memberof WorkspaceSpecificRunParams
     */
    'command'?: string | null;
    /**
     * Arguments to the command that the container running the workspace executes.
     * @type {string}
     * @memberof WorkspaceSpecificRunParams
     */
    'args'?: string | null;
    /**
     * Set of environment variables to populate into the container running the workspace.
     * @type {Array<EnvironmentVariable>}
     * @memberof WorkspaceSpecificRunParams
     */
    'environmentVariables'?: Array<EnvironmentVariable> | null;
    /**
     * The user id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsUid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof WorkspaceSpecificRunParams
     */
    'runAsUid'?: number | null;
    /**
     * The group id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsGid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof WorkspaceSpecificRunParams
     */
    'runAsGid'?: number | null;
    /**
     * Comma seperated list of groups that the user running the container belongs to, in addition to the group indicated by runAsGid. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled. Empty string implies reverting to the supplementary groups of the image.
     * @type {string}
     * @memberof WorkspaceSpecificRunParams
     */
    'supplementalGroups'?: string | null;
    /**
     * Nodes (machines) or a group of nodes on which the workload will run. To use this feature, your Administrator will need to label nodes as explained in the Group Nodes guide at https://docs.run.ai/admin/researcher-setup/limit-to-node-group. This flag can be used in conjunction with Project-based affinity. In this case, the flag is used to refine the list of allowable node groups set in the Project. For more information consult the Projects guide at https://docs.run.ai/admin/admin-ui-setup/project-setup.
     * @type {string}
     * @memberof WorkspaceSpecificRunParams
     */
    'nodeType'?: string | null;
    /**
     * A prioritize list of node pools for the scheduler to run the workspace on. The scheduler will always try to use the first node pool before moving to the next one when the fist is not available.
     * @type {Array<string>}
     * @memberof WorkspaceSpecificRunParams
     */
    'nodePools'?: Array<string> | null;
    /**
     * Set of annotations to populate into the container running the workspace.
     * @type {Array<Annotation>}
     * @memberof WorkspaceSpecificRunParams
     */
    'annotations'?: Array<Annotation> | null;
    /**
     * Set of labels to populate into the container running the workspace.
     * @type {Array<Label>}
     * @memberof WorkspaceSpecificRunParams
     */
    'labels'?: Array<Label> | null;
    /**
     * Indicates whether the job should be terminated, by the system, after it has been preempted.
     * @type {boolean}
     * @memberof WorkspaceSpecificRunParams
     */
    'terminateAfterPreemption'?: boolean | null;
    /**
     * Specifies the duration after which a finished workload (Completed or Failed) will be automatically deleted.
     * @type {number}
     * @memberof WorkspaceSpecificRunParams
     */
    'autoDeletionTimeAfterCompletionSeconds'?: number | null;
    /**
     * Specifies the number of retries before marking a workload as failed.
     * @type {number}
     * @memberof WorkspaceSpecificRunParams
     */
    'backoffLimit'?: number | null;
    /**
     * 
     * @type {Array<SpecificRunConnectionInfo>}
     * @memberof WorkspaceSpecificRunParams
     */
    'connections'?: Array<SpecificRunConnectionInfo> | null;
    /**
     * Whether to allow the workload to exceed the quota of the project.
     * @type {boolean}
     * @memberof WorkspaceSpecificRunParams
     */
    'allowOverQuota'?: boolean | null;
}
/**
 * information about a workspace created based on assets.
 * @export
 * @interface WorkspaceV2
 */
export interface WorkspaceV2 {
    /**
     * 
     * @type {WorkloadMeta}
     * @memberof WorkspaceV2
     */
    'meta': WorkloadMeta;
    /**
     * 
     * @type {AssetsIds}
     * @memberof WorkspaceV2
     */
    'assets': AssetsIds;
    /**
     * 
     * @type {WorkspaceSpecificRunParams}
     * @memberof WorkspaceV2
     */
    'specificRunParams'?: WorkspaceSpecificRunParams | null;
}

/**
 * ComputeApi - axios parameter creator
 * @export
 */
export const ComputeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a compute asset.
         * @summary Create compute asset.
         * @param {ComputeCreationRequest} [computeCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComputeAsset: async (computeCreationRequest?: ComputeCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/compute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(computeCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a compute asset, by id.
         * @summary Delete compute asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComputeAssetById: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deleteComputeAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/compute/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of a compute asset, by id.
         * @summary Get compute asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComputeAssetById: async (assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getComputeAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/compute/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of compute assets.
         * @summary List compute assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComputeAssets: async (name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/compute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the details of a compute asset, by id.
         * @summary Update compute asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {ComputeUpdateRequest} [computeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComputeAssetById: async (assetId: string, computeUpdateRequest?: ComputeUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('updateComputeAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/compute/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(computeUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComputeApi - functional programming interface
 * @export
 */
export const ComputeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComputeApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a compute asset.
         * @summary Create compute asset.
         * @param {ComputeCreationRequest} [computeCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComputeAsset(computeCreationRequest?: ComputeCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComputeAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComputeAsset(computeCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a compute asset, by id.
         * @summary Delete compute asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComputeAssetById(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComputeAssetById(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of a compute asset, by id.
         * @summary Get compute asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComputeAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComputeAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComputeAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of compute assets.
         * @summary List compute assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listComputeAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComputeListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listComputeAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the details of a compute asset, by id.
         * @summary Update compute asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {ComputeUpdateRequest} [computeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateComputeAssetById(assetId: string, computeUpdateRequest?: ComputeUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComputeAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateComputeAssetById(assetId, computeUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComputeApi - factory interface
 * @export
 */
export const ComputeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComputeApiFp(configuration)
    return {
        /**
         * Creates a compute asset.
         * @summary Create compute asset.
         * @param {ComputeCreationRequest} [computeCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComputeAsset(computeCreationRequest?: ComputeCreationRequest, options?: any): AxiosPromise<ComputeAsset> {
            return localVarFp.createComputeAsset(computeCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a compute asset, by id.
         * @summary Delete compute asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComputeAssetById(assetId: string, options?: any): AxiosPromise<HttpResponse> {
            return localVarFp.deleteComputeAssetById(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of a compute asset, by id.
         * @summary Get compute asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComputeAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: any): AxiosPromise<ComputeAsset> {
            return localVarFp.getComputeAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of compute assets.
         * @summary List compute assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComputeAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: any): AxiosPromise<ComputeListResponse> {
            return localVarFp.listComputeAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the details of a compute asset, by id.
         * @summary Update compute asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {ComputeUpdateRequest} [computeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComputeAssetById(assetId: string, computeUpdateRequest?: ComputeUpdateRequest, options?: any): AxiosPromise<ComputeAsset> {
            return localVarFp.updateComputeAssetById(assetId, computeUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComputeApi - object-oriented interface
 * @export
 * @class ComputeApi
 * @extends {BaseAPI}
 */
export class ComputeApi extends BaseAPI {
    /**
     * Creates a compute asset.
     * @summary Create compute asset.
     * @param {ComputeCreationRequest} [computeCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComputeApi
     */
    public createComputeAsset(computeCreationRequest?: ComputeCreationRequest, options?: AxiosRequestConfig) {
        return ComputeApiFp(this.configuration).createComputeAsset(computeCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a compute asset, by id.
     * @summary Delete compute asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComputeApi
     */
    public deleteComputeAssetById(assetId: string, options?: AxiosRequestConfig) {
        return ComputeApiFp(this.configuration).deleteComputeAssetById(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of a compute asset, by id.
     * @summary Get compute asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComputeApi
     */
    public getComputeAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: AxiosRequestConfig) {
        return ComputeApiFp(this.configuration).getComputeAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of compute assets.
     * @summary List compute assets.
     * @param {string} [name] Filter results by name.
     * @param {string} [scope] Filter results by scope.
     * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
     * @param {string} [departmentId] department id to filter by
     * @param {string} [clusterId] cluster id to filter by
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComputeApi
     */
    public listComputeAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: AxiosRequestConfig) {
        return ComputeApiFp(this.configuration).listComputeAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the details of a compute asset, by id.
     * @summary Update compute asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {ComputeUpdateRequest} [computeUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComputeApi
     */
    public updateComputeAssetById(assetId: string, computeUpdateRequest?: ComputeUpdateRequest, options?: AxiosRequestConfig) {
        return ComputeApiFp(this.configuration).updateComputeAssetById(assetId, computeUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigMapApi - axios parameter creator
 * @export
 */
export const ConfigMapApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a ConfigMap datasource asset.
         * @summary Create ConfigMap asset.
         * @param {ConfigMapCreationRequest} [configMapCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConfigMapAsset: async (configMapCreationRequest?: ConfigMapCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/datasource/config-map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configMapCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes ConfigMap datasource asset, by id.
         * @summary Delete ConfigMap asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConfigMapAssetById: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deleteConfigMapAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/config-map/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of ConfigMap datasource asset, by id.
         * @summary Get ConfigMap asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigMapAssetById: async (assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getConfigMapAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/config-map/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }

            if (statusInfo !== undefined) {
                localVarQueryParameter['statusInfo'] = statusInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of ConfigMap datasource assets.
         * @summary List ConfigMap datasource assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConfigMapAssets: async (name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/datasource/config-map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }

            if (statusInfo !== undefined) {
                localVarQueryParameter['statusInfo'] = statusInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the details of ConfigMap datasource asset, by id.
         * @summary Update ConfigMap asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {ConfigMapUpdateRequest} [configMapUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfigMapAssetById: async (assetId: string, configMapUpdateRequest?: ConfigMapUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('updateConfigMapAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/config-map/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configMapUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigMapApi - functional programming interface
 * @export
 */
export const ConfigMapApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigMapApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a ConfigMap datasource asset.
         * @summary Create ConfigMap asset.
         * @param {ConfigMapCreationRequest} [configMapCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConfigMapAsset(configMapCreationRequest?: ConfigMapCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigMapAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConfigMapAsset(configMapCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes ConfigMap datasource asset, by id.
         * @summary Delete ConfigMap asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConfigMapAssetById(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConfigMapAssetById(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of ConfigMap datasource asset, by id.
         * @summary Get ConfigMap asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigMapAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigMapAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigMapAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of ConfigMap datasource assets.
         * @summary List ConfigMap datasource assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConfigMapAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigMapListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConfigMapAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the details of ConfigMap datasource asset, by id.
         * @summary Update ConfigMap asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {ConfigMapUpdateRequest} [configMapUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConfigMapAssetById(assetId: string, configMapUpdateRequest?: ConfigMapUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigMapAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConfigMapAssetById(assetId, configMapUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigMapApi - factory interface
 * @export
 */
export const ConfigMapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigMapApiFp(configuration)
    return {
        /**
         * Creates a ConfigMap datasource asset.
         * @summary Create ConfigMap asset.
         * @param {ConfigMapCreationRequest} [configMapCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConfigMapAsset(configMapCreationRequest?: ConfigMapCreationRequest, options?: any): AxiosPromise<ConfigMapAsset> {
            return localVarFp.createConfigMapAsset(configMapCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes ConfigMap datasource asset, by id.
         * @summary Delete ConfigMap asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConfigMapAssetById(assetId: string, options?: any): AxiosPromise<HttpResponse> {
            return localVarFp.deleteConfigMapAssetById(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of ConfigMap datasource asset, by id.
         * @summary Get ConfigMap asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigMapAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: any): AxiosPromise<ConfigMapAsset> {
            return localVarFp.getConfigMapAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of ConfigMap datasource assets.
         * @summary List ConfigMap datasource assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConfigMapAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: any): AxiosPromise<ConfigMapListResponse> {
            return localVarFp.listConfigMapAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the details of ConfigMap datasource asset, by id.
         * @summary Update ConfigMap asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {ConfigMapUpdateRequest} [configMapUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfigMapAssetById(assetId: string, configMapUpdateRequest?: ConfigMapUpdateRequest, options?: any): AxiosPromise<ConfigMapAsset> {
            return localVarFp.updateConfigMapAssetById(assetId, configMapUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigMapApi - object-oriented interface
 * @export
 * @class ConfigMapApi
 * @extends {BaseAPI}
 */
export class ConfigMapApi extends BaseAPI {
    /**
     * Creates a ConfigMap datasource asset.
     * @summary Create ConfigMap asset.
     * @param {ConfigMapCreationRequest} [configMapCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigMapApi
     */
    public createConfigMapAsset(configMapCreationRequest?: ConfigMapCreationRequest, options?: AxiosRequestConfig) {
        return ConfigMapApiFp(this.configuration).createConfigMapAsset(configMapCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes ConfigMap datasource asset, by id.
     * @summary Delete ConfigMap asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigMapApi
     */
    public deleteConfigMapAssetById(assetId: string, options?: AxiosRequestConfig) {
        return ConfigMapApiFp(this.configuration).deleteConfigMapAssetById(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of ConfigMap datasource asset, by id.
     * @summary Get ConfigMap asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigMapApi
     */
    public getConfigMapAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig) {
        return ConfigMapApiFp(this.configuration).getConfigMapAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of ConfigMap datasource assets.
     * @summary List ConfigMap datasource assets.
     * @param {string} [name] Filter results by name.
     * @param {string} [scope] Filter results by scope.
     * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
     * @param {string} [departmentId] department id to filter by
     * @param {string} [clusterId] cluster id to filter by
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigMapApi
     */
    public listConfigMapAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig) {
        return ConfigMapApiFp(this.configuration).listConfigMapAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the details of ConfigMap datasource asset, by id.
     * @summary Update ConfigMap asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {ConfigMapUpdateRequest} [configMapUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigMapApi
     */
    public updateConfigMapAssetById(assetId: string, configMapUpdateRequest?: ConfigMapUpdateRequest, options?: AxiosRequestConfig) {
        return ConfigMapApiFp(this.configuration).updateConfigMapAssetById(assetId, configMapUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CredentialsApi - axios parameter creator
 * @export
 */
export const CredentialsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates S3-compatible access key asset.
         * @summary Create access key.
         * @param {AccessKeyCreationRequest} accessKeyCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessKey: async (accessKeyCreationRequest: AccessKeyCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessKeyCreationRequest' is not null or undefined
            assertParamExists('createAccessKey', 'accessKeyCreationRequest', accessKeyCreationRequest)
            const localVarPath = `/api/v1/asset/credentials/access-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessKeyCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates docker registry credentials asset containing user, password and url
         * @summary Create docker registry credentials.
         * @param {DockerRegistryCreationRequest} dockerRegistryCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDockerRegistry: async (dockerRegistryCreationRequest: DockerRegistryCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dockerRegistryCreationRequest' is not null or undefined
            assertParamExists('createDockerRegistry', 'dockerRegistryCreationRequest', dockerRegistryCreationRequest)
            const localVarPath = `/api/v1/asset/credentials/docker-registry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dockerRegistryCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates credentials asset containing user and password.
         * @summary Create password credentials.
         * @param {PasswordCreationRequest} passwordCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPassword: async (passwordCreationRequest: PasswordCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordCreationRequest' is not null or undefined
            assertParamExists('createPassword', 'passwordCreationRequest', passwordCreationRequest)
            const localVarPath = `/api/v1/asset/credentials/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes S3-compatible access key asset, by id.
         * @summary Delete access key.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessKey: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deleteAccessKey', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/credentials/access-key/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes docker registry credentials asset, by id.
         * @summary Delete docker registry credentials asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDockerRegistry: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deleteDockerRegistry', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/credentials/docker-registry/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes password credentials asset, by id.
         * @summary Delete password asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePassword: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deletePassword', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/credentials/password/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of S3-compatible access key asset, by id.
         * @summary Get access key.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessKeyById: async (assetId: string, usageInfo?: boolean, statusInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getAccessKeyById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/credentials/access-key/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (statusInfo !== undefined) {
                localVarQueryParameter['statusInfo'] = statusInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of docker registry credentials asset, by id.
         * @summary Get docker registry credentials.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDockerRegistryById: async (assetId: string, usageInfo?: boolean, statusInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getDockerRegistryById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/credentials/docker-registry/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (statusInfo !== undefined) {
                localVarQueryParameter['statusInfo'] = statusInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of password credentials asset, by id.
         * @summary Get password asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPasswordById: async (assetId: string, usageInfo?: boolean, statusInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getPasswordById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/credentials/password/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (statusInfo !== undefined) {
                localVarQueryParameter['statusInfo'] = statusInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of S3-compatible access key assets.
         * @summary List access keys.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccessKeys: async (name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, statusInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/credentials/access-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (statusInfo !== undefined) {
                localVarQueryParameter['statusInfo'] = statusInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a combined list of credentials.
         * @summary List credentials assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCredentialsAssets: async (name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, statusInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (statusInfo !== undefined) {
                localVarQueryParameter['statusInfo'] = statusInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of docker registry credentials assets.
         * @summary List docker registry credentials.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDockerRegistries: async (name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, statusInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/credentials/docker-registry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (statusInfo !== undefined) {
                localVarQueryParameter['statusInfo'] = statusInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of password credentials assets.
         * @summary List password credentials.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPasswords: async (name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, statusInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/credentials/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (statusInfo !== undefined) {
                localVarQueryParameter['statusInfo'] = statusInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the details of S3-compatible access key asset, by id.
         * @summary Update access key.
         * @param {string} assetId Unique identifier of the asset.
         * @param {AccessKeyUpdateRequest} accessKeyUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccessKey: async (assetId: string, accessKeyUpdateRequest: AccessKeyUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('updateAccessKey', 'assetId', assetId)
            // verify required parameter 'accessKeyUpdateRequest' is not null or undefined
            assertParamExists('updateAccessKey', 'accessKeyUpdateRequest', accessKeyUpdateRequest)
            const localVarPath = `/api/v1/asset/credentials/access-key/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessKeyUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the details of docker registry credentials asset, by id.
         * @summary Update docker registry credentials.
         * @param {string} assetId Unique identifier of the asset.
         * @param {DockerRegistryUpdateRequest} dockerRegistryUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDockerRegistry: async (assetId: string, dockerRegistryUpdateRequest: DockerRegistryUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('updateDockerRegistry', 'assetId', assetId)
            // verify required parameter 'dockerRegistryUpdateRequest' is not null or undefined
            assertParamExists('updateDockerRegistry', 'dockerRegistryUpdateRequest', dockerRegistryUpdateRequest)
            const localVarPath = `/api/v1/asset/credentials/docker-registry/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dockerRegistryUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the details of password credentials asset, by id.
         * @summary Update password credentials.
         * @param {string} assetId Unique identifier of the asset.
         * @param {PasswordUpdateRequest} passwordUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (assetId: string, passwordUpdateRequest: PasswordUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('updatePassword', 'assetId', assetId)
            // verify required parameter 'passwordUpdateRequest' is not null or undefined
            assertParamExists('updatePassword', 'passwordUpdateRequest', passwordUpdateRequest)
            const localVarPath = `/api/v1/asset/credentials/password/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CredentialsApi - functional programming interface
 * @export
 */
export const CredentialsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CredentialsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates S3-compatible access key asset.
         * @summary Create access key.
         * @param {AccessKeyCreationRequest} accessKeyCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccessKey(accessKeyCreationRequest: AccessKeyCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccessKey(accessKeyCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates docker registry credentials asset containing user, password and url
         * @summary Create docker registry credentials.
         * @param {DockerRegistryCreationRequest} dockerRegistryCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDockerRegistry(dockerRegistryCreationRequest: DockerRegistryCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DockerRegistry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDockerRegistry(dockerRegistryCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates credentials asset containing user and password.
         * @summary Create password credentials.
         * @param {PasswordCreationRequest} passwordCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPassword(passwordCreationRequest: PasswordCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Password>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPassword(passwordCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes S3-compatible access key asset, by id.
         * @summary Delete access key.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccessKey(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccessKey(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes docker registry credentials asset, by id.
         * @summary Delete docker registry credentials asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDockerRegistry(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDockerRegistry(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes password credentials asset, by id.
         * @summary Delete password asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePassword(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePassword(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of S3-compatible access key asset, by id.
         * @summary Get access key.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessKeyById(assetId: string, usageInfo?: boolean, statusInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessKeyById(assetId, usageInfo, statusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of docker registry credentials asset, by id.
         * @summary Get docker registry credentials.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDockerRegistryById(assetId: string, usageInfo?: boolean, statusInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DockerRegistry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDockerRegistryById(assetId, usageInfo, statusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of password credentials asset, by id.
         * @summary Get password asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPasswordById(assetId: string, usageInfo?: boolean, statusInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Password>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPasswordById(assetId, usageInfo, statusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of S3-compatible access key assets.
         * @summary List access keys.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccessKeys(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, statusInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessKeyListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAccessKeys(name, scope, projectId, departmentId, clusterId, usageInfo, statusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a combined list of credentials.
         * @summary List credentials assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCredentialsAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, statusInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CredentialsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCredentialsAssets(name, scope, projectId, departmentId, clusterId, usageInfo, statusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of docker registry credentials assets.
         * @summary List docker registry credentials.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDockerRegistries(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, statusInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DockerRegistryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDockerRegistries(name, scope, projectId, departmentId, clusterId, usageInfo, statusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of password credentials assets.
         * @summary List password credentials.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPasswords(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, statusInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPasswords(name, scope, projectId, departmentId, clusterId, usageInfo, statusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the details of S3-compatible access key asset, by id.
         * @summary Update access key.
         * @param {string} assetId Unique identifier of the asset.
         * @param {AccessKeyUpdateRequest} accessKeyUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccessKey(assetId: string, accessKeyUpdateRequest: AccessKeyUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccessKey(assetId, accessKeyUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the details of docker registry credentials asset, by id.
         * @summary Update docker registry credentials.
         * @param {string} assetId Unique identifier of the asset.
         * @param {DockerRegistryUpdateRequest} dockerRegistryUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDockerRegistry(assetId: string, dockerRegistryUpdateRequest: DockerRegistryUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DockerRegistry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDockerRegistry(assetId, dockerRegistryUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the details of password credentials asset, by id.
         * @summary Update password credentials.
         * @param {string} assetId Unique identifier of the asset.
         * @param {PasswordUpdateRequest} passwordUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(assetId: string, passwordUpdateRequest: PasswordUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Password>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(assetId, passwordUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CredentialsApi - factory interface
 * @export
 */
export const CredentialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CredentialsApiFp(configuration)
    return {
        /**
         * Creates S3-compatible access key asset.
         * @summary Create access key.
         * @param {AccessKeyCreationRequest} accessKeyCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessKey(accessKeyCreationRequest: AccessKeyCreationRequest, options?: any): AxiosPromise<AccessKey> {
            return localVarFp.createAccessKey(accessKeyCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates docker registry credentials asset containing user, password and url
         * @summary Create docker registry credentials.
         * @param {DockerRegistryCreationRequest} dockerRegistryCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDockerRegistry(dockerRegistryCreationRequest: DockerRegistryCreationRequest, options?: any): AxiosPromise<DockerRegistry> {
            return localVarFp.createDockerRegistry(dockerRegistryCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates credentials asset containing user and password.
         * @summary Create password credentials.
         * @param {PasswordCreationRequest} passwordCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPassword(passwordCreationRequest: PasswordCreationRequest, options?: any): AxiosPromise<Password> {
            return localVarFp.createPassword(passwordCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes S3-compatible access key asset, by id.
         * @summary Delete access key.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessKey(assetId: string, options?: any): AxiosPromise<HttpResponse> {
            return localVarFp.deleteAccessKey(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes docker registry credentials asset, by id.
         * @summary Delete docker registry credentials asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDockerRegistry(assetId: string, options?: any): AxiosPromise<HttpResponse> {
            return localVarFp.deleteDockerRegistry(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes password credentials asset, by id.
         * @summary Delete password asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePassword(assetId: string, options?: any): AxiosPromise<HttpResponse> {
            return localVarFp.deletePassword(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of S3-compatible access key asset, by id.
         * @summary Get access key.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessKeyById(assetId: string, usageInfo?: boolean, statusInfo?: boolean, options?: any): AxiosPromise<AccessKey> {
            return localVarFp.getAccessKeyById(assetId, usageInfo, statusInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of docker registry credentials asset, by id.
         * @summary Get docker registry credentials.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDockerRegistryById(assetId: string, usageInfo?: boolean, statusInfo?: boolean, options?: any): AxiosPromise<DockerRegistry> {
            return localVarFp.getDockerRegistryById(assetId, usageInfo, statusInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of password credentials asset, by id.
         * @summary Get password asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPasswordById(assetId: string, usageInfo?: boolean, statusInfo?: boolean, options?: any): AxiosPromise<Password> {
            return localVarFp.getPasswordById(assetId, usageInfo, statusInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of S3-compatible access key assets.
         * @summary List access keys.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccessKeys(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, statusInfo?: boolean, options?: any): AxiosPromise<AccessKeyListResponse> {
            return localVarFp.listAccessKeys(name, scope, projectId, departmentId, clusterId, usageInfo, statusInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a combined list of credentials.
         * @summary List credentials assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCredentialsAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, statusInfo?: boolean, options?: any): AxiosPromise<CredentialsListResponse> {
            return localVarFp.listCredentialsAssets(name, scope, projectId, departmentId, clusterId, usageInfo, statusInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of docker registry credentials assets.
         * @summary List docker registry credentials.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDockerRegistries(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, statusInfo?: boolean, options?: any): AxiosPromise<DockerRegistryListResponse> {
            return localVarFp.listDockerRegistries(name, scope, projectId, departmentId, clusterId, usageInfo, statusInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of password credentials assets.
         * @summary List password credentials.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPasswords(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, statusInfo?: boolean, options?: any): AxiosPromise<PasswordListResponse> {
            return localVarFp.listPasswords(name, scope, projectId, departmentId, clusterId, usageInfo, statusInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the details of S3-compatible access key asset, by id.
         * @summary Update access key.
         * @param {string} assetId Unique identifier of the asset.
         * @param {AccessKeyUpdateRequest} accessKeyUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccessKey(assetId: string, accessKeyUpdateRequest: AccessKeyUpdateRequest, options?: any): AxiosPromise<AccessKey> {
            return localVarFp.updateAccessKey(assetId, accessKeyUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the details of docker registry credentials asset, by id.
         * @summary Update docker registry credentials.
         * @param {string} assetId Unique identifier of the asset.
         * @param {DockerRegistryUpdateRequest} dockerRegistryUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDockerRegistry(assetId: string, dockerRegistryUpdateRequest: DockerRegistryUpdateRequest, options?: any): AxiosPromise<DockerRegistry> {
            return localVarFp.updateDockerRegistry(assetId, dockerRegistryUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the details of password credentials asset, by id.
         * @summary Update password credentials.
         * @param {string} assetId Unique identifier of the asset.
         * @param {PasswordUpdateRequest} passwordUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(assetId: string, passwordUpdateRequest: PasswordUpdateRequest, options?: any): AxiosPromise<Password> {
            return localVarFp.updatePassword(assetId, passwordUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CredentialsApi - object-oriented interface
 * @export
 * @class CredentialsApi
 * @extends {BaseAPI}
 */
export class CredentialsApi extends BaseAPI {
    /**
     * Creates S3-compatible access key asset.
     * @summary Create access key.
     * @param {AccessKeyCreationRequest} accessKeyCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public createAccessKey(accessKeyCreationRequest: AccessKeyCreationRequest, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).createAccessKey(accessKeyCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates docker registry credentials asset containing user, password and url
     * @summary Create docker registry credentials.
     * @param {DockerRegistryCreationRequest} dockerRegistryCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public createDockerRegistry(dockerRegistryCreationRequest: DockerRegistryCreationRequest, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).createDockerRegistry(dockerRegistryCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates credentials asset containing user and password.
     * @summary Create password credentials.
     * @param {PasswordCreationRequest} passwordCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public createPassword(passwordCreationRequest: PasswordCreationRequest, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).createPassword(passwordCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes S3-compatible access key asset, by id.
     * @summary Delete access key.
     * @param {string} assetId Unique identifier of the asset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public deleteAccessKey(assetId: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).deleteAccessKey(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes docker registry credentials asset, by id.
     * @summary Delete docker registry credentials asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public deleteDockerRegistry(assetId: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).deleteDockerRegistry(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes password credentials asset, by id.
     * @summary Delete password asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public deletePassword(assetId: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).deletePassword(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of S3-compatible access key asset, by id.
     * @summary Get access key.
     * @param {string} assetId Unique identifier of the asset.
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public getAccessKeyById(assetId: string, usageInfo?: boolean, statusInfo?: boolean, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).getAccessKeyById(assetId, usageInfo, statusInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of docker registry credentials asset, by id.
     * @summary Get docker registry credentials.
     * @param {string} assetId Unique identifier of the asset.
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public getDockerRegistryById(assetId: string, usageInfo?: boolean, statusInfo?: boolean, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).getDockerRegistryById(assetId, usageInfo, statusInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of password credentials asset, by id.
     * @summary Get password asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public getPasswordById(assetId: string, usageInfo?: boolean, statusInfo?: boolean, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).getPasswordById(assetId, usageInfo, statusInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of S3-compatible access key assets.
     * @summary List access keys.
     * @param {string} [name] Filter results by name.
     * @param {string} [scope] Filter results by scope.
     * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
     * @param {string} [departmentId] department id to filter by
     * @param {string} [clusterId] cluster id to filter by
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public listAccessKeys(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, statusInfo?: boolean, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).listAccessKeys(name, scope, projectId, departmentId, clusterId, usageInfo, statusInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a combined list of credentials.
     * @summary List credentials assets.
     * @param {string} [name] Filter results by name.
     * @param {string} [scope] Filter results by scope.
     * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
     * @param {string} [departmentId] department id to filter by
     * @param {string} [clusterId] cluster id to filter by
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public listCredentialsAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, statusInfo?: boolean, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).listCredentialsAssets(name, scope, projectId, departmentId, clusterId, usageInfo, statusInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of docker registry credentials assets.
     * @summary List docker registry credentials.
     * @param {string} [name] Filter results by name.
     * @param {string} [scope] Filter results by scope.
     * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
     * @param {string} [departmentId] department id to filter by
     * @param {string} [clusterId] cluster id to filter by
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public listDockerRegistries(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, statusInfo?: boolean, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).listDockerRegistries(name, scope, projectId, departmentId, clusterId, usageInfo, statusInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of password credentials assets.
     * @summary List password credentials.
     * @param {string} [name] Filter results by name.
     * @param {string} [scope] Filter results by scope.
     * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
     * @param {string} [departmentId] department id to filter by
     * @param {string} [clusterId] cluster id to filter by
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public listPasswords(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, statusInfo?: boolean, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).listPasswords(name, scope, projectId, departmentId, clusterId, usageInfo, statusInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the details of S3-compatible access key asset, by id.
     * @summary Update access key.
     * @param {string} assetId Unique identifier of the asset.
     * @param {AccessKeyUpdateRequest} accessKeyUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public updateAccessKey(assetId: string, accessKeyUpdateRequest: AccessKeyUpdateRequest, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).updateAccessKey(assetId, accessKeyUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the details of docker registry credentials asset, by id.
     * @summary Update docker registry credentials.
     * @param {string} assetId Unique identifier of the asset.
     * @param {DockerRegistryUpdateRequest} dockerRegistryUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public updateDockerRegistry(assetId: string, dockerRegistryUpdateRequest: DockerRegistryUpdateRequest, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).updateDockerRegistry(assetId, dockerRegistryUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the details of password credentials asset, by id.
     * @summary Update password credentials.
     * @param {string} assetId Unique identifier of the asset.
     * @param {PasswordUpdateRequest} passwordUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public updatePassword(assetId: string, passwordUpdateRequest: PasswordUpdateRequest, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).updatePassword(assetId, passwordUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DatasourcesApi - axios parameter creator
 * @export
 */
export const DatasourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Placeholder.
         * @param {AssetMeta} assetMeta 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDatasourceAsset: async (assetMeta: AssetMeta, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetMeta' is not null or undefined
            assertParamExists('createDatasourceAsset', 'assetMeta', assetMeta)
            const localVarPath = `/api/v1/asset/datasource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetMeta, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a combined list of data-sources.
         * @summary List datasource assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDatasourceAssets: async (name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/datasource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }

            if (statusInfo !== undefined) {
                localVarQueryParameter['statusInfo'] = statusInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasourcesApi - functional programming interface
 * @export
 */
export const DatasourcesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasourcesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Placeholder.
         * @param {AssetMeta} assetMeta 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDatasourceAsset(assetMeta: AssetMeta, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDatasourceAsset(assetMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a combined list of data-sources.
         * @summary List datasource assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDatasourceAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasourceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDatasourceAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DatasourcesApi - factory interface
 * @export
 */
export const DatasourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasourcesApiFp(configuration)
    return {
        /**
         * 
         * @summary Placeholder.
         * @param {AssetMeta} assetMeta 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDatasourceAsset(assetMeta: AssetMeta, options?: any): AxiosPromise<void> {
            return localVarFp.createDatasourceAsset(assetMeta, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a combined list of data-sources.
         * @summary List datasource assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDatasourceAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: any): AxiosPromise<DatasourceListResponse> {
            return localVarFp.listDatasourceAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DatasourcesApi - object-oriented interface
 * @export
 * @class DatasourcesApi
 * @extends {BaseAPI}
 */
export class DatasourcesApi extends BaseAPI {
    /**
     * 
     * @summary Placeholder.
     * @param {AssetMeta} assetMeta 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasourcesApi
     */
    public createDatasourceAsset(assetMeta: AssetMeta, options?: AxiosRequestConfig) {
        return DatasourcesApiFp(this.configuration).createDatasourceAsset(assetMeta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a combined list of data-sources.
     * @summary List datasource assets.
     * @param {string} [name] Filter results by name.
     * @param {string} [scope] Filter results by scope.
     * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
     * @param {string} [departmentId] department id to filter by
     * @param {string} [clusterId] cluster id to filter by
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasourcesApi
     */
    public listDatasourceAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig) {
        return DatasourcesApiFp(this.configuration).listDatasourceAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnvironmentApi - axios parameter creator
 * @export
 */
export const EnvironmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates an environment asset.
         * @summary Create environment asset.
         * @param {EnvironmentCreationRequest} [environmentCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnvironmentAsset: async (environmentCreationRequest?: EnvironmentCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/environment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(environmentCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an environment asset, by id.
         * @summary Delete environment asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironmentAssetById: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deleteEnvironmentAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/environment/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of environment asset, by id.
         * @summary Get environment asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironmentAssetById: async (assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getEnvironmentAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/environment/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of environment assets.
         * @summary List environment assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {string} [distributedFramework] Filter results to workload of type distributed and distributedFramework.
         * @param {boolean} [isDistributed] Filter results to workload of type distributed.
         * @param {boolean} [isTraining] Filter results to workload of type training.
         * @param {boolean} [isWorkspace] Filter results to workload of type workspace.
         * @param {boolean} [isInference] Filter results to workload of type inference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEnvironmentAssets: async (name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', distributedFramework?: string, isDistributed?: boolean, isTraining?: boolean, isWorkspace?: boolean, isInference?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/environment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }

            if (distributedFramework !== undefined) {
                localVarQueryParameter['distributedFramework'] = distributedFramework;
            }

            if (isDistributed !== undefined) {
                localVarQueryParameter['isDistributed'] = isDistributed;
            }

            if (isTraining !== undefined) {
                localVarQueryParameter['isTraining'] = isTraining;
            }

            if (isWorkspace !== undefined) {
                localVarQueryParameter['isWorkspace'] = isWorkspace;
            }

            if (isInference !== undefined) {
                localVarQueryParameter['isInference'] = isInference;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the details of environment asset, by id.
         * @summary Update environment asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {EnvironmentUpdateRequest} [environmentUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnvironmentAssetById: async (assetId: string, environmentUpdateRequest?: EnvironmentUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('updateEnvironmentAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/environment/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(environmentUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnvironmentApi - functional programming interface
 * @export
 */
export const EnvironmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnvironmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates an environment asset.
         * @summary Create environment asset.
         * @param {EnvironmentCreationRequest} [environmentCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEnvironmentAsset(environmentCreationRequest?: EnvironmentCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEnvironmentAsset(environmentCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an environment asset, by id.
         * @summary Delete environment asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEnvironmentAssetById(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEnvironmentAssetById(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of environment asset, by id.
         * @summary Get environment asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnvironmentAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnvironmentAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of environment assets.
         * @summary List environment assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {string} [distributedFramework] Filter results to workload of type distributed and distributedFramework.
         * @param {boolean} [isDistributed] Filter results to workload of type distributed.
         * @param {boolean} [isTraining] Filter results to workload of type training.
         * @param {boolean} [isWorkspace] Filter results to workload of type workspace.
         * @param {boolean} [isInference] Filter results to workload of type inference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEnvironmentAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', distributedFramework?: string, isDistributed?: boolean, isTraining?: boolean, isWorkspace?: boolean, isInference?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEnvironmentAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, distributedFramework, isDistributed, isTraining, isWorkspace, isInference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the details of environment asset, by id.
         * @summary Update environment asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {EnvironmentUpdateRequest} [environmentUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEnvironmentAssetById(assetId: string, environmentUpdateRequest?: EnvironmentUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEnvironmentAssetById(assetId, environmentUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnvironmentApi - factory interface
 * @export
 */
export const EnvironmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnvironmentApiFp(configuration)
    return {
        /**
         * Creates an environment asset.
         * @summary Create environment asset.
         * @param {EnvironmentCreationRequest} [environmentCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnvironmentAsset(environmentCreationRequest?: EnvironmentCreationRequest, options?: any): AxiosPromise<EnvironmentAsset> {
            return localVarFp.createEnvironmentAsset(environmentCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an environment asset, by id.
         * @summary Delete environment asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironmentAssetById(assetId: string, options?: any): AxiosPromise<HttpResponse> {
            return localVarFp.deleteEnvironmentAssetById(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of environment asset, by id.
         * @summary Get environment asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironmentAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: any): AxiosPromise<EnvironmentAsset> {
            return localVarFp.getEnvironmentAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of environment assets.
         * @summary List environment assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {string} [distributedFramework] Filter results to workload of type distributed and distributedFramework.
         * @param {boolean} [isDistributed] Filter results to workload of type distributed.
         * @param {boolean} [isTraining] Filter results to workload of type training.
         * @param {boolean} [isWorkspace] Filter results to workload of type workspace.
         * @param {boolean} [isInference] Filter results to workload of type inference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEnvironmentAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', distributedFramework?: string, isDistributed?: boolean, isTraining?: boolean, isWorkspace?: boolean, isInference?: boolean, options?: any): AxiosPromise<EnvironmentListResponse> {
            return localVarFp.listEnvironmentAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, distributedFramework, isDistributed, isTraining, isWorkspace, isInference, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the details of environment asset, by id.
         * @summary Update environment asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {EnvironmentUpdateRequest} [environmentUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnvironmentAssetById(assetId: string, environmentUpdateRequest?: EnvironmentUpdateRequest, options?: any): AxiosPromise<EnvironmentAsset> {
            return localVarFp.updateEnvironmentAssetById(assetId, environmentUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnvironmentApi - object-oriented interface
 * @export
 * @class EnvironmentApi
 * @extends {BaseAPI}
 */
export class EnvironmentApi extends BaseAPI {
    /**
     * Creates an environment asset.
     * @summary Create environment asset.
     * @param {EnvironmentCreationRequest} [environmentCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentApi
     */
    public createEnvironmentAsset(environmentCreationRequest?: EnvironmentCreationRequest, options?: AxiosRequestConfig) {
        return EnvironmentApiFp(this.configuration).createEnvironmentAsset(environmentCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an environment asset, by id.
     * @summary Delete environment asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentApi
     */
    public deleteEnvironmentAssetById(assetId: string, options?: AxiosRequestConfig) {
        return EnvironmentApiFp(this.configuration).deleteEnvironmentAssetById(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of environment asset, by id.
     * @summary Get environment asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentApi
     */
    public getEnvironmentAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: AxiosRequestConfig) {
        return EnvironmentApiFp(this.configuration).getEnvironmentAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of environment assets.
     * @summary List environment assets.
     * @param {string} [name] Filter results by name.
     * @param {string} [scope] Filter results by scope.
     * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
     * @param {string} [departmentId] department id to filter by
     * @param {string} [clusterId] cluster id to filter by
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {string} [distributedFramework] Filter results to workload of type distributed and distributedFramework.
     * @param {boolean} [isDistributed] Filter results to workload of type distributed.
     * @param {boolean} [isTraining] Filter results to workload of type training.
     * @param {boolean} [isWorkspace] Filter results to workload of type workspace.
     * @param {boolean} [isInference] Filter results to workload of type inference.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentApi
     */
    public listEnvironmentAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', distributedFramework?: string, isDistributed?: boolean, isTraining?: boolean, isWorkspace?: boolean, isInference?: boolean, options?: AxiosRequestConfig) {
        return EnvironmentApiFp(this.configuration).listEnvironmentAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, distributedFramework, isDistributed, isTraining, isWorkspace, isInference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the details of environment asset, by id.
     * @summary Update environment asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {EnvironmentUpdateRequest} [environmentUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentApi
     */
    public updateEnvironmentAssetById(assetId: string, environmentUpdateRequest?: EnvironmentUpdateRequest, options?: AxiosRequestConfig) {
        return EnvironmentApiFp(this.configuration).updateEnvironmentAssetById(assetId, environmentUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GitApi - axios parameter creator
 * @export
 */
export const GitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a git datasource asset.
         * @summary Create git asset.
         * @param {GitCreationRequest} [gitCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGitAsset: async (gitCreationRequest?: GitCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/datasource/git`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gitCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a git datasource asset, by id.
         * @summary Delete git asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGitAssetById: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deleteGitAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/git/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of git datasource asset, by id.
         * @summary Get git asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGitAssetById: async (assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getGitAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/git/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }

            if (statusInfo !== undefined) {
                localVarQueryParameter['statusInfo'] = statusInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of git datasource assets.
         * @summary List git assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGitAssets: async (name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/datasource/git`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }

            if (statusInfo !== undefined) {
                localVarQueryParameter['statusInfo'] = statusInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the details of git datasource asset, by id.
         * @summary Update git asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {GitUpdateRequest} [gitUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGitAssetById: async (assetId: string, gitUpdateRequest?: GitUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('updateGitAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/git/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gitUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GitApi - functional programming interface
 * @export
 */
export const GitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GitApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a git datasource asset.
         * @summary Create git asset.
         * @param {GitCreationRequest} [gitCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGitAsset(gitCreationRequest?: GitCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GitAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGitAsset(gitCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a git datasource asset, by id.
         * @summary Delete git asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGitAssetById(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGitAssetById(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of git datasource asset, by id.
         * @summary Get git asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGitAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GitAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGitAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of git datasource assets.
         * @summary List git assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGitAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GitListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGitAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the details of git datasource asset, by id.
         * @summary Update git asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {GitUpdateRequest} [gitUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGitAssetById(assetId: string, gitUpdateRequest?: GitUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GitAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGitAssetById(assetId, gitUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GitApi - factory interface
 * @export
 */
export const GitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GitApiFp(configuration)
    return {
        /**
         * Creates a git datasource asset.
         * @summary Create git asset.
         * @param {GitCreationRequest} [gitCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGitAsset(gitCreationRequest?: GitCreationRequest, options?: any): AxiosPromise<GitAsset> {
            return localVarFp.createGitAsset(gitCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a git datasource asset, by id.
         * @summary Delete git asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGitAssetById(assetId: string, options?: any): AxiosPromise<HttpResponse> {
            return localVarFp.deleteGitAssetById(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of git datasource asset, by id.
         * @summary Get git asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGitAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: any): AxiosPromise<GitAsset> {
            return localVarFp.getGitAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of git datasource assets.
         * @summary List git assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGitAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: any): AxiosPromise<GitListResponse> {
            return localVarFp.listGitAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the details of git datasource asset, by id.
         * @summary Update git asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {GitUpdateRequest} [gitUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGitAssetById(assetId: string, gitUpdateRequest?: GitUpdateRequest, options?: any): AxiosPromise<GitAsset> {
            return localVarFp.updateGitAssetById(assetId, gitUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GitApi - object-oriented interface
 * @export
 * @class GitApi
 * @extends {BaseAPI}
 */
export class GitApi extends BaseAPI {
    /**
     * Creates a git datasource asset.
     * @summary Create git asset.
     * @param {GitCreationRequest} [gitCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitApi
     */
    public createGitAsset(gitCreationRequest?: GitCreationRequest, options?: AxiosRequestConfig) {
        return GitApiFp(this.configuration).createGitAsset(gitCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a git datasource asset, by id.
     * @summary Delete git asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitApi
     */
    public deleteGitAssetById(assetId: string, options?: AxiosRequestConfig) {
        return GitApiFp(this.configuration).deleteGitAssetById(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of git datasource asset, by id.
     * @summary Get git asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitApi
     */
    public getGitAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig) {
        return GitApiFp(this.configuration).getGitAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of git datasource assets.
     * @summary List git assets.
     * @param {string} [name] Filter results by name.
     * @param {string} [scope] Filter results by scope.
     * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
     * @param {string} [departmentId] department id to filter by
     * @param {string} [clusterId] cluster id to filter by
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitApi
     */
    public listGitAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig) {
        return GitApiFp(this.configuration).listGitAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the details of git datasource asset, by id.
     * @summary Update git asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {GitUpdateRequest} [gitUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitApi
     */
    public updateGitAssetById(assetId: string, gitUpdateRequest?: GitUpdateRequest, options?: AxiosRequestConfig) {
        return GitApiFp(this.configuration).updateGitAssetById(assetId, gitUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HostPathApi - axios parameter creator
 * @export
 */
export const HostPathApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates host path datasource asset.
         * @summary Create host path asset.
         * @param {HostPathCreationRequest} hostPathCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHostPath: async (hostPathCreationRequest: HostPathCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hostPathCreationRequest' is not null or undefined
            assertParamExists('createHostPath', 'hostPathCreationRequest', hostPathCreationRequest)
            const localVarPath = `/api/v1/asset/datasource/host-path`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hostPathCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes host path datasource asset, by id.
         * @summary Delete host path asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHostPathById: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deleteHostPathById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/host-path/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of a host path datasource, by id.
         * @summary Get host path asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHostPathById: async (assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getHostPathById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/host-path/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of host path datasource assets.
         * @summary List host path assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHostPathAssets: async (name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/datasource/host-path`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request to update credentials of type host path
         * @summary Update host path asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {HostPathUpdateRequest} hostPathUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHostPathById: async (assetId: string, hostPathUpdateRequest: HostPathUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('updateHostPathById', 'assetId', assetId)
            // verify required parameter 'hostPathUpdateRequest' is not null or undefined
            assertParamExists('updateHostPathById', 'hostPathUpdateRequest', hostPathUpdateRequest)
            const localVarPath = `/api/v1/asset/datasource/host-path/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hostPathUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HostPathApi - functional programming interface
 * @export
 */
export const HostPathApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HostPathApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates host path datasource asset.
         * @summary Create host path asset.
         * @param {HostPathCreationRequest} hostPathCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHostPath(hostPathCreationRequest: HostPathCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HostPathAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHostPath(hostPathCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes host path datasource asset, by id.
         * @summary Delete host path asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHostPathById(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHostPathById(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of a host path datasource, by id.
         * @summary Get host path asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHostPathById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HostPathAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHostPathById(assetId, usageInfo, complyToProject, complyToWorkloadType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of host path datasource assets.
         * @summary List host path assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listHostPathAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HostPathListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listHostPathAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Request to update credentials of type host path
         * @summary Update host path asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {HostPathUpdateRequest} hostPathUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHostPathById(assetId: string, hostPathUpdateRequest: HostPathUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HostPathAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHostPathById(assetId, hostPathUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HostPathApi - factory interface
 * @export
 */
export const HostPathApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HostPathApiFp(configuration)
    return {
        /**
         * Creates host path datasource asset.
         * @summary Create host path asset.
         * @param {HostPathCreationRequest} hostPathCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHostPath(hostPathCreationRequest: HostPathCreationRequest, options?: any): AxiosPromise<HostPathAsset> {
            return localVarFp.createHostPath(hostPathCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes host path datasource asset, by id.
         * @summary Delete host path asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHostPathById(assetId: string, options?: any): AxiosPromise<HttpResponse> {
            return localVarFp.deleteHostPathById(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of a host path datasource, by id.
         * @summary Get host path asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHostPathById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: any): AxiosPromise<HostPathAsset> {
            return localVarFp.getHostPathById(assetId, usageInfo, complyToProject, complyToWorkloadType, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of host path datasource assets.
         * @summary List host path assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHostPathAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: any): AxiosPromise<HostPathListResponse> {
            return localVarFp.listHostPathAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, options).then((request) => request(axios, basePath));
        },
        /**
         * Request to update credentials of type host path
         * @summary Update host path asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {HostPathUpdateRequest} hostPathUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHostPathById(assetId: string, hostPathUpdateRequest: HostPathUpdateRequest, options?: any): AxiosPromise<HostPathAsset> {
            return localVarFp.updateHostPathById(assetId, hostPathUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HostPathApi - object-oriented interface
 * @export
 * @class HostPathApi
 * @extends {BaseAPI}
 */
export class HostPathApi extends BaseAPI {
    /**
     * Creates host path datasource asset.
     * @summary Create host path asset.
     * @param {HostPathCreationRequest} hostPathCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostPathApi
     */
    public createHostPath(hostPathCreationRequest: HostPathCreationRequest, options?: AxiosRequestConfig) {
        return HostPathApiFp(this.configuration).createHostPath(hostPathCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes host path datasource asset, by id.
     * @summary Delete host path asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostPathApi
     */
    public deleteHostPathById(assetId: string, options?: AxiosRequestConfig) {
        return HostPathApiFp(this.configuration).deleteHostPathById(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of a host path datasource, by id.
     * @summary Get host path asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostPathApi
     */
    public getHostPathById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: AxiosRequestConfig) {
        return HostPathApiFp(this.configuration).getHostPathById(assetId, usageInfo, complyToProject, complyToWorkloadType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of host path datasource assets.
     * @summary List host path assets.
     * @param {string} [name] Filter results by name.
     * @param {string} [scope] Filter results by scope.
     * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
     * @param {string} [departmentId] department id to filter by
     * @param {string} [clusterId] cluster id to filter by
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostPathApi
     */
    public listHostPathAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: AxiosRequestConfig) {
        return HostPathApiFp(this.configuration).listHostPathAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request to update credentials of type host path
     * @summary Update host path asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {HostPathUpdateRequest} hostPathUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostPathApi
     */
    public updateHostPathById(assetId: string, hostPathUpdateRequest: HostPathUpdateRequest, options?: AxiosRequestConfig) {
        return HostPathApiFp(this.configuration).updateHostPathById(assetId, hostPathUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InferenceApi - axios parameter creator
 * @export
 */
export const InferenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a inference workload from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
         * @summary Create inference workload.
         * @param {WorkloadCreationRequest} [workloadCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInference: async (workloadCreationRequest?: WorkloadCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workloadCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete inference workload leftover from backend, by id.
         * @summary Delete inference workload leftover from backend.
         * @param {string} id Unique identifier of the inference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInferenceById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteInferenceById', 'id', id)
            const localVarPath = `/api/v1/inference/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the content of the inference, i.e. its set of assets and specific env. The response is similar to that of a template, so it can be loaded to the inference creation form
         * @summary Get inference content.
         * @param {string} id Unique identifier of the inference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInferenceById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInferenceById', 'id', id)
            const localVarPath = `/api/v1/inference/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InferenceApi - functional programming interface
 * @export
 */
export const InferenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InferenceApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a inference workload from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
         * @summary Create inference workload.
         * @param {WorkloadCreationRequest} [workloadCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInference(workloadCreationRequest?: WorkloadCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Inference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInference(workloadCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete inference workload leftover from backend, by id.
         * @summary Delete inference workload leftover from backend.
         * @param {string} id Unique identifier of the inference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInferenceById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInferenceById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the content of the inference, i.e. its set of assets and specific env. The response is similar to that of a template, so it can be loaded to the inference creation form
         * @summary Get inference content.
         * @param {string} id Unique identifier of the inference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInferenceById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Inference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInferenceById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InferenceApi - factory interface
 * @export
 */
export const InferenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InferenceApiFp(configuration)
    return {
        /**
         * Creates a inference workload from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
         * @summary Create inference workload.
         * @param {WorkloadCreationRequest} [workloadCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInference(workloadCreationRequest?: WorkloadCreationRequest, options?: any): AxiosPromise<Inference> {
            return localVarFp.createInference(workloadCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete inference workload leftover from backend, by id.
         * @summary Delete inference workload leftover from backend.
         * @param {string} id Unique identifier of the inference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInferenceById(id: string, options?: any): AxiosPromise<HttpResponse> {
            return localVarFp.deleteInferenceById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the content of the inference, i.e. its set of assets and specific env. The response is similar to that of a template, so it can be loaded to the inference creation form
         * @summary Get inference content.
         * @param {string} id Unique identifier of the inference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInferenceById(id: string, options?: any): AxiosPromise<Inference> {
            return localVarFp.getInferenceById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InferenceApi - object-oriented interface
 * @export
 * @class InferenceApi
 * @extends {BaseAPI}
 */
export class InferenceApi extends BaseAPI {
    /**
     * Creates a inference workload from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
     * @summary Create inference workload.
     * @param {WorkloadCreationRequest} [workloadCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InferenceApi
     */
    public createInference(workloadCreationRequest?: WorkloadCreationRequest, options?: AxiosRequestConfig) {
        return InferenceApiFp(this.configuration).createInference(workloadCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete inference workload leftover from backend, by id.
     * @summary Delete inference workload leftover from backend.
     * @param {string} id Unique identifier of the inference.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InferenceApi
     */
    public deleteInferenceById(id: string, options?: AxiosRequestConfig) {
        return InferenceApiFp(this.configuration).deleteInferenceById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the content of the inference, i.e. its set of assets and specific env. The response is similar to that of a template, so it can be loaded to the inference creation form
     * @summary Get inference content.
     * @param {string} id Unique identifier of the inference.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InferenceApi
     */
    public getInferenceById(id: string, options?: AxiosRequestConfig) {
        return InferenceApiFp(this.configuration).getInferenceById(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModelApi - axios parameter creator
 * @export
 */
export const ModelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a model asset.
         * @summary Create model asset.
         * @param {ModelCreationRequest} [modelCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createModelAsset: async (modelCreationRequest?: ModelCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/model`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modelCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes model asset, by id.
         * @summary Delete model asset.
         * @param {string} modelId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModelAssetById: async (modelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('deleteModelAssetById', 'modelId', modelId)
            const localVarPath = `/api/v1/asset/model/{ModelId}`
                .replace(`{${"ModelId"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of model asset, by id.
         * @summary Get model asset.
         * @param {string} modelId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAssetById: async (modelId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('getModelAssetById', 'modelId', modelId)
            const localVarPath = `/api/v1/asset/model/{ModelId}`
                .replace(`{${"ModelId"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of model assets.
         * @summary List model assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {string} [environmentId] The id of the environment to which the asset belongs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listModelAssets: async (name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', environmentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/model`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }

            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the details of model asset, by id.
         * @summary Update model asset.
         * @param {string} modelId Unique identifier of the asset.
         * @param {ModelUpdateRequest} [modelUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelAssetById: async (modelId: string, modelUpdateRequest?: ModelUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('updateModelAssetById', 'modelId', modelId)
            const localVarPath = `/api/v1/asset/model/{ModelId}`
                .replace(`{${"ModelId"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modelUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelApi - functional programming interface
 * @export
 */
export const ModelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModelApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a model asset.
         * @summary Create model asset.
         * @param {ModelCreationRequest} [modelCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createModelAsset(modelCreationRequest?: ModelCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createModelAsset(modelCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes model asset, by id.
         * @summary Delete model asset.
         * @param {string} modelId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteModelAssetById(modelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteModelAssetById(modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of model asset, by id.
         * @summary Get model asset.
         * @param {string} modelId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelAssetById(modelId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelAssetById(modelId, usageInfo, complyToProject, complyToWorkloadType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of model assets.
         * @summary List model assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {string} [environmentId] The id of the environment to which the asset belongs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listModelAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', environmentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listModelAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, environmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the details of model asset, by id.
         * @summary Update model asset.
         * @param {string} modelId Unique identifier of the asset.
         * @param {ModelUpdateRequest} [modelUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateModelAssetById(modelId: string, modelUpdateRequest?: ModelUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateModelAssetById(modelId, modelUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModelApi - factory interface
 * @export
 */
export const ModelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModelApiFp(configuration)
    return {
        /**
         * Creates a model asset.
         * @summary Create model asset.
         * @param {ModelCreationRequest} [modelCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createModelAsset(modelCreationRequest?: ModelCreationRequest, options?: any): AxiosPromise<ModelAsset> {
            return localVarFp.createModelAsset(modelCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes model asset, by id.
         * @summary Delete model asset.
         * @param {string} modelId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModelAssetById(modelId: string, options?: any): AxiosPromise<HttpResponse> {
            return localVarFp.deleteModelAssetById(modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of model asset, by id.
         * @summary Get model asset.
         * @param {string} modelId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAssetById(modelId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: any): AxiosPromise<ModelAsset> {
            return localVarFp.getModelAssetById(modelId, usageInfo, complyToProject, complyToWorkloadType, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of model assets.
         * @summary List model assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {string} [environmentId] The id of the environment to which the asset belongs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listModelAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', environmentId?: string, options?: any): AxiosPromise<ModelListResponse> {
            return localVarFp.listModelAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, environmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the details of model asset, by id.
         * @summary Update model asset.
         * @param {string} modelId Unique identifier of the asset.
         * @param {ModelUpdateRequest} [modelUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelAssetById(modelId: string, modelUpdateRequest?: ModelUpdateRequest, options?: any): AxiosPromise<ModelAsset> {
            return localVarFp.updateModelAssetById(modelId, modelUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModelApi - object-oriented interface
 * @export
 * @class ModelApi
 * @extends {BaseAPI}
 */
export class ModelApi extends BaseAPI {
    /**
     * Creates a model asset.
     * @summary Create model asset.
     * @param {ModelCreationRequest} [modelCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelApi
     */
    public createModelAsset(modelCreationRequest?: ModelCreationRequest, options?: AxiosRequestConfig) {
        return ModelApiFp(this.configuration).createModelAsset(modelCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes model asset, by id.
     * @summary Delete model asset.
     * @param {string} modelId Unique identifier of the asset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelApi
     */
    public deleteModelAssetById(modelId: string, options?: AxiosRequestConfig) {
        return ModelApiFp(this.configuration).deleteModelAssetById(modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of model asset, by id.
     * @summary Get model asset.
     * @param {string} modelId Unique identifier of the asset.
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelApi
     */
    public getModelAssetById(modelId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: AxiosRequestConfig) {
        return ModelApiFp(this.configuration).getModelAssetById(modelId, usageInfo, complyToProject, complyToWorkloadType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of model assets.
     * @summary List model assets.
     * @param {string} [name] Filter results by name.
     * @param {string} [scope] Filter results by scope.
     * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
     * @param {string} [departmentId] department id to filter by
     * @param {string} [clusterId] cluster id to filter by
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {string} [environmentId] The id of the environment to which the asset belongs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelApi
     */
    public listModelAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', environmentId?: string, options?: AxiosRequestConfig) {
        return ModelApiFp(this.configuration).listModelAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, environmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the details of model asset, by id.
     * @summary Update model asset.
     * @param {string} modelId Unique identifier of the asset.
     * @param {ModelUpdateRequest} [modelUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelApi
     */
    public updateModelAssetById(modelId: string, modelUpdateRequest?: ModelUpdateRequest, options?: AxiosRequestConfig) {
        return ModelApiFp(this.configuration).updateModelAssetById(modelId, modelUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModelInferenceApi - axios parameter creator
 * @export
 */
export const ModelInferenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates model inference.
         * @summary Create model inference.
         * @param {ModelInferenceCreationRequest} [modelInferenceCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createModelInference: async (modelInferenceCreationRequest?: ModelInferenceCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/model-inference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modelInferenceCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelInferenceApi - functional programming interface
 * @export
 */
export const ModelInferenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModelInferenceApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates model inference.
         * @summary Create model inference.
         * @param {ModelInferenceCreationRequest} [modelInferenceCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createModelInference(modelInferenceCreationRequest?: ModelInferenceCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelInference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createModelInference(modelInferenceCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModelInferenceApi - factory interface
 * @export
 */
export const ModelInferenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModelInferenceApiFp(configuration)
    return {
        /**
         * Creates model inference.
         * @summary Create model inference.
         * @param {ModelInferenceCreationRequest} [modelInferenceCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createModelInference(modelInferenceCreationRequest?: ModelInferenceCreationRequest, options?: any): AxiosPromise<ModelInference> {
            return localVarFp.createModelInference(modelInferenceCreationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModelInferenceApi - object-oriented interface
 * @export
 * @class ModelInferenceApi
 * @extends {BaseAPI}
 */
export class ModelInferenceApi extends BaseAPI {
    /**
     * Creates model inference.
     * @summary Create model inference.
     * @param {ModelInferenceCreationRequest} [modelInferenceCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelInferenceApi
     */
    public createModelInference(modelInferenceCreationRequest?: ModelInferenceCreationRequest, options?: AxiosRequestConfig) {
        return ModelInferenceApiFp(this.configuration).createModelInference(modelInferenceCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NFSApi - axios parameter creator
 * @export
 */
export const NFSApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates NFS datasource asset.
         * @summary Create NFS asset.
         * @param {NFSCreationRequest} [nFSCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNfsAsset: async (nFSCreationRequest?: NFSCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/datasource/nfs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nFSCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes NFS datasource asset, by id.
         * @summary Delete NFS asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNfsAssetById: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deleteNfsAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/nfs/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of NFS datasource asset, by id.
         * @summary Get NFS asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNfsAssetById: async (assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getNfsAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/nfs/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of NFS datasource assets.
         * @summary List NFS assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNfsAssets: async (name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/datasource/nfs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the details of NFS datasource asset, by id.
         * @summary Update NFS asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {NFSUpdateRequest} [nFSUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNfsAssetById: async (assetId: string, nFSUpdateRequest?: NFSUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('updateNfsAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/nfs/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nFSUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NFSApi - functional programming interface
 * @export
 */
export const NFSApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NFSApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates NFS datasource asset.
         * @summary Create NFS asset.
         * @param {NFSCreationRequest} [nFSCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNfsAsset(nFSCreationRequest?: NFSCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNfsAsset(nFSCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes NFS datasource asset, by id.
         * @summary Delete NFS asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNfsAssetById(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNfsAssetById(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of NFS datasource asset, by id.
         * @summary Get NFS asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNfsAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NFSAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNfsAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of NFS datasource assets.
         * @summary List NFS assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNfsAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NFSListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNfsAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the details of NFS datasource asset, by id.
         * @summary Update NFS asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {NFSUpdateRequest} [nFSUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNfsAssetById(assetId: string, nFSUpdateRequest?: NFSUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NFSAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNfsAssetById(assetId, nFSUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NFSApi - factory interface
 * @export
 */
export const NFSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NFSApiFp(configuration)
    return {
        /**
         * Creates NFS datasource asset.
         * @summary Create NFS asset.
         * @param {NFSCreationRequest} [nFSCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNfsAsset(nFSCreationRequest?: NFSCreationRequest, options?: any): AxiosPromise<S3Asset> {
            return localVarFp.createNfsAsset(nFSCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes NFS datasource asset, by id.
         * @summary Delete NFS asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNfsAssetById(assetId: string, options?: any): AxiosPromise<HttpResponse> {
            return localVarFp.deleteNfsAssetById(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of NFS datasource asset, by id.
         * @summary Get NFS asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNfsAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: any): AxiosPromise<NFSAsset> {
            return localVarFp.getNfsAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of NFS datasource assets.
         * @summary List NFS assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNfsAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: any): AxiosPromise<NFSListResponse> {
            return localVarFp.listNfsAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the details of NFS datasource asset, by id.
         * @summary Update NFS asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {NFSUpdateRequest} [nFSUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNfsAssetById(assetId: string, nFSUpdateRequest?: NFSUpdateRequest, options?: any): AxiosPromise<NFSAsset> {
            return localVarFp.updateNfsAssetById(assetId, nFSUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NFSApi - object-oriented interface
 * @export
 * @class NFSApi
 * @extends {BaseAPI}
 */
export class NFSApi extends BaseAPI {
    /**
     * Creates NFS datasource asset.
     * @summary Create NFS asset.
     * @param {NFSCreationRequest} [nFSCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NFSApi
     */
    public createNfsAsset(nFSCreationRequest?: NFSCreationRequest, options?: AxiosRequestConfig) {
        return NFSApiFp(this.configuration).createNfsAsset(nFSCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes NFS datasource asset, by id.
     * @summary Delete NFS asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NFSApi
     */
    public deleteNfsAssetById(assetId: string, options?: AxiosRequestConfig) {
        return NFSApiFp(this.configuration).deleteNfsAssetById(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of NFS datasource asset, by id.
     * @summary Get NFS asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NFSApi
     */
    public getNfsAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: AxiosRequestConfig) {
        return NFSApiFp(this.configuration).getNfsAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of NFS datasource assets.
     * @summary List NFS assets.
     * @param {string} [name] Filter results by name.
     * @param {string} [scope] Filter results by scope.
     * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
     * @param {string} [departmentId] department id to filter by
     * @param {string} [clusterId] cluster id to filter by
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NFSApi
     */
    public listNfsAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', options?: AxiosRequestConfig) {
        return NFSApiFp(this.configuration).listNfsAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the details of NFS datasource asset, by id.
     * @summary Update NFS asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {NFSUpdateRequest} [nFSUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NFSApi
     */
    public updateNfsAssetById(assetId: string, nFSUpdateRequest?: NFSUpdateRequest, options?: AxiosRequestConfig) {
        return NFSApiFp(this.configuration).updateNfsAssetById(assetId, nFSUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PVCApi - axios parameter creator
 * @export
 */
export const PVCApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a PVC datasource asset.
         * @summary Create PVC asset.
         * @param {PVCCreationRequest} [pVCCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPvcAsset: async (pVCCreationRequest?: PVCCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/datasource/pvc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pVCCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes PVC datasource asset, by id.
         * @summary Delete PVC asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePvcAssetById: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deletePvcAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/pvc/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of PVC datasource asset, by id.
         * @summary Get PVC asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPvcAssetById: async (assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getPvcAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/pvc/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }

            if (statusInfo !== undefined) {
                localVarQueryParameter['statusInfo'] = statusInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of PVC datasource assets.
         * @summary List PVC assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPvcAssets: async (name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/datasource/pvc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }

            if (statusInfo !== undefined) {
                localVarQueryParameter['statusInfo'] = statusInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the details of PVC datasource asset, by id.
         * @summary Update PVC asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {PVCUpdateRequest} [pVCUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePvcAssetById: async (assetId: string, pVCUpdateRequest?: PVCUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('updatePvcAssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/pvc/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pVCUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PVCApi - functional programming interface
 * @export
 */
export const PVCApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PVCApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a PVC datasource asset.
         * @summary Create PVC asset.
         * @param {PVCCreationRequest} [pVCCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPvcAsset(pVCCreationRequest?: PVCCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVCAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPvcAsset(pVCCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes PVC datasource asset, by id.
         * @summary Delete PVC asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePvcAssetById(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePvcAssetById(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of PVC datasource asset, by id.
         * @summary Get PVC asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPvcAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVCAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPvcAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of PVC datasource assets.
         * @summary List PVC assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPvcAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVCListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPvcAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the details of PVC datasource asset, by id.
         * @summary Update PVC asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {PVCUpdateRequest} [pVCUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePvcAssetById(assetId: string, pVCUpdateRequest?: PVCUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVCAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePvcAssetById(assetId, pVCUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PVCApi - factory interface
 * @export
 */
export const PVCApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PVCApiFp(configuration)
    return {
        /**
         * Creates a PVC datasource asset.
         * @summary Create PVC asset.
         * @param {PVCCreationRequest} [pVCCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPvcAsset(pVCCreationRequest?: PVCCreationRequest, options?: any): AxiosPromise<PVCAsset> {
            return localVarFp.createPvcAsset(pVCCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes PVC datasource asset, by id.
         * @summary Delete PVC asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePvcAssetById(assetId: string, options?: any): AxiosPromise<HttpResponse> {
            return localVarFp.deletePvcAssetById(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of PVC datasource asset, by id.
         * @summary Get PVC asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPvcAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: any): AxiosPromise<PVCAsset> {
            return localVarFp.getPvcAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of PVC datasource assets.
         * @summary List PVC assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPvcAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: any): AxiosPromise<PVCListResponse> {
            return localVarFp.listPvcAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the details of PVC datasource asset, by id.
         * @summary Update PVC asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {PVCUpdateRequest} [pVCUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePvcAssetById(assetId: string, pVCUpdateRequest?: PVCUpdateRequest, options?: any): AxiosPromise<PVCAsset> {
            return localVarFp.updatePvcAssetById(assetId, pVCUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PVCApi - object-oriented interface
 * @export
 * @class PVCApi
 * @extends {BaseAPI}
 */
export class PVCApi extends BaseAPI {
    /**
     * Creates a PVC datasource asset.
     * @summary Create PVC asset.
     * @param {PVCCreationRequest} [pVCCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCApi
     */
    public createPvcAsset(pVCCreationRequest?: PVCCreationRequest, options?: AxiosRequestConfig) {
        return PVCApiFp(this.configuration).createPvcAsset(pVCCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes PVC datasource asset, by id.
     * @summary Delete PVC asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCApi
     */
    public deletePvcAssetById(assetId: string, options?: AxiosRequestConfig) {
        return PVCApiFp(this.configuration).deletePvcAssetById(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of PVC datasource asset, by id.
     * @summary Get PVC asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCApi
     */
    public getPvcAssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig) {
        return PVCApiFp(this.configuration).getPvcAssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of PVC datasource assets.
     * @summary List PVC assets.
     * @param {string} [name] Filter results by name.
     * @param {string} [scope] Filter results by scope.
     * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
     * @param {string} [departmentId] department id to filter by
     * @param {string} [clusterId] cluster id to filter by
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCApi
     */
    public listPvcAssets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig) {
        return PVCApiFp(this.configuration).listPvcAssets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the details of PVC datasource asset, by id.
     * @summary Update PVC asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {PVCUpdateRequest} [pVCUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCApi
     */
    public updatePvcAssetById(assetId: string, pVCUpdateRequest?: PVCUpdateRequest, options?: AxiosRequestConfig) {
        return PVCApiFp(this.configuration).updatePvcAssetById(assetId, pVCUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegistryApi - axios parameter creator
 * @export
 */
export const RegistryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates registry asset containing registry base url and credentials.
         * @summary Create registry.
         * @param {RegistryCreationRequest} registryCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRegistry: async (registryCreationRequest: RegistryCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registryCreationRequest' is not null or undefined
            assertParamExists('createRegistry', 'registryCreationRequest', registryCreationRequest)
            const localVarPath = `/api/v1/asset/registries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registryCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes registry asset containing registry base url and credentials.
         * @summary Delete registry.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRegistry: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deleteRegistry', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/registries/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a registry asset.
         * @summary Get a registry.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistryById: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getRegistryById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/registries/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns list of repositories.
         * @summary Get the repositories in the registry.
         * @param {string} assetId Unique identifier of the asset.
         * @param {string} [searchName] Filter results that contains searchName.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepositories: async (assetId: string, searchName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getRepositories', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/registries/{AssetId}/repositories`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchName !== undefined) {
                localVarQueryParameter['searchName'] = searchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns list of repository tags.
         * @summary Get the repositories tags in the registry.
         * @param {string} assetId Unique identifier of the asset.
         * @param {string} repository The repository name that its tags are requested.
         * @param {string} [searchName] Filter results that contains searchName.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepositoryTags: async (assetId: string, repository: string, searchName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getRepositoryTags', 'assetId', assetId)
            // verify required parameter 'repository' is not null or undefined
            assertParamExists('getRepositoryTags', 'repository', repository)
            const localVarPath = `/api/v1/asset/registries/{AssetId}/repositories/tags`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (repository !== undefined) {
                localVarQueryParameter['repository'] = repository;
            }

            if (searchName !== undefined) {
                localVarQueryParameter['searchName'] = searchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a registries assets list.
         * @summary Get registries.
         * @param {string} [name] Filter results by name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegistries: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/registries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates registry asset containing registry base url and credentials.
         * @summary Update registry.
         * @param {string} assetId Unique identifier of the asset.
         * @param {RegistryUpdateRequest} registryUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegistry: async (assetId: string, registryUpdateRequest: RegistryUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('updateRegistry', 'assetId', assetId)
            // verify required parameter 'registryUpdateRequest' is not null or undefined
            assertParamExists('updateRegistry', 'registryUpdateRequest', registryUpdateRequest)
            const localVarPath = `/api/v1/asset/registries/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registryUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegistryApi - functional programming interface
 * @export
 */
export const RegistryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegistryApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates registry asset containing registry base url and credentials.
         * @summary Create registry.
         * @param {RegistryCreationRequest} registryCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRegistry(registryCreationRequest: RegistryCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Registry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRegistry(registryCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes registry asset containing registry base url and credentials.
         * @summary Delete registry.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRegistry(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRegistry(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a registry asset.
         * @summary Get a registry.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistryById(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Registry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegistryById(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns list of repositories.
         * @summary Get the repositories in the registry.
         * @param {string} assetId Unique identifier of the asset.
         * @param {string} [searchName] Filter results that contains searchName.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRepositories(assetId: string, searchName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Repositories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRepositories(assetId, searchName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns list of repository tags.
         * @summary Get the repositories tags in the registry.
         * @param {string} assetId Unique identifier of the asset.
         * @param {string} repository The repository name that its tags are requested.
         * @param {string} [searchName] Filter results that contains searchName.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRepositoryTags(assetId: string, repository: string, searchName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepositoryTags>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRepositoryTags(assetId, repository, searchName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a registries assets list.
         * @summary Get registries.
         * @param {string} [name] Filter results by name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRegistries(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRegistries(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates registry asset containing registry base url and credentials.
         * @summary Update registry.
         * @param {string} assetId Unique identifier of the asset.
         * @param {RegistryUpdateRequest} registryUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRegistry(assetId: string, registryUpdateRequest: RegistryUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Registry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRegistry(assetId, registryUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegistryApi - factory interface
 * @export
 */
export const RegistryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegistryApiFp(configuration)
    return {
        /**
         * Creates registry asset containing registry base url and credentials.
         * @summary Create registry.
         * @param {RegistryCreationRequest} registryCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRegistry(registryCreationRequest: RegistryCreationRequest, options?: any): AxiosPromise<Registry> {
            return localVarFp.createRegistry(registryCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes registry asset containing registry base url and credentials.
         * @summary Delete registry.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRegistry(assetId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRegistry(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a registry asset.
         * @summary Get a registry.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistryById(assetId: string, options?: any): AxiosPromise<Registry> {
            return localVarFp.getRegistryById(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns list of repositories.
         * @summary Get the repositories in the registry.
         * @param {string} assetId Unique identifier of the asset.
         * @param {string} [searchName] Filter results that contains searchName.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepositories(assetId: string, searchName?: string, options?: any): AxiosPromise<Repositories> {
            return localVarFp.getRepositories(assetId, searchName, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns list of repository tags.
         * @summary Get the repositories tags in the registry.
         * @param {string} assetId Unique identifier of the asset.
         * @param {string} repository The repository name that its tags are requested.
         * @param {string} [searchName] Filter results that contains searchName.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepositoryTags(assetId: string, repository: string, searchName?: string, options?: any): AxiosPromise<RepositoryTags> {
            return localVarFp.getRepositoryTags(assetId, repository, searchName, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a registries assets list.
         * @summary Get registries.
         * @param {string} [name] Filter results by name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegistries(name?: string, options?: any): AxiosPromise<RegistryListResponse> {
            return localVarFp.listRegistries(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates registry asset containing registry base url and credentials.
         * @summary Update registry.
         * @param {string} assetId Unique identifier of the asset.
         * @param {RegistryUpdateRequest} registryUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegistry(assetId: string, registryUpdateRequest: RegistryUpdateRequest, options?: any): AxiosPromise<Registry> {
            return localVarFp.updateRegistry(assetId, registryUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegistryApi - object-oriented interface
 * @export
 * @class RegistryApi
 * @extends {BaseAPI}
 */
export class RegistryApi extends BaseAPI {
    /**
     * Creates registry asset containing registry base url and credentials.
     * @summary Create registry.
     * @param {RegistryCreationRequest} registryCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistryApi
     */
    public createRegistry(registryCreationRequest: RegistryCreationRequest, options?: AxiosRequestConfig) {
        return RegistryApiFp(this.configuration).createRegistry(registryCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes registry asset containing registry base url and credentials.
     * @summary Delete registry.
     * @param {string} assetId Unique identifier of the asset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistryApi
     */
    public deleteRegistry(assetId: string, options?: AxiosRequestConfig) {
        return RegistryApiFp(this.configuration).deleteRegistry(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a registry asset.
     * @summary Get a registry.
     * @param {string} assetId Unique identifier of the asset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistryApi
     */
    public getRegistryById(assetId: string, options?: AxiosRequestConfig) {
        return RegistryApiFp(this.configuration).getRegistryById(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns list of repositories.
     * @summary Get the repositories in the registry.
     * @param {string} assetId Unique identifier of the asset.
     * @param {string} [searchName] Filter results that contains searchName.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistryApi
     */
    public getRepositories(assetId: string, searchName?: string, options?: AxiosRequestConfig) {
        return RegistryApiFp(this.configuration).getRepositories(assetId, searchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns list of repository tags.
     * @summary Get the repositories tags in the registry.
     * @param {string} assetId Unique identifier of the asset.
     * @param {string} repository The repository name that its tags are requested.
     * @param {string} [searchName] Filter results that contains searchName.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistryApi
     */
    public getRepositoryTags(assetId: string, repository: string, searchName?: string, options?: AxiosRequestConfig) {
        return RegistryApiFp(this.configuration).getRepositoryTags(assetId, repository, searchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a registries assets list.
     * @summary Get registries.
     * @param {string} [name] Filter results by name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistryApi
     */
    public listRegistries(name?: string, options?: AxiosRequestConfig) {
        return RegistryApiFp(this.configuration).listRegistries(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates registry asset containing registry base url and credentials.
     * @summary Update registry.
     * @param {string} assetId Unique identifier of the asset.
     * @param {RegistryUpdateRequest} registryUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistryApi
     */
    public updateRegistry(assetId: string, registryUpdateRequest: RegistryUpdateRequest, options?: AxiosRequestConfig) {
        return RegistryApiFp(this.configuration).updateRegistry(assetId, registryUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * S3Api - axios parameter creator
 * @export
 */
export const S3ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates S3-compatible datasource asset.
         * @summary Create S3 asset.
         * @param {S3CreationRequest} [s3CreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createS3Asset: async (s3CreationRequest?: S3CreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/datasource/s3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(s3CreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes S3-compatible datasource asset, by id.
         * @summary Delete S3 asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteS3AssetById: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deleteS3AssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/s3/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of S3-compatible datasource asset, by id.
         * @summary Get S3 asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getS3AssetById: async (assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getS3AssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/s3/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }

            if (statusInfo !== undefined) {
                localVarQueryParameter['statusInfo'] = statusInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of S3-compatible datasource assets.
         * @summary List S3 assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listS3Assets: async (name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/datasource/s3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (usageInfo !== undefined) {
                localVarQueryParameter['usageInfo'] = usageInfo;
            }

            if (complyToProject !== undefined) {
                localVarQueryParameter['complyToProject'] = complyToProject;
            }

            if (complyToWorkloadType !== undefined) {
                localVarQueryParameter['complyToWorkloadType'] = complyToWorkloadType;
            }

            if (statusInfo !== undefined) {
                localVarQueryParameter['statusInfo'] = statusInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the details of S3-compatible datasource asset, by id.
         * @summary Update S3 asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {S3UpdateRequest} [s3UpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateS3AssetById: async (assetId: string, s3UpdateRequest?: S3UpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('updateS3AssetById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/datasource/s3/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(s3UpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * S3Api - functional programming interface
 * @export
 */
export const S3ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = S3ApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates S3-compatible datasource asset.
         * @summary Create S3 asset.
         * @param {S3CreationRequest} [s3CreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createS3Asset(s3CreationRequest?: S3CreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createS3Asset(s3CreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes S3-compatible datasource asset, by id.
         * @summary Delete S3 asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteS3AssetById(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteS3AssetById(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of S3-compatible datasource asset, by id.
         * @summary Get S3 asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getS3AssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getS3AssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of S3-compatible datasource assets.
         * @summary List S3 assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listS3Assets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3ListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listS3Assets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the details of S3-compatible datasource asset, by id.
         * @summary Update S3 asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {S3UpdateRequest} [s3UpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateS3AssetById(assetId: string, s3UpdateRequest?: S3UpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateS3AssetById(assetId, s3UpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * S3Api - factory interface
 * @export
 */
export const S3ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = S3ApiFp(configuration)
    return {
        /**
         * Creates S3-compatible datasource asset.
         * @summary Create S3 asset.
         * @param {S3CreationRequest} [s3CreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createS3Asset(s3CreationRequest?: S3CreationRequest, options?: any): AxiosPromise<S3Asset> {
            return localVarFp.createS3Asset(s3CreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes S3-compatible datasource asset, by id.
         * @summary Delete S3 asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteS3AssetById(assetId: string, options?: any): AxiosPromise<HttpResponse> {
            return localVarFp.deleteS3AssetById(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of S3-compatible datasource asset, by id.
         * @summary Get S3 asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getS3AssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: any): AxiosPromise<S3Asset> {
            return localVarFp.getS3AssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of S3-compatible datasource assets.
         * @summary List S3 assets.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
         * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
         * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listS3Assets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: any): AxiosPromise<S3ListResponse> {
            return localVarFp.listS3Assets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the details of S3-compatible datasource asset, by id.
         * @summary Update S3 asset.
         * @param {string} assetId Unique identifier of the asset.
         * @param {S3UpdateRequest} [s3UpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateS3AssetById(assetId: string, s3UpdateRequest?: S3UpdateRequest, options?: any): AxiosPromise<S3Asset> {
            return localVarFp.updateS3AssetById(assetId, s3UpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * S3Api - object-oriented interface
 * @export
 * @class S3Api
 * @extends {BaseAPI}
 */
export class S3Api extends BaseAPI {
    /**
     * Creates S3-compatible datasource asset.
     * @summary Create S3 asset.
     * @param {S3CreationRequest} [s3CreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S3Api
     */
    public createS3Asset(s3CreationRequest?: S3CreationRequest, options?: AxiosRequestConfig) {
        return S3ApiFp(this.configuration).createS3Asset(s3CreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes S3-compatible datasource asset, by id.
     * @summary Delete S3 asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S3Api
     */
    public deleteS3AssetById(assetId: string, options?: AxiosRequestConfig) {
        return S3ApiFp(this.configuration).deleteS3AssetById(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of S3-compatible datasource asset, by id.
     * @summary Get S3 asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S3Api
     */
    public getS3AssetById(assetId: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig) {
        return S3ApiFp(this.configuration).getS3AssetById(assetId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of S3-compatible datasource assets.
     * @summary List S3 assets.
     * @param {string} [name] Filter results by name.
     * @param {string} [scope] Filter results by scope.
     * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
     * @param {string} [departmentId] department id to filter by
     * @param {string} [clusterId] cluster id to filter by
     * @param {boolean} [usageInfo] Whether the query should include asset usage information as part of the response.
     * @param {number} [complyToProject] Include workload creation compliance information of an asset, for a given project, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {'Workspace' | 'Training'} [complyToWorkloadType] Include workload creation compliance information of an asset, for a given workload type, as part of the response. To check compliance, you need to provide both project id and workload type.
     * @param {boolean} [statusInfo] Whether the query should include asset status information as part of the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S3Api
     */
    public listS3Assets(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, usageInfo?: boolean, complyToProject?: number, complyToWorkloadType?: 'Workspace' | 'Training', statusInfo?: boolean, options?: AxiosRequestConfig) {
        return S3ApiFp(this.configuration).listS3Assets(name, scope, projectId, departmentId, clusterId, usageInfo, complyToProject, complyToWorkloadType, statusInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the details of S3-compatible datasource asset, by id.
     * @summary Update S3 asset.
     * @param {string} assetId Unique identifier of the asset.
     * @param {S3UpdateRequest} [s3UpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S3Api
     */
    public updateS3AssetById(assetId: string, s3UpdateRequest?: S3UpdateRequest, options?: AxiosRequestConfig) {
        return S3ApiFp(this.configuration).updateS3AssetById(assetId, s3UpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SynchronizationApi - axios parameter creator
 * @export
 */
export const SynchronizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns summary information about changes of access key assets at the control plane.
         * @summary Get access keys sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessKeySyncInfo: async (clusterId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/sync/credentials/access-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns summary information about changes of access key assets at the control plane.
         * @summary Get access keys sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] the scope level of the asset. If empty - tenant scoped assets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessKeySyncInfoV2: async (clusterId?: string, scope?: 'tenant' | 'cluster' | 'department' | 'project', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/asset/sync/credentials/access-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns summary information about changes of docker registry credentials assets at the control plane.
         * @summary Get docker registry sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dockerRegistrySyncInfo: async (clusterId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/sync/credentials/docker-registry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns summary information about changes of docker registry credentials assets at the control plane.
         * @summary Get docker registry sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] the scope level of the asset. If empty - tenant scoped assets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dockerRegistrySyncInfoV2: async (clusterId?: string, scope?: 'tenant' | 'cluster' | 'department' | 'project', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/asset/sync/credentials/docker-registry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of assets.
         * @summary List assets by their ids
         * @param {string} id comma seperated list of asset ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssetsById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listAssetsById', 'id', id)
            const localVarPath = `/api/v1/asset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns summary information about changes of password assets at the control plane.
         * @summary Get passwords sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordSyncInfo: async (clusterId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/sync/credentials/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns summary information about changes of password assets at the control plane.
         * @summary Get passwords sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] the scope level of the asset. If empty - tenant scoped assets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordSyncInfoV2: async (clusterId?: string, scope?: 'tenant' | 'cluster' | 'department' | 'project', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/asset/sync/credentials/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns summary information about changes of pvc assets at the control plane.
         * @summary Get pvcs sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pvcSyncInfo: async (clusterId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/sync/storage/pvc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns summary information about changes of pvc assets at the control plane.
         * @summary Get pvcs sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] the scope level of the asset. If empty - tenant scoped assets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pvcSyncInfoV2: async (clusterId?: string, scope?: 'tenant' | 'cluster' | 'department' | 'project', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/asset/sync/storage/pvc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Informs the backend about existing access key assets as stored in the cluster, in order to synchronize them with the access key assets at the control-plane.
         * @summary Sync access key assets.
         * @param {SyncRequest} [syncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAccessKeys: async (syncRequest?: SyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/sync/credentials/access-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Informs the backend about existing access key assets as stored in the cluster, in order to synchronize them with the access key assets at the control-plane.
         * @summary Sync access key assets.
         * @param {SyncRequestV2} [syncRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAccessKeysV2: async (syncRequestV2?: SyncRequestV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/asset/sync/credentials/access-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Informs the backend about existing docker registry secrets as stored in the cluster, in order to synchronize them with the docker registry assets at the control-plane.
         * @summary Sync docker registry credentials assets.
         * @param {SyncRequest} [syncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDockerRegistry: async (syncRequest?: SyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/sync/credentials/docker-registry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Informs the backend about existing docker registry secrets as stored in the cluster, in order to synchronize them with the docker registry assets at the control-plane.
         * @summary Sync docker registry credentials assets.
         * @param {SyncRequestV2} [syncRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDockerRegistryV2: async (syncRequestV2?: SyncRequestV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/asset/sync/credentials/docker-registry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Informs the backend about existing password assets as stored in the cluster, in order to synchronize them with the password assets at the control-plane.
         * @summary Sync password assets.
         * @param {SyncRequest} [syncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPasswords: async (syncRequest?: SyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/sync/credentials/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Informs the backend about existing password assets as stored in the cluster, in order to synchronize them with the password assets at the control-plane.
         * @summary Sync password assets.
         * @param {SyncRequestV2} [syncRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPasswordsV2: async (syncRequestV2?: SyncRequestV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/asset/sync/credentials/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Informs the backend about existing pvc assets as stored in the cluster, in order to synchronize them with the pvc assets at the control-plane.
         * @summary Sync pvc assets.
         * @param {SyncRequest} [syncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPvcs: async (syncRequest?: SyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/sync/storage/pvc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Informs the backend about existing pvc assets status according to status in cluster.
         * @summary Sync pvc assets status.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PVCStatusRequest} [pVCStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPvcsStatus: async (clusterUuid: string, pVCStatusRequest?: PVCStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncPvcsStatus', 'clusterUuid', clusterUuid)
            const localVarPath = `/api/v2/asset/sync/storage/pvc/{clusterUuid}/status`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pVCStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Informs the backend about existing pvc assets as stored in the cluster, in order to synchronize them with the pvc assets at the control-plane.
         * @summary Sync pvc assets.
         * @param {SyncRequestV2} [syncRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPvcsV2: async (syncRequestV2?: SyncRequestV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/asset/sync/storage/pvc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SynchronizationApi - functional programming interface
 * @export
 */
export const SynchronizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SynchronizationApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns summary information about changes of access key assets at the control plane.
         * @summary Get access keys sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accessKeySyncInfo(clusterId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accessKeySyncInfo(clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns summary information about changes of access key assets at the control plane.
         * @summary Get access keys sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] the scope level of the asset. If empty - tenant scoped assets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accessKeySyncInfoV2(clusterId?: string, scope?: 'tenant' | 'cluster' | 'department' | 'project', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncInfoResponseV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accessKeySyncInfoV2(clusterId, scope, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns summary information about changes of docker registry credentials assets at the control plane.
         * @summary Get docker registry sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dockerRegistrySyncInfo(clusterId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dockerRegistrySyncInfo(clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns summary information about changes of docker registry credentials assets at the control plane.
         * @summary Get docker registry sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] the scope level of the asset. If empty - tenant scoped assets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dockerRegistrySyncInfoV2(clusterId?: string, scope?: 'tenant' | 'cluster' | 'department' | 'project', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncInfoResponseV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dockerRegistrySyncInfoV2(clusterId, scope, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of assets.
         * @summary List assets by their ids
         * @param {string} id comma seperated list of asset ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAssetsById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetListByIdsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAssetsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns summary information about changes of password assets at the control plane.
         * @summary Get passwords sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordSyncInfo(clusterId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordSyncInfo(clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns summary information about changes of password assets at the control plane.
         * @summary Get passwords sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] the scope level of the asset. If empty - tenant scoped assets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordSyncInfoV2(clusterId?: string, scope?: 'tenant' | 'cluster' | 'department' | 'project', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncInfoResponseV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordSyncInfoV2(clusterId, scope, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns summary information about changes of pvc assets at the control plane.
         * @summary Get pvcs sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pvcSyncInfo(clusterId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pvcSyncInfo(clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns summary information about changes of pvc assets at the control plane.
         * @summary Get pvcs sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] the scope level of the asset. If empty - tenant scoped assets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pvcSyncInfoV2(clusterId?: string, scope?: 'tenant' | 'cluster' | 'department' | 'project', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncInfoResponseV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pvcSyncInfoV2(clusterId, scope, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Informs the backend about existing access key assets as stored in the cluster, in order to synchronize them with the access key assets at the control-plane.
         * @summary Sync access key assets.
         * @param {SyncRequest} [syncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAccessKeys(syncRequest?: SyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessKeysSyncResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAccessKeys(syncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Informs the backend about existing access key assets as stored in the cluster, in order to synchronize them with the access key assets at the control-plane.
         * @summary Sync access key assets.
         * @param {SyncRequestV2} [syncRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAccessKeysV2(syncRequestV2?: SyncRequestV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessKeysSyncResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAccessKeysV2(syncRequestV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Informs the backend about existing docker registry secrets as stored in the cluster, in order to synchronize them with the docker registry assets at the control-plane.
         * @summary Sync docker registry credentials assets.
         * @param {SyncRequest} [syncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncDockerRegistry(syncRequest?: SyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DockerRegistrySyncResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncDockerRegistry(syncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Informs the backend about existing docker registry secrets as stored in the cluster, in order to synchronize them with the docker registry assets at the control-plane.
         * @summary Sync docker registry credentials assets.
         * @param {SyncRequestV2} [syncRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncDockerRegistryV2(syncRequestV2?: SyncRequestV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DockerRegistrySyncResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncDockerRegistryV2(syncRequestV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Informs the backend about existing password assets as stored in the cluster, in order to synchronize them with the password assets at the control-plane.
         * @summary Sync password assets.
         * @param {SyncRequest} [syncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncPasswords(syncRequest?: SyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordsSyncResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncPasswords(syncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Informs the backend about existing password assets as stored in the cluster, in order to synchronize them with the password assets at the control-plane.
         * @summary Sync password assets.
         * @param {SyncRequestV2} [syncRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncPasswordsV2(syncRequestV2?: SyncRequestV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordsSyncResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncPasswordsV2(syncRequestV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Informs the backend about existing pvc assets as stored in the cluster, in order to synchronize them with the pvc assets at the control-plane.
         * @summary Sync pvc assets.
         * @param {SyncRequest} [syncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncPvcs(syncRequest?: SyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVCsSyncResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncPvcs(syncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Informs the backend about existing pvc assets status according to status in cluster.
         * @summary Sync pvc assets status.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PVCStatusRequest} [pVCStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncPvcsStatus(clusterUuid: string, pVCStatusRequest?: PVCStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncPvcsStatus(clusterUuid, pVCStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Informs the backend about existing pvc assets as stored in the cluster, in order to synchronize them with the pvc assets at the control-plane.
         * @summary Sync pvc assets.
         * @param {SyncRequestV2} [syncRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncPvcsV2(syncRequestV2?: SyncRequestV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVCsSyncResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncPvcsV2(syncRequestV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SynchronizationApi - factory interface
 * @export
 */
export const SynchronizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SynchronizationApiFp(configuration)
    return {
        /**
         * Returns summary information about changes of access key assets at the control plane.
         * @summary Get access keys sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessKeySyncInfo(clusterId?: string, options?: any): AxiosPromise<SyncInfoResponse> {
            return localVarFp.accessKeySyncInfo(clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns summary information about changes of access key assets at the control plane.
         * @summary Get access keys sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] the scope level of the asset. If empty - tenant scoped assets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessKeySyncInfoV2(clusterId?: string, scope?: 'tenant' | 'cluster' | 'department' | 'project', options?: any): AxiosPromise<SyncInfoResponseV2> {
            return localVarFp.accessKeySyncInfoV2(clusterId, scope, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns summary information about changes of docker registry credentials assets at the control plane.
         * @summary Get docker registry sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dockerRegistrySyncInfo(clusterId?: string, options?: any): AxiosPromise<SyncInfoResponse> {
            return localVarFp.dockerRegistrySyncInfo(clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns summary information about changes of docker registry credentials assets at the control plane.
         * @summary Get docker registry sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] the scope level of the asset. If empty - tenant scoped assets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dockerRegistrySyncInfoV2(clusterId?: string, scope?: 'tenant' | 'cluster' | 'department' | 'project', options?: any): AxiosPromise<SyncInfoResponseV2> {
            return localVarFp.dockerRegistrySyncInfoV2(clusterId, scope, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of assets.
         * @summary List assets by their ids
         * @param {string} id comma seperated list of asset ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssetsById(id: string, options?: any): AxiosPromise<AssetListByIdsResponse> {
            return localVarFp.listAssetsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns summary information about changes of password assets at the control plane.
         * @summary Get passwords sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordSyncInfo(clusterId?: string, options?: any): AxiosPromise<SyncInfoResponse> {
            return localVarFp.passwordSyncInfo(clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns summary information about changes of password assets at the control plane.
         * @summary Get passwords sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] the scope level of the asset. If empty - tenant scoped assets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordSyncInfoV2(clusterId?: string, scope?: 'tenant' | 'cluster' | 'department' | 'project', options?: any): AxiosPromise<SyncInfoResponseV2> {
            return localVarFp.passwordSyncInfoV2(clusterId, scope, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns summary information about changes of pvc assets at the control plane.
         * @summary Get pvcs sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pvcSyncInfo(clusterId?: string, options?: any): AxiosPromise<SyncInfoResponse> {
            return localVarFp.pvcSyncInfo(clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns summary information about changes of pvc assets at the control plane.
         * @summary Get pvcs sync info.
         * @param {string} [clusterId] cluster id to filter by
         * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] the scope level of the asset. If empty - tenant scoped assets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pvcSyncInfoV2(clusterId?: string, scope?: 'tenant' | 'cluster' | 'department' | 'project', options?: any): AxiosPromise<SyncInfoResponseV2> {
            return localVarFp.pvcSyncInfoV2(clusterId, scope, options).then((request) => request(axios, basePath));
        },
        /**
         * Informs the backend about existing access key assets as stored in the cluster, in order to synchronize them with the access key assets at the control-plane.
         * @summary Sync access key assets.
         * @param {SyncRequest} [syncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAccessKeys(syncRequest?: SyncRequest, options?: any): AxiosPromise<AccessKeysSyncResponse> {
            return localVarFp.syncAccessKeys(syncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Informs the backend about existing access key assets as stored in the cluster, in order to synchronize them with the access key assets at the control-plane.
         * @summary Sync access key assets.
         * @param {SyncRequestV2} [syncRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAccessKeysV2(syncRequestV2?: SyncRequestV2, options?: any): AxiosPromise<AccessKeysSyncResponse> {
            return localVarFp.syncAccessKeysV2(syncRequestV2, options).then((request) => request(axios, basePath));
        },
        /**
         * Informs the backend about existing docker registry secrets as stored in the cluster, in order to synchronize them with the docker registry assets at the control-plane.
         * @summary Sync docker registry credentials assets.
         * @param {SyncRequest} [syncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDockerRegistry(syncRequest?: SyncRequest, options?: any): AxiosPromise<DockerRegistrySyncResponse> {
            return localVarFp.syncDockerRegistry(syncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Informs the backend about existing docker registry secrets as stored in the cluster, in order to synchronize them with the docker registry assets at the control-plane.
         * @summary Sync docker registry credentials assets.
         * @param {SyncRequestV2} [syncRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDockerRegistryV2(syncRequestV2?: SyncRequestV2, options?: any): AxiosPromise<DockerRegistrySyncResponse> {
            return localVarFp.syncDockerRegistryV2(syncRequestV2, options).then((request) => request(axios, basePath));
        },
        /**
         * Informs the backend about existing password assets as stored in the cluster, in order to synchronize them with the password assets at the control-plane.
         * @summary Sync password assets.
         * @param {SyncRequest} [syncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPasswords(syncRequest?: SyncRequest, options?: any): AxiosPromise<PasswordsSyncResponse> {
            return localVarFp.syncPasswords(syncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Informs the backend about existing password assets as stored in the cluster, in order to synchronize them with the password assets at the control-plane.
         * @summary Sync password assets.
         * @param {SyncRequestV2} [syncRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPasswordsV2(syncRequestV2?: SyncRequestV2, options?: any): AxiosPromise<PasswordsSyncResponse> {
            return localVarFp.syncPasswordsV2(syncRequestV2, options).then((request) => request(axios, basePath));
        },
        /**
         * Informs the backend about existing pvc assets as stored in the cluster, in order to synchronize them with the pvc assets at the control-plane.
         * @summary Sync pvc assets.
         * @param {SyncRequest} [syncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPvcs(syncRequest?: SyncRequest, options?: any): AxiosPromise<PVCsSyncResponse> {
            return localVarFp.syncPvcs(syncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Informs the backend about existing pvc assets status according to status in cluster.
         * @summary Sync pvc assets status.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PVCStatusRequest} [pVCStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPvcsStatus(clusterUuid: string, pVCStatusRequest?: PVCStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.syncPvcsStatus(clusterUuid, pVCStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Informs the backend about existing pvc assets as stored in the cluster, in order to synchronize them with the pvc assets at the control-plane.
         * @summary Sync pvc assets.
         * @param {SyncRequestV2} [syncRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPvcsV2(syncRequestV2?: SyncRequestV2, options?: any): AxiosPromise<PVCsSyncResponse> {
            return localVarFp.syncPvcsV2(syncRequestV2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SynchronizationApi - object-oriented interface
 * @export
 * @class SynchronizationApi
 * @extends {BaseAPI}
 */
export class SynchronizationApi extends BaseAPI {
    /**
     * Returns summary information about changes of access key assets at the control plane.
     * @summary Get access keys sync info.
     * @param {string} [clusterId] cluster id to filter by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public accessKeySyncInfo(clusterId?: string, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).accessKeySyncInfo(clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns summary information about changes of access key assets at the control plane.
     * @summary Get access keys sync info.
     * @param {string} [clusterId] cluster id to filter by
     * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] the scope level of the asset. If empty - tenant scoped assets.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public accessKeySyncInfoV2(clusterId?: string, scope?: 'tenant' | 'cluster' | 'department' | 'project', options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).accessKeySyncInfoV2(clusterId, scope, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns summary information about changes of docker registry credentials assets at the control plane.
     * @summary Get docker registry sync info.
     * @param {string} [clusterId] cluster id to filter by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public dockerRegistrySyncInfo(clusterId?: string, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).dockerRegistrySyncInfo(clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns summary information about changes of docker registry credentials assets at the control plane.
     * @summary Get docker registry sync info.
     * @param {string} [clusterId] cluster id to filter by
     * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] the scope level of the asset. If empty - tenant scoped assets.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public dockerRegistrySyncInfoV2(clusterId?: string, scope?: 'tenant' | 'cluster' | 'department' | 'project', options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).dockerRegistrySyncInfoV2(clusterId, scope, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of assets.
     * @summary List assets by their ids
     * @param {string} id comma seperated list of asset ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public listAssetsById(id: string, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).listAssetsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns summary information about changes of password assets at the control plane.
     * @summary Get passwords sync info.
     * @param {string} [clusterId] cluster id to filter by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public passwordSyncInfo(clusterId?: string, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).passwordSyncInfo(clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns summary information about changes of password assets at the control plane.
     * @summary Get passwords sync info.
     * @param {string} [clusterId] cluster id to filter by
     * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] the scope level of the asset. If empty - tenant scoped assets.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public passwordSyncInfoV2(clusterId?: string, scope?: 'tenant' | 'cluster' | 'department' | 'project', options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).passwordSyncInfoV2(clusterId, scope, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns summary information about changes of pvc assets at the control plane.
     * @summary Get pvcs sync info.
     * @param {string} [clusterId] cluster id to filter by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public pvcSyncInfo(clusterId?: string, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).pvcSyncInfo(clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns summary information about changes of pvc assets at the control plane.
     * @summary Get pvcs sync info.
     * @param {string} [clusterId] cluster id to filter by
     * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] the scope level of the asset. If empty - tenant scoped assets.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public pvcSyncInfoV2(clusterId?: string, scope?: 'tenant' | 'cluster' | 'department' | 'project', options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).pvcSyncInfoV2(clusterId, scope, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Informs the backend about existing access key assets as stored in the cluster, in order to synchronize them with the access key assets at the control-plane.
     * @summary Sync access key assets.
     * @param {SyncRequest} [syncRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public syncAccessKeys(syncRequest?: SyncRequest, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).syncAccessKeys(syncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Informs the backend about existing access key assets as stored in the cluster, in order to synchronize them with the access key assets at the control-plane.
     * @summary Sync access key assets.
     * @param {SyncRequestV2} [syncRequestV2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public syncAccessKeysV2(syncRequestV2?: SyncRequestV2, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).syncAccessKeysV2(syncRequestV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Informs the backend about existing docker registry secrets as stored in the cluster, in order to synchronize them with the docker registry assets at the control-plane.
     * @summary Sync docker registry credentials assets.
     * @param {SyncRequest} [syncRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public syncDockerRegistry(syncRequest?: SyncRequest, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).syncDockerRegistry(syncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Informs the backend about existing docker registry secrets as stored in the cluster, in order to synchronize them with the docker registry assets at the control-plane.
     * @summary Sync docker registry credentials assets.
     * @param {SyncRequestV2} [syncRequestV2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public syncDockerRegistryV2(syncRequestV2?: SyncRequestV2, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).syncDockerRegistryV2(syncRequestV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Informs the backend about existing password assets as stored in the cluster, in order to synchronize them with the password assets at the control-plane.
     * @summary Sync password assets.
     * @param {SyncRequest} [syncRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public syncPasswords(syncRequest?: SyncRequest, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).syncPasswords(syncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Informs the backend about existing password assets as stored in the cluster, in order to synchronize them with the password assets at the control-plane.
     * @summary Sync password assets.
     * @param {SyncRequestV2} [syncRequestV2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public syncPasswordsV2(syncRequestV2?: SyncRequestV2, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).syncPasswordsV2(syncRequestV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Informs the backend about existing pvc assets as stored in the cluster, in order to synchronize them with the pvc assets at the control-plane.
     * @summary Sync pvc assets.
     * @param {SyncRequest} [syncRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public syncPvcs(syncRequest?: SyncRequest, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).syncPvcs(syncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Informs the backend about existing pvc assets status according to status in cluster.
     * @summary Sync pvc assets status.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {PVCStatusRequest} [pVCStatusRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public syncPvcsStatus(clusterUuid: string, pVCStatusRequest?: PVCStatusRequest, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).syncPvcsStatus(clusterUuid, pVCStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Informs the backend about existing pvc assets as stored in the cluster, in order to synchronize them with the pvc assets at the control-plane.
     * @summary Sync pvc assets.
     * @param {SyncRequestV2} [syncRequestV2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public syncPvcsV2(syncRequestV2?: SyncRequestV2, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).syncPvcsV2(syncRequestV2, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplateApi - axios parameter creator
 * @export
 */
export const TemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a template.
         * @summary Create template.
         * @param {WorkloadTemplateCreationRequest} [workloadTemplateCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate: async (workloadTemplateCreationRequest?: WorkloadTemplateCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/workload-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workloadTemplateCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a template, by id.
         * @summary Delete template.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplateById: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deleteTemplateById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/workload-template/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of a template, by id.
         * @summary Get workspace template.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateById: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getTemplateById', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/workload-template/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of templates.
         * @summary List templates.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {string} [distributedFramework] Filter results to workload of type distributed and distributedFramework.
         * @param {boolean} [isDistributed] Filter results to workload of type distributed.
         * @param {boolean} [isTraining] Filter results to workload of type training.
         * @param {boolean} [isWorkspace] Filter results to workload of type workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplates: async (name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, distributedFramework?: string, isDistributed?: boolean, isTraining?: boolean, isWorkspace?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/asset/workload-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (distributedFramework !== undefined) {
                localVarQueryParameter['distributedFramework'] = distributedFramework;
            }

            if (isDistributed !== undefined) {
                localVarQueryParameter['isDistributed'] = isDistributed;
            }

            if (isTraining !== undefined) {
                localVarQueryParameter['isTraining'] = isTraining;
            }

            if (isWorkspace !== undefined) {
                localVarQueryParameter['isWorkspace'] = isWorkspace;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the details of a template, by id.
         * @summary Update template.
         * @param {string} assetId Unique identifier of the asset.
         * @param {WorkloadTemplateUpdateRequest} [workloadTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate: async (assetId: string, workloadTemplateUpdateRequest?: WorkloadTemplateUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('updateTemplate', 'assetId', assetId)
            const localVarPath = `/api/v1/asset/workload-template/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workloadTemplateUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateApi - functional programming interface
 * @export
 */
export const TemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a template.
         * @summary Create template.
         * @param {WorkloadTemplateCreationRequest} [workloadTemplateCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTemplate(workloadTemplateCreationRequest?: WorkloadTemplateCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkloadTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTemplate(workloadTemplateCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a template, by id.
         * @summary Delete template.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplateById(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemplateById(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of a template, by id.
         * @summary Get workspace template.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplateById(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkloadTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplateById(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of templates.
         * @summary List templates.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {string} [distributedFramework] Filter results to workload of type distributed and distributedFramework.
         * @param {boolean} [isDistributed] Filter results to workload of type distributed.
         * @param {boolean} [isTraining] Filter results to workload of type training.
         * @param {boolean} [isWorkspace] Filter results to workload of type workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTemplates(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, distributedFramework?: string, isDistributed?: boolean, isTraining?: boolean, isWorkspace?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkloadTemplateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTemplates(name, scope, projectId, departmentId, clusterId, distributedFramework, isDistributed, isTraining, isWorkspace, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the details of a template, by id.
         * @summary Update template.
         * @param {string} assetId Unique identifier of the asset.
         * @param {WorkloadTemplateUpdateRequest} [workloadTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplate(assetId: string, workloadTemplateUpdateRequest?: WorkloadTemplateUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkloadTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTemplate(assetId, workloadTemplateUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplateApi - factory interface
 * @export
 */
export const TemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateApiFp(configuration)
    return {
        /**
         * Creates a template.
         * @summary Create template.
         * @param {WorkloadTemplateCreationRequest} [workloadTemplateCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate(workloadTemplateCreationRequest?: WorkloadTemplateCreationRequest, options?: any): AxiosPromise<WorkloadTemplate> {
            return localVarFp.createTemplate(workloadTemplateCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a template, by id.
         * @summary Delete template.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplateById(assetId: string, options?: any): AxiosPromise<HttpResponse> {
            return localVarFp.deleteTemplateById(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of a template, by id.
         * @summary Get workspace template.
         * @param {string} assetId Unique identifier of the asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateById(assetId: string, options?: any): AxiosPromise<WorkloadTemplate> {
            return localVarFp.getTemplateById(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of templates.
         * @summary List templates.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {string} [clusterId] cluster id to filter by
         * @param {string} [distributedFramework] Filter results to workload of type distributed and distributedFramework.
         * @param {boolean} [isDistributed] Filter results to workload of type distributed.
         * @param {boolean} [isTraining] Filter results to workload of type training.
         * @param {boolean} [isWorkspace] Filter results to workload of type workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplates(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, distributedFramework?: string, isDistributed?: boolean, isTraining?: boolean, isWorkspace?: boolean, options?: any): AxiosPromise<WorkloadTemplateListResponse> {
            return localVarFp.listTemplates(name, scope, projectId, departmentId, clusterId, distributedFramework, isDistributed, isTraining, isWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the details of a template, by id.
         * @summary Update template.
         * @param {string} assetId Unique identifier of the asset.
         * @param {WorkloadTemplateUpdateRequest} [workloadTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate(assetId: string, workloadTemplateUpdateRequest?: WorkloadTemplateUpdateRequest, options?: any): AxiosPromise<WorkloadTemplate> {
            return localVarFp.updateTemplate(assetId, workloadTemplateUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateApi - object-oriented interface
 * @export
 * @class TemplateApi
 * @extends {BaseAPI}
 */
export class TemplateApi extends BaseAPI {
    /**
     * Creates a template.
     * @summary Create template.
     * @param {WorkloadTemplateCreationRequest} [workloadTemplateCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public createTemplate(workloadTemplateCreationRequest?: WorkloadTemplateCreationRequest, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).createTemplate(workloadTemplateCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a template, by id.
     * @summary Delete template.
     * @param {string} assetId Unique identifier of the asset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public deleteTemplateById(assetId: string, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).deleteTemplateById(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of a template, by id.
     * @summary Get workspace template.
     * @param {string} assetId Unique identifier of the asset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public getTemplateById(assetId: string, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).getTemplateById(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of templates.
     * @summary List templates.
     * @param {string} [name] Filter results by name.
     * @param {string} [scope] Filter results by scope.
     * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
     * @param {string} [departmentId] department id to filter by
     * @param {string} [clusterId] cluster id to filter by
     * @param {string} [distributedFramework] Filter results to workload of type distributed and distributedFramework.
     * @param {boolean} [isDistributed] Filter results to workload of type distributed.
     * @param {boolean} [isTraining] Filter results to workload of type training.
     * @param {boolean} [isWorkspace] Filter results to workload of type workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public listTemplates(name?: string, scope?: string, projectId?: number, departmentId?: string, clusterId?: string, distributedFramework?: string, isDistributed?: boolean, isTraining?: boolean, isWorkspace?: boolean, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).listTemplates(name, scope, projectId, departmentId, clusterId, distributedFramework, isDistributed, isTraining, isWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the details of a template, by id.
     * @summary Update template.
     * @param {string} assetId Unique identifier of the asset.
     * @param {WorkloadTemplateUpdateRequest} [workloadTemplateUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public updateTemplate(assetId: string, workloadTemplateUpdateRequest?: WorkloadTemplateUpdateRequest, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).updateTemplate(assetId, workloadTemplateUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrainingApi - axios parameter creator
 * @export
 */
export const TrainingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a training workload from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
         * @summary Create training workload.
         * @param {WorkloadCreationRequest} [workloadCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTraining: async (workloadCreationRequest?: WorkloadCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/training`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workloadCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a training from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
         * @summary Create training.
         * @param {TrainingCreationRequestV2} [trainingCreationRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTrainingV2: async (trainingCreationRequestV2?: TrainingCreationRequestV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/training`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainingCreationRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the content of the training, i.e. its set of assets and specific env. The response is similar to that of a template, so it can be loaded to the training creation form
         * @summary Get training content.
         * @param {string} id Unique identifier of the training.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTrainingById', 'id', id)
            const localVarPath = `/api/v1/training/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List training in the cluster
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodeId] Unique identifier of the node.
         * @param {string} [filter] 
         * @param {string} [sortBy] Order of the results.
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [from] Start the response from a given number of result. Used along with \&#39;limit\&#39; to retrieve the results paginated.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTrainings: async (clusterUuid: string, nodeId?: string, filter?: string, sortBy?: string, sortDirection?: 'ASC' | 'DESC', from?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('listTrainings', 'clusterUuid', clusterUuid)
            const localVarPath = `/v1/k8s/clusters/{clusterUuid}/trainings`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeId !== undefined) {
                localVarQueryParameter['nodeId'] = nodeId;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainingApi - functional programming interface
 * @export
 */
export const TrainingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrainingApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a training workload from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
         * @summary Create training workload.
         * @param {WorkloadCreationRequest} [workloadCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTraining(workloadCreationRequest?: WorkloadCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Training>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTraining(workloadCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a training from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
         * @summary Create training.
         * @param {TrainingCreationRequestV2} [trainingCreationRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTrainingV2(trainingCreationRequestV2?: TrainingCreationRequestV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainingV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTrainingV2(trainingCreationRequestV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the content of the training, i.e. its set of assets and specific env. The response is similar to that of a template, so it can be loaded to the training creation form
         * @summary Get training content.
         * @param {string} id Unique identifier of the training.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrainingById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Training>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrainingById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List training in the cluster
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodeId] Unique identifier of the node.
         * @param {string} [filter] 
         * @param {string} [sortBy] Order of the results.
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [from] Start the response from a given number of result. Used along with \&#39;limit\&#39; to retrieve the results paginated.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTrainings(clusterUuid: string, nodeId?: string, filter?: string, sortBy?: string, sortDirection?: 'ASC' | 'DESC', from?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainingListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTrainings(clusterUuid, nodeId, filter, sortBy, sortDirection, from, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrainingApi - factory interface
 * @export
 */
export const TrainingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrainingApiFp(configuration)
    return {
        /**
         * Creates a training workload from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
         * @summary Create training workload.
         * @param {WorkloadCreationRequest} [workloadCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTraining(workloadCreationRequest?: WorkloadCreationRequest, options?: any): AxiosPromise<Training> {
            return localVarFp.createTraining(workloadCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a training from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
         * @summary Create training.
         * @param {TrainingCreationRequestV2} [trainingCreationRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTrainingV2(trainingCreationRequestV2?: TrainingCreationRequestV2, options?: any): AxiosPromise<TrainingV2> {
            return localVarFp.createTrainingV2(trainingCreationRequestV2, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the content of the training, i.e. its set of assets and specific env. The response is similar to that of a template, so it can be loaded to the training creation form
         * @summary Get training content.
         * @param {string} id Unique identifier of the training.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingById(id: string, options?: any): AxiosPromise<Training> {
            return localVarFp.getTrainingById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List training in the cluster
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodeId] Unique identifier of the node.
         * @param {string} [filter] 
         * @param {string} [sortBy] Order of the results.
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [from] Start the response from a given number of result. Used along with \&#39;limit\&#39; to retrieve the results paginated.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTrainings(clusterUuid: string, nodeId?: string, filter?: string, sortBy?: string, sortDirection?: 'ASC' | 'DESC', from?: number, limit?: number, options?: any): AxiosPromise<TrainingListResponse> {
            return localVarFp.listTrainings(clusterUuid, nodeId, filter, sortBy, sortDirection, from, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrainingApi - object-oriented interface
 * @export
 * @class TrainingApi
 * @extends {BaseAPI}
 */
export class TrainingApi extends BaseAPI {
    /**
     * Creates a training workload from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
     * @summary Create training workload.
     * @param {WorkloadCreationRequest} [workloadCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApi
     */
    public createTraining(workloadCreationRequest?: WorkloadCreationRequest, options?: AxiosRequestConfig) {
        return TrainingApiFp(this.configuration).createTraining(workloadCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a training from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
     * @summary Create training.
     * @param {TrainingCreationRequestV2} [trainingCreationRequestV2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApi
     */
    public createTrainingV2(trainingCreationRequestV2?: TrainingCreationRequestV2, options?: AxiosRequestConfig) {
        return TrainingApiFp(this.configuration).createTrainingV2(trainingCreationRequestV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the content of the training, i.e. its set of assets and specific env. The response is similar to that of a template, so it can be loaded to the training creation form
     * @summary Get training content.
     * @param {string} id Unique identifier of the training.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApi
     */
    public getTrainingById(id: string, options?: AxiosRequestConfig) {
        return TrainingApiFp(this.configuration).getTrainingById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List training in the cluster
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} [nodeId] Unique identifier of the node.
     * @param {string} [filter] 
     * @param {string} [sortBy] Order of the results.
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {number} [from] Start the response from a given number of result. Used along with \&#39;limit\&#39; to retrieve the results paginated.
     * @param {number} [limit] The maximum number of entries to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApi
     */
    public listTrainings(clusterUuid: string, nodeId?: string, filter?: string, sortBy?: string, sortDirection?: 'ASC' | 'DESC', from?: number, limit?: number, options?: AxiosRequestConfig) {
        return TrainingApiFp(this.configuration).listTrainings(clusterUuid, nodeId, filter, sortBy, sortDirection, from, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkloadApi - axios parameter creator
 * @export
 */
export const WorkloadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Check if a given asset name creates naming conflicts under the given scope. Returns conflict (409) in case the name is not available, or 204 (no content) if it is ok.
         * @summary Asset name conflict validation
         * @param {string} kind Filter results by asset kind.
         * @param {string} assetName the name of the asset
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scopeType The resource scope type to filter by.
         * @param {string} [scopeId] The id of the cluster, department or project, depending on the scope type.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        assetNameConflictValidation: async (kind: string, assetName: string, scopeType: 'tenant' | 'cluster' | 'department' | 'project', scopeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'kind' is not null or undefined
            assertParamExists('assetNameConflictValidation', 'kind', kind)
            // verify required parameter 'assetName' is not null or undefined
            assertParamExists('assetNameConflictValidation', 'assetName', assetName)
            // verify required parameter 'scopeType' is not null or undefined
            assertParamExists('assetNameConflictValidation', 'scopeType', scopeType)
            const localVarPath = `/api/v1/asset/name-availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kind !== undefined) {
                localVarQueryParameter['kind'] = kind;
            }

            if (assetName !== undefined) {
                localVarQueryParameter['assetName'] = assetName;
            }

            if (scopeType !== undefined) {
                localVarQueryParameter['scopeType'] = scopeType;
            }

            if (scopeId !== undefined) {
                localVarQueryParameter['scopeId'] = scopeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkloadApi - functional programming interface
 * @export
 */
export const WorkloadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkloadApiAxiosParamCreator(configuration)
    return {
        /**
         * Check if a given asset name creates naming conflicts under the given scope. Returns conflict (409) in case the name is not available, or 204 (no content) if it is ok.
         * @summary Asset name conflict validation
         * @param {string} kind Filter results by asset kind.
         * @param {string} assetName the name of the asset
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scopeType The resource scope type to filter by.
         * @param {string} [scopeId] The id of the cluster, department or project, depending on the scope type.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async assetNameConflictValidation(kind: string, assetName: string, scopeType: 'tenant' | 'cluster' | 'department' | 'project', scopeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetNameConflictValidation(kind, assetName, scopeType, scopeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkloadApi - factory interface
 * @export
 */
export const WorkloadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkloadApiFp(configuration)
    return {
        /**
         * Check if a given asset name creates naming conflicts under the given scope. Returns conflict (409) in case the name is not available, or 204 (no content) if it is ok.
         * @summary Asset name conflict validation
         * @param {string} kind Filter results by asset kind.
         * @param {string} assetName the name of the asset
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scopeType The resource scope type to filter by.
         * @param {string} [scopeId] The id of the cluster, department or project, depending on the scope type.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        assetNameConflictValidation(kind: string, assetName: string, scopeType: 'tenant' | 'cluster' | 'department' | 'project', scopeId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.assetNameConflictValidation(kind, assetName, scopeType, scopeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkloadApi - object-oriented interface
 * @export
 * @class WorkloadApi
 * @extends {BaseAPI}
 */
export class WorkloadApi extends BaseAPI {
    /**
     * Check if a given asset name creates naming conflicts under the given scope. Returns conflict (409) in case the name is not available, or 204 (no content) if it is ok.
     * @summary Asset name conflict validation
     * @param {string} kind Filter results by asset kind.
     * @param {string} assetName the name of the asset
     * @param {'tenant' | 'cluster' | 'department' | 'project'} scopeType The resource scope type to filter by.
     * @param {string} [scopeId] The id of the cluster, department or project, depending on the scope type.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof WorkloadApi
     */
    public assetNameConflictValidation(kind: string, assetName: string, scopeType: 'tenant' | 'cluster' | 'department' | 'project', scopeId?: string, options?: AxiosRequestConfig) {
        return WorkloadApiFp(this.configuration).assetNameConflictValidation(kind, assetName, scopeType, scopeId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkspaceApi - axios parameter creator
 * @export
 */
export const WorkspaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the number of assets that match a given set of filters. The API is deprecated.
         * @summary Count assets.
         * @param {string} kind Filter results by asset kind.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        countAssets: async (kind: string, name?: string, scope?: string, projectId?: number, departmentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'kind' is not null or undefined
            assertParamExists('countAssets', 'kind', kind)
            const localVarPath = `/api/v1/asset/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kind !== undefined) {
                localVarQueryParameter['kind'] = kind;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a workspace from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
         * @summary Create workspace.
         * @param {WorkloadCreationRequest} [workloadCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspace: async (workloadCreationRequest?: WorkloadCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/workspace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workloadCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a workspace from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
         * @summary Create workspace.
         * @param {WorkspaceCreationRequestV2} [workspaceCreationRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspaceV2: async (workspaceCreationRequestV2?: WorkspaceCreationRequestV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/workspace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspaceCreationRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the content of the workspace, i.e. its set of assets and specific env. The response is similar to that of a template, so it can be loaded to the workspace creation form, just like a workspace template.
         * @summary Get workspace content.
         * @param {string} id Unique identifier of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWorkspaceById', 'id', id)
            const localVarPath = `/api/v1/workspace/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List workspaces in the cluster
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodeId] Unique identifier of the node.
         * @param {string} [filter] 
         * @param {string} [sortBy] Order of the results.
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [from] Start the response from a given number of result. Used along with \&#39;limit\&#39; to retrieve the results paginated.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkspaces: async (clusterUuid: string, nodeId?: string, filter?: string, sortBy?: string, sortDirection?: 'ASC' | 'DESC', from?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('listWorkspaces', 'clusterUuid', clusterUuid)
            const localVarPath = `/v1/k8s/clusters/{clusterUuid}/workspaces`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeId !== undefined) {
                localVarQueryParameter['nodeId'] = nodeId;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspaceApi - functional programming interface
 * @export
 */
export const WorkspaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkspaceApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the number of assets that match a given set of filters. The API is deprecated.
         * @summary Count assets.
         * @param {string} kind Filter results by asset kind.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async countAssets(kind: string, name?: string, scope?: string, projectId?: number, departmentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countAssets(kind, name, scope, projectId, departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a workspace from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
         * @summary Create workspace.
         * @param {WorkloadCreationRequest} [workloadCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkspace(workloadCreationRequest?: WorkloadCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workspace>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkspace(workloadCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a workspace from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
         * @summary Create workspace.
         * @param {WorkspaceCreationRequestV2} [workspaceCreationRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkspaceV2(workspaceCreationRequestV2?: WorkspaceCreationRequestV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkspaceV2(workspaceCreationRequestV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the content of the workspace, i.e. its set of assets and specific env. The response is similar to that of a template, so it can be loaded to the workspace creation form, just like a workspace template.
         * @summary Get workspace content.
         * @param {string} id Unique identifier of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workspace>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaceById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List workspaces in the cluster
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodeId] Unique identifier of the node.
         * @param {string} [filter] 
         * @param {string} [sortBy] Order of the results.
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [from] Start the response from a given number of result. Used along with \&#39;limit\&#39; to retrieve the results paginated.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWorkspaces(clusterUuid: string, nodeId?: string, filter?: string, sortBy?: string, sortDirection?: 'ASC' | 'DESC', from?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWorkspaces(clusterUuid, nodeId, filter, sortBy, sortDirection, from, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkspaceApi - factory interface
 * @export
 */
export const WorkspaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkspaceApiFp(configuration)
    return {
        /**
         * Returns the number of assets that match a given set of filters. The API is deprecated.
         * @summary Count assets.
         * @param {string} kind Filter results by asset kind.
         * @param {string} [name] Filter results by name.
         * @param {string} [scope] Filter results by scope.
         * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
         * @param {string} [departmentId] department id to filter by
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        countAssets(kind: string, name?: string, scope?: string, projectId?: number, departmentId?: string, options?: any): AxiosPromise<AssetCountResponse> {
            return localVarFp.countAssets(kind, name, scope, projectId, departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a workspace from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
         * @summary Create workspace.
         * @param {WorkloadCreationRequest} [workloadCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspace(workloadCreationRequest?: WorkloadCreationRequest, options?: any): AxiosPromise<Workspace> {
            return localVarFp.createWorkspace(workloadCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a workspace from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
         * @summary Create workspace.
         * @param {WorkspaceCreationRequestV2} [workspaceCreationRequestV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspaceV2(workspaceCreationRequestV2?: WorkspaceCreationRequestV2, options?: any): AxiosPromise<WorkspaceV2> {
            return localVarFp.createWorkspaceV2(workspaceCreationRequestV2, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the content of the workspace, i.e. its set of assets and specific env. The response is similar to that of a template, so it can be loaded to the workspace creation form, just like a workspace template.
         * @summary Get workspace content.
         * @param {string} id Unique identifier of the workspace.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceById(id: string, options?: any): AxiosPromise<Workspace> {
            return localVarFp.getWorkspaceById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List workspaces in the cluster
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodeId] Unique identifier of the node.
         * @param {string} [filter] 
         * @param {string} [sortBy] Order of the results.
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [from] Start the response from a given number of result. Used along with \&#39;limit\&#39; to retrieve the results paginated.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkspaces(clusterUuid: string, nodeId?: string, filter?: string, sortBy?: string, sortDirection?: 'ASC' | 'DESC', from?: number, limit?: number, options?: any): AxiosPromise<WorkspaceListResponse> {
            return localVarFp.listWorkspaces(clusterUuid, nodeId, filter, sortBy, sortDirection, from, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkspaceApi - object-oriented interface
 * @export
 * @class WorkspaceApi
 * @extends {BaseAPI}
 */
export class WorkspaceApi extends BaseAPI {
    /**
     * Returns the number of assets that match a given set of filters. The API is deprecated.
     * @summary Count assets.
     * @param {string} kind Filter results by asset kind.
     * @param {string} [name] Filter results by name.
     * @param {string} [scope] Filter results by scope.
     * @param {number} [projectId] Filter results by project id. if scope filter is project, only assets from the specific project will be included in the response. otherwise, the response will include both project, department, cluster and tenant assets.
     * @param {string} [departmentId] department id to filter by
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public countAssets(kind: string, name?: string, scope?: string, projectId?: number, departmentId?: string, options?: AxiosRequestConfig) {
        return WorkspaceApiFp(this.configuration).countAssets(kind, name, scope, projectId, departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a workspace from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
     * @summary Create workspace.
     * @param {WorkloadCreationRequest} [workloadCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public createWorkspace(workloadCreationRequest?: WorkloadCreationRequest, options?: AxiosRequestConfig) {
        return WorkspaceApiFp(this.configuration).createWorkspace(workloadCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a workspace from a given set of assets. The set must include one environment asset and can include one compute asset and multiple datasource assets.
     * @summary Create workspace.
     * @param {WorkspaceCreationRequestV2} [workspaceCreationRequestV2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public createWorkspaceV2(workspaceCreationRequestV2?: WorkspaceCreationRequestV2, options?: AxiosRequestConfig) {
        return WorkspaceApiFp(this.configuration).createWorkspaceV2(workspaceCreationRequestV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the content of the workspace, i.e. its set of assets and specific env. The response is similar to that of a template, so it can be loaded to the workspace creation form, just like a workspace template.
     * @summary Get workspace content.
     * @param {string} id Unique identifier of the workspace.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public getWorkspaceById(id: string, options?: AxiosRequestConfig) {
        return WorkspaceApiFp(this.configuration).getWorkspaceById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List workspaces in the cluster
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} [nodeId] Unique identifier of the node.
     * @param {string} [filter] 
     * @param {string} [sortBy] Order of the results.
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {number} [from] Start the response from a given number of result. Used along with \&#39;limit\&#39; to retrieve the results paginated.
     * @param {number} [limit] The maximum number of entries to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public listWorkspaces(clusterUuid: string, nodeId?: string, filter?: string, sortBy?: string, sortDirection?: 'ASC' | 'DESC', from?: number, limit?: number, options?: AxiosRequestConfig) {
        return WorkspaceApiFp(this.configuration).listWorkspaces(clusterUuid, nodeId, filter, sortBy, sortDirection, from, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


