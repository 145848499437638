// models
import type { IIdpMapperItem } from "@/models/setting.model";
import type {
  IdpCreationRequest,
  Mappers,
  OidcCreationData,
  SamlCreationData,
} from "@/swagger-models/identity-manager-client";
import type { QNotifyCreateOptions } from "quasar/dist/types";
import { IdpTypeEnum } from "@/swagger-models/identity-manager-client";

// utils
import { alertUtil } from "@/utils/alert.util";

// store
import { useAuthStore } from "@/stores/auth.store";
import type { Setting } from "@/swagger-models/backend-client";
export const settingsUtil = {
  getIdpMappersItems,
  isIdpMappersEqual,
  getMappersFromIdpMapperItems,
  createIdpCreationRequest,
  getSettingErrorMessage,
  getToggleSectionCollapsedTitle,
};

enum IdpMapperIndex {
  Gid,
  Uid,
  Groups,
  SupplementaryGroups,
}
function getIdpMappersItems(mappers: Mappers): Array<IIdpMapperItem> {
  return [
    {
      key: "gid",
      label: "Linux user ID",
      value: mappers?.gid || "",
    },
    {
      key: "uid",
      label: "Linux group ID",
      value: mappers?.uid || "",
    },
    {
      key: "groups",
      label: "User role groups",
      value: mappers?.groups || "",
    },
    {
      key: "supplementaryGroups",
      label: "SUPPLEMENTARYGROUPS",
      value: mappers?.supplementaryGroups || "",
    },
  ];
}

function isIdpMappersEqual(mappers: Mappers, items: Array<IIdpMapperItem>): boolean {
  return (
    mappers.gid === items[IdpMapperIndex.Gid].value &&
    mappers.uid === items[IdpMapperIndex.Uid].value &&
    mappers.groups === items[IdpMapperIndex.Groups].value &&
    mappers.supplementaryGroups === items[IdpMapperIndex.SupplementaryGroups].value
  );
}

function getMappersFromIdpMapperItems(items: Array<IIdpMapperItem>): Mappers {
  return {
    gid: items[IdpMapperIndex.Gid].value,
    uid: items[IdpMapperIndex.Uid].value,
    groups: items[IdpMapperIndex.Groups].value,
    supplementaryGroups: items[IdpMapperIndex.SupplementaryGroups].value,
  };
}

function createIdpCreationRequest(
  alias: string,
  idpNewFields: OidcCreationData | SamlCreationData,
  mappers: Mappers,
): IdpCreationRequest | null {
  switch (alias) {
    case IdpTypeEnum.Oidc:
      return {
        type: alias,
        oidcData: idpNewFields as OidcCreationData,
        mappers: mappers,
      };

    case IdpTypeEnum.Saml:
      return {
        type: alias,
        samlData: idpNewFields as SamlCreationData,
        mappers: mappers,
      };

    default:
      return null;
  }
}

function getSettingErrorMessage(): QNotifyCreateOptions {
  const email = useAuthStore().currentUser.email;
  const message = `Settings couldn't be saved. Try again. If the issue persists, <a href="https://runai.my.salesforce-sites.com/casesupport/CreateCaseForm?email=${email}" target="_blank" style="color: white; text-decoration: underline">contact Run:ai support.</a>`;
  return alertUtil.getError(message, { html: true });
}

function getToggleSectionCollapsedTitle(settings: object): string {
  let enabled = 0;
  let disabled = 0;
  Object.values(settings)
    .filter((value) => typeof value?.value === "boolean")
    .forEach((value: Setting) => {
      if (!value) return;
      if (value.value) {
        enabled++;
      } else {
        disabled++;
      }
    });
  return `Enabled: (${enabled}) / Disabled (${disabled})`;
}
