// services
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { workloadService } from "@/services/cluster/workload.service/workload.service";
import { prometheusService } from "@/services/control-plane/prometheus.service/prometheus.service";
import { filterService } from "@/services/filter.service/filter.service";
import { storageUtil } from "@/utils/storage.util";
import { researcherService } from "@/services/cluster/researcher.service/researcher.service";
import { dateUtil } from "@/utils/date.util";
import { pick } from "@/utils/common.util";

// models
import type { WorkloadCreationRequest, Training, Scope } from "@/swagger-models/assets-service-client";
import type { IWorkloadCreate, IWorkloadResponse, IWorkloadSpec } from "@/models/workload.model";
import type { IExtendJobValues } from "@/models/workspace.model";
import type { ITrainingList, ITrainingFilterBy } from "@/models/training.model";
import { allTrainingColumns } from "@/table-models/training.table-model";
import type { IPrometheusResponse, IPrometheusMetric } from "@/models/prometheus.model";
import type { ILocalStatus } from "@/models/global.model";
import type { TrainingPolicy } from "@/swagger-models/policy-service-client";

// constants
import { K8S_API } from "@/common/api.constant";
import { API } from "@/common/api.constant";
import { EWorkloadStatus } from "@/common/status.constant";

const TRAINING_STATUS = "trainingStatus";

interface IClusterResponse {
  ok: boolean;
  name: string;
  error?: {
    details: string;
    message: string;
    status: number;
  };
}

interface IRemoveTrainingResponse {
  data: Array<IClusterResponse>;
}

export const trainingService = {
  createFromAssets,
  list,
  remove,
  getById,
  getByIdFromCluster,
  activate,
  stop,
  getPolicy,
};

const endpoint = `${API.v1}/training`;
function trainingsEndpoint(clusterUuid: string): string {
  return `${K8S_API.v1}/clusters/${clusterUuid}/trainings`;
}

// api calls
async function list(clusterUuid: string, filterBy: ITrainingFilterBy = {}): Promise<Array<ITrainingList>> {
  const filters: ITrainingFilterBy = pick(filterBy, "sortBy", "page", "rowsPerPage", "sortDirection", "name", "id");
  let trainings: Array<ITrainingList> = await controlPlaneService
    .get(`${trainingsEndpoint(clusterUuid)}`, filters)
    .then((res) => res.entries);
  const prometheusQueries: Record<string, string> = createPrometheusQueries(clusterUuid);
  const promData: Array<IPrometheusResponse> = await prometheusService.multipleQueries(prometheusQueries);
  trainings = _preparePromData(trainings, promData);
  trainings = _updateTrainingsByLocalStatuses(trainings);

  if (!filterBy || !filterBy.displayedColumns) return trainings;

  if (filterBy.searchTerm) {
    trainings = filterService.filterBySearchTerm<ITrainingList>(
      trainings,
      filterBy.searchTerm,
      filterBy.displayedColumns,
      allTrainingColumns,
    );
  }
  if (filterBy.columnFilters && filterBy.columnFilters.length) {
    trainings = filterService.filterByColumns(trainings, filterBy.columnFilters, allTrainingColumns);
  }
  return trainings;
}

async function remove(training: ITrainingList): Promise<ITrainingList> {
  if (!training.job?.project) throw new Error("Can't delete training. Job is missing.");
  const res: IRemoveTrainingResponse = await researcherService.deleteJob(training.meta.name, training.job.project);
  const errorMessage: string | undefined = _handleActionResult(res.data);
  if (errorMessage) throw new Error(errorMessage);
  _updateLocalStatues(training, EWorkloadStatus.Deleting);

  training.job.status = EWorkloadStatus.Deleting;
  training.job.lastStatusUpdateTime = 0;
  training.job.msSinceLastStatusUpdate = 0;
  return training;
}

async function getById(trainingId: string): Promise<Training> {
  return controlPlaneService.get(`${endpoint}/${trainingId}`);
}

async function getByIdFromCluster(clusterUuid: string, trainingId: string): Promise<ITrainingList> {
  let training: ITrainingList = await controlPlaneService.get(`${trainingsEndpoint(clusterUuid)}/${trainingId}`);
  training = _updateTrainingStatus(training);
  return training;
}

async function createFromAssets(training: WorkloadCreationRequest): Promise<Training> {
  const createdTraining: Training = await controlPlaneService.post(endpoint, training);
  try {
    const workloadCreate: IWorkloadCreate = createdTraining.workload as IWorkloadCreate;
    const workloadType = training.distributed ? "distributed" : "training";
    await createWorkload(workloadCreate, workloadType);
  } catch (e) {
    // submit failure status
    await workloadService.handleFailedWorkloadClusterCreation(createdTraining.meta.id, e);
    await _submitTrainingCreationStatus(training.clusterId, createdTraining.meta.id, false);
    throw e;
  }
  // submit success status
  await _submitTrainingCreationStatus(training.clusterId, createdTraining.meta.id, true);
  return createdTraining;
}

async function activate(training: ITrainingList) {
  if (!training.job?.project) throw new Error("Can't active training. Job is missing.");
  const res = await researcherService.activateWorkload(training.meta.name, training.job.project);
  const errorMessage: string | undefined = _handleActionResult(res.data);
  if (errorMessage) throw new Error(errorMessage);
  _updateLocalStatues(training, EWorkloadStatus.Activating);
}

async function stop(training: ITrainingList): Promise<void> {
  if (!training.job?.project) throw new Error("Can't stop training. Job is missing.");
  const res = await researcherService.stopWorkload(training.meta.name, training.job.project);
  const errorMessage: string | undefined = _handleActionResult(res.data);
  if (errorMessage) throw new Error(errorMessage);
  _updateLocalStatues(training, EWorkloadStatus.Stopping);
}

async function getPolicy(projectId: number, scope: Scope): Promise<TrainingPolicy> {
  return await controlPlaneService.get(`${API.v1}/policy/training`, { scope, projectId });
}

async function _submitTrainingCreationStatus(clusterUid: string, trainingId: string, status: boolean): Promise<void> {
  await controlPlaneService.put(`${trainingsEndpoint(clusterUid)}/${trainingId}/submission`, { success: status });
}

async function createWorkload(workloadCreate: IWorkloadCreate, workloadType: string): Promise<IWorkloadResponse> {
  const workloadRoute = workloadType === "training" ? "TrainingWorkload" : "DistributedWorkload";
  const spec: IWorkloadSpec = {
    ...workloadCreate.spec,
    name: { value: workloadCreate.metadata.name },
  };

  let masterSpec: IWorkloadSpec | undefined;
  if (workloadCreate.masterSpec) {
    masterSpec = workloadCreate.masterSpec;
  }

  return workloadService.createWorkload(workloadRoute, workloadCreate.metadata, spec, masterSpec);
}

function createPrometheusQueries(clusterUuid: string): Record<string, string> {
  const clusterFilter: string = clusterUuid ? `{clusterId="${clusterUuid}"}` : "";

  return {
    gpusUtilization: `(avg(runai_gpu_utilization_per_pod_per_gpu${clusterFilter}) by(pod_group_uuid)) or (sum by (pod_group_uuid) (runai_pod_group_gpu_utilization${clusterFilter})
    / on (pod_group_uuid)
    (count(runai_pod_group_gpu_utilization${clusterFilter}) by (pod_group_uuid)
    or
    sum(runai_utilization_shared_gpu_jobs${clusterFilter}) by (pod_group_uuid)))`,
    usedCPUs: `runai_job_cpu_usage${clusterFilter}`,
    usedMemory: `runai_job_memory_used_bytes${clusterFilter}`,
    usedGpuMemory: `(sum(runai_gpu_memory_used_mebibytes_per_pod_per_gpu${clusterFilter} * 1024 * 1024) by (pod_group_uuid, job_uuid)) or (sum(runai_pod_group_used_gpu_memory${clusterFilter} * 1024 * 1024) by (pod_group_uuid, job_uuid))`, // this query returns value in Bytes. (without the multiplication its MiB)
    swapCPUMemory: `runai_pod_group_swap_memory_used_bytes${clusterFilter}`,
  };
}

function _handleActionResult(statuses: Array<IClusterResponse>): string | undefined {
  return statuses[0]?.error ? statuses[0].error.details : undefined;
}

function _updateLocalStatues(training: ITrainingList, loadingStatus: EWorkloadStatus): void {
  const currentStatus: Record<string, ILocalStatus> = _getTrainingStatus();
  _saveTrainingStatus({
    ...currentStatus,
    [training.meta.id]: {
      oldStatus: training.job?.status || "",
      loadingStatus,
      creationTimestamp: Date.now(),
    },
  });
}

function _updateTrainingsByLocalStatuses(trainings: Array<ITrainingList>): Array<ITrainingList> {
  const currentStatus: Record<string, ILocalStatus> = _getTrainingStatus();
  if (!currentStatus) return trainings;

  return trainings.map((training: ITrainingList) => _updateTrainingStatus(training));
}

function _updateTrainingStatus(training: ITrainingList): ITrainingList {
  const currentStatus: Record<string, ILocalStatus> = _getTrainingStatus();
  if (!training.job || !currentStatus) return training;

  if (training.job.status === EWorkloadStatus.Submitted) {
    training.job.status = EWorkloadStatus.Creating;
  }

  const trainingId: string = training.meta.id;
  const trainingToCheck: ILocalStatus | undefined = currentStatus[trainingId];
  if (!trainingToCheck) return training;

  if (_shouldRemoveLocalStatus(trainingToCheck, training)) {
    delete currentStatus[trainingId];
    _saveTrainingStatus(currentStatus);
  } else if (trainingToCheck.loadingStatus) {
    training.job.status = trainingToCheck.loadingStatus;
  }
  return training;
}

function _cleanupStatus() {
  const currentStatus: Record<string, ILocalStatus> = _getTrainingStatus();
  if (!currentStatus || Object.keys(currentStatus).length === 0) return;
  Object.keys(currentStatus).forEach((key: string) => {
    if (_shouldCleanLocalStatus(currentStatus[key])) {
      delete currentStatus[key];
    }
  });
  _saveTrainingStatus(currentStatus);
}

// this should be called only once in a while,
// because it's a heavy operation and not needed to be called on every status update
_cleanupStatus();

function _shouldRemoveLocalStatus(trainingToCheck: ILocalStatus, training: ITrainingList): boolean {
  return (
    trainingToCheck.oldStatus !== training.job?.status || dateUtil.moreThanHourFromNow(trainingToCheck.creationTimestamp)
  );
}

function _shouldCleanLocalStatus(trainingToCheck: ILocalStatus): boolean {
  return dateUtil.moreThanHourFromNow(trainingToCheck.creationTimestamp);
}

function _preparePromData(
  apiData: Array<ITrainingList>,
  queryResponses: Array<IPrometheusResponse>,
): Array<ITrainingList> {
  const promDataByPodId: Record<string, IExtendJobValues> = {};
  queryResponses.forEach((currResponse: IPrometheusResponse) => {
    currResponse.data.forEach(({ metric, value }: IPrometheusMetric) => {
      promDataByPodId[metric.pod_group_uuid] ??= {};
      promDataByPodId[metric.pod_group_uuid] = {
        ...promDataByPodId[metric.pod_group_uuid],
        [currResponse.key]: value[1],
      };
    });
  });

  return apiData.map((training: ITrainingList) => {
    if (!training.job?.podGroupId || !promDataByPodId[training.job?.podGroupId]) return training;
    return { ...training, extendJobValues: { ...promDataByPodId[training.job.podGroupId] } };
  });
}

function _getTrainingStatus(): Record<string, ILocalStatus> {
  return storageUtil.get<Record<string, ILocalStatus>>(TRAINING_STATUS);
}

function _saveTrainingStatus(trainingStatus: Record<string, ILocalStatus>): void {
  storageUtil.save<Record<string, ILocalStatus>>(TRAINING_STATUS, trainingStatus);
}
