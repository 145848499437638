<template>
  <section class="runai-delete-modal">
    <runai-base-dialog :model-value="true" @close="onCancel" size="width-md" :no-padding="true">
      <template #header>Delete</template>

      <template #body>
        <div class="delete-cluster-body q-px-lg q-py-md column">
          <p class="delete-modal-msg q-mt-md">
            {{ title }}
          </p>
          <div class="delete-modal-info q-mt-lg q-mb-xl">
            <slot name="description"> {{ description }} </slot>
          </div>
        </div>
      </template>

      <template #footer-left> </template>
      <template #footer>
        <q-btn label="Cancel" color="primary" flat @click="onCancel" aid="delete-modal-cancel-button" />
        <q-btn label="Delete" color="primary" :loading="deleting" @click="onDelete" aid="delete-modal-delete-button" />
      </template>
    </runai-base-dialog>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "@vue/runtime-core";
import { getEntityWithArticle } from "@/utils/string.util/string.util";
// Components
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";

const enum Events {
  cancel = "cancel",
  delete = "delete",
}
export default defineComponent({
  components: {
    RunaiBaseDialog,
  },
  emits: [Events.cancel, Events.delete],
  props: {
    entityName: {
      type: String as PropType<string>,
      required: false,
    },
    entityType: {
      type: String as PropType<string>,
      default: "",
    },
    customDescription: {
      type: String as PropType<string>,
      default: null,
    },
    customTitle: {
      type: String as PropType<string>,
      default: null,
    },
    deleting: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    title(): string {
      return this.customTitle ? this.customTitle : `Permanently delete ${this.entityType}: ${this.entityName}?`;
    },
    description(): string {
      return this.customDescription
        ? this.customDescription
        : `Once ${getEntityWithArticle(this.entityType)} is deleted, it cannot be restored`;
    },
  },
  methods: {
    onDelete(): void {
      this.$emit(Events.delete);
    },
    onCancel(): void {
      this.$emit(Events.cancel);
    },
  },
});
</script>

<style lang="scss" scoped>
.runai-delete-modal {
  .delete-modal-msg {
    font-size: 16px;
  }
  .delete-modal-info {
    font-size: 14px;
  }
}
</style>
