<template>
  <stat-widget-wrapper :options="options" @link-clicked="redirectToNodes" :loading="displayLoading" :error="error">
    <template #data> {{ allocatedGpu }}</template>
  </stat-widget-wrapper>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
//routes
import { workloadService } from "@/services/cluster/workload.service/workload.service";
//store
import { useClusterStore } from "@/stores/cluster.store";
//models
import { EWidgetEntity, type IWidgetWrapperOptions } from "@/models/chart.model";
import { HttpErrorResponse } from "@/models/http-response.model";
import { WorkloadTelemetryType } from "@/swagger-models/workloads-service-client";
//cmps
import { StatWidgetWrapper } from "@/components/dashboard-v2/widgets/common/widget-wrapper/stat-widget-wrapper";
import { NODE_ROUTE_NAMES } from "@/router/node.routes";
import type { TelemetryResponse } from "@/swagger-models/cluster-service-client";
import { EWorkloadTelemetryGroupBy } from "@/models/workload.model";
import { widgetUtil } from "@/utils/widget.util";
import type { IFilterModel } from "@/models/filter.model";

export default defineComponent({
  name: "allocated-gpu-widget",
  components: { StatWidgetWrapper },
  props: {
    filterBy: {
      type: Array as PropType<IFilterModel[]>,
      required: false,
    },
  },
  data() {
    return {
      options: {
        timeFrame: "Now",
        linkText: "All nodes",
        title: "Allocated GPU compute",
        entityType: EWidgetEntity.Node,
      } as IWidgetWrapperOptions,
      allocatedGpu: 0 as number,
      displayLoading: true as boolean,
      error: false as boolean,
    };
  },
  created() {
    this.getGpuAllocationTelemetry();
  },
  computed: {
    clusterId(): string {
      return useClusterStore().currentClusterId;
    },
    nodePoolFilter(): string | undefined {
      const nodePoolFilter = this.filterBy?.find((filter: IFilterModel) => filter.field === "nodepool");
      if (nodePoolFilter) {
        return nodePoolFilter.term;
      }
      return undefined;
    },
  },
  methods: {
    async getGpuAllocationTelemetry(): Promise<void> {
      try {
        this.error = false;
        const response: TelemetryResponse = await workloadService.getWorkloadsTelemetry(
          WorkloadTelemetryType.GpuAllocation,
          this.clusterId,
          this.nodePoolFilter,
          undefined,
          [EWorkloadTelemetryGroupBy.ClusterId],
        );
        this.allocatedGpu = widgetUtil.sumAllTelemetryValues(response.values);
      } catch (error: unknown) {
        this.handleError(error);
      } finally {
        this.displayLoading = false;
      }
    },
    handleError(error: unknown): void {
      this.error = true;
      if (error instanceof HttpErrorResponse) {
        console.error(error.message);
      } else {
        console.error("Error fetching data", error);
      }
    },
    redirectToNodes(): void {
      this.$router.push({ name: NODE_ROUTE_NAMES.NODE_INDEX });
    },
  },
  watch: {
    filterBy: {
      async handler(): Promise<void> {
        this.displayLoading = true;
        await this.getGpuAllocationTelemetry();
      },
      deep: true,
    },
  },
});
</script>

<style scoped lang="scss"></style>
