<template>
  <q-menu fit>
    <q-list>
      <q-item
        clickable
        v-close-popup
        v-for="opt in options"
        :key="opt.title"
        @click="$emit('data-source-selected', opt.title)"
        :aid="opt.title + '-btn'"
      >
        <q-item-section class="option">
          <runai-svg-icon v-if="opt.icon" :name="opt.icon" size="25px" />{{ opt.title }}
        </q-item-section>
      </q-item>
    </q-list>
  </q-menu>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import { DATA_SOURCES_TYPES_TO_NAMES } from "@/models/data-source.model";
import { DATA_SOURCE_ICONS_NAMES } from "@/common/icons.constant";
import { AssetKind } from "@/swagger-models/assets-service-client";

interface IDataSourceOption {
  id: AssetKind;
  title: string;
  icon: string;
}

export default defineComponent({
  components: {
    RunaiSvgIcon,
  },
  props: {
    allowedOptions: {
      type: Object as PropType<Set<AssetKind>>,
      required: false,
      default: () => new Set(),
    },
  },
  data() {
    return {
      allOptions: [
        {
          id: AssetKind.Nfs,
          title: DATA_SOURCES_TYPES_TO_NAMES.NFS,
          icon: DATA_SOURCE_ICONS_NAMES.nfs,
        },
        {
          id: AssetKind.Pvc,
          title: DATA_SOURCES_TYPES_TO_NAMES.PVC,
          icon: DATA_SOURCE_ICONS_NAMES.pvc,
        },
        {
          id: AssetKind.S3,
          title: DATA_SOURCES_TYPES_TO_NAMES.S3,
          icon: DATA_SOURCE_ICONS_NAMES.s3,
        },
        {
          id: AssetKind.Git,
          title: DATA_SOURCES_TYPES_TO_NAMES.GIT,
          icon: DATA_SOURCE_ICONS_NAMES.git,
        },
        {
          id: AssetKind.HostPath,
          title: DATA_SOURCES_TYPES_TO_NAMES.HOST_PATH,
          icon: DATA_SOURCE_ICONS_NAMES.hostPath,
        },
        {
          id: AssetKind.ConfigMap,
          title: DATA_SOURCES_TYPES_TO_NAMES.CONFIG_MAP,
          icon: DATA_SOURCE_ICONS_NAMES["config-map"],
        },
      ] as Array<IDataSourceOption>,

      options: [] as Array<IDataSourceOption>,
    };
  },
  created() {
    this.options =
      this.allowedOptions.size > 0
        ? this.allOptions.filter((dataSourceOption: IDataSourceOption) => this.allowedOptions.has(dataSourceOption.id))
        : this.allOptions;
  },
});
</script>

<style lang="scss" scoped>
.option {
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
}
</style>
