export const WORKLOADS_TOGGLE = "workloads-toggle";
export const WORKLOAD_INTRO_MODAL_SHOWN = "workload-intro-has-been-shown";
export const OVERVIEW_INTRO_MODAL_SHOWN = "overview-intro-has-been-shown";
export const IS_OVERVIEW_V2_MODE_ON = "is-overview-v2-mode-on";
export const ACCESS_TOKEN = "access-token";
export const REFRESH_TOKEN = "refresh-token";
export const EXTERNAL_TOKEN = "external-token";
export const USE_EXTERNAL_TOKEN = "use-external-token";
export const OVERVIEW_FILTERS = "overview-filters";
export const OVERVIEW_DATE_FILTERS = "overview-date-filters";
export const NEW_DEPLOYMENTS_PAGE = "new-deployments-page";
