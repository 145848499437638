// services
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { workloadService } from "@/services/cluster/workload.service/workload.service";
import { researcherService } from "@/services/cluster/researcher.service/researcher.service";

// models
import type { IDeploymentList } from "@/models/deployment.model";
import type { IWorkloadCreate, IWorkloadResponse } from "@/models/workload.model";
import type { WorkloadCreationRequest, Inference } from "@/swagger-models/assets-service-client";

// constants
import { API } from "@/common/api.constant";
export interface IDeploymentListTemp extends Omit<IDeploymentList, "connections"> {
  connections?: Array<string>;
}

export const deploymentService = {
  create,
  getById,
  remove,
};

const endpoint = `${API.v1}/inference`;

async function create(inference: WorkloadCreationRequest): Promise<Inference> {
  const createdInference: Inference = await controlPlaneService.post(endpoint, inference);
  try {
    const workloadCreate: IWorkloadCreate = createdInference.workload as IWorkloadCreate;
    await _createWorkload(workloadCreate);
    return createdInference;
  } catch (e: unknown) {
    await workloadService.handleFailedWorkloadClusterCreation(createdInference.meta.id, e);
    console.error(e);
    throw new Error("Failed to create workload");
  }
}

async function getById(inferenceId: string): Promise<Inference> {
  return await controlPlaneService.get(`${endpoint}/${inferenceId}`);
}

async function remove(deploymentName: string, namespace: string): Promise<void> {
  await researcherService.deleteWorkload(deploymentName, namespace);
}

async function _createWorkload(workloadCreate: IWorkloadCreate): Promise<IWorkloadResponse> {
  return workloadService.createWorkload("InferenceWorkload", workloadCreate.metadata, {
    ...workloadCreate.spec,
    name: { value: workloadCreate.metadata.name },
  });
}
