export interface IProjectOptsModel {
  action: string;
  departmentId?: number;
}

export interface ISelectDepartment {
  label: string;
  value?: number;
}

export enum EDeleteOrPass {
  Delete = "delete",
  Pass = "pass",
}
