<template>
  <q-card class="chart-widget-wrapper" :bordered="!hideBorder" :flat="hideBorder">
    <div class="chart-widget-header row items-center q-py-xs q-pl-md">
      <div class="q-mr-auto text-black-70" :aid="aidForTitle">
        {{ options.title }}
      </div>
      <widget-link-btn
        class="q-mr-lg"
        v-if="options.linkText"
        :link-text="options.linkText"
        :entity-type="options.entityType"
        @click="$emit('link-clicked')"
      />

      <div v-if="options.timeFrame" class="timeframe-container text-black-54 q-mr-xs flex">
        <q-icon name="fa-regular fa-calendar-clock q-mr-sm items-center text-black-30" size="xs" class="dash-icon" />
        <span class="text-black-54">{{ options.timeFrame }}</span>
      </div>
      <runai-actions-menu v-if="!hideActions" round hide-label :action-items="actionMenuItems" />
    </div>
    <q-separator v-if="!hideSeparator" />
    <q-card-section class="col flex-1 q-px-sm" :class="{ 'no-padding-top-and-bottom': bodyNoPadding }">
      <div class="loading-section" v-if="loading">
        <q-skeleton height="230px" square></q-skeleton>
      </div>
      <div v-else-if="error" class="centered-container error-section">Data is not available right now</div>
      <div v-else-if="empty" class="centered-container empty-section">To view data, first create workloads</div>
      <template v-else>
        <div class="breadcrumbs-container" v-if="breadcrumbs">
          <transition name="fade">
            <chart-breadcrumbs :breadcrumbs="breadcrumbs" @breadcrumb-clicked="$emit('breadcrumb-clicked', $event)" />
          </transition>
        </div>
        <div class="q-pt-md" style="width: 100%">
          <slot></slot>
        </div>
      </template>
    </q-card-section>
  </q-card>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
//model
import type { IWidgetWrapperOptions } from "@/models/chart.model";
//cmps
import { RunaiActionsMenu } from "@/components/common/runai-page-actions/runai-actions-menu";
import { ChartBreadcrumbs } from "@/components/dashboard-v2/widgets/common/chart-breadcrumbs";
import { WidgetLinkBtn } from "@/components/dashboard-v2/widgets/common/buttons/widget-link-btn";

export default defineComponent({
  name: "chart-widget-wrapper",
  components: { WidgetLinkBtn, ChartBreadcrumbs, RunaiActionsMenu },
  emits: ["export-csv", "link-clicked", "breadcrumb-clicked"],
  props: {
    options: {
      type: Object as PropType<IWidgetWrapperOptions>,
      required: true,
    },
    breadcrumbs: {
      type: Array as PropType<string[]>,
      required: false,
    },
    bodyNoPadding: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    hideActions: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    hideBorder: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    error: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    empty: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    hideSeparator: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      actionMenuItems: [
        {
          label: "Download as CSV",
          action: () => this.$emit("export-csv"),
        },
      ],
    };
  },
  computed: {
    aidForTitle(): string {
      return this.options.title.toLowerCase().trim().replace(/ /g, "-");
    },
  },
});
</script>

<style scoped lang="scss">
.chart-widget-wrapper {
  height: 320px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.breadcrumbs-container {
  position: absolute;
  top: 12px;
  z-index: 2;
}

.centered-container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.loading-section {
  width: 100%;
}

.no-padding-top-and-bottom {
  padding: 0 16px;
  display: flex;
  align-items: center;
}

.timeframe-container:last-child {
  margin-right: 20px;
}
</style>
