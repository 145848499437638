<template>
  <q-card class="stat-box-wrapper">
    <q-card-section class="stat-box-section">
      <div class="row wrap">
        <div class="q-mr-auto text-black-70 w-sm-100" :aid="aidForTitle">{{ options.title }}</div>
        <div class="row w-sm-100 mt-sm">
          <q-icon name="fa-regular fa-calendar-clock q-mr-sm text-black-30" size="xs" class="dash-icon" />
          <span class="text-black-54">{{ options.timeFrame }}</span>
        </div>
      </div>

      <div v-if="loading">
        <q-skeleton height="40px" square></q-skeleton>
      </div>
      <div v-else-if="error" class="error-section">Data is not available right now</div>
      <div v-else class="row">
        <div class="q-mr-auto data w-sm-100 mt-sm stat-data"><slot name="data"></slot></div>
        <widget-link-btn
          class="widget-link-btn"
          v-if="options.linkText"
          :link-text="options.linkText"
          :entity-type="options.entityType"
          @click="$emit('link-clicked')"
        />
      </div>
    </q-card-section>
  </q-card>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
//store
import { useClusterStore } from "@/stores/cluster.store";
//model
import type { IWidgetWrapperOptions } from "@/models/chart.model";
//cmps
import { WidgetLinkBtn } from "@/components/dashboard-v2/widgets/common/buttons/widget-link-btn";

export default defineComponent({
  name: "stat-widget-wrapper",
  components: { WidgetLinkBtn },
  emits: ["link-clicked"],
  props: {
    options: {
      type: Object as PropType<IWidgetWrapperOptions>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    error: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
  },
  computed: {
    clusterId(): string {
      return useClusterStore().currentClusterId;
    },
    aidForTitle(): string {
      return this.options.title.toLowerCase().trim().replace(/ /g, "-");
    },
  },
});
</script>
<style scoped lang="scss">
.data {
  font-size: 30px;
  font-weight: 500;
}

.stat-box-wrapper {
  height: 90px; /* Default height */
}

@media screen and (max-width: 1335px) {
  .stat-box-wrapper {
    height: 151px; /* Height for screens less than 1500px wide */
  }

  .stat-box-section {
    padding: 12px;
  }
  .w-sm-100 {
    width: 100%;
  }
  .mt-sm {
    margin-top: 7px;
  }
}
@media screen and (max-width: 985px) {
  .stat-box-wrapper {
    height: 141px;
    font-size: 12px;
  }

  .stat-box-section {
    padding: 10px;
  }
}

@media screen and (max-width: 860px) {
  .stat-box-section {
    padding: 8px;
  }
}

.error-section {
  height: 40px;
  color: $black-54;
  margin-top: 13px;
}
</style>
