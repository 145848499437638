<template>
  <runai-base-popover @hide="onHide" :no-padding="true" size="width-sm" ref="popover" max-height="50vh" :cover="cover">
    <template #header> {{ title }} </template>
    <template #body>
      <div class="row">
        <q-input
          class="col-12"
          v-model="searchTerm"
          :placeholder="placeholderSearch"
          :input-style="{ paddingInline: '16px' }"
        >
          <template v-slot:append>
            <q-icon name="fa-regular fa-magnifying-glass" size="xs" class="q-mr-md" />
          </template>
        </q-input>
      </div>
      <q-separator />
      <div class="popover-body">
        <runai-org-tree
          :selected="selected"
          :readonly="readonly"
          @update:selected="onUpdatedSelected"
          :search-term="searchTerm"
          :no-results-label="noResultsLabel"
          :hide-other-clusters="hideOtherClusters"
          :allowed-scopes="allowedScopes"
          :scopes-supported-versions="scopesSupportedVersions"
          :disable-tenant="disableTenant"
          :disable-cluster="disableCluster"
          :forced-disabled-scopes="forcedDisabledScopes"
        />
      </div>
    </template>
    <template #footer>
      <section>
        <q-btn
          aid="org-tree-modal-cancel-btn"
          flat
          color="primary"
          label="Cancel"
          class="q-mr-sm"
          @click="hide"
          v-if="!readonly"
        />
        <q-btn
          aid="org-tree-modal-select-btn"
          color="primary"
          :label="selectButtonCaption"
          @click="onSelect"
          :disable="!selectedNode?.id"
        />
      </section>
    </template>
  </runai-base-popover>
</template>

<script lang="ts">
// stores
import { defineComponent, type PropType } from "vue";

// models
import type { IOrgTreeNodeId } from "@/models/org-tree.model";

// cmps
import { RunaiBasePopover } from "@/components/common/runai-base-popover";
import { RunaiOrgTree } from "@/components/common/runai-org-tree";
import type { PermittedScopes, ScopeType } from "@/swagger-models/authorization-client";

// services
type RunaiBasePopoverType = InstanceType<typeof RunaiBasePopover>;

export default defineComponent({
  components: { RunaiOrgTree, RunaiBasePopover },
  emits: ["update:selected", "hide"],
  props: {
    title: {
      type: String as PropType<string>,
      default: "Scope",
    },
    selected: {
      type: Object as PropType<IOrgTreeNodeId>,
      default: {} as IOrgTreeNodeId,
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    placeholderSearch: {
      type: String as PropType<string>,
      default: `Search for a cluster, department, or project`,
    },
    noResultsLabel: {
      type: String as PropType<string>,
      default: "No cluster, department, or project match your filters",
    },
    hideOtherClusters: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    cover: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    allowedScopes: {
      type: Object as PropType<PermittedScopes>,
    },
    scopesSupportedVersions: {
      type: Object as PropType<Record<ScopeType, string>>,
      default: () => ({}),
    },
    disableTenant: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disableCluster: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    forcedDisabledScopes: {
      type: Object as PropType<Record<ScopeType, boolean>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedNode: {} as IOrgTreeNodeId,
      searchTerm: "" as string,
    };
  },
  computed: {
    selectButtonCaption(): string {
      return this.readonly ? "Close" : "Apply";
    },
  },
  methods: {
    onSelect(): void {
      this.$emit("update:selected", this.selectedNode);
      this.hide();
    },
    hide(): void {
      const popover = this.$refs.popover as RunaiBasePopoverType;
      popover.hide();
    },
    onHide(): void {
      this.searchTerm = "";
      this.selectedNode = {} as IOrgTreeNodeId;
      this.$emit("hide");
    },
    onUpdatedSelected(selected: IOrgTreeNodeId): void {
      this.selectedNode = selected;
    },
  },
});
</script>

<style lang="scss">
.popover-body {
  max-height: calc(50vh - 184px);
}
</style>
