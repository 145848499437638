<template>
  <section class="inference-assets-based column items-center q-pt-md">
    <workload-default-create-form
      v-if="isPageReady && selectedProject"
      :workload-type="formType"
      :workload="inference"
      :selected-project="selectedProject"
      submit-btn-text="Create Inference"
      :submitting="submitting"
      :workload-sections-options="workloadSectionsOptions"
      @workload-changed="onWorkloadChanged"
      @canceled="onCancel"
      @submit="onSubmit"
      @back-clicked="onBack"
    />
    <model-license-modal
      v-if="displayModelAssetLicense"
      :license="agreementLicense"
      @approved="onUserApprovedLicense"
      @close="displayModelAssetLicense = false"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// components
import { WorkloadDefaultCreateForm } from "@/components/workload/workload-create/workload-default-create-form";
import { ModelLicenseModal } from "@/components/model-spec/model-license-modal";

// models
import { EWorkloadFormType, EWorkloadType, type IUIWorkloadCreation } from "@/models/workload.model";
import type { IProject } from "@/models/project.model";
import type { IAssetsFilter } from "@/models/filter.model";
import type { IWorkloadCreateFormConfig } from "src/components/workload/workload-create/workload-create-form";
import type { IUIWorkloadEnvSectionModel, IWorkloadEnvSectionOptions } from "@/components/section/environment-section";
import type { IWorkloadComputeSectionOptions } from "@/components/section/compute-resource-section";
import {
  Scope,
  AssetKind,
  type EnvironmentAsset,
  type ComputeAsset,
  type AssetIdAndKind,
  type DatasourceListResponseEntry,
  type WorkloadCreationRequest,
  type SpecificRunConnectionInfo,
  type Inference,
  type PVCAsset,
  type ModelAsset,
} from "@/swagger-models/assets-service-client";
import type { Workload } from "@/swagger-models/workloads-service-client";
import type { IUIVolume } from "@/models/data-source.model";
import { SettingKeys } from "@/models/setting.model";

// stores
import { useAppStore } from "@/stores/app.store";
import { useDeploymentStore } from "@/stores/deployment.store";
import { useDataSourceStore } from "@/stores/data-source.store";
import { useEnvironmentStore } from "@/stores/environment.store";
import { useComputeResourceStore } from "@/stores/compute-resource.store";
import { useProjectStore } from "@/stores/project.store";
import { useClusterStore } from "@/stores/cluster.store";
import { useWorkloadStore } from "@/stores/workload.store";
import { useAuthStore } from "@/stores/auth.store";
import { useSettingStore } from "@/stores/setting.store";

// services
import { dataSourceService } from "@/services/control-plane/data-source.service/data-source.service";
import { requestToLeave } from "@/services/infra/router.service/router.service";
import { deploymentService } from "@/services/control-plane/deployment.service/deployment.service";
import { modelAssetService } from "@/services/control-plane/model-asset.service/model-asset.service";
// utils
import { alertUtil } from "@/utils/alert.util";
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { environmentSectionUtil } from "@/components/section/environment-section";
import { ErrorAlert } from "@/utils/error-alert.util";
import { dataSourceUtil } from "@/utils/data-source.util";

// routes
import { DEPLOYMENT_ROUTE_NAMES } from "@/router/deployment.routes/deployment.routes.names";
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes";
import { MODEL_SPEC_NAMES } from "@/router/model-spec.routes/model-spec.routes.names";
import { useModelSpecStore } from "@/stores/model-spec.store";

export default defineComponent({
  components: {
    WorkloadDefaultCreateForm,
    ModelLicenseModal,
  },
  provide() {
    return {
      policy: {},
    };
  },
  data() {
    return {
      modelSpecStore: useModelSpecStore(),
      appStore: useAppStore(),
      projectStore: useProjectStore(),
      deploymentStore: useDeploymentStore(),
      environmentStore: useEnvironmentStore(),
      computeResourceStore: useComputeResourceStore(),
      workloadStore: useWorkloadStore(),
      authStore: useAuthStore(),
      settingStore: useSettingStore(),
      dataSourceStore: useDataSourceStore(),
      clusterStore: useClusterStore(),
      formType: EWorkloadFormType.Inference,
      inference: workloadUtil.getEmptyUIWorkloadCreation() as IUIWorkloadCreation,
      submitting: false as boolean,
      workloadSectionsOptions: {} as IWorkloadCreateFormConfig,
      envSectionOptions: {
        canAddEnvVariable: true,
      } as IWorkloadEnvSectionOptions,
      computeSectionOptions: {
        applyPolicyDefaults: false,
        autoScale: true,
      } as IWorkloadComputeSectionOptions,
      assetsFilter: {} as IAssetsFilter,
      displayModelAssetLicense: false as boolean,
      agreementLicense: "" as string,
    };
  },
  async created() {
    const { kind, fromCopyId, createdEntityId } = this.$route.query;
    this.inference = this.deploymentStore.inference;
    try {
      if (fromCopyId) {
        await this.loadFromExistingDeployment(String(fromCopyId));
      }
      this.assetsFilter = {
        projectId: this.inference.projectId,
        complyToProject: this.inference.projectId,
        complyToWorkloadType: EWorkloadType.Inference,
      };

      await this.loadProjects();

      const createdEnvironmentId: string | undefined =
        kind === AssetKind.Environment ? createdEntityId?.toString() : undefined;
      const createdComputeId: string | undefined = kind === AssetKind.Compute ? createdEntityId?.toString() : undefined;

      const isDataSource = [
        AssetKind.HostPath,
        AssetKind.Nfs,
        AssetKind.Git,
        AssetKind.S3,
        AssetKind.Pvc,
        AssetKind.ConfigMap,
      ].some((assetKind) => assetKind === kind);

      const createdDataSourceId: string | undefined = isDataSource ? createdEntityId?.toString() : undefined;

      await Promise.all([
        this.loadEnvironments(createdEnvironmentId),
        this.loadComputeResources(createdComputeId),
        this.loadDataSources(createdDataSourceId, kind as AssetKind),
      ]);

      this.computeSectionOptions.applyPolicyDefaults = !!fromCopyId;
      this.workloadSectionsOptions = {
        project: {
          projects: this.projects,
        },
        workloadName: {
          sectionDefaultOpen: !!fromCopyId,
        },
        environment: {
          environments: this.environments,
          sectionOptions: this.envSectionOptions,
        },
        compute: {
          computeResources: this.computeResources,
          sectionOptions: this.computeSectionOptions,
        },
        dataSource: {
          dataSources: this.dataSources,
          sectionDefaultOpen: !!createdDataSourceId,
        },
      };

      if (this.settingStore.isFeatureEnabled(SettingKeys.EnableModelCatalog)) {
        this.workloadSectionsOptions.modelSummary = {
          summary: "Standard",
        };
      }

      this.appStore.setPageLoading(false);
    } catch (error: unknown) {
      this.$q.notify(alertUtil.getError("Failed to load assets"));
      console.error(error);
      this.appStore.setFallback(true);
    }
  },
  computed: {
    allowDeployingGatedModelsEnabled(): boolean {
      return this.settingStore.isAllowDeployingGatedModelsEnabled;
    },
    isPageReady(): boolean {
      return !this.appStore.isPageLoading;
    },
    projects(): Array<IProject> {
      return this.projectStore.projectList;
    },
    selectedProject(): IProject | undefined {
      return this.projects.find((project: IProject) => project.id === this.projectId);
    },
    projectId(): number {
      return this.inference.projectId;
    },
    environments(): Array<EnvironmentAsset> {
      return this.environmentStore.environmentList;
    },
    computeResources(): Array<ComputeAsset> {
      return this.computeResourceStore.computeResourcesList;
    },
    dataSources(): Array<DatasourceListResponseEntry> {
      return this.dataSourceStore.dataSourceList;
    },
    clusterUid(): string {
      return this.clusterStore.currentCluster.uuid;
    },
  },
  methods: {
    async loadModels(): Promise<ModelAsset[]> {
      return this.modelSpecStore.loadModels();
    },
    async loadFromExistingDeployment(inferenceId: string): Promise<void> {
      try {
        const originalInference: Inference = await deploymentService.getById(inferenceId);
        let uiVolumes: Array<IUIVolume> | undefined;
        if (originalInference.spec.assets.workloadVolumes?.length) {
          const pvcs: Array<PVCAsset> = await dataSourceService.loadPVCAssets(
            originalInference.spec.assets.workloadVolumes,
          );
          uiVolumes = dataSourceUtil.mapPvcsToUiVolumes(pvcs);
        }
        this.inference = workloadUtil.convertWorkloadToWorkloadUI(originalInference, uiVolumes);
        this.saveInference(this.inference);
      } catch (error: unknown) {
        console.error("failed to get inference with id:", inferenceId, error);
        this.$q.notify(alertUtil.getError("Failed to load inference"));
        this.appStore.setFallback(true);
      }
    },
    async loadEnvironments(createdEnvironmentId: string | undefined): Promise<void> {
      if (!this.selectedProject) return;
      const environmentsFilter = {
        ...this.assetsFilter,
        isInference: true,
      };
      await this.environmentStore.loadEnvironments(environmentsFilter);

      if (createdEnvironmentId) {
        this.setEnvironment(createdEnvironmentId);
      }
    },
    async loadComputeResources(createdComputeResourceId: string | undefined): Promise<void> {
      if (!this.selectedProject) return;
      await this.computeResourceStore.loadComputeResources(this.assetsFilter);

      // set the last created data source as selected
      if (createdComputeResourceId) {
        this.setComputeResource(String(createdComputeResourceId));
      }
    },
    async loadDataSources(createdDataSourceId: string | undefined, kind: AssetKind): Promise<void> {
      if (!this.selectedProject) return;
      await this.dataSourceStore.loadDataSources(this.assetsFilter);

      // set the last created data source as selected
      if (createdDataSourceId && kind) {
        this.setDataSource(createdDataSourceId, kind);
      }
    },
    async loadProjects(): Promise<void> {
      await this.projectStore.loadProjects();
    },
    setEnvironment(environmentId: string): void {
      const environment = this.environments.find((environment) => environment.meta.id === environmentId);
      if (!environment) return;

      const specificEnv: IUIWorkloadEnvSectionModel = environmentSectionUtil.getSpecificEnvFromEnvironment(environment);
      this.inference.assets.environment = environmentId;
      this.inference.specificEnv = { ...this.inference.specificEnv, ...specificEnv };
    },
    setComputeResource(id: string): void {
      this.inference.assets.compute = id;
    },
    setDataSource(id: string, kind: AssetKind): void {
      if (!this.inference.assets.datasources) this.inference.assets.datasources = [];
      const alreadyExist: AssetIdAndKind | undefined = this.inference.assets.datasources.find(
        (datasource: AssetIdAndKind) => datasource.id === id,
      );
      if (!alreadyExist) {
        this.inference.assets.datasources.push({ id, kind });
      }
    },
    async onSubmit(): Promise<void> {
      try {
        this.submitting = true;
        const environmentId: string = this.inference.assets.environment || "";
        const license: string = await this.getImageLicense(environmentId);
        if (license) {
          this.openApprovalLicenseModal(license);
          this.submitting = false;
          return;
        }

        this.submitInference();
      } catch (error: unknown) {
        const errorAlert = new ErrorAlert({
          generalMessage: ErrorAlert.failedCreateMessage("inference"),
        });
        this.$q.notify(errorAlert.getNotification(error));
        this.submitting = false;
      }
    },
    async getImageLicense(environmentId: string): Promise<string> {
      const modelAssetList: Array<ModelAsset> = await modelAssetService.list({ environmentId });
      const modelAsset: ModelAsset | undefined = modelAssetList.find(
        (modelAsset: ModelAsset) => modelAsset.spec.license,
      );

      return modelAsset?.spec.license || "";
    },
    openApprovalLicenseModal(license: string): void {
      this.agreementLicense = license;
      this.displayModelAssetLicense = true;
    },
    onUserApprovedLicense(): void {
      this.agreementLicense = "";
      this.displayModelAssetLicense = false;
      this.submitInference();
    },
    async submitInference(): Promise<void> {
      try {
        this.submitting = true;
        this.inference.clusterId = this.clusterUid;
        this.inference.namespace = this.projectStore.getNamespaceByProjectId(this.inference.projectId);

        this.setPrivateConnections();

        this.saveInference(this.inference);
        let workloadVolumes: Array<string> | undefined;
        if (this.inference.assets.uiVolumes?.length) {
          workloadVolumes = await dataSourceService.createWorkloadVolumes(
            this.inference.name,
            this.inference.assets.uiVolumes,
            {
              scope: Scope.Project,
              projectId: this.projectId,
            },
          );
        }

        const workloadCreationRequest: WorkloadCreationRequest = workloadUtil.getWorkloadCreationRequest(
          this.inference,
          workloadVolumes,
        );

        const createdInference: Inference = await this.deploymentStore.createInference(workloadCreationRequest);
        const workloadAdded: Workload = workloadUtil.convertSpecificWorkloadTypeToWorkload(
          EWorkloadType.Inference,
          createdInference,
          this.selectedProject?.name || "",
        );
        this.workloadStore.setWorkloadAdded(workloadAdded);
        this.$q.notify(alertUtil.getSuccess(`Inference ${this.inference.name} created`));
        this.redirectToPrevRoute();
      } catch (error: unknown) {
        const errorAlert = new ErrorAlert({
          generalMessage: ErrorAlert.failedCreateMessage("inference"),
        });
        this.$q.notify(errorAlert.getNotification(error));
      } finally {
        this.submitting = false;
      }
    },
    saveInference(inference: IUIWorkloadCreation): void {
      this.deploymentStore.setInference(inference);
    },
    async onCancel(): Promise<void> {
      const allowToLeave: boolean = await requestToLeave();
      if (allowToLeave) {
        this.redirectToPrevRoute();
      }
    },
    redirectToPrevRoute(): void {
      if (this.$route.meta.prevRoute === WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX) {
        this.$router.push({ name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX });
      } else {
        this.$router.push({ name: MODEL_SPEC_NAMES.MODEL_SPEC_INDEX });
      }
    },
    onBack(): void {
      this.$router.push({ name: DEPLOYMENT_ROUTE_NAMES.DEPLOYMENT_NEW });
    },
    onWorkloadChanged(inference: IUIWorkloadCreation): void {
      this.inference = inference;
      this.saveInference(inference);
    },
    setPrivateConnections(): void {
      // TODO: the day they change this feature to include more users other than the creator this code becomes useless
      if (!this.inference.specificEnv.connections) return;
      this.inference.specificEnv.connections = this.inference.specificEnv.connections?.map(
        (c: SpecificRunConnectionInfo) => {
          if (!c.authorizedUsers) return c;
          return {
            ...c,
            authorizedUsers: [this.authStore.userEmail],
          };
        },
      );
    },
  },
});
</script>
