<template>
  <div class="settings-box">
    <q-btn
      :label="openerButtonLabel"
      flat
      color="primary"
      @click="isBoxOpen = true"
      v-if="!isBoxOpen && openerButtonLabel"
      :aid="aidOpenerButton"
    />
    <div v-else>
      <div class="settings-box-header row" v-if="hasHeader">
        <div class="col-9 justify-start q-pt-xs">{{ settingBoxMessages.headerTitle }}</div>
        <div class="col-3 row no-wrap q-gutter-sm justify-end" v-if="hasHeaderButtons">
          <q-btn icon="fa-solid fa-pen" class="q-pa-sm icon" flat size="sm" rounded @click="editClicked">
            <q-tooltip>{{ settingBoxMessages.editToolTip }}</q-tooltip>
          </q-btn>
          <q-btn
            :disable="removeDisabled"
            icon="fa-solid fa-xmark"
            class="q-pa-sm icon"
            flat
            size="sm"
            rounded
            @click="isDeleteModalOpen = true"
          >
            <q-tooltip>{{ removeToolTip }}</q-tooltip>
          </q-btn>
        </div>
      </div>
      <div class="settings-box-body row" :class="{ 'settings-box-body-no-footer': !hasFooter }">
        <slot></slot>
      </div>
      <div class="settings-box-footer row" v-if="hasFooter">
        <div
          class="justify-start validation-message"
          :class="{ 'col-9': !isWideFooterButtons, 'col-8': isWideFooterButtons }"
        >
          {{ validationMessage }}
        </div>
        <div
          class="justify-end no-wrap q-gutter-sm"
          :class="{ 'col-3': !isWideFooterButtons, 'col-4': isWideFooterButtons }"
        >
          <q-btn :label="secondaryButtonLabel" flat color="primary" @click="SecondaryButtonClicked" />
          <q-btn :label="primaryButtonLabel" flat color="primary" @click="primaryButtonClicked" />
        </div>
      </div>
    </div>
  </div>
  <setting-delete-modal
    v-if="isDeleteModalOpen && settingBoxMessages"
    :delete-confirm-message="settingBoxMessages.confirmRemoveTitle"
    :delete-confirm-message-subtitle="settingBoxMessages.confirmRemoveSubTitle"
    @cancel="isDeleteModalOpen = false"
    @delete="confirmRemoveClicked"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import SettingDeleteModal from "@/components/settings/setting-delete-modal/setting-delete-modal.vue";
import type { IWrapperBoxSettingMessages } from "@/models/setting.model";
import { replaceAll } from "@/utils/string.util/string.util";

export default defineComponent({
  name: "setting-wrapper-box",
  components: { SettingDeleteModal },
  emits: ["primary-button-clicked", "secondary-button-clicked", "edit-clicked", "removed-clicked"],
  props: {
    settingBoxMessages: {
      type: Object as PropType<IWrapperBoxSettingMessages>,
      required: false,
    },
    openerButtonLabel: {
      type: String as PropType<string>,
    },
    validationMessage: {
      type: String as PropType<string>,
    },
    primaryButtonLabel: {
      type: String as PropType<string>,
      default: "Save",
    },
    secondaryButtonLabel: {
      type: String as PropType<string>,
      default: "Cancel",
    },
    isOpen: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    hasFooter: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    hasHeader: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    hasHeaderButtons: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    removeDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isWideFooterButtons: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      isDeleteModalOpen: false as boolean,
      isBoxOpen: this.isOpen as boolean,
    };
  },
  created() {
    this.isBoxOpen = this.isOpen;
  },
  computed: {
    removeToolTip(): string {
      if (this.removeDisabled) {
        return this.settingBoxMessages?.removeDisable || "";
      }
      return this.settingBoxMessages?.removeToolTip || "";
    },
    aidOpenerButton(): string {
      const aidLabel = replaceAll(replaceAll(this.openerButtonLabel, " ", "-"), "+", "").toLowerCase();
      return `opener-button${aidLabel}`;
    },
  },
  methods: {
    primaryButtonClicked(): void {
      this.$emit("primary-button-clicked");
    },
    SecondaryButtonClicked(): void {
      this.isBoxOpen = false;
      this.$emit("secondary-button-clicked");
    },
    editClicked(): void {
      this.$emit("edit-clicked");
    },
    confirmRemoveClicked(): void {
      this.isDeleteModalOpen = false;
      this.isBoxOpen = false;
      this.$emit("removed-clicked");
    },
  },
});
</script>
<style lang="scss" scoped>
.settings-box {
  .settings-box-body,
  .settings-box-footer,
  .settings-box-header {
    border: 1px solid $black-12;
  }
  .settings-box-body {
    border-bottom: none;
    padding: 20px 20px 20px 20px;
  }
  .settings-box-footer {
    padding: 10px;
  }
  .settings-box-header {
    font-size: 16px;
    padding: 15px 0 15px 18px;
    border-bottom: none;
  }
  .settings-box-body-no-footer {
    border-bottom: 1px solid $black-12;
  }
  .validation-message {
    padding: 10px 0 0 10px;
    font-size: 12px;
    color: $negative;
  }
  .icon {
    color: $black-54;
  }
}
</style>
