import type { IAssignedResources } from "@/models/resource.model";
import type { INodePoolResources } from "./project.model";
import type { NodePoolQuotaStatus } from "@/swagger-models/backend-client";

export const DEFAULT_DEPARTMENT_NAME = "default";
export interface IDepartmentProject {
  name: string;
  id: number;
  deserved_gpus: number;
}

export interface IDepartment {
  id?: number;
  name: string;
  tenantId?: number;
  deservedGpus?: number;
  maxAllowedGpus?: number | null;
  clusterUuid?: string;
  projectsDeservedGpus?: null | number;
  projects?: Array<IDepartmentProject>;
  resources: IAssignedResources;
  nodePoolsResources: INodePoolResources[];
  assignedResourcesId: number;
  createdAt: string;
  roles?: string[];
  allocatedCpu?: number;
  allocatedGpus?: number;
  allocatedMemory?: number;
  quotaStatuses?: Array<NodePoolQuotaStatus>;
}

export interface IRemoveDepartmentAction {
  action: string;
  toDepartmentId: number;
  fromDepartmentId: number;
}

export interface IDepartmentValidation {
  name?: boolean;
  cpu?: boolean;
  gpu?: boolean;
  memory?: boolean;
}

export interface ILoadDepartmentsOptions {
  withMetrics?: boolean;
  withAccessRules?: boolean;
}
