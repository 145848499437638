import { subjectTypeOptionsWithSSO, type AccessRuleWithGroup } from "@/models/access-rule.model";
import type { IFilterBy } from "@/models/filter.model";
import type { ISelectOption } from "@/models/global.model";
import { ECustomCell, type ITableColumn } from "@/models/table.model";
import { AccessRulesSortFilterFields, type AccessRule, SubjectType } from "@/swagger-models/authorization-client";
import { dateUtil } from "@/utils/date.util";

const subjectTypeOptionsMap: Map<string, string> = new Map(
  subjectTypeOptionsWithSSO.map((option: ISelectOption) => [option.value as string, option.label as string]),
);

export const allAccessRulesColumnsMap: Record<string, ITableColumn> = {
  type: {
    name: AccessRulesSortFilterFields.SubjectType,
    label: "Type",
    field: (row: AccessRule) => row.subjectType,
    sortable: true,
    align: "left",
    display: true,
    format: (subjectType: SubjectType) => subjectTypeOptionsMap.get(subjectType) || "-",
  },
  group: {
    name: "group",
    label: "Group",
    field: (row: AccessRuleWithGroup) => row.group,
    sortable: false,
    align: "left",
    display: false,
    format: (group: string) => group || "-",
    hideFilter: true,
  },
  subject: {
    name: AccessRulesSortFilterFields.SubjectId,
    label: "Subject",
    field: (row: AccessRule) => row.subjectId,
    sortable: true,
    align: "left",
    display: true,
  },
  role: {
    name: AccessRulesSortFilterFields.RoleName,
    label: "Role",
    field: (row: AccessRule) => row.roleName,
    sortable: true,
    align: "left",
    display: true,
    customCell: ECustomCell.LINK_COL,
    customCellEvent: { emitName: "role-clicked" },
    hideFilter: false,
  },
  roleWithNoLink: {
    name: AccessRulesSortFilterFields.RoleName,
    label: "Role",
    field: (row: AccessRule) => row.roleName,
    sortable: true,
    align: "left",
    display: false,
    hideFilter: false,
  },
  scope: {
    name: AccessRulesSortFilterFields.ScopeName,
    label: "Scope",
    field: (row: AccessRule) => row.scopeName,
    sortable: true,
    align: "left",
    display: true,
    customCell: ECustomCell.ORG_TREE_COL,
    customCellEvent: { emitName: "scope-clicked" },
  },
  createdBy: {
    name: AccessRulesSortFilterFields.CreatedBy,
    label: "Authorized by",
    field: (row: AccessRule) => row.createdBy,
    sortable: true,
    align: "left",
    display: true,
    format: (createdBy: string) => createdBy || "-",
  },
  creationTime: {
    name: AccessRulesSortFilterFields.CreatedAt,
    label: "Creation time",
    field: (row: AccessRule) => row.createdAt,
    sortable: true,
    align: "left",
    display: true,
    format: (createdAt: string) => createdAt && dateUtil.dateAndTimeFormat(new Date(createdAt)),
  },
  lastUpdated: {
    name: "lastUpdated",
    label: "Last updated",
    field: (row: AccessRule) => row.updatedAt,
    hideFilter: true,
    sortable: false,
    align: "left",
    display: true,
    format: (createdAt: string) => createdAt && dateUtil.dateAndTimeFormat(new Date(createdAt)),
  },
};

export const allAssignmentsColumns: Array<ITableColumn> = [
  allAccessRulesColumnsMap.type,
  allAccessRulesColumnsMap.subject,
  allAccessRulesColumnsMap.role,
  allAccessRulesColumnsMap.roleWithNoLink,
  allAccessRulesColumnsMap.scope,
  allAccessRulesColumnsMap.createdBy,
  allAccessRulesColumnsMap.creationTime,
  allAccessRulesColumnsMap.lastUpdated,
];

export const accessRulesIndexColumns: Array<ITableColumn> = [
  { ...allAccessRulesColumnsMap.type, display: true, mandatory: true },
  { ...allAccessRulesColumnsMap.subject, display: true },
  { ...allAccessRulesColumnsMap.roleWithNoLink, display: true },
  { ...allAccessRulesColumnsMap.scope, display: true },
  { ...allAccessRulesColumnsMap.createdBy, display: true },
  { ...allAccessRulesColumnsMap.creationTime, display: true },
  { ...allAccessRulesColumnsMap.lastUpdated, display: true },
];

export const accessRulesMiniTableColumns: Array<ITableColumn> = [
  { ...allAccessRulesColumnsMap.subject, display: true },
  { ...allAccessRulesColumnsMap.type, display: true },
  { ...allAccessRulesColumnsMap.scope, display: true },
  { ...allAccessRulesColumnsMap.roleWithNoLink, display: true },
  { ...allAccessRulesColumnsMap.createdBy, display: true },
  { ...allAccessRulesColumnsMap.lastUpdated, display: true },
];

export const roleAccessRulesMiniTableColumns: Array<ITableColumn> = [
  { ...allAccessRulesColumnsMap.subject, display: true },
  { ...allAccessRulesColumnsMap.type, display: true },
  { ...allAccessRulesColumnsMap.scope, display: true },
  { ...allAccessRulesColumnsMap.createdBy, display: true },
  { ...allAccessRulesColumnsMap.lastUpdated, display: true },
];

export const subjectRolesMiniTableColumns: Array<ITableColumn> = [
  { ...allAccessRulesColumnsMap.roleWithNoLink, display: true },
  { ...allAccessRulesColumnsMap.scope, display: true },
  { ...allAccessRulesColumnsMap.createdBy, display: true },
  { ...allAccessRulesColumnsMap.creationTime, display: true },
  { ...allAccessRulesColumnsMap.lastUpdated, display: true },
];

export const subjectRolesMiniTableColumnsWithSSO: Array<ITableColumn> = [
  { ...allAccessRulesColumnsMap.roleWithNoLink, display: true },
  { ...allAccessRulesColumnsMap.scope, display: true },
  { ...allAccessRulesColumnsMap.type, display: true },
  { ...allAccessRulesColumnsMap.group, display: true },
  { ...allAccessRulesColumnsMap.createdBy, display: true },
  { ...allAccessRulesColumnsMap.creationTime, display: true },
  { ...allAccessRulesColumnsMap.lastUpdated, display: true },
];

// TODO: @Barak NOT USED?
export const defaultAccessRulesFilterBy: IFilterBy = {
  sortBy: "type",
  descending: false,
  page: 1,
  rowsPerPage: 20,
  columnFilters: [],
  searchTerm: "",
  displayedColumns: [
    allAccessRulesColumnsMap.type.name,
    allAccessRulesColumnsMap.subject.name,
    allAccessRulesColumnsMap.role.name,
    allAccessRulesColumnsMap.scope.name,
    allAccessRulesColumnsMap.createdBy.name,
    allAccessRulesColumnsMap.creationTime.name,
    allAccessRulesColumnsMap.lastUpdated.name,
  ],
};
