import type { Setting } from "@/swagger-models/backend-client";
import type { Mappers } from "@/swagger-models/identity-manager-client";

export const enum SettingKeys {
  DashboardShowOld = "dashboard.show_old",
  DashboardOptimization = "dashboard.optimized_analytics_queries",
  DepartmentsUse = "departments.use",
  ProjectPermissionUse = "project.permissions.use",
  ProjectManualOverQuota = "project.quota.manual_over_quota",
  CPUResourcesQuota = "project.quota.cpu_resources",
  LoginWithSSO = "tenant.login_with_sso",
  AllowSwap = "tenant.allow_swap",
  RegistryIntegration = "tenant.enable_registry_integration",
  AllowDeployingGatedModels = "tenant.allow_deploying_gated_models",
  ConsumptionReport = "tenant.show_consumption_report",
  ResourcesCost = "tenant.resources_cost",
  AdvancedGPUMetrics = "tenant.enable_advanced_gpu_metrics",
  EnableWandbSweeps = "tenant.enable_wandb_sweeps",
  EnableLegacyMpi = "tenant.enable_legacy_mpi",
  WandbHost = "tenant.wandb_host",
  EnableInactivityLogout = "tenant.enable_inactivity_logout",
  InactivityMinutesTillLogout = "tenant.inactivity_minutes_till_logout",
  ExcludeViewerInactivityLogout = "tenant.exclude_viewers_from_inactivity_logout",
  EnableCpuDashboards = "tenant.enable_cpu_dashboards",
  EnableJobSubmitForm = "tenant.enable_job_submit_form",
  LimitQuotaOverSubscription = "tenant.limit_quota_over_subscription",
  LogoutURI = "tenant.logout_uri",
  EnablePolicies = "tenant.enable_policies",
  EnableModelCatalog = "tenant.enable_model_catalog",
  AdminMessage = "tenant.admin_message",
  GpuOverProvisioning = "tenant.gpu_over_provisioning",
  JobsGridEnabled = "tenant.jobs_grid_enabled",
  EnableLegacyDeployment = "tenant.enable_legacy_deployment",
}

export interface IResourcesCost {
  gpu: number | null;
  cpu: number | null;
  memory: number | null;
}

export interface ISecuritySettings {
  timeout: number;
  logoutUrl: string | null;
}

export interface IAdminMessage {
  msg: string;
  id: string;
  createdAt: Date;
  enableDismiss: boolean;
}

export type TSettingValue = boolean | string | number | IResourcesCost | IAdminMessage;

export interface IAdminMessageSetting extends Omit<Setting, "value"> {
  value: IAdminMessage;
}

export interface IAnalyticsSectionSettings {
  cpu: Setting;
  consumption: Setting;
  advancedGpuMetrics: Setting;
  optimizationEnabled: Setting;
}

export interface IWorkloadsSectionSettings {
  policies: Setting;
  models: Setting;
  jobSubmission: Setting;
  jobGrid: Setting;
  wabSweeps: Setting;
  wabHost: string;
  mpiDistribution: Setting;
  dockerRegistry: Setting;
  allowDeployingGatedModels: Setting;
  dockerRegistryData?: IRegistryData;
}

export interface IResourceSectionSettings {
  departments: Setting;
  projectsOverDepartmentQuota: Setting;
  cpuOverQuota: Setting;
  overQuotaPriority: Setting;
  gpuOptimization: Setting;
}

export interface IIdpMapperItem {
  key: string;
  label: string;
  value: string;
}

export const defaultMappers: Mappers = {
  gid: "GID",
  uid: "UID",
  groups: "GROUPS",
  supplementaryGroups: "SUPPLEMENTARYGROUPS",
};

export interface IWrapperBoxSettingMessages {
  headerTitle: string;
  editToolTip: string;
  removeToolTip: string;
  removeDisable: string;
  confirmRemoveTitle: string;
  confirmRemoveSubTitle: string;
}

export interface IRegistryData {
  url: string;
  user: string;
  password: string;
}

export interface SamlConfigSettings {
  idpEntityId: string;
  singleSignOnServiceUrl: string;
  signingCertificate: string;
}
export const settingUpdateSuccessMessage = "Settings saved";

export enum EBadgeLabel {
  Experimental = "Experimental",
  Beta = "Beta",
  Legacy = "Legacy",
}

export const AlphaStage = "Alpha";
