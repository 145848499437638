<template>
  <section class="auto-scale-section">
    <section class="min-max-section">
      <div class="q-my-md">
        Set the minimum and maximum number of replicas for your inference
        <runai-tooltip :tooltip-text="minMaxTooltip" tooltip-position="right" width="450px" />
      </div>
      <div class="min-max-replicas q-my-lg">
        <div class="row">
          <span>
            <q-input
              class="replicas-input"
              type="number"
              :model-value="autoScaleData.minReplicas"
              @update:model-value="updateMin"
              stack-label
              label="Minimum"
              :min="minReplicasAllowed"
              :disable="disabled || isScaleDownToZeroSelected"
              no-error-icon
              ref="minRepInput"
              aid="min-rep-input"
            />
            <q-tooltip v-if="isScaleDownToZeroSelected">
              The minimum number of replicas is 0. This can't be changed when automatic scaling to zero is enabled.
            </q-tooltip>
          </span>

          <span class="col-1 row justify-center items-center q-pt-sm">
            <hr style="width: 10px; display: block" />
          </span>

          <q-input
            class="replicas-input"
            type="number"
            :model-value="autoScaleData.maxReplicas"
            @update:model-value="updateMax"
            stack-label
            label="Maximum"
            :min="1"
            no-error-icon
            :disable="disabled"
            :error-message="maxNumberInvalidErrorMessage"
            :error="isMaxInvalid"
            ref="maxRepInput"
            aid="max-rep-input"
          />
        </div>
      </div>
    </section>

    <div class="dashed-seperator" />

    <q-slide-transition>
      <section class="new-replica-condition-section" v-if="showReplicaCondition">
        <div class="q-my-md">Set the conditions for creating a new replica</div>
        <div class="row new-replica-condition-container">
          <q-select
            aid="threshold-metric-options"
            class="col-6 threshold-metric-options"
            label="Variable"
            option-value="id"
            option-label="name"
            map-options
            :model-value="autoScaleData.thresholdMetric"
            @update:model-value="onThresholdMetricChanged"
            no-error-icon
            :options="thresholdMetricOptions"
            :disable="disabled"
          />
          <q-select
            class="col-2"
            label="Operator"
            :model-value="1"
            option-value="id"
            option-label="name"
            map-options
            :options="[{ id: 1, name: '>' }]"
            no-error-icon
            :disable="true"
            hide-dropdown-icon
          />
          <q-input
            class="col-2"
            type="number"
            :model-value="autoScaleData.thresholdValue"
            @update:model-value="onThresholdValueChanged"
            stack-label
            label="Value"
            min="1"
            :disable="disabled"
            no-error-icon
            :rules="[isValueEmpty, isGreaterThanZero]"
            ref="thresholValueInput"
            aid="threshold-value-input"
          />
        </div>
      </section>
    </q-slide-transition>

    <section class="scale-down-section">
      <div class="q-my-md">
        Set when the replicas should be automatically scaled down to zero
        <runai-tooltip :tooltip-text="scaleDownTooltip" tooltip-position="right" width="315px" />
      </div>
      <div class="row q-my-md justify-between gap-15">
        <q-select
          aid="scale-down-select"
          class="scale-down-select"
          option-label="name"
          :model-value="scaleDownSelected"
          @update:model-value="onScaleDownChanged"
          no-error-icon
          :options="scaleDownOptions"
          :disable="disabled"
        />
        <runai-information-bar v-if="isScaleDownToZeroSelected">
          When automatic scaling to zero is enabled, the minimum number of replicas is 0
        </runai-information-bar>
      </div>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// cmps
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { RunaiInformationBar } from "@/components/common/runai-information-bar";

import { errorMessages } from "@/common/error-message.constant";
import { isMaxEqualOrHeigherThenMin } from "@/common/form.validators";

import { is } from "quasar";

// models
import {
  type IAutoScaleData,
  type IThresholdMetricOption,
  type IScaleDownOption,
  scaleDownOptions,
  thresholdMetricOptions,
} from "./auto-scale-section.models";
import { SpecificRunAutoScalingAutoScalingThresholdMetricEnum } from "@/swagger-models/assets-service-client";

export default defineComponent({
  components: {
    RunaiTooltip,
    RunaiInformationBar,
  },
  emits: ["on-auto-scale-changed"],
  props: {
    autoScaleData: {
      type: Object as PropType<IAutoScaleData>,
      required: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      minMaxTooltip:
        "Set the minimum and maximum number of replicas to be scaled up and down. If there is a difference between the two, you'll need to set conditions for auto-scaling." as string,
      scaleDownTooltip:
        "Compute resources can be freed up when the model is inactive (i.e., there are no requests being sent)" as string,
      maxNumberInvalidErrorMessage: errorMessages.EQUAL_TO_OR_HIGHER_THAN_THE_MINIMUM,
      scaleDownOptions: scaleDownOptions,
      scaleDownSelected: scaleDownOptions[0] as IScaleDownOption,
      thresholdMetricOptions: thresholdMetricOptions,
      minReplicasLastValueTyped: 1 as number,
      thresholdDefaultMetric:
        SpecificRunAutoScalingAutoScalingThresholdMetricEnum.Throughput as SpecificRunAutoScalingAutoScalingThresholdMetricEnum,
      thresholdDefaultValue: 95,
      minimumInput: null as HTMLElement | null,
      maximumInput: null as HTMLElement | null,
      thresholValueInput: null as HTMLElement | null,
    };
  },
  created() {
    if (this.autoScaleData.minReplicas === 0) {
      this.scaleDownSelected = this.scaleDownOptions[1] as IScaleDownOption;
    }
  },
  mounted() {
    // Quasar input is incrementing its value on scroll if the window reaches the bottom of the page, therefore we blur the element whenever the scroll event is fired in order to prevent that
    this.addScrollListeners();
  },
  computed: {
    isMaxInvalid(): boolean {
      return !isMaxEqualOrHeigherThenMin(this.autoScaleData.maxReplicas, this.autoScaleData.minReplicas);
    },
    isScaleDownToZeroSelected(): boolean {
      return this.scaleDownSelected.id === "scaleDownToZero";
    },
    minReplicasAllowed(): number {
      return this.isScaleDownToZeroSelected ? 0 : 1;
    },
    showReplicaCondition(): boolean {
      if (this.autoScaleData.minReplicas == 0 && this.autoScaleData.maxReplicas == 1) {
        return false;
      }
      return this.autoScaleData.maxReplicas > this.autoScaleData.minReplicas;
    },
  },
  methods: {
    updateMin(num: number | string | null): void {
      const minReplicas: number = num && Number(num) > 0 ? Number(num) : 1;

      let thresholdMetric = this.autoScaleData.thresholdMetric;
      let thresholdValue = this.autoScaleData.thresholdValue;
      if (minReplicas >= this.autoScaleData.maxReplicas) {
        thresholdMetric = undefined;
        thresholdValue = undefined;
      } else if (thresholdMetric === undefined && thresholdValue === undefined) {
        thresholdMetric = this.thresholdDefaultMetric;
        thresholdValue = this.thresholdDefaultValue;
      }

      this.updateModel({
        ...this.autoScaleData,
        minReplicas,
        thresholdMetric,
        thresholdValue,
      });
    },
    updateMax(max: number | string | null): void {
      const maxReplicas: number = max ? Number(max) : 1;
      let thresholdMetric = this.autoScaleData.thresholdMetric;
      let thresholdValue = this.autoScaleData.thresholdValue;
      if (this.autoScaleData.minReplicas >= maxReplicas) {
        thresholdMetric = undefined;
        thresholdValue = undefined;
      } else if (thresholdMetric === undefined && thresholdValue === undefined) {
        thresholdMetric = this.thresholdDefaultMetric;
        thresholdValue = this.thresholdDefaultValue;
      }

      this.updateModel({
        ...this.autoScaleData,
        maxReplicas,
        thresholdMetric,
        thresholdValue,
      });
    },
    onScaleDownChanged(option: IScaleDownOption): void {
      this.scaleDownSelected = option;
      if (this.isScaleDownToZeroSelected) {
        this.minReplicasLastValueTyped = this.autoScaleData.minReplicas;
        this.updateModel({
          ...this.autoScaleData,
          minReplicas: 0,
        });
      } else {
        this.updateModel({
          ...this.autoScaleData,
          minReplicas: this.minReplicasLastValueTyped,
        });
      }
    },
    onThresholdMetricChanged(value: IThresholdMetricOption): void {
      this.updateModel({
        ...this.autoScaleData,
        thresholdMetric: value.id,
      });
    },
    onThresholdValueChanged(value: number | string | null): void {
      this.updateModel({
        ...this.autoScaleData,
        thresholdValue: value ? Number(value) : undefined,
      });
    },
    updateModel(autoScaleData: IAutoScaleData): void {
      this.$emit("on-auto-scale-changed", autoScaleData);
    },
    isValueEmpty(val: string): boolean | string {
      return is.number(val) || errorMessages.ENTER_A_VALUE;
    },
    isGreaterThanZero(val: string): boolean | string {
      return (is.number(val) && Number(val) > 0) || errorMessages.VALUE_ABOVE_ZERO;
    },
    blurMinimumInput(): void {
      this.minimumInput?.blur();
    },
    blurMaximumInput(): void {
      this.maximumInput?.blur();
    },
    blurThresholValueInputInput(): void {
      this.thresholValueInput?.blur();
    },
    addScrollListeners(): void {
      this.minimumInput = this.$refs.minRepInput as HTMLElement | null;
      this.maximumInput = this.$refs.maxRepInput as HTMLElement | null;
      window.addEventListener("scroll", this.blurMinimumInput);
      window.addEventListener("scroll", this.blurMaximumInput);
    },
    removeScrollListeners(): void {
      window.removeEventListener("scroll", this.blurMinimumInput);
      window.removeEventListener("scroll", this.blurMaximumInput);

      if (this.thresholValueInput) {
        window.removeEventListener("scroll", this.blurThresholValueInputInput);
      }
    },
  },
  watch: {
    showReplicaCondition(isShow: boolean): void {
      if (isShow) {
        this.thresholValueInput = this.$refs.thresholValueInput as HTMLElement | null;
        window.addEventListener("scroll", this.blurThresholValueInputInput);
      } else {
        window.removeEventListener("scroll", this.blurThresholValueInputInput);
        this.thresholValueInput = null;
      }
    },
  },
  beforeUnmount() {
    // Quasar input is incrementing its value on scroll if the window reaches the bottom of the page, therefore we blur the element whenever the scroll event is fired in order to prevent that
    this.removeScrollListeners();
  },
});
</script>
<style lang="scss" scoped>
.auto-scale-section {
  .replicas-input {
    width: 160px;
  }
  .scale-down-select {
    width: 200px;
  }

  .dashed-seperator {
    border: 1px dashed $black-12;
  }

  .new-replica-condition-section {
    .new-replica-condition-container {
      gap: 45px;
      background-color: $body-background-color;
      padding: 15px;
    }
  }
}
</style>
