<template>
  <div class="mapper-item q-mb-md">
    <div class="row">
      <div class="col-3 label">
        <q-input :placeholder="label" no-error-icon stack-label disable />
      </div>
      <div class="col-1 align-content-center">
        <span><q-icon name="fa-regular fa-arrow-right-long" size="xs" class="q-mr-md arrow" /></span>
      </div>
      <div class="col-4">
        <q-input :model-value="value" @update:model-value="updateValue" no-error-icon stack-label />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "mapper-item",
  emits: ["update-value"],
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    value: {
      type: String as PropType<string>,
      required: true,
    },
  },
  methods: {
    updateValue(value: string): void {
      this.$emit("update-value", value);
    },
  },
});
</script>
<style lang="scss" scoped>
.mapper-item {
  .label {
    cursor: default !important;
  }
  .arrow {
    padding: 20px 0 0 20px;
  }
}
</style>
<style lang="scss">
.mapper-item {
  .q-field__inner {
    cursor: default !important;
  }
}
</style>
