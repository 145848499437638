/* tslint:disable */
/* eslint-disable */
/**
 * Runai API (Control plane v2.17)
 * # Introduction  The Run:AI Control-Plane API reference is a guide that provides an easy-to-use programming interface for adding administrative tasks to your application.  ## API Authentication To use the Run:ai APIs you will need to authenticate against your system. Run:ai uses *bearer tokens* to authenticate your application. Bearer tokens are added to the the request when using the endpoint.  ### Obtaining Bearer Tokens You can obtain a token by: * Creating a new *Application* in the Run:ai user interface, then copy the *secret* field and use it as the token in your request. * Log in to the Run:ai user interface using your Run:ai user or a valid SSO user, then run the `all token operations` endpoint.  * Use the Run:ai command line with a Kubernetes profile and authenticate by running `runai login` or `oc login` with OpenShift. Then, run the the [Authentication API](#).  To call APIs, the application must pass the retrieved `access_token` as a Bearer token in the Authorization header of your HTTP request. 
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from "./configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';

export const BASE_PATH = "https://app.run.ai".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: AxiosRequestConfig;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}
