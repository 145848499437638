import { ResourceType, Action } from "@/swagger-models/authorization-client";

import OldDeploymentIndex from "@/views/deployment/old-deployment/old-deployment-index.vue";
import OldDeploymentNew from "@/views/deployment/old-deployment/old-deployment-new.vue";
import OldDeploymentHistory from "@/views/deployment/old-deployment/old-deployment-history.vue";
import { OLD_DEPLOYMENT_ROUTE_NAMES } from "./old-deployment.routes.names";
import type { RunaiRouteRecord } from "vue-router";

export const oldDeploymentRoutes: RunaiRouteRecord = {
  path: "/deployments",
  component: () => import("@/views/deployment/old-deployment/old-deployment-app.vue"),
  children: [
    {
      path: "",
      name: OLD_DEPLOYMENT_ROUTE_NAMES.DEPLOYMENT_INDEX,
      component: OldDeploymentIndex,
      meta: {
        pageTitle: "Deployments",
        resourceType: ResourceType.Deployments,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
        hideVersion: true,
      },
    },
    {
      path: "new",
      name: OLD_DEPLOYMENT_ROUTE_NAMES.DEPLOYMENT_NEW,
      component: OldDeploymentNew,
      meta: {
        pageTitle: "Deployments",
        resourceType: ResourceType.Deployments,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
        hideVersion: true,
      },
    },
    {
      path: "history",
      name: OLD_DEPLOYMENT_ROUTE_NAMES.DEPLOYMENT_HISTORY,
      component: OldDeploymentHistory,
      meta: {
        pageTitle: "Deployments",
        resourceType: ResourceType.Deployments,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
        hideVersion: true,
      },
    },
  ],
};
